const quotas: Record<string, string> = {
    noName: 'Без имени',
    confirmDeletionQuotaInfoText: 'Вы действительно хотите удалить квоту "{title}"?',
    creating: 'Создание квоты',
    create: 'Создать квоту',
    editing: 'Редактирование квоты',
    quota: 'Квота',
};

export default quotas;
