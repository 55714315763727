import { Box } from '@material-ui/core';
import { ApprovalSheetDTO, ApprovalTab } from '@platform/approval-ui';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { generatePath, Redirect, Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import { entities, permissions } from '../../../authSchemeConfig';
import { clientRoute } from '../../../clientRoute';
import { AuthorizationCheck } from '../../../components';
import { useStore } from '../../../hooks';
import { useObjectRoutesContext } from '../../../hooks';
import { RequestModel } from '../../../models';
import { RequestFormParams } from '../../../types';
import { StyledCircularProgress } from './campaign-request-page-print-forms';
import { CampaignRequestForm } from './CampaignRequestForm';

export type CampaignRequestRoutesProps = {
    locale: string;
    requestModel: RequestModel;
    setFormIsChanged: (formIsChanged: boolean) => void;
    requestNumber: string;
    reloadModelForModuleTab: () => void;
};

export const CampaignRequestRoutes = observer((props: CampaignRequestRoutesProps): JSX.Element => {
    const { requestModel, setFormIsChanged, requestNumber, reloadModelForModuleTab } = props;
    const { id } = useParams<RequestFormParams>();
    const objectRoutes = useObjectRoutesContext();
    const { firstTabId: tabId, firstSubTabId: subTabId } = requestModel;

    const location = useLocation();
    const history = useHistory();

    const { approvalStore, intlStore, personStore } = useStore();
    const {
        loadApprovalSheets,
        sheets,
        isSheetsLoading,
        hasApprovalTab,
        changeApprovalSheetStatus,
        updateApprovalSheet,
        sendApprovalSheetResolution,
        saveApprovalSheetGroups,
        getAllUsers,
    } = approvalStore;

    useEffect(() => {
        loadApprovalSheets(id);
    }, [loadApprovalSheets, id]);

    useEffect(() => {
        if (location.pathname === generatePath(objectRoutes.object, { id }) && tabId) {
            history.replace(generatePath(objectRoutes.objectTab, { id, tabId }));
        }

        return (): void => {
            requestModel.resetFirstTabId();
        };
    }, [location.pathname, tabId, history, id]);

    const firstFormRedirect = tabId && <Redirect to={generatePath(objectRoutes.objectTab, { id, tabId })} />;

    const getApprovalTabOrRedirect = (): JSX.Element | null =>
        hasApprovalTab ? (
            <AuthorizationCheck entityCode={entities.System} permCode={permissions.System.Administration}>
                {(allowed: boolean): JSX.Element => (
                    <ApprovalTab
                        sheets={sheets?.toJS() as ApprovalSheetDTO[]}
                        locale={intlStore.locale}
                        currentUserId={personStore.user.id}
                        isAdmin={allowed}
                        updateApprovalSheet={updateApprovalSheet}
                        saveApprovalSheetGroups={saveApprovalSheetGroups}
                        changeApprovalSheetStatus={changeApprovalSheetStatus(reloadModelForModuleTab)}
                        sendApprovalSheetResolution={sendApprovalSheetResolution(reloadModelForModuleTab)}
                        getAllUsers={getAllUsers}
                    />
                )}
            </AuthorizationCheck>
        ) : (
            firstFormRedirect || null
        );

    const requestSubTabPath =
        tabId &&
        subTabId &&
        generatePath(objectRoutes.objectSubTabEdit, {
            id,
            tabId,
            subTabId,
        });

    const requestTabPath = tabId && generatePath(objectRoutes.objectTabEdit, { id, tabId });

    return (
        <Switch>
            <Route path={objectRoutes.objectEdit} key={objectRoutes.objectEdit} exact>
                {requestSubTabPath ? (
                    <Redirect to={requestSubTabPath} />
                ) : (
                    requestTabPath && <Redirect to={requestTabPath} />
                )}
            </Route>
            <Route path={[objectRoutes.objectSubTab, objectRoutes.objectTab]}>
                {requestNumber && (
                    <CampaignRequestForm
                        key={tabId + id}
                        requestModel={requestModel}
                        setFormIsChanged={setFormIsChanged}
                        requestNumber={requestNumber}
                    />
                )}
            </Route>
            <Route path={objectRoutes.objectApprovalSheets} key={objectRoutes.objectApprovalSheets}>
                {isSheetsLoading ? (
                    <Box px={5}>
                        <StyledCircularProgress />
                    </Box>
                ) : (
                    getApprovalTabOrRedirect()
                )}
            </Route>
        </Switch>
    );
});
