import { Preset, RowsData, TTable, TTableProps, TTableQueryData, TTableRow } from '@platform/ttable';
import { observer } from 'mobx-react';
import React from 'react';
import { useParams } from 'react-router-dom';
import { entities, permissions } from '../../authSchemeConfig';
import { AuthorizationCheck } from '../../components';
import { useStore } from '../../hooks';
import { RequestLogType } from '../../types';
import { RequestLogPageParams } from './RequestLogPage';

const registryCodeTTable = 'logging';

type RequestLogTableProps = {
    requestNumber: string;
};

const requestLogTableVisibilitySettings: TTableProps<RequestLogType>['visibleSettings'] = {
    toolbar: {
        isWithPresets: false,
        isWithAddCustomColumns: false,
    },
    column: {
        isWithDraggableColumns: false,
    },
};

export const RequestLogTable = observer((props: RequestLogTableProps): JSX.Element => {
    const { requestNumber } = props;
    const { requestStore, presetStore, intlStore, catalogStore, userStore } = useStore();
    const { id } = useParams<RequestLogPageParams>();

    const saveListPresets = <RowType extends TTableRow>(settings: Preset<RowType>[]): Promise<void> =>
        presetStore.saveListPresets(settings);

    const registry = (
        registryCode: string,
        query: TTableQueryData<RequestLogType>,
    ): Promise<RowsData<RequestLogType>> => {
        return requestStore.registryRequestLog(registryCode, id, query);
    };

    const upload = (query: TTableQueryData<RequestLogType>): Promise<void> => {
        return requestStore.uploadRequestLog(query, registryCodeTTable, id, requestNumber);
    };

    return (
        <AuthorizationCheck entityCode={entities.System} permCode={permissions.System.Administration}>
            {(allowed: boolean): JSX.Element => (
                <TTable<RequestLogType>
                    registryCode={registryCodeTTable}
                    fetchData={registry}
                    fetchColumns={requestStore.registryRequestLogColumns}
                    fetchCatalog={catalogStore.getCatalog}
                    presetsSetting={presetStore}
                    visibleSettings={requestLogTableVisibilitySettings}
                    fetchSelectDataByUrl={catalogStore.getSelectDataByUrl}
                    upload={upload}
                    lang={intlStore.locale}
                    saveListPresets={saveListPresets}
                    fetchUserRoleList={userStore.userRoleList}
                    isAdmin={allowed}
                />
            )}
        </AuthorizationCheck>
    );
});
