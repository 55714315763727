import { CircularProgress, Container, createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Authentication } from '../../components';
import { useStore } from '../../hooks';
import { MainPageWidgetData } from '../../types';
import { MainPageWidgetCard } from './MainPageWidgetCard';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pageContainer: {
            paddingTop: theme.spacing(4),
        },
        titleContainer: {
            marginBottom: theme.spacing(10),
        },
        errorText: {
            textAlign: 'center',
        },
        widgetCardsContainer: {
            width: `calc(100% + ${theme.spacing(8)}px)`,
            margin: -theme.spacing(4),
        },
        widgetCardGridItem: {
            margin: theme.spacing(4),
            display: 'flex',
        },
    }),
);

export const MainPage = observer((): JSX.Element => {
    const classes = useStyles();
    const { requestStore } = useStore();

    const [widgetsData, setWidgetsData] = useState<MainPageWidgetData[]>([]);
    const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
    const [isLoadError, setLoadError] = useState<boolean>(false);

    useEffect(() => {
        requestStore
            .getMainPageStats()
            .then((data) => setWidgetsData(data.stats))
            .catch(() => setLoadError(true))
            .finally(() => setIsLoadingData(false));
    }, [requestStore]);

    return (
        <Authentication>
            <Container className={classes.pageContainer} maxWidth="lg">
                <Grid container direction="column">
                    <Grid item className={classes.titleContainer}>
                        <Typography variant="h1">
                            <FormattedMessage id="mainPage.title" />
                        </Typography>
                    </Grid>
                    {isLoadingData ? (
                        <Grid item container alignItems="center" justify="center">
                            <Grid item>
                                <CircularProgress />
                            </Grid>
                        </Grid>
                    ) : isLoadError ? (
                        <Grid item className={classes.titleContainer}>
                            <Typography variant="h3" className={classes.errorText}>
                                <FormattedMessage id="mainPage.errorText" />
                            </Typography>
                        </Grid>
                    ) : (
                        <React.Fragment>
                            <Grid
                                className={classes.widgetCardsContainer}
                                item
                                container
                                alignItems="stretch"
                                wrap="wrap"
                            >
                                {widgetsData.map((widgetData, index) => {
                                    const { title, value } = widgetData;
                                    return (
                                        <Grid key={index} item className={classes.widgetCardGridItem}>
                                            <MainPageWidgetCard title={title} value={value} />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </React.Fragment>
                    )}
                </Grid>
            </Container>
        </Authentication>
    );
});
