import { ApprovalSheetGroupsDTO, ApprovalSheetResolution, ApprovalSheetStatus } from '@platform/approval-ui';
import { FullSubmission } from '@platform/formiojs-react';
import { NotificationsQueryData } from '@platform/notification-widget';
import { Preset, TTableQueryData, TTableRow } from '@platform/ttable';
import { AxiosRequestConfig } from 'axios';
import {
    CampaignRequestListFilterData,
    ConfirmEmailDTO,
    EmailDTO,
    LoginDTO,
    NewPasswordDTO,
    RegistrationDTO,
    TableQueryData,
} from './models';
import { ExecutorSettings, RequestFormTabSettingsDTO, RequestRow } from './pages';
import { AuthorizationCheckQuery, UserUpdateBody } from './store';
import { CountryGroup, QuotaCreateDTO } from './types';

class ApiConfigs {
    login: (data: LoginDTO) => AxiosRequestConfig = (data) => {
        return {
            url: '/login',
            method: 'PUT',
            data,
        };
    };

    esiaAuthUrl: AxiosRequestConfig = {
        url: '/authUrl/esia',
        method: 'GET',
    };

    esiaLogin: (search: string) => AxiosRequestConfig = (search) => {
        return {
            url: `/login/esia${search}`,
            method: 'PUT',
        };
    };

    samlAuthUrl: (provider: string, backUrl: string) => AxiosRequestConfig = (provider, backUrl) => {
        const backUrlParam = backUrl ? `?backUrl=${backUrl}` : '';
        return {
            url: `/saml/${provider}/loginUrl${backUrlParam}`,
            method: 'GET',
        };
    };

    logout: AxiosRequestConfig = {
        url: '/logout',
        method: 'PUT',
    };

    resetPassword: (data: NewPasswordDTO) => AxiosRequestConfig = (data) => {
        return {
            url: '/reset-password',
            method: 'PUT',
            data,
        };
    };

    forgotPassword: (data: EmailDTO) => AxiosRequestConfig = (data) => {
        return {
            url: '/forgot-password',
            method: 'PUT',
            data,
        };
    };

    confirmEmail: (data: ConfirmEmailDTO) => AxiosRequestConfig = (data) => {
        return {
            url: '/confirm-email',
            method: 'PUT',
            data,
        };
    };

    register: (data: RegistrationDTO) => AxiosRequestConfig = (data) => {
        return {
            url: '/register',
            method: 'PUT',
            data,
        };
    };

    authorizationCheck: (queries: Readonly<AuthorizationCheckQuery[]>) => AxiosRequestConfig = (queries) => {
        return {
            url: '/check',
            method: 'PUT',
            data: queries,
        };
    };

    person: (lang: string) => AxiosRequestConfig = (lang) => {
        return {
            url: '/person',
            method: 'GET',
            params: {
                lang,
            },
        };
    };

    setLang: (lang: string) => AxiosRequestConfig = (lang) => {
        return {
            url: `/person?lang=${lang}`,
            method: 'PUT',
        };
    };

    userList: (data: TableQueryData) => AxiosRequestConfig = (data) => {
        return {
            url: '/administration/users',
            method: 'PUT',
            data,
        };
    };

    loadUser: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/administration/user/${id}`,
            method: 'GET',
        };
    };

    saveUser: (id: string, submission: FullSubmission) => AxiosRequestConfig = (id, submission) => {
        return {
            url: `/administration/user/${id}`,
            method: 'PUT',
            data: submission,
        };
    };

    userRoleList: AxiosRequestConfig = {
        url: `roles`,
        method: 'GET',
    };

    saveUserHead: (id: string, body: UserUpdateBody) => AxiosRequestConfig = (id, body) => {
        return {
            url: `/administration/user/${id}/update`,
            method: 'PUT',
            data: body,
        };
    };

    blockUser: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/administration/user/${id}/block`,
            method: 'PUT',
        };
    };

    unblockUser: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/administration/user/${id}/unblock`,
            method: 'PUT',
        };
    };

    deleteUser: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/administration/user/${id}`,
            method: 'DELETE',
        };
    };

    loginAsUser: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/login-as/${id}`,
            method: 'PUT',
        };
    };

    createCampaign: (lifecycleCode: string) => AxiosRequestConfig = (lifecycleCode) => {
        return {
            url: '/campaign',
            method: 'POST',
            data: {
                lifecycleCode,
            },
        };
    };

    campaigns: AxiosRequestConfig = {
        url: '/campaigns',
        method: 'GET',
    };

    campaign: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/campaign/${id}`,
            method: 'GET',
        };
    };

    editCampaign: (id: string, data: FullSubmission) => AxiosRequestConfig = (id, data) => {
        return {
            url: `/campaign/${id}`,
            method: 'PUT',
            data,
        };
    };

    campaignForm: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/campaign/${id}/form`,
            method: 'GET',
        };
    };

    campaignFileUrl = (id: string): string => `/campaign/file/${id}`;

    campaignFile: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: this.campaignFileUrl(id),
            method: 'GET',
            responseType: 'blob',
        };
    };

    createRegForm: (campaignId: string) => AxiosRequestConfig & { params?: { campaign: string } } = (campaignId) => {
        return {
            url: '/regForm',
            method: 'POST',
            params: {
                campaign: campaignId,
            },
        };
    };

    editRegForm: (id: string, submission: FullSubmission) => AxiosRequestConfig = (id, submission) => {
        return {
            url: `/regForm/${id}`,
            method: 'PUT',
            data: submission,
        };
    };

    regFormCard: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/regForm/${id}`,
            method: 'GET',
        };
    };

    regForms: (campaignId: string, minimal: boolean, edit?: boolean) => AxiosRequestConfig = (
        campaignId,
        minimal = false,
        edit,
    ) => {
        return {
            url: '/regForms',
            method: 'GET',
            params: {
                campaign: campaignId,
                minimal: minimal,
                edit: edit,
            },
        };
    };

    regFormsByCampaignsForSelect: (campaigns: string[]) => AxiosRequestConfig = (campaigns) => {
        const campaignsQuery = campaigns.map((c) => `campaign=${c}`).join('&');
        return {
            url: `/select/regForms/grouped?${campaignsQuery}`,
            method: 'GET',
        };
    };

    changeRegFormsPosition: (regFormId: string, newPosition: number) => AxiosRequestConfig = (
        regFormId,
        newPosition,
    ) => {
        return {
            url: `/regForm/${regFormId}/position/${newPosition}`,
            method: 'PUT',
        };
    };

    regForm: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/regForm/${id}/form`,
            method: 'GET',
        };
    };

    deleteRegForm: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/regForm/${id}`,
            method: 'DELETE',
        };
    };

    regFormFile: (fileId: string) => AxiosRequestConfig = (fileId) => {
        return {
            url: `/regForm/file/${fileId}`,
            method: 'GET',
            responseType: 'blob',
        };
    };

    templates: (regFormId: string) => AxiosRequestConfig = (regFormId) => {
        return {
            url: `/regForm/${regFormId}/templates`,
            method: 'GET',
        };
    };

    template: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/template/${id}`,
            method: 'GET',
        };
    };

    createTemplate: (regFormId: string, formData: FormData) => AxiosRequestConfig & { params?: { regForm: string } } = (
        regFormId,
        formData,
    ) => {
        return {
            url: `/template`,
            method: 'POST',
            params: {
                regForm: regFormId,
            },
            data: formData,
        };
    };

    saveTemplate: (templateId: string, formData: FormData) => AxiosRequestConfig = (templateId, formData) => {
        return {
            url: `/template/${templateId}`,
            method: 'PUT',
            data: formData,
        };
    };

    deleteTemplate: (templateId: string) => AxiosRequestConfig = (templateId) => {
        return {
            url: `/template/${templateId}`,
            method: 'DELETE',
        };
    };

    changeTemplatePosition: (templateId: string, newPosition: number) => AxiosRequestConfig = (
        templateId,
        newPosition,
    ) => {
        return {
            url: `/template/${templateId}/position/${newPosition}`,
            method: 'PUT',
        };
    };

    downloadTemplateFile: (templateId: string) => AxiosRequestConfig = (templateId) => {
        return {
            url: `/template/${templateId}/file`,
            method: 'GET',
            responseType: 'blob',
        };
    };

    createRequest: (regFormId: string) => AxiosRequestConfig = (regFormId) => {
        return {
            url: `/request?regForm=${regFormId}`,
            method: 'POST',
        };
    };

    getRequest: (requestId: string) => AxiosRequestConfig = (requestId) => {
        return {
            url: `/request/${requestId}`,
            method: 'GET',
        };
    };

    getDeadlines: (requestId: string) => AxiosRequestConfig = (requestId) => {
        return {
            url: `/request/${requestId}/deadline`,
            method: 'GET',
        };
    };

    getMainPageStats: () => AxiosRequestConfig = () => {
        return {
            url: `/stats/request`,
            method: 'GET',
        };
    };

    updatePreScores: (requestId: string, submission: FullSubmission) => AxiosRequestConfig = (
        requestId,
        submission,
    ) => {
        return {
            url: `/request/${requestId}/preScores`,
            method: 'PUT',
            data: submission,
        };
    };

    updatePreExpertise: (requestId: string, submission: FullSubmission) => AxiosRequestConfig = (
        requestId,
        submission,
    ) => {
        return {
            url: `/request/${requestId}/preExpertise`,
            method: 'PUT',
            data: submission,
        };
    };

    updateExpertise: (requestId: string, submission: FullSubmission) => AxiosRequestConfig = (
        requestId,
        submission,
    ) => {
        return {
            url: `/request/${requestId}/expertise`,
            method: 'PUT',
            data: submission,
        };
    };

    deleteRequest: (requestId: string) => AxiosRequestConfig = (requestId) => {
        return {
            url: `/request/${requestId}`,
            method: 'DELETE',
        };
    };

    loadRequestApprovalSheets = (requestId: string): AxiosRequestConfig => ({
        url: `/request/${requestId}/approval-sheets`,
        method: 'GET',
    });

    getApprovalSheet = (sheetId: string): AxiosRequestConfig => ({
        url: `/approval-sheet/${sheetId}`,
        method: 'GET',
    });

    changeApprovalSheetStatus = (sheetId: string, status: ApprovalSheetStatus): AxiosRequestConfig => ({
        url: `/approval-sheet/${sheetId}/manage`,
        method: 'POST',
        data: {
            decision: status,
        },
    });

    sendApprovalSheetResolution = (
        sheetId: string,
        resolution: ApprovalSheetResolution,
        participantId: string,
    ): AxiosRequestConfig => ({
        url: `/approval-sheet/${sheetId}/sendResolution`,
        method: 'POST',
        data: {
            participantId,
            resolution,
        },
    });

    saveApprovalSheetGroups = (dto: ApprovalSheetGroupsDTO): AxiosRequestConfig => ({
        url: `/approval-sheet/${dto.sheetId}/editGroups`,
        method: 'POST',
        data: dto.groups,
    });

    getAllActiveNonBlockedUsers = (): AxiosRequestConfig => ({
        url: `/select/users/v2`,
        method: 'GET',
        params: {
            blocked: false,
            emailConfirmed: true,
        },
    });

    requestList: (queryData: TableQueryData) => AxiosRequestConfig = (queryData) => {
        return {
            url: '/requests',
            method: 'PUT',
            data: queryData,
        };
    };

    registry: (registryCode: string, queryData: TTableQueryData<RequestRow>) => AxiosRequestConfig = (
        registryCode,
        queryData,
    ) => {
        return {
            url: '/registry/table',
            method: 'PUT',
            params: {
                code: registryCode,
            },
            data: queryData,
        };
    };

    registryColumns: (registryCode: string) => AxiosRequestConfig = (registryCode) => {
        return {
            url: '/registry/columns',
            method: 'GET',
            params: {
                code: registryCode,
            },
        };
    };

    registryRequestLogColumns: (registryCode: string) => AxiosRequestConfig = (registryCode) => {
        return {
            url: `/registry/${registryCode}/columns`,
            method: 'GET',
        };
    };

    registryRequestLog: (
        registryCode: string,
        entityId: string,
        queryData: TTableQueryData<RequestRow>,
    ) => AxiosRequestConfig = (registryCode, entityId, queryData) => {
        return {
            url: `/registry/${registryCode}/entity/${entityId}/table`,
            method: 'PUT',
            data: queryData,
        };
    };

    uploadRequestLog: <RowType extends TTableRow>(
        tableQueryData: TTableQueryData<RowType>,
        registryCode: string,
        entityId: string,
    ) => AxiosRequestConfig = (tableQueryData, registryCode, entityId) => ({
        url: `/registry/${registryCode}/entity/${entityId}/export`,
        method: 'PUT',
        data: tableQueryData,
        responseType: 'arraybuffer',
    });

    requestListFilterData: (data: CampaignRequestListFilterData) => AxiosRequestConfig = (data) => {
        return {
            url: '/requests/filters',
            method: 'PUT',
            data,
        };
    };

    requestListXls: (queryData: TableQueryData) => AxiosRequestConfig = (queryData) => {
        return {
            url: '/requestsXls',
            method: 'PUT',
            responseType: 'blob',
            data: queryData,
        };
    };

    transitionRequest: (requestId: string) => AxiosRequestConfig = (requestId) => {
        return {
            url: `/request/${requestId}/transitions`,
            method: 'GET',
        };
    };

    transitionToNextLifeCycleStep: (transitionId: string, requestId: string) => AxiosRequestConfig = (
        transitionId,
        requestId,
    ) => {
        return {
            url: `/request/${requestId}/changeState?transition=${transitionId}`,
            method: 'PUT',
        };
    };

    personsListFilterData: AxiosRequestConfig = {
        url: '/persons?filter',
        method: 'GET',
    };

    requestPrintForms: (formId: string) => AxiosRequestConfig = (formId) => {
        return {
            url: `/request-form/${formId}/templates`,
            method: 'GET',
        };
    };

    requestLicensePrintForm: (requestId: string) => AxiosRequestConfig = (requestId) => {
        return {
            url: `/request/${requestId}/templates`,
            method: 'GET',
            params: {
                identifier: 'license',
            },
        };
    };

    getPrintForm: (templateId: string, formId: string) => AxiosRequestConfig = (templateId, formId) => {
        return {
            url: `/pf-template/${templateId}/form/${formId}`,
            method: 'GET',
        };
    };

    generatePrintForm: (formId: string, templateId: string, submission?: FullSubmission) => AxiosRequestConfig = (
        formId,
        templateId,
        submission,
    ) => {
        return {
            method: 'POST',
            url: '/pf',
            params: {
                template: templateId,
                customForm: formId,
            },
            data: submission,
        };
    };

    downloadPrintFormFile: (pfId: string) => AxiosRequestConfig = (pfId) => {
        return {
            url: `/pf/${pfId}`,
            method: 'GET',
            responseType: 'blob',
        };
    };

    downloadAllPrintFormFiles: (formId: string) => AxiosRequestConfig = (formId) => {
        return {
            url: `/pf/${formId}/all`,
            method: 'GET',
            responseType: 'blob',
        };
    };

    getActualCampaignList: AxiosRequestConfig = {
        url: `/campaigns/active`,
        method: 'GET',
    };

    getLifecycleList: AxiosRequestConfig = {
        url: `/select/lifecycles?processCode=CampaignProcess`,
        method: 'GET',
    };

    catalogItems: (catalogCode: string, lang: string) => AxiosRequestConfig = (catalogCode, lang) => {
        return {
            url: `/catalogItems`,
            method: 'GET',
            params: {
                catalogCode,
                lang,
            },
        };
    };

    deleteCampaign: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/campaign/${id}`,
            method: 'DELETE',
        };
    };

    transitionCampaign: (campaignId: string) => AxiosRequestConfig = (campaignId) => {
        return {
            url: `/campaign/${campaignId}/transitions`,
            method: 'GET',
        };
    };

    getFormForGeneralSettings: (campaignId: string) => AxiosRequestConfig = (campaignId) => {
        return {
            url: `/campaign/${campaignId}/general-settings-form`,
            method: 'GET',
        };
    };

    transitionToNextLifeCycleStepCampaign: (transitionId: string, campaignId: string) => AxiosRequestConfig = (
        transitionId,
        campaignId,
    ) => {
        return {
            url: `/campaign/${campaignId}/changeState?transition=${transitionId}`,
            method: 'PUT',
        };
    };

    saveFormForGeneralSettings: (id: string, submission: FullSubmission) => AxiosRequestConfig = (
        campaignId,
        submission,
    ) => {
        return {
            url: `/campaign/${campaignId}/general-settings-form`,
            method: 'PUT',
            data: submission,
        };
    };

    loginAnonymously: AxiosRequestConfig = {
        url: `/login-anonymous`,
        method: 'PUT',
    };

    //<editor-fold desc="Конфиги для настроек форм заявки">

    requestFormSettings: (regFormId: string) => AxiosRequestConfig = (regFormId) => {
        return {
            url: '/request-form-settings',
            method: 'GET',
            params: {
                regForm: regFormId,
            },
        };
    };

    createRequestFormSetting: (
        regFormId: string,
        dto: RequestFormTabSettingsDTO,
    ) => AxiosRequestConfig & { params?: { regForm: string } } = (regFormId, dto) => {
        return {
            url: '/request-form-setting',
            method: 'POST',
            params: {
                regForm: regFormId,
            },
            data: dto,
        };
    };

    loadRequestFormSetting: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/request-form-setting/${id}`,
            method: 'GET',
        };
    };

    saveRequestFormSetting: (id: string, dto: RequestFormTabSettingsDTO) => AxiosRequestConfig = (id, dto) => {
        return {
            url: `/request-form-setting/${id}`,
            method: 'PUT',
            data: dto,
        };
    };

    deleteRequestFormSetting: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/request-form-setting/${id}`,
            method: 'DELETE',
        };
    };

    changePositionRequestFormSetting: (id: string, position: number) => AxiosRequestConfig = (id, position) => {
        return {
            url: `/request-form-setting/${id}/position/${position}`,
            method: 'PUT',
        };
    };

    loadParentTabs: (rfId: string) => AxiosRequestConfig = (rfId) => {
        return {
            url: `/request-form-setting/${rfId}/settings`,
            method: 'GET',
        };
    };

    loadParentTabsWhenTabSettingIsEditing: (rfId: string, currentTabId: string) => AxiosRequestConfig = (
        rfId,
        currentTabId,
    ) => {
        return {
            url: `/request-form-setting/${rfId}/settings?curTab=${currentTabId}`,
            method: 'GET',
        };
    };

    loadExecutors: () => AxiosRequestConfig = () => {
        return {
            url: `/select/users/v2?role=Employee&role=SeniorEmployee`,
            method: 'GET',
            params: {
                blocked: false,
            },
        };
    };

    loadStates: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/regForm/${id}/states`,
            method: 'GET',
        };
    };

    loadPermissions: () => AxiosRequestConfig = () => {
        return {
            url: `/select/permissions?entityCode=RequestCustomForm`,
            method: 'GET',
        };
    };

    loadFormCode: () => AxiosRequestConfig = () => {
        return {
            url: `/select/forms/search?code=RequestCustomForm_`,
            method: 'GET',
        };
    };
    //<editor-fold/>

    //<editor-fold desc="Кастомные формы заявки">
    loadRequestForm: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/request-form/${id}`,
            method: 'GET',
        };
    };

    saveRequestForm: (id: string, submission: FullSubmission) => AxiosRequestConfig = (id, submission) => {
        return {
            url: `/request-form/${id}`,
            method: 'PUT',
            data: submission,
        };
    };

    requestFormCheckEditable: (requestFormId: string) => AxiosRequestConfig = (requestFormId) => {
        return {
            url: `/request-form/${requestFormId}/checkEditable`,
            method: 'GET',
        };
    };

    //<editor-fold/>

    fetchExecutorList: (regFormId: string) => AxiosRequestConfig = (regFormId) => {
        return {
            url: `/regForm/${regFormId}/executors`,
            method: 'GET',
        };
    };

    deleteExecutor: (regFormId: string, settingsExecId: string) => AxiosRequestConfig = (regFormId, settingsExecId) => {
        return {
            url: `/regForm/${regFormId}/executor/${settingsExecId}`,
            method: 'DELETE',
        };
    };

    addExecutor: (regFormId: string, settings: ExecutorSettings) => AxiosRequestConfig = (regFormId, settings) => {
        return {
            method: 'POST',
            url: `/regForm/${regFormId}/executor`,
            data: settings,
        };
    };

    fetchUsers: () => AxiosRequestConfig = () => {
        return {
            url: '/persons',
            method: 'GET',
        };
    };

    fetchExecutorsSettings: (regFormId: string) => AxiosRequestConfig = (regFormId) => {
        return {
            url: `regForm/${regFormId}/executors-settings`,
            method: 'GET',
        };
    };

    getAllActiveEmployees: () => AxiosRequestConfig = () => {
        return {
            url: `/select/users?role=Employee&role=SeniorEmployee`,
            method: 'GET',
            params: {
                blocked: false,
            },
        };
    };

    getAllEmployees: () => AxiosRequestConfig = () => {
        return {
            url: `/select/users?role=Employee&role=SeniorEmployee`,
            method: 'GET',
        };
    };

    setCurrentUserAsExecutor: (requestId: string) => AxiosRequestConfig = (requestId) => {
        return {
            url: `/request/${requestId}/executor`,
            method: 'GET',
        };
    };

    getExecutorSettings: (requestId: string) => AxiosRequestConfig = (requestId) => {
        return {
            url: `/request/${requestId}/executorSetting`,
            method: 'GET',
        };
    };

    editExecutorSettings: (requestId: string, userId: string) => AxiosRequestConfig = (requestId, userId) => {
        return {
            url: `/request/${requestId}/executorSetting`,
            method: 'PUT',
            data: {
                userId,
            },
        };
    };

    //<editor-fold desc="Пресеты">
    listPreset: (registryCode: string) => AxiosRequestConfig = (registryCode) => {
        return {
            url: `presets`,
            method: 'GET',
            params: {
                registryCode,
            },
        };
    };

    savePreset: <RowType extends TTableRow>(preset: Preset<RowType>) => AxiosRequestConfig = (preset) => {
        return {
            url: `preset`,
            method: `PUT`,
            data: preset,
        };
    };

    deletePreset: (presetId: string) => AxiosRequestConfig = (presetId) => {
        return {
            url: `preset/${presetId}`,
            method: 'DELETE',
        };
    };

    saveListPresets = <RowType extends TTableRow>(presets: Preset<RowType>[]): AxiosRequestConfig => ({
        url: '/presets',
        method: 'PUT',
        data: presets,
    });

    getLastPreset = (registryCode: string): AxiosRequestConfig => ({
        url: '/lastPreset',
        method: 'GET',
        params: {
            registryCode,
        },
    });

    saveLastPreset = (registryCode: string, presetId: string): AxiosRequestConfig => ({
        url: '/lastPreset',
        method: 'PUT',
        params: {
            registryCode,
            presetId,
        },
    });

    //<editor-fold/>

    //<editor-fold desc="Уведомления">
    loadNotifications = (queryData: NotificationsQueryData): AxiosRequestConfig => {
        return {
            url: `/notifications/lk`,
            method: 'PUT',
            data: queryData,
        };
    };

    loadCountNotViewedMessages = (): AxiosRequestConfig => ({
        url: '/notifications/lk/countUnviewedMessage',
        method: 'GET',
    });

    changeViewed = (id: string, viewed: boolean): AxiosRequestConfig => {
        return {
            url: `/notification/lk/changeViewed`,
            method: 'PUT',
            params: {
                deliveredNotificationId: id,
                viewed,
            },
        };
    };

    deleteNotification = (id: string): AxiosRequestConfig => {
        return {
            url: `/notification/lk`,
            method: 'DELETE',
            params: {
                deliveredNotificationId: id,
            },
        };
    };
    //<editor-fold/>

    loadModulesList = (): AxiosRequestConfig => ({
        url: '/getRoutes',
        method: 'GET',
    });

    upload: (tableQueryData: TTableQueryData<RequestRow>) => AxiosRequestConfig = (tableQueryData) => ({
        url: '/registry/export',
        method: 'PUT',
        data: tableQueryData,
        responseType: 'arraybuffer',
    });

    loadTemplatePermissions = (settingsId: string): AxiosRequestConfig => {
        return {
            url: `/settings/${settingsId}/templatePermissions`,
            method: 'GET',
        };
    };

    savedTemplatePermissions = (settingsId: string, permissions: any[]): AxiosRequestConfig => {
        return {
            url: `/settings/${settingsId}/templatePermissions`,
            method: 'PUT',
            data: permissions,
        };
    };

    checkEditablePf = (customFormId: string): AxiosRequestConfig => {
        return {
            url: `/request-form/${customFormId}/checkEditablePf`,
            method: 'GET',
        };
    };

    checkIsPasswordRecoveryTokenValid = (token: string): AxiosRequestConfig => {
        return {
            url: `/checkPasswordResetToken?token=${token}`,
            method: 'GET',
        };
    };

    getFileByte = (printFormId: string): AxiosRequestConfig => {
        return {
            url: `/pf/${printFormId}/toByte`,
            method: 'GET',
            responseType: 'arraybuffer',
        };
    };

    uploadSignature = (printFormId: string, signature: string): AxiosRequestConfig => {
        return {
            url: `/pf/${printFormId}/sign`,
            method: 'POST',
            data: { sign: signature },
        };
    };

    uploadSignatureFile = (printFormId: string, signatureFile: FormData): AxiosRequestConfig => {
        return {
            url: `/pf/${printFormId}/upload-sign`,
            method: 'POST',
            data: signatureFile,
        };
    };

    getSignatureInfo = (printFormId: string): AxiosRequestConfig => {
        return {
            url: `/pf/${printFormId}/sign-info`,
            method: 'GET',
        };
    };

    downloadSignature = (printFormId: string, isWithFile: boolean): AxiosRequestConfig => {
        return {
            url: `/pf/${printFormId}/sign`,
            method: 'GET',
            params: {
                attached: isWithFile,
            },
        };
    };

    deleteSignature = (printFormId: string): AxiosRequestConfig => {
        return {
            url: `/pf/${printFormId}/sign`,
            method: 'DELETE',
        };
    };

    getFormioFileByte = (url: string): AxiosRequestConfig => {
        return {
            url,
            method: 'GET',
            responseType: 'arraybuffer',
        };
    };

    uploadFormioSignature = (url: string, signature: string): AxiosRequestConfig => {
        return {
            url,
            method: 'POST',
            data: { sign: signature },
        };
    };

    uploadFormioSignatureFile = (url: string, signatureFile: FormData): AxiosRequestConfig => {
        return {
            url,
            method: 'POST',
            data: signatureFile,
        };
    };

    getFormioSignatureInfo = (url: string): AxiosRequestConfig => {
        return {
            url,
            method: 'GET',
        };
    };

    downloadFormioSignature = (url: string, isWithFile: boolean): AxiosRequestConfig => {
        return {
            url,
            method: 'GET',
            params: {
                attached: isWithFile,
            },
        };
    };

    deleteFormioSignature = (url: string): AxiosRequestConfig => {
        return {
            url,
            method: 'DELETE',
        };
    };

    runScript: (script: string) => AxiosRequestConfig = (script) => {
        return {
            url: `/console/script`,
            method: 'POST',
            responseType: 'blob',
            data: script,
        };
    };

    registryLicenseColumns = (token: string): AxiosRequestConfig => {
        return {
            url: `registry/license/columns`,
            method: 'GET',
        };
    };

    registryLicense: (registryCode: string, queryData: TTableQueryData<RequestRow>) => AxiosRequestConfig = (
        registryCode,
        queryData,
    ) => {
        return {
            url: '/registry/license/table',
            method: 'PUT',
            params: {
                code: registryCode,
            },
            data: queryData,
        };
    };

    uploadLicenseXlsx: (tableQueryData: TTableQueryData<RequestRow>) => AxiosRequestConfig = (tableQueryData) => ({
        url: '/registry/license/export',
        method: 'PUT',
        data: tableQueryData,
        responseType: 'arraybuffer',
    });

    uploadLicenseDoc: (tableQueryData: TTableQueryData<RequestRow>) => AxiosRequestConfig = (tableQueryData) => ({
        url: '/registry/license/export/doc',
        method: 'PUT',
        data: tableQueryData,
        responseType: 'arraybuffer',
    });

    uploadRequestsDoc: (tableQueryData: TTableQueryData<RequestRow>) => AxiosRequestConfig = (tableQueryData) => ({
        url: '/registry/request/export/doc',
        method: 'PUT',
        data: tableQueryData,
        responseType: 'arraybuffer',
    });

    setThirdCharLicenseNumberAndChangeState: (
        transitionId: string,
        requestId: string,
        thirdCharLicenseNumber: number,
    ) => AxiosRequestConfig = (transitionId, requestId, thirdCharLicenseNumber) => {
        return {
            url: `/request/${requestId}/setThirdCharLicenseNumberAndChangeState?transition=${transitionId}`,
            method: 'PUT',
            data: thirdCharLicenseNumber,
            headers: {
                'Content-Type': 'application/json',
            },
        };
    };

    flkValidateFields = (customFormId: string): AxiosRequestConfig => {
        return {
            url: `/request-form/${customFormId}/flk`,
            method: 'GET',
        };
    };

    downloadLicenseDuplicate: (id: string) => AxiosRequestConfig = (id) => ({
        url: `/gisp/license/${id}/duplicate`,
        method: 'GET',
        responseType: 'arraybuffer',
    });

    getRequestChecksData: (tabId: string) => AxiosRequestConfig = (tabId) => ({
        url: `/request-form/${tabId}/checks`,
        method: 'GET',
    });

    downloadRequestChecksAsFile: (tabId: string) => AxiosRequestConfig = (tabId) => ({
        url: `/request-form/${tabId}/checks/xls`,
        method: 'GET',
        responseType: 'arraybuffer',
    });

    downloadRequestAllDocs: (requestId: string) => AxiosRequestConfig = (requestId) => ({
        url: `/request/${requestId}/all-docs`,
        method: 'GET',
        responseType: 'blob',
    });

    getRequestExecutorList: (requestId: string) => AxiosRequestConfig = (requestId) => ({
        url: `/gisp/request/${requestId}/executors`,
        method: 'GET',
    });

    getSignatories: () => AxiosRequestConfig = () => {
        return {
            url: `/select/users?role=Signatory`,
            method: 'GET',
        };
    };

    checkFTS: (requestId: string) => AxiosRequestConfig = (requestId) => {
        return {
            url: `/gisp/check/fts/${requestId}`,
            method: 'GET',
        };
    };

    triggerRequestFTSExecutionInfo: (requestId: string) => AxiosRequestConfig = (requestId) => {
        return {
            url: `/gisp/publish/fts/${requestId}`,
            method: 'POST',
        };
    };

    licenseSignatory: (requestId: string, signatoryId: string) => AxiosRequestConfig = (requestId, signatoryId) => {
        return {
            url: `/request/${requestId}/licenseSignatory`,
            method: 'GET',
            params: {
                signatoryId,
            },
        };
    };

    assignLicenseSignatoryAndChangeState: (
        transitionId: string,
        requestId: string,
        signatoryId: string,
    ) => AxiosRequestConfig = (transitionId, requestId, signatoryId) => {
        return {
            url: `/request/${requestId}/assignLicenseSignatoryAndChangeState`,
            method: 'PUT',
            params: {
                transitionId,
                signatoryId,
            },
            headers: {
                'Content-Type': 'application/json',
            },
        };
    };

    assignLicenseSignatory: (requestId: string, signatoryId: string) => AxiosRequestConfig = (
        requestId,
        signatoryId,
    ) => {
        return {
            url: `/request/${requestId}/assignLicenseSignatory`,
            method: 'PUT',
            params: {
                signatoryId,
            },
        };
    };

    submitSignatureAndChangeState: (transitionId: string, requestId: string) => AxiosRequestConfig = (
        transitionId,
        requestId,
    ) => {
        return {
            url: `/request/${requestId}/submitSignatureAndChangeState`,
            method: 'PUT',
            params: {
                transitionId,
            },
        };
    };

    licenseFile: (requestId: string) => AxiosRequestConfig = (requestId) => ({
        url: `/gisp/request/${requestId}/licenseFile`,
        method: 'GET',
    });

    getRequestFileByte = (fileId: string): AxiosRequestConfig => {
        return {
            url: `/request/file/${fileId}/toByte`,
            method: 'GET',
            responseType: 'arraybuffer',
        };
    };

    uploadRequestSignature = (fileId: string, signature: string): AxiosRequestConfig => {
        return {
            url: `/request/file/${fileId}/sign`,
            method: 'POST',
            data: { sign: signature },
        };
    };

    uploadRequestSignatureFile = (fileId: string, signatureFile: FormData): AxiosRequestConfig => {
        return {
            url: `/request/file/${fileId}/upload-sign`,
            method: 'POST',
            data: signatureFile,
        };
    };

    getRequestSignatureInfo = (fileId: string): AxiosRequestConfig => {
        return {
            url: `/request/file/${fileId}/sign-info`,
            method: 'GET',
        };
    };

    downloadRequestSignature = (fileId: string, isWithFile: boolean): AxiosRequestConfig => {
        return {
            url: `/request/file/${fileId}/sign`,
            method: 'GET',
            params: {
                attached: isWithFile,
            },
        };
    };

    deleteRequestSignature = (fileId: string): AxiosRequestConfig => {
        return {
            url: `/request/file/${fileId}/sign`,
            method: 'DELETE',
        };
    };

    downloadRequestFile: (id: string) => AxiosRequestConfig = (id) => ({
        url: `/gisp/request/file/${id}`,
        method: 'GET',
        responseType: 'arraybuffer',
    });

    notificationToken: AxiosRequestConfig = {
        url: `/gisp/notification/token`,
        method: 'GET',
    };

    getAuthorityDoc: (docId: string) => AxiosRequestConfig = (docId) => {
        return {
            url: `/authorityDoc/${docId}`,
            method: 'GET',
        };
    };

    getAuthorityDocDetails: (docId: string) => AxiosRequestConfig = (docId) => {
        return {
            url: `/authorityDoc/${docId}/details`,
            method: 'GET',
        };
    };

    getAuthorityDocForm: (docId: string) => AxiosRequestConfig = (docId) => {
        return {
            url: `/authorityDoc/${docId}/form`,
            method: 'GET',
        };
    };

    getAuthorityDocRequests: (docId: string) => AxiosRequestConfig = (docId) => {
        return {
            url: `/authorityDoc/${docId}/requests`,
            method: 'GET',
        };
    };

    getAuthorityDocLicenses: (docId: string) => AxiosRequestConfig = (docId) => {
        return {
            url: `/authorityDoc/${docId}/licenses`,
            method: 'GET',
        };
    };

    authorityDocsColumns = (): AxiosRequestConfig => {
        return {
            url: '/registry/authDocs/columns',
            method: 'GET',
        };
    };

    authorityDocs: (registryCode: string, queryData: TTableQueryData<TTableRow>) => AxiosRequestConfig = (
        registryCode,
        queryData,
    ) => {
        return {
            url: '/registry/authDocs/table',
            method: 'PUT',
            params: {
                code: registryCode,
            },
            data: queryData,
        };
    };

    exportAuthorityDocXlsx: (tableQueryData: TTableQueryData<RequestRow>) => AxiosRequestConfig = (tableQueryData) => ({
        url: '/registry/authDocs/export',
        method: 'PUT',
        data: tableQueryData,
        responseType: 'arraybuffer',
    });

    exportAuthorityDocDoc: (tableQueryData: TTableQueryData<RequestRow>) => AxiosRequestConfig = (tableQueryData) => ({
        url: '/registry/authDocs/export/doc',
        method: 'PUT',
        data: tableQueryData,
        responseType: 'arraybuffer',
    });

    updateQuota: (quotaData: QuotaCreateDTO, quotaId: string) => AxiosRequestConfig = (quotaData, quotaId) => {
        return {
            url: `/quota/update?id=${quotaId}`,
            method: 'PUT',
            data: quotaData,
        };
    };

    deleteQuota: (quotaId: string) => AxiosRequestConfig = (quotaId) => {
        return {
            url: `/quota/delete?id=${quotaId}`,
            method: 'DELETE',
        };
    };

    getQuota: (quotaId: string) => AxiosRequestConfig = (quotaId) => {
        return {
            url: `/quota/get?id=${quotaId}`,
            method: 'GET',
        };
    };

    postQuota: (quotaData: QuotaCreateDTO) => AxiosRequestConfig = (quotaData) => {
        return {
            url: '/quota/create',
            method: 'POST',
            data: quotaData,
        };
    };

    getQuotaCategoryOptions: () => AxiosRequestConfig = () => {
        return {
            url: `/catalogItems?catalogCode=QuotaCategory`,
            method: 'GET',
        };
    };

    getQuotaTypeOptions: () => AxiosRequestConfig = () => {
        return {
            url: `/catalogItems?catalogCode=QuotaType`,
            method: 'GET',
        };
    };

    getCNOptions: () => AxiosRequestConfig = () => {
        return {
            url: `/select/CNCategory`,
            method: 'GET',
        };
    };

    getOperationOptions: (productCategoryCode: string) => AxiosRequestConfig = (productCategoryCode) => {
        return {
            url: `/select/LicOperation?categoryCode=${productCategoryCode}`,
            method: 'GET',
        };
    };

    getDatamosOkeiCatalog: () => AxiosRequestConfig = () => {
        return {
            url: `/select/datamos_okei`,
            method: 'GET',
        };
    };

    getQuotaMeasurementUnitOptions: (productCategoryCode: string) => AxiosRequestConfig = (productCategoryCode) => {
        return {
            url: `/quota-unit`,
            method: 'GET',
            params: {
                categoryCode: productCategoryCode,
            },
        };
    };

    getCountryGroupsOptions: AxiosRequestConfig = {
        url: `/select/country-groups`,
        method: 'GET',
    };

    getQuotasColumns = (): AxiosRequestConfig => {
        return {
            url: '/registry/quota/columns',
            method: 'GET',
        };
    };

    getQuotasList: (registryCode: string, queryData: TTableQueryData<TTableRow>) => AxiosRequestConfig = (
        registryCode,
        queryData,
    ) => {
        return {
            url: '/registry/quota/table',
            method: 'PUT',
            params: {
                code: registryCode,
            },
            data: queryData,
        };
    };

    uploadQuotaParticipantsDistributionFile: (quotaId: string, formData: FormData) => AxiosRequestConfig = (
        quotaId,
        formData,
    ) => {
        return {
            url: '/quota/participants/import',
            method: 'PUT',
            params: {
                id: quotaId,
            },
            data: formData,
        };
    };

    getDatamosOKSMCatalog: () => AxiosRequestConfig = () => {
        return {
            url: '/select/datamos_oksm',
            method: 'GET',
        };
    };

    exportQuotasXlsx: (tableQueryData: TTableQueryData<RequestRow>) => AxiosRequestConfig = (tableQueryData) => ({
        url: '/registry/quota/export',
        method: 'PUT',
        data: tableQueryData,
        responseType: 'arraybuffer',
    });

    exportQuotasDoc: (tableQueryData: TTableQueryData<RequestRow>) => AxiosRequestConfig = (tableQueryData) => ({
        url: '/registry/quota/export/doc',
        method: 'PUT',
        data: tableQueryData,
        responseType: 'arraybuffer',
    });

    createCountyGroup: (countryGroup: CountryGroup) => AxiosRequestConfig = (countryGroup) => ({
        url: '/country-group/create',
        method: 'POST',
        data: countryGroup,
    });

    getCountryGroup: (countryGroupId: string) => AxiosRequestConfig = (countryGroupId) => ({
        url: '/country-group/get',
        method: 'GET',
        params: {
            id: countryGroupId,
        },
    });

    updateCountryGroup: (countryGroup: CountryGroup, countryGroupId: string) => AxiosRequestConfig = (
        countryGroup,
        countryGroupId,
    ) => ({
        url: '/country-group/update',
        method: 'PUT',
        data: countryGroup,
        params: {
            id: countryGroupId,
        },
    });

    getCountryGroupsColumns: () => AxiosRequestConfig = () => ({
        url: '/registry/country-group/columns',
        method: 'GET',
    });

    getCountryGroupsTable: (registryCode: string, queryData: TTableQueryData<TTableRow>) => AxiosRequestConfig = (
        registryCode,
        queryData,
    ) => ({
        url: '/registry/country-group/table',
        method: 'PUT',
        params: {
            code: registryCode,
        },
        data: queryData,
    });

    exportCountryGroupsXlsx: (tableQueryData: TTableQueryData<RequestRow>) => AxiosRequestConfig = (
        tableQueryData,
    ) => ({
        url: '/registry/country-group/export',
        method: 'PUT',
        data: tableQueryData,
        responseType: 'arraybuffer',
    });

    exportCountryGroupsDoc: (tableQueryData: TTableQueryData<RequestRow>) => AxiosRequestConfig = (tableQueryData) => ({
        url: '/registry/country-group/export/doc',
        method: 'PUT',
        data: tableQueryData,
        responseType: 'arraybuffer',
    });
}

export const apiConfigs = new ApiConfigs();
