import { Container, Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { useParams } from 'react-router-dom';
import { entities, permissions } from '../../../authSchemeConfig';
import { AuthorizationCheck, StickyWrapper, TotObjectActionsBarStyled } from '../../../components';
import { useQuotaContext } from '../../../hooks';
import { RouteParamsDefault } from '../../../types';
import { QuotasEditModeButtonsWithDialog, QuotasViewModeButtons } from './buttons';

export const QuotasControlPanel = observer((): JSX.Element => {
    const { isCreating, isEditing, isViewOnly } = useQuotaContext();
    const { id } = useParams<RouteParamsDefault>();
    return (
        <AuthorizationCheck entityCode={entities.System} entityId={id} permCode={permissions.Quotas.ManageQuotas}>
            <StickyWrapper mode="bottom">
                <TotObjectActionsBarStyled>
                    <Container maxWidth="lg">
                        <Grid alignItems="center" justify="space-between" container>
                            <Grid item>
                                {(isEditing || isCreating) && <QuotasEditModeButtonsWithDialog />}
                                {isViewOnly && <QuotasViewModeButtons />}
                            </Grid>
                        </Grid>
                    </Container>
                </TotObjectActionsBarStyled>
            </StickyWrapper>
        </AuthorizationCheck>
    );
});
