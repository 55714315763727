import { useEffect, useMemo, useState } from 'react';
import { AuthorizationCheckQuery } from '../store';
import { permissionsConfig } from '../authSchemeConfig';
import { useStore } from './useStore';

export type UsePermissionsActionButtonsProps = {
    rowId: string;
};

export type UsePermissionActionButtons = [boolean[], () => void];

export const usePermissionsActionButtons = (props: UsePermissionsActionButtonsProps): UsePermissionActionButtons => {
    const { rowId } = props;

    const [permissionsData, setPermissionsData] = useState<boolean[]>([]);

    const { authorizationStore } = useStore();

    const allPermissionsQueries = useMemo<AuthorizationCheckQuery[]>(() => {
        return [
            permissionsConfig.addActualAppointment(rowId),
            permissionsConfig.updateActualAppointment(rowId),
            permissionsConfig.editCampaignRequest(rowId),
            permissionsConfig.deleteCampaignRequest(rowId),
        ];
    }, [rowId]);

    useEffect(() => {
        authorizationStore.checkAll(allPermissionsQueries).then(setPermissionsData);
    }, [allPermissionsQueries]);

    const reloadPermission = (): void => {
        authorizationStore.checkAll(allPermissionsQueries).then(setPermissionsData);
    };

    return [permissionsData, reloadPermission];
};
