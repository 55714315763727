import { action, computed, observable } from 'mobx';
import { ChangeEvent } from 'react';
import { RequestFormTabSettingsDTO } from '../pages';
import { bannedSymbols } from '../resources';
import { CampaignsStore, IntlStore } from '../store';
import { codeTitleToIdTitle } from '../utils';
import { CodeTitle } from './CodeTitle';
import { IdTitle } from './IdTitle';

export type AccessPermission = {
    permission: CodeTitle;
    states: CodeTitle[];
    edit: boolean;
};

export class RequestFormSettingModel {
    @observable campaignsStore: CampaignsStore;
    @observable intl: IntlStore;

    @observable id: string;
    @observable rfId: string;
    @observable title: string = '';
    @observable identifier: string = '';
    @observable parentTabId: string = '';
    @observable formCode: string = '';
    @observable fileSize: string = '';
    @observable executorId: string = '';
    @observable openOnCreation: boolean = false;

    @observable permissions: AccessPermission[] = [];

    @observable permission: CodeTitle = { code: '', title: '' };
    @observable states: CodeTitle[] = [];
    @observable editPermission: boolean = false;

    @observable parentTabsList: IdTitle[] = [];
    @observable formCodeList: IdTitle[] = [];
    @observable statesList: CodeTitle[] = [];
    @observable permissionsList: CodeTitle[] = [];
    @observable executorsList: IdTitle[] = [];

    @observable formValidationStarted: boolean = false;
    @observable accessPermissionsValidationStarted: boolean = false;

    constructor(id: string, rfId: string, campaignsStore: CampaignsStore, intl: IntlStore) {
        this.id = id;
        this.rfId = rfId;
        this.campaignsStore = campaignsStore;
        this.intl = intl;
    }

    @computed
    get requestFormTabSettingsData(): RequestFormTabSettingsDTO {
        const { title, identifier, parentTabId, formCode, executorId, fileSize, permissions, openOnCreation } = this;
        let data: RequestFormTabSettingsDTO = {
            title,
            identifier,
            formCode,
            permissions,
            openOnCreation,
        };
        if (!!parentTabId) {
            data.parentTabId = parentTabId;
        }
        if (!!executorId) {
            data.executorId = executorId;
        }
        if (!!fileSize) {
            data.fileSize = +fileSize;
        }
        return data;
    }

    @action.bound
    load(dto: RequestFormTabSettingsDTO): void {
        this.title = dto.title;
        this.identifier = dto.identifier;
        this.parentTabId = dto.parentTabId || '';
        this.formCode = dto.formCode;
        this.fileSize = (dto.fileSize && dto.fileSize.toString()) || '';
        this.permissions = dto.permissions;
        this.executorId = dto.executorId || '';
        this.openOnCreation = dto.openOnCreation;
    }

    @action.bound
    async loadData(rfId: string, settingId?: string): Promise<void> {
        const formCodeList = await this.campaignsStore.loadFormCode();
        this.parentTabsList =
            typeof settingId === 'string'
                ? await this.campaignsStore.loadParentTabsWhenTabSettingIsEditing(rfId, settingId)
                : await this.campaignsStore.loadParentTabs(rfId);
        this.permissionsList = await this.campaignsStore.loadPermissions();
        this.executorsList = await this.campaignsStore.loadExecutors();
        this.formCodeList = codeTitleToIdTitle(formCodeList);

        this.statesList = await this.campaignsStore.loadStates(rfId);
    }

    @action.bound
    addAccessPermission() {
        this.accessPermissionsValidationStarted = true;

        if (!this.isAccessPermissionsValid) {
            return;
        }

        this.permissions.push({
            permission: this.permission,
            states: this.states,
            edit: this.editPermission,
        });
        this.permission = { code: '', title: '' };
        this.states = [];
        this.editPermission = false;
        this.accessPermissionsValidationStarted = false;
    }

    @action.bound
    deleteAccessPermission(index: number): void {
        this.permissions.splice(index, 1);
    }

    @action.bound
    handleUpdatePermission(index: number, updatedPermission: AccessPermission): void {
        this.permissions[index] = updatedPermission;
    }

    @action.bound
    onChangeTitle(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void {
        this.title = e.target.value ?? '';
    }

    @action.bound
    onChangeIdentifier(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void {
        this.identifier = e.target.value ?? '';
    }

    @action.bound
    onChangeParentTab(e: ChangeEvent<{}>, value: string | null): void {
        this.parentTabId = value ?? '';
    }

    @action.bound
    onChangeFileSize(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void {
        this.fileSize = e.target.value ?? '';
    }

    @action.bound
    onChangeExecutor(e: ChangeEvent<{}>, value: string | null): void {
        this.executorId = value ?? '';
    }

    @action.bound
    onChangeFormCode(e: ChangeEvent<{}>, value: string | null): void {
        this.formCode = value ?? '';
    }

    @action.bound
    onChangePermission(e: ChangeEvent<{}>, value: CodeTitle | any): void {
        this.permission = value ?? { code: '', title: '' };
    }

    @action.bound
    onChangeStates(e: ChangeEvent<{}>, value: CodeTitle[] | any): void {
        this.states = value ?? [];
    }

    @action.bound
    onChangeEditPermission() {
        this.editPermission = !this.editPermission;
    }

    @action.bound
    onChangeOpenOnCreation() {
        this.openOnCreation = !this.openOnCreation;
    }

    @computed
    get requiredMessage(): string {
        return this.intl.formatMessage('validation.required');
    }

    @computed
    get isValid(): boolean {
        const { title, fileSize, identifier, formCode } = this;
        return (
            !!title &&
            !identifier.match(bannedSymbols) &&
            !!identifier &&
            !!formCode &&
            (!fileSize || !isNaN(+fileSize)) &&
            !!this.permissions.length
        );
    }

    @computed
    get titleMessage(): string {
        const { formValidationStarted, title, requiredMessage } = this;

        if (formValidationStarted && !title) {
            return requiredMessage;
        }

        return '';
    }

    @computed
    get identifierMessage(): string {
        const { formValidationStarted, identifier, requiredMessage, intl } = this;
        if (formValidationStarted && !identifier) {
            return requiredMessage;
        }

        if (formValidationStarted && !!identifier.match(bannedSymbols)) {
            return intl.formatMessage('validation.bannedSymbols', { symbols: '/ \\ : * ? < > |' });
        }

        return '';
    }

    @computed
    get formCodeMessage(): string {
        const { formValidationStarted, formCode, requiredMessage } = this;

        if (formValidationStarted && !formCode) {
            return requiredMessage;
        }
        return '';
    }

    @computed
    get fileSizeMessage(): string {
        const { formValidationStarted, fileSize, requiredMessage } = this;

        if (formValidationStarted && fileSize && isNaN(+fileSize)) {
            return requiredMessage;
        }
        return '';
    }

    @computed
    get isAccessPermissionsValid(): boolean {
        return !!this.permission.code;
    }

    @computed
    get permissionMessage(): string {
        const { accessPermissionsValidationStarted, formValidationStarted, permission, requiredMessage } = this;

        if (accessPermissionsValidationStarted && !permission.code) {
            return requiredMessage;
        }

        if (formValidationStarted && !permission.code && !this.permissions.length) {
            return requiredMessage;
        }

        return '';
    }
}
