export enum ConfirmationButtons {
    delete = 'delete',
    addExecutor = 'addExecutor',
}

export type SettingsConfirmDialogType = {
    id: ConfirmationButtons;
    title: string;
    message: string;
    onConfirm: () => Promise<void>;
};

export type OnConfirmCallback = () => Promise<void>;

export type SettingsPropsType = Record<ConfirmationButtons, OnConfirmCallback>;
export type Settings = Record<ConfirmationButtons, SettingsConfirmDialogType>;

export function settingsConfirmDialog(props: SettingsPropsType): Settings {
    return {
        delete: {
            id: ConfirmationButtons.delete,
            title: 'common.confirmDeletion',
            message: 'campaignRequest.confirmDeletionInfoText',
            onConfirm: props[ConfirmationButtons.delete],
        },
        addExecutor: {
            id: ConfirmationButtons.addExecutor,
            title: 'campaignRequest.dialog.executorsTitle',
            message: 'campaignRequest.dialog.executorsMessage',
            onConfirm: props[ConfirmationButtons.addExecutor],
        },
    };
}
