import { Paper, Table, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useCallback } from 'react';
import { DragDropContext, Droppable, DroppableProvided, DropResult } from 'react-beautiful-dnd';
import { FormattedMessage } from 'react-intl';
import { useStore } from '../../../../../../hooks';
import { RequestFormSettingListDTO } from '../../../../../../store';
import { reorderList } from '../../../../../../utils';
import { RequestFormTabsSettingsDroppableTableBody } from './RequestFormTabsSettingsDroppableTableBody';

export type RequestFormTabsSettingsTableProps = {
    setSettings: React.Dispatch<React.SetStateAction<RequestFormSettingListDTO[]>>;
    settings: RequestFormSettingListDTO[];
};

export const renderDroppableRequestFormTabsSettingsTableBody = (
    settings: RequestFormSettingListDTO[],
    deleteSetting: (settingId: string) => () => Promise<void>,
) => {
    return function DroppableRequestFormTabsSettingsTableBody(droppableProvided: DroppableProvided): JSX.Element {
        return (
            <RequestFormTabsSettingsDroppableTableBody
                settings={settings}
                deleteSetting={deleteSetting}
                droppableProvided={droppableProvided}
            />
        );
    };
};

export const RequestFormTabsSettingsTable = observer((props: RequestFormTabsSettingsTableProps): JSX.Element => {
    const { setSettings, settings } = props;

    const { campaignsStore } = useStore();

    const setReordered = (sourceIndex: number, destinationIndex: number): void => {
        const reordered = reorderList<RequestFormSettingListDTO>(settings, sourceIndex, destinationIndex);
        setSettings(reordered);
    };

    const onDragEnd = (result: DropResult): void => {
        if (!result.destination) {
            return;
        }
        const destinationIndex = result.destination.index;
        const settingsBefore = JSON.parse(JSON.stringify(settings));
        campaignsStore.changePositionRequestFormSetting(result.draggableId, destinationIndex + 1).catch(() => {
            setSettings(settingsBefore);
        });
        setReordered(result.source.index, destinationIndex);
    };

    const deleteSetting = useCallback(
        (settingId: string) => async (): Promise<void> => {
            await campaignsStore.deleteRequestFormSetting(settingId).then((data) => {
                if (data) {
                    setSettings((prevState) => prevState.filter((setting) => setting.id !== settingId));
                }
            });
        },
        [campaignsStore],
    );

    const droppableTableBody = renderDroppableRequestFormTabsSettingsTableBody(settings, deleteSetting);

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography>
                                <FormattedMessage id="campaign.requestFormSettings.title" />
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography>
                                <FormattedMessage id="campaign.requestFormSettings.formTitle" />
                            </Typography>
                        </TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">{droppableTableBody}</Droppable>
                </DragDropContext>
            </Table>
        </TableContainer>
    );
});
