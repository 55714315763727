import { Box, Button, CircularProgress, Grid, Paper, Typography } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import { FormApi } from '@platform/formiojs-react';
import { observer } from 'mobx-react';
import React, { ReactNode, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { ErrorDialog } from '../../../../components';
import { useFetchCampaignRequestPFs } from '../../../../hooks';
import { CampaignRequestPfModel } from '../../../../models';
import { TableToolbar } from './CampaignRequestPrintForms.styled';
import { CampaignRequestPrintFormsList } from './CampaignRequestPrintFormsList';
import { CampaignRequestPrintFormsTable } from './capmpaign-request-page-print-forms-table';

export type CampaignRequestPrintFormsProps = {
    isShown: boolean; // пф показываются на onFormReady,
    // т.к. если отрендерить их раньше, выглядит не очень приятно, когда перед ними появляется форма
    canGenerate: boolean;
    formApi?: FormApi;
    validateEditPage(onSuccess?: () => Promise<void>): Promise<void>;
    validateReadonlyPage(onSuccess?: () => Promise<void>, withoutSignatureValidate?: boolean): Promise<void>;
    requestNumber: string;
};

const StyledProgress = withTheme(styled(CircularProgress)`
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -${({ theme }) => theme.spacing(3)}px;
    margin-left: -${({ theme }) => theme.spacing(3)}px;
`);

export const CampaignRequestPrintForms = observer((props: CampaignRequestPrintFormsProps): JSX.Element => {
    const { isShown, validateEditPage, validateReadonlyPage, formApi, requestNumber, canGenerate } = props;
    const [errorDownloadFiles, setErrorDownloadFiles] = useState<string | ReactNode>('');

    const { isPFsFetching, printForms, isFilesLoading, pfListModel, fetchPrintForms } = useFetchCampaignRequestPFs();

    useEffect(() => {
        fetchPrintForms();
    }, []);

    if (!isShown) {
        return <React.Fragment />;
    }

    let shouldRenderPrintForms = printForms.length > 0;
    let generatedPrintForms: CampaignRequestPfModel[] = [];
    if (!canGenerate) {
        generatedPrintForms = pfListModel.generatedPF;
        shouldRenderPrintForms = generatedPrintForms.length > 0;
    }

    if (isPFsFetching) {
        return (
            <Box mb={8} mt={6}>
                <Grid container direction="column" justify="center" alignItems="center" spacing={6}>
                    <Grid item>
                        <FormattedMessage id="campaignRequest.printFormsTable.loading" />
                    </Grid>
                    <Grid item>
                        <CircularProgress />
                    </Grid>
                </Grid>
            </Box>
        );
    }

    return (
        <React.Fragment>
            {shouldRenderPrintForms && (
                <Box mt={5} mb={5}>
                    <Paper elevation={1}>
                        <TableToolbar>
                            <Grid container direction="column" spacing={2}>
                                <Grid item>
                                    <Typography variant="h3">
                                        <FormattedMessage id="campaignRequest.printFormsTable.title" />
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Button
                                        disabled={isFilesLoading}
                                        color="primary"
                                        variant="contained"
                                        onClick={() => pfListModel.downloadAllPf(requestNumber)}
                                        style={{ position: 'relative' }}
                                    >
                                        {isFilesLoading && <StyledProgress size={24} />}
                                        <FormattedMessage id="campaignRequest.printFormsTable.downloadAllButton" />
                                    </Button>
                                </Grid>
                            </Grid>
                        </TableToolbar>

                        {canGenerate ? (
                            <CampaignRequestPrintFormsTable
                                formApi={formApi}
                                validateEditPage={validateEditPage}
                                validateReadonlyPage={validateReadonlyPage}
                                printForms={printForms}
                            />
                        ) : (
                            <CampaignRequestPrintFormsList printForms={generatedPrintForms} />
                        )}
                    </Paper>

                    <ErrorDialog
                        message={errorDownloadFiles}
                        open={!!errorDownloadFiles}
                        onClose={() => setErrorDownloadFiles('')}
                    />
                </Box>
            )}
        </React.Fragment>
    );
});
