import { Dialog, DialogContent, DialogTitle, Grid, IconButton, List, ListItem, ListItemText } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { TTableQueryData, TTableRow } from '@platform/ttable';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export type ExportRegistryDialogProps = {
    openUploadDialog: boolean;
    uploadTableQuery: TTableQueryData<TTableRow> | undefined;
    handleCloseExportDialog: () => void;
    exportXlsx: (tableQueryData: TTableQueryData<TTableRow>) => Promise<void>;
    exportDoc: (tableQueryData: TTableQueryData<TTableRow>) => Promise<void>;
};

export const ExportRegistryDialog = (props: ExportRegistryDialogProps): JSX.Element => {
    const { openUploadDialog, uploadTableQuery, handleCloseExportDialog, exportXlsx, exportDoc } = props;

    const handleExportXlsx = (): void => {
        if (uploadTableQuery) {
            exportXlsx(uploadTableQuery).finally(handleCloseExportDialog);
        }
    };

    const handleExportDoc = (): void => {
        if (uploadTableQuery) {
            exportDoc(uploadTableQuery).finally(handleCloseExportDialog);
        }
    };

    return (
        <Dialog open={openUploadDialog}>
            <DialogTitle>
                <Grid container alignItems="center" alignContent="space-between">
                    <Grid item xs={11}>
                        <FormattedMessage id="exportRegistryDialog.selectFileFormat" />
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton onClick={handleCloseExportDialog}>
                            <Close />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <List disablePadding>
                    <ListItem button onClick={handleExportXlsx}>
                        <ListItemText>
                            <FormattedMessage id="exportRegistryDialog.xlsxFileFormat" />
                        </ListItemText>
                    </ListItem>
                    <ListItem button onClick={handleExportDoc}>
                        <ListItemText>
                            <FormattedMessage id="exportRegistryDialog.docFileFormat" />
                        </ListItemText>
                    </ListItem>
                </List>
            </DialogContent>
        </Dialog>
    );
};
