import { multilevelCatalog_ru } from '@platform/multilevel-catalog';
import { ttable_ru } from '@platform/ttable';
import { recordToLang } from '../utils';
import authentication from './authentication';
import authorityDoc from './authorityDoc';
import breadcrumbs from './breadcrumbs';
import campaign from './campaign';
import campaignRequest from './campaignRequest';
import common from './common';
import console from './console';
import digitalSignature from './digitalSignature';
import error from './error';
import exportRegistryDialog from './exportRegistryDialog';
import footer from './footer';
import homePage from './homePage';
import notFoundPage from './notFoundPage';
import notifications from './notifications';
import { requestLog } from './requestLog';
import sidebar from './sidebar';
import startPage from './startPage';
import users from './users';
import validation from './validation';
import quotas from './quotas';
import mainPage from './mainPage';
import countryGroups from './countryGroups';

const lang: Record<string, string> = recordToLang({
    productTitle: 'TOT X',
    authentication,
    validation,
    startPage,
    homePage,
    campaign,
    campaignRequest,
    users,
    common,
    sidebar,
    breadcrumbs,
    notifications,
    digitalSignature,
    exportRegistryDialog,
    requestLog: requestLog,
    console,
    footer,
    ttable: ttable_ru,
    multilevelCatalog: multilevelCatalog_ru,
    notFoundPage,
    error,
    authorityDoc,
    quotas,
    mainPage,
    countryGroups,
});

export default lang;
