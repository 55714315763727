const campaignRequest: Record<string, Record<string, string> | string> = {
    registryTitle: 'Statements',
    withoutNumber: 'Without a number',
    registryColumns: {
        direction: 'Direction',
        institute: 'Institute',
        department: 'Department',
        campaign: 'Service',
        executor: 'Expert',
        number: 'Number',
        author: 'Author',
        regForm: 'Registration form',
        state: 'State',
        created: 'Created',
        sent: 'Sent',
    },
    modalTitle: 'Status change',
    modalInfoText: 'Status will be changed to "{toState}"',
    registryPagingInfo: 'Total {count, number} statements. Displayed from {from, number} to {to, number}.',
    readonlyRequestValidationMsg: 'The statement form is filled out incorrectly. Go to <a>edit</a> to fix errors.',
    invalidDateMessage: 'Invalid date format',
    maxDateMessage: 'Date should not be after than the current date',
    resultsOption: 'Results not found',
    printFormsTable: {
        loading: 'Please, wait, preparation for the generation of printed forms of documents is in progress',
        title: 'Printed forms',
        fileColumnName: 'File',
        authorColumnName: 'Author',
        dateColumnName: 'Date',
        generateAction: 'Generate',
        updateAction: 'Update',
        cannotGenerateError: 'Printed form cannot be generated: statement form contains validation errors',
        cannotGenerateErrorWithLink:
            'Printed form cannot be generated: statement form contains validation errors. Go to <a>edit</a> to fix errors.',
        generationError: 'An error occurred while generating the print form',
        downloadAllButton: 'Download all',
        taskStatus: 'Generation status',
    },
    taskStatus: {
        generating: 'Generation',
        completed: 'Generated',
        failed: 'Generation failed',
    },
    regFormCard: {
        description: 'Description',
        registrationStartDate: 'Registration start',
        registrationEndDate: 'Registration end',
        afterDeadline: 'Reception after deadline',
        instruction: 'Instruction',
    },
    regFormsPageTitle: 'Selection of the statement form',
    RequestN: 'Statement № {number}',
    LicenseN: 'License № {number}',
    ResolutionNHeader: 'Resolution № {number}',
    ResolutionN: 'Resolution № ',
    relatedRequest: 'Request for License № ',
    relatedLicense: 'License № ',
    relatedResolution: 'Request for Resolution  № ',
    creationDate: 'Creation Date:',
    licenseIssueDate: 'License Issue Date:',
    resolutionIssueDate: 'Resolution Issue Date:',
    registered: 'Registration Date:',
    licenses: 'Licenses',
    confirmDeletionInfoText: 'Are you sure you want to delete the statement № {number}?',
    createRequest: 'Create statement',
    newRequest: 'New statement',
    dialog: {
        leaveMessage: 'Are you sure you want to leave the current Statement without saving your changes?',
        executorsMessage: 'You will be appointed as the responsible expert for the statement',
        editExecutorTitle: 'Appoint a responsible expert',
        changeExecutorTitle: 'Change a responsible expert',
        closeMessage: 'Are you sure you want to close edit mode without saving your changes?',
        tooltipMessage: 'To save the changes made, click the "Save" button on the page below.',
        title: 'Confirmation of closing edit mode',
        confirm: 'Confirm',
        cancel: 'Cancel',
        executorsTitle: 'Confirmation of the choice of the responsible executor',
    },
    registryPagingInfoIfNull: 'Total 0 statements. Displayed 0 statements',
    deleteRequest: 'Delete',
    allFiles: 'All documents for {title}',
    takeToWork: 'Take to Work',
    openEditMode: 'Edit',
    saveChanges: 'Save',
    chooseExecutor: 'Appoint a responsible executor',
    actions: {
        requestToWork: 'Take statement to work',
        appointExecutor: 'Appoint a responsible executor',
        changeExecutor: 'Change a responsible executor',
    },
    expandWorkArea: 'Expand work area',
    reduceWorkArea: 'Reduce work area',
    deadlines: {
        entity: 'Total deadline',
        state: 'Deadline',
        error: 'Error. Failed to calculate deadline',
        notRelevant: 'Not relevant in this status',
        notRelevantTooltip:
            'The time spent in this status is not taken into account in the total duration of the work. Completion date will be recalculated upon transition to a different status',
    },
    thirdChar: 'Enter the third license sign for additional product classification',
    assignLicenseSignatory: 'Assign license signatory',
    signatory: 'Signatory',
    responsibleSignatory: 'Responsible signatory',
    uin: 'UIN:',
    requestLog: 'Log',
    licenseDuplicate: 'Duplicate of License № {number}',
    downloadLicenseDuplicate: 'Get a duplicate',
    signingLicense: 'Signing of License',
    licenseGenerationInProgress: 'Please, wait',
    checks: 'Checks',
    checksTableHeaderTitles: {
        number: '№',
        dateAndTime: 'Date and time',
        checkName: 'Check name',
        result: 'Result',
    },
    requestFTSExecutionInfo: 'Request execution info',
    downloadAllDocs: 'Seizure of the attached documents',
    downloadAllDocsArchiveName: 'Seizure of the attached documents related to License № {number}',
    protectedPaperArchive: 'Protected paper archive',
};

export default campaignRequest;
