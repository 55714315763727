import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import { clientRoute } from '../../../../../../clientRoute';
import { useStore } from '../../../../../../hooks';
import { RequestFormSettingListDTO } from '../../../../../../store';
import { CampaignRegFormRouteParams } from '../../types';
import { RequestFormTabSettingsDTO, RequestFormTabsSettingDialog } from '../dialogs';
import { RequestFormTabsSettingsTable } from './RequestFormTabsSettingsTable';
import { RequestFromTabsSettingsHeader } from './RequestFromTabsSettingsHeader';

export const RequestFormTabsSettings = observer((): JSX.Element => {
    const { campaignsStore } = useStore();
    const { id, rfId } = useParams<CampaignRegFormRouteParams>();

    const [settings, setSettings] = useState<RequestFormSettingListDTO[]>([]);

    useEffect(() => {
        campaignsStore.requestFormSettings(rfId).then(setSettings);
    }, [id, rfId, campaignsStore]);

    const createSetting = useCallback(
        async (dto: RequestFormTabSettingsDTO): Promise<void> => {
            await campaignsStore.createRequestFormSetting(rfId, dto);
            await campaignsStore.requestFormSettings(rfId).then(setSettings);
        },
        [id, rfId, campaignsStore],
    );

    const updateSetting = useCallback(
        async (settingId: string, dto: RequestFormTabSettingsDTO): Promise<void | number> => {
            const response = await campaignsStore.saveRequestFormSetting(settingId, dto);
            await campaignsStore.requestFormSettings(rfId).then(setSettings);
            return response;
        },
        [rfId, campaignsStore],
    );

    return (
        <React.Fragment>
            <Switch>
                <Route path={clientRoute.campaignRequestFormTabSetting}>
                    <RequestFormTabsSettingDialog updateSetting={updateSetting} />
                </Route>
                <Route path={clientRoute.campaignRequestFormTabSettingCreate}>
                    <RequestFormTabsSettingDialog createSetting={createSetting} />
                </Route>
            </Switch>

            <Grid container direction="column" spacing={5}>
                <Grid item>
                    <RequestFromTabsSettingsHeader />
                </Grid>
                <Grid item>
                    <RequestFormTabsSettingsTable setSettings={setSettings} settings={settings} />
                </Grid>
            </Grid>
        </React.Fragment>
    );
});
