const campaign: Record<string, string | Record<string, string>> = {
    titleCampaignListPage: 'Услуги',
    titleCreateCampaignPage: 'Создание услуги',
    noCategoriesText: 'В данный момент нет активных услуг для подачи объекта',
    newCampaign: 'Новая услуга',
    campaignPeriod: 'Подача объектов',
    createCampaign: 'Создать услугу',
    editCampaign: 'Редактирование услуги',
    campaignRequests: 'Объекты по услуге',
    campaignSettings: 'Настройка услуги',
    create: 'Создать',
    sections: 'Разделы',
    description: 'Описание: ',
    files: 'Файлы',
    tabsSettings: 'Настройка вкладок',
    pfTemplates: 'Шаблоны ПФ',
    noTemplates: 'Нет настроенных шаблонов',

    campaignDescription: 'Описание услуги',
    campaignDescriptionEdit: 'Редактирование услуги',

    campaignRegForms: 'Формы объектов',
    createRequest: 'Создать  заявление',
    campaignRegForm: '{count, plural, one {Форма} other {Формы}} объектов',
    addCampaignRegFormButton: 'Добавить форму объекта',
    newCampaignRegForm: 'Новая форма объекта',
    editCampaignRegForm: 'Редактирование формы объекта "{formTitle}"',
    deleteCampaignRegForm: 'Удаление формы объекта',
    confirmDeleteCampaignRegForm: 'Вы действительно хотите удалить форму объекта "{formTitle}"?',
    noRegForms: 'Формы регистрации отсутствуют',
    regFormInfo: 'Основная информация',
    regFormExecutors: 'Ответственные эксперты',

    templatesAndExport: 'Шаблоны и выгрузки',
    campaignManagement: 'Управление услугой',
    export: 'Выгрузки',
    templates: 'Шаблоны',
    addTemplate: 'Добавить шаблон',
    editTemplate: 'Редактировать шаблон',
    editPermission: 'Настроить доступ',
    generate: 'Генерация',
    accessSetting: 'Настройка доступа',
    confirmDeletionTemplateInfoText: 'Вы действительно хотите удалить шаблон "{title}"?',
    lifecycle: 'Жизненный цикл',
    tabSettingForm: {
        title: 'Наименование вкладки',
        identifier: 'Идентификатор',
        parentTab: 'Родительская вкладка',
        formCode: 'Код используемой формы',
        fileSize: 'Размер пакета загружаемых документов в Мб',
        executor: 'Ответственный эксперт',
        access: 'Доступ',
        edit: 'Редактирование',
        view: 'Просмотр',
    },
    tabSettingFormTable: {
        role: 'Роль',
        status: 'Статус ЖЦ',
        access: 'Доступ',
        allStatuses: 'Все статусы',
    },
    templateForm: {
        title: 'Название',
        description: 'Описание',
        regForms: 'Формы объектов',
        file: 'Файл шаблона',
        templateIsRequired: 'Является обязательным для генерации',
        includeRequestNumber: 'Вставить в название номер объекта',
        identifier: 'Идентификатор для шаблона ПФ',
    },
    templateFormErrors: {
        notUniqueTemplateName: 'Название шаблона должно быть уникальным',
        executorsNotAllowed:
            'Данный способ назначения недоступен, так как выбран способ назначения ответственных по факту',
    },
    documentCategory: 'Категория документа',
    tabs: 'Вкладки',

    campaignManagementDelete: 'Вы действительно хотите удалить услугу "{campaignName}"?',
    deleteCampaign: 'Удалить услугу',
    campaignTitle: 'Управление услугой {campaignName}',
    generalSettings: 'Общие настройки',

    requestFormSettingsTitle: 'Настройка вкладок для объекта',
    campaignRegFormExecutorRequestTab: 'Вкладка объекта',
    requestTabLabel: 'Вкладка объекта',
    campaignRegFormExecutorResponsibleExecutor: 'Ответственный эксперт',
    requestFormSetting: 'Настройка вкладки',
    createRequestFormSettings: 'Создать вкладку',
    confirmDeletionRequestFormSettingInfoText: 'Вы действительно хотите удалить вкладку "{title}"?',
    requestFormSettings: {
        title: 'Название',
        formTitle: 'Форма',
    },

    fileFormat: 'Формат',
    campaignRegFormExecutorEmployee: 'Сотрудник',

    campaignRegFormExecutorAdd: 'Добавить',

    confirmDeletionInfoText: 'Вы действительно хотите удалить ответственного эксперта {executorName}?',

    employeeLabel: 'Сотрудник',

    collectionCompleted: 'Сбор завершен',
    createRequestError:
        'Вами уже создан объект по данной услуге. Для просмотра и редактирования перейдите на вкладку Объекта',
};

export default campaign;
