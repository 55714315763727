import { useCallback, useState } from 'react';

type UseReloadType = [number, () => void];

export function useReload(): UseReloadType {
    const [reloadKey, setReloadKey] = useState<number>(0);

    const reloadIncrement = useCallback((): void => {
        setReloadKey((reloadKey) => reloadKey + 1);
    }, [setReloadKey]);

    return [reloadKey, reloadIncrement];
}
