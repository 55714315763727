import { Button } from '@material-ui/core';
import React from 'react';
import { withConfirmDialog } from '../../../../../hocs';
import { useStore } from '../../../../../hooks';

export const CampaignRequestHeaderButtonWithDialog = withConfirmDialog(({ openConfirmDialog }): JSX.Element => {
    const { intlStore: intl } = useStore();
    const takeToWorkCampaignRequest = intl.formatMessage('campaignRequest.takeToWork');

    return (
        <Button onClick={openConfirmDialog} color="secondary" size="small" variant="contained">
            {takeToWorkCampaignRequest}
        </Button>
    );
});
