import { Card, CardContent, createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { mainFont } from '../../module/themes/gisp/fonts';
import { MainPageWidgetData } from '../../types';

export type MainPageWidgetCardProps = MainPageWidgetData;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            width: '240px',
            backgroundColor: theme.variables.palette.mainPageCard.background,
        },
        cardContent: {
            height: '100%',
            boxSizing: 'border-box',
        },
        cardTextWrapper: {
            height: '100%'
        },
        title: {
            fontWeight: 600,
            fontSize: '16px',
            textAlign: 'center',
            lineHeight: '19px',
            fontFamily: mainFont,
        },
        value: {
            paddingTop: theme.spacing(2.5),
            fontFamily: mainFont,
            fontWeight: 700,
            fontSize: '64px',
            lineHeight: '77px',
            color: theme.variables.palette.accentDark,
        },
    }),
);

export const MainPageWidgetCard = observer((props: MainPageWidgetCardProps): JSX.Element => {
    const { title, value } = props;
    const classes = useStyles();

    return (
        <Card className={classes.card} elevation={2}>
            <CardContent className={classes.cardContent}>
                <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justify="space-between"
                    className={classes.cardTextWrapper}
                >
                    <Grid item>
                        <Typography className={classes.title}>{title}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.value}>{value}</Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
});
