import { AxiosPromise } from 'axios';
import { action, computed, observable } from 'mobx';
import { asyncAction } from 'mobx-utils';
import { apiConfigs } from '../apiConfigs';
import {
    LoginModel,
    NewPasswordModel,
    RecoveryPasswordModel,
    RegistrationConfirmModel,
    RegistrationModel,
} from '../models';
import { ApiStore } from './ApiStore';
import { RootStore } from './RootStore';

export interface LogoutResponse {
    redirect?: string;
}

export class AuthenticationStore {
    @observable rootStore: RootStore;
    @observable api: ApiStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.api = rootStore.api;
    }

    @computed
    get loginModel(): LoginModel {
        return new LoginModel(this.rootStore);
    }

    @computed
    get registrationModel(): RegistrationModel {
        return new RegistrationModel(this.rootStore.api, this.rootStore.intlStore);
    }

    @computed
    get registrationConfirmModel(): RegistrationConfirmModel {
        return new RegistrationConfirmModel(this.rootStore.api, this.rootStore.intlStore);
    }

    @computed
    get recoveryPasswordModel(): RecoveryPasswordModel {
        return new RecoveryPasswordModel(this.rootStore.api, this.rootStore.intlStore);
    }

    @computed
    get newPasswordModel(): NewPasswordModel {
        return new NewPasswordModel(this.rootStore.api, this.rootStore.intlStore);
    }

    @action.bound
    esiaGetAuthUrl(): Promise<string> {
        return this.api.client(apiConfigs.esiaAuthUrl).then((r) => r.data);
    }

    @action.bound
    samlGetAuthUrl(provider: string, backUrl: string): Promise<string> {
        return this.api.client(apiConfigs.samlAuthUrl(provider, backUrl)).then((r) => r.data);
    }

    @action.bound
    esiaLogin(search: string): Promise<void> {
        return this.api.client(apiConfigs.esiaLogin(search)).then((r) => r.data);
    }

    @asyncAction
    @action.bound
    logout(): AxiosPromise<LogoutResponse> {
        return this.api.client(apiConfigs.logout);
    }

    @action.bound
    loginAnonymously(): AxiosPromise<void> {
        return this.api.client(apiConfigs.loginAnonymously);
    }
}
