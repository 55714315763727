import React from 'react';
import { generatePath, NavLink } from 'react-router-dom';
import { clientRoute } from '../../../../../clientRoute';
import AddIcon from '@material-ui/icons/Add';
import { FormattedMessage } from 'react-intl';
import { Button } from '@material-ui/core';

export type AddPfTemplateButtonProps = {
    campaignId: string;
    rfId: string;
    settingsId: string;
};

export const AddPfTemplateButton = (props: AddPfTemplateButtonProps): JSX.Element => {
    const { campaignId, rfId, settingsId } = props;

    return (
        <Button
            component={NavLink}
            to={generatePath(clientRoute.campaignRegFormTemplatesCreate, {
                id: campaignId,
                rfId,
                settingsId,
            })}
            variant="text"
            color="primary"
            size="small"
            startIcon={<AddIcon />}
        >
            <FormattedMessage id="campaign.addTemplate" />
        </Button>
    );
};
