import { ColumnData, RowsData, TTableQueryData, TTableRow } from '@platform/ttable';
import { History } from 'history';
import fileDownload from 'js-file-download';
import { action, computed, observable } from 'mobx';
import { apiConfigs } from '../apiConfigs';
import { FileDTOWithId } from '../models';
import { RequestRow } from '../pages';
import { CampaignRequestRowNew } from '../types';
import { ApiStore } from './ApiStore';
import { NotificationStore } from './NotificationStore';
import { RootStore } from './RootStore';

export class LicenseStore {
    @observable protected rootStore: RootStore;
    @observable protected api: ApiStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.api = rootStore.api;
    }

    @computed
    get history(): History {
        return this.rootStore.history;
    }

    @action.bound
    registryLicense(
        registryCode: string,
        query: TTableQueryData<RequestRow & CampaignRequestRowNew>,
    ): Promise<RowsData<RequestRow & CampaignRequestRowNew>> {
        return this.api.client(apiConfigs.registryLicense(registryCode, query)).then((r) => r.data);
    }

    @action.bound
    registryLicenseColumns(registryCode: string): Promise<ColumnData<RequestRow>[]> {
        return this.api.client(apiConfigs.registryLicenseColumns(registryCode)).then((r) => r.data);
    }

    @action.bound
    async uploadLicenseXlsx<RowType extends TTableRow>(tableQueryData: TTableQueryData<RowType>): Promise<void> {
        try {
            const { data } = await this.api.client(apiConfigs.uploadLicenseXlsx(tableQueryData));
            const filename = this.rootStore.intlStore.formatMessage('homePage.headerLinks.licenses');
            await fileDownload(data, `${filename}.xlsx`);
        } catch (error) {
            console.error(error);
        }
    }

    @action.bound
    async uploadLicenseDoc<RowType extends TTableRow>(tableQueryData: TTableQueryData<RowType>): Promise<void> {
        try {
            const { data } = await this.api.client(apiConfigs.uploadLicenseDoc(tableQueryData));
            const filename = this.rootStore.intlStore.formatMessage('homePage.headerLinks.licenses');
            await fileDownload(data, `${filename}.doc`);
        } catch (error) {
            console.error(error);
        }
    }

    @action.bound
    setThirdCharLicenseNumberAndChangeState(
        transitionId: string,
        requestId: string,
        thirdCharLicenseNumber: number,
    ): Promise<void> {
        return this.api
            .client(apiConfigs.setThirdCharLicenseNumberAndChangeState(transitionId, requestId, thirdCharLicenseNumber))
            .then((r) => r.data);
    }

    @action.bound
    submitSignatureAndChangeState(transitionId: string, requestId: string): Promise<void> {
        return this.api.client(apiConfigs.submitSignatureAndChangeState(transitionId, requestId)).then((r) => r.data);
    }

    @action.bound
    licenseFile(requestId: string): Promise<FileDTOWithId> {
        return this.api
            .client(apiConfigs.licenseFile(requestId))
            .then((r) => r.data)
            .catch((error) => {
                this.notificationStore.onError(error.response.data);
                throw error;
            });
    }

    @computed
    get notificationStore(): NotificationStore {
        return this.rootStore.notificationStore;
    }
}
