import { Backdrop, Grid } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import { useFeature } from 'feature-toggle-jsx';
import { observer } from 'mobx-react';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import totBackgroundLayer2 from 'tot-background-layer-2.png';
import totBackgroundLayer3 from 'tot-background-layer-3.png';

type CustomBackgroundProps = {
    backgroundImage: string;
};

const Background = withTheme(
    styled.div.attrs(() => ({
        className: 'tot-background',
    }))`
        height: 100%;
        background: ${(props) => {
            const secondary = props.theme.palette.secondary;
            return `url(${totBackgroundLayer3}) center,
                    url(${totBackgroundLayer2}) center,
                    linear-gradient(-55deg, ${secondary.main} 0%, ${secondary.dark} 100%)`;
        }};
        background-blend-mode: soft-light, overlay, normal;
        display: flex;
    `,
);

const CustomBackground = withTheme(
    styled.div<CustomBackgroundProps>`
        height: 100%;
        background: ${(props) => {
            const {
                backgroundImage,
                theme: { variables },
            } = props;

            return variables.palette.background.overlay
                ? `url(${backgroundImage}) center, ${variables.palette.background.overlay}`
                : `url(${backgroundImage}) center`;
        }};
        background-size: cover;
        background-blend-mode: soft-light, overlay, normal;
        display: flex;
    `,
);

export const TotBackground = observer((props: PropsWithChildren<{}>): JSX.Element => {
    const [customBackground, config] = useFeature('customBackground');

    if (customBackground) {
        return config.src ? (
            <CustomBackground backgroundImage={config.src} style={config.style}>
                {props.children}
            </CustomBackground>
        ) : (
            <CustomBackground>
                <Grid container style={{ zIndex: 2 }}>
                    {props.children}
                </Grid>
                <Backdrop style={{ zIndex: 1 }} open={true} />
            </CustomBackground>
        );
    }

    return <Background>{props.children}</Background>;
});

export const TotBackgroundWithAvailableHeader = observer((props: PropsWithChildren<{}>): JSX.Element => {
    const [customBackground, config] = useFeature('customBackground');

    if (customBackground) {
        return (
            <CustomBackground backgroundImage={config.src || ''} style={config.style}>
                <Grid container style={{ zIndex: 2 }}>
                    {props.children}
                </Grid>
                <Backdrop style={{ zIndex: 1 }} open={true} />
            </CustomBackground>
        );
    }

    return (
        <Background>
            <Grid container style={{ zIndex: 2 }}>
                {props.children}
            </Grid>
            <Backdrop style={{ zIndex: 1 }} open={true} />
        </Background>
    );
});
