import { Grid, MenuItem, TextField, Typography } from '@material-ui/core';
import { Pagination, PaginationProps } from '@material-ui/lab';
import React, { ChangeEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import { renderPaginationItem } from './PaginationItem';

type TotPaginationProps = {
    onChangePage: (event: unknown, page: number) => void;
    onChangeRowsPerPage: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    rowsPerPageOptions: number[];
    rowsPerPage: number; // размер страницы
    page: number; // номер страницы
    count: number; // Число строк
    pagingInfoMessageId: string;
    pagingInfoMessageIdIfNull: string;
};

export type TotTablePaginationProps = PaginationProps & TotPaginationProps;

export const TotTablePagination = (props: TotTablePaginationProps): JSX.Element => {
    const {
        rowsPerPage,
        count,
        page,
        rowsPerPageOptions,
        onChangePage,
        onChangeRowsPerPage,
        pagingInfoMessageId,
        pagingInfoMessageIdIfNull,
    } = props;

    const pagesCount = Math.ceil(count / rowsPerPage);
    const from = page * rowsPerPage + 1;
    let to = (page + 1) * rowsPerPage;

    if (to > count) {
        to = count;
    }

    return (
        <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid item>
                <Grid container spacing={2} alignItems="center" wrap="nowrap">
                    <Grid item>
                        <Typography>
                            <FormattedMessage id="common.rowsPerPage" />
                        </Typography>
                    </Grid>
                    <Grid item>
                        <TextField
                            id="filled-select-currency"
                            select
                            size="small"
                            value={rowsPerPage}
                            onChange={onChangeRowsPerPage}
                            variant="outlined"
                        >
                            {rowsPerPageOptions &&
                                rowsPerPageOptions.map((option: number) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                        </TextField>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Pagination
                    count={pagesCount}
                    page={page + 1}
                    onChange={onChangePage}
                    color="primary"
                    showFirstButton
                    showLastButton
                    renderItem={renderPaginationItem}
                />
            </Grid>
            <Grid item>
                <Typography>
                    {count ? (
                        <FormattedMessage id={pagingInfoMessageId} values={{ count, from, to }} />
                    ) : (
                        <FormattedMessage id={pagingInfoMessageIdIfNull} />
                    )}
                </Typography>
            </Grid>
        </Grid>
    );
};
