import { action, observable } from 'mobx';
import { QuotaParticipantVolumeDTO } from '../../types';
import { filterLetters } from '../../utils';

export class QuotaParticipantVolume implements QuotaParticipantVolumeDTO {
    @observable id: string;
    @observable countryGroupId: string | undefined;
    @observable volume: number;

    constructor(volume: number, id: string, countryGroupId?: string) {
        this.id = id;
        this.countryGroupId = countryGroupId;
        this.volume = volume;
    }

    @action.bound
    setVolume(eventTargetValue: string): void {
        this.volume = Number(filterLetters(eventTargetValue));
    }
}
