import { TextField, TextFieldProps } from '@material-ui/core';
import { Autocomplete, AutocompleteProps, AutocompleteRenderInputParams, UseAutocompleteProps } from '@material-ui/lab';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { useQuotaPageStyles } from '../quota-styles';
import { extractOptionLabel } from '.';
import { CodeTitleNull } from '../../../types';
import { getSelectedOption } from '../../../utils';

export type AutocompleteWithOmittedProperties<T> = Omit<
    AutocompleteProps<T, undefined, undefined, undefined>,
    'onChange' | 'renderInput'
>;
export type TextFieldWithOmittedProperties = Omit<TextFieldProps, 'onChange'>;

export type QuotasAutocompleteMobxChangeHandler<T> = UseAutocompleteProps<
    T,
    undefined,
    undefined,
    undefined
>['onChange'];

export type OnChangeTypeOverride<T> = {
    onChange?: (value: T | null) => void;
};

export type QuotasAutocompleteMobxFieldProps<T> = AutocompleteWithOmittedProperties<T> &
    TextFieldWithOmittedProperties &
    OnChangeTypeOverride<T>;

export const QuotasAutocompleteMobxField = observer(
    <T extends CodeTitleNull>(props: QuotasAutocompleteMobxFieldProps<T>): JSX.Element => {
        const classes = useQuotaPageStyles();
        const { onChange, error, helperText, label, variant, required, value } = props;

        const autocompleteClasses = {
            popupIndicator: classes.popupIndicator,
        };

        const handleChange: QuotasAutocompleteMobxChangeHandler<T> = (event, value): void => {
            onChange && onChange(value);
        };

        const renderInput = (params: AutocompleteRenderInputParams): JSX.Element => {
            return (
                <TextField
                    variant={variant}
                    {...params}
                    label={label}
                    error={error}
                    helperText={helperText}
                    required={required}
                />
            );
        };

        const handleOptionSelection = (option: T, value: T): boolean => {
            return option && value && getSelectedOption(option, value);
        };

        const handleOptionLabelExtraction = (option: T): string => {
            return option && extractOptionLabel(option);
        };

        return (
            <Autocomplete
                {...props}
                value={toJS(value)}
                onChange={handleChange}
                renderInput={renderInput}
                getOptionLabel={handleOptionLabelExtraction}
                getOptionSelected={handleOptionSelection}
                classes={autocompleteClasses}
            />
        );
    },
);
