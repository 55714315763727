import {
    Box,
    Button,
    Container,
    createStyles,
    Grid,
    LinearProgress,
    Link,
    Paper,
    SvgIcon,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useFeature } from 'feature-toggle-jsx';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { generatePath, NavLink, useLocation } from 'react-router-dom';
import { entities, permissions, permissionsConfig } from '../../../authSchemeConfig';
import { clientRoute } from '../../../clientRoute';
import {
    AuthorizationCheck,
    AuthorizationCheckAll,
    BreadcrumbsComponent,
    TotTablePagination,
} from '../../../components';
import { useModal, useStore } from '../../../hooks';
import { CampaignRequestListModel, CampaignRequestRow } from '../../../models';
import { ReactComponent as ClearFilter } from '../../../resources/images/icons/clear-filter.svg';
import { ReactComponent as GetApp } from '../../../resources/images/icons/export.svg';
import { ExecutorEditDialog } from '../campaign-request-page';
import { CampaignRequestActionsBtns } from './campaign-request-action-buttons';
import { CampaignRequestListFilterPanel } from './campaign-requests-list-filters';
import { CampaignRequestCreateDialog } from './CampaignRequestCreateDialog';

const useCampaignRequestListPageStyles = makeStyles(() =>
    createStyles({
        tableBody: {
            overflowX: 'auto',
        },
    }),
);

export const CampaignRequestListPage = observer((): JSX.Element => {
    const location = useLocation();

    const { api, requestStore, authorizationStore, breadcrumbsStore } = useStore();

    const { breadCrumbsSettings } = breadcrumbsStore;

    const model = useMemo(() => new CampaignRequestListModel(api), [location.search, api]);

    const [requestRowModel, setRequestRowModel] = useState<CampaignRequestRow>();
    const [isConfirm, setIsConfirm] = useState<boolean>(false);

    const [isExecutorsAllowed, setIsExecutorsAllowed] = useState<boolean>(false);
    const [open, setModalIsOpen, setModalIsClosed] = useModal();
    const [editExecutorDialogOpen, setEditExecutorDialogOpen, setEditExecutorDialogClosed] = useModal();
    const [editExecutorDialogTitle, setEditExecutorDialogTitle] = useState<string>();

    const classes = useCampaignRequestListPageStyles();

    useEffect(() => {
        model.getFilterData(model.filtering.campaign.values);
        return () => model.dispose();
    }, [model]);

    useEffect(() => {
        authorizationStore
            .check({
                entityCode: entities.System,
                permCode: permissions.System.ViewCampReqExecutorColumn,
            })
            .then((allowed) => {
                setIsExecutorsAllowed(allowed);
            });
    }, [authorizationStore]);

    const onChangePage = useCallback(
        (e: unknown, newPage: number): void => {
            const root = document.getElementById('root') as HTMLDivElement;
            root.scrollTop = 0;
            model.onChangePage(e, newPage);
        },
        [model],
    );

    const exportListXls = useCallback((): void => {
        requestStore.exportListXls(model.queryData);
    }, [requestStore]);

    const handleEditExecutorDialogOpen = useCallback(
        (campaignRow: CampaignRequestRow, title?: string): void => {
            setEditExecutorDialogOpen();
            setRequestRowModel(campaignRow);
            title && setEditExecutorDialogTitle(title);
        },
        [setEditExecutorDialogOpen, setRequestRowModel, setEditExecutorDialogTitle],
    );

    const handleClickClearFilterButton = useCallback(() => {
        model.getFilterData();
        model.clearFilters();
    }, [model]);

    const { sorting, rows } = model;

    const [showCreateRequestButton] = useFeature('showCreateRequestButton');

    // в исходной ф-ии queries генерились при каждом ререндере, а новый AuthorizationCheckAll теперь реагирует на их изменение
    const queries = useMemo(() => {
        return rows.map((r) => {
            const { id } = r;
            return [
                permissionsConfig.addActualAppointment(id),
                permissionsConfig.updateActualAppointment(id),
                permissionsConfig.editCampaignRequest(id),
                permissionsConfig.deleteCampaignRequest(id),
            ];
        });
    }, [rows]);

    const renderRows = useCallback(() => {
        return rows.map((r, index) => {
            const { id } = r;
            return (
                <TableRow key={id} hover>
                    <TableCell>
                        <Link component={NavLink} underline="none" to={generatePath(clientRoute.request, { id })}>
                            {r.number || <FormattedMessage id="campaignRequest.withoutNumber" />}
                        </Link>
                    </TableCell>
                    <TableCell>{r.authorName}</TableCell>
                    <TableCell>
                        <Link
                            component={NavLink}
                            underline="none"
                            to={generatePath(clientRoute.campaign, {
                                id: r.campaignId,
                            })}
                        >
                            {r.campaignTitle}
                        </Link>
                    </TableCell>
                    <TableCell>{r.regFormTitle}</TableCell>
                    <TableCell>{r.state}</TableCell>
                    <TableCell>
                        <FormattedDate value={r.created} />
                    </TableCell>
                    <TableCell>{r.sent && <FormattedDate value={r.sent} />}</TableCell>
                    {isExecutorsAllowed && <TableCell>{r.executorsList}</TableCell>}
                    <AuthorizationCheckAll or queries={queries[index]}>
                        <TableCell>
                            <CampaignRequestActionsBtns
                                campaignRow={r}
                                reloadData={model.reloadData}
                                handleEditExecutorDialogOpen={handleEditExecutorDialogOpen}
                                isConfirm={isConfirm}
                                setIsConfirm={setIsConfirm}
                            />
                        </TableCell>
                    </AuthorizationCheckAll>
                </TableRow>
            );
        });
    }, [rows, isExecutorsAllowed, model, handleEditExecutorDialogOpen, queries, isConfirm]);

    return (
        <React.Fragment>
            {open && <CampaignRequestCreateDialog onClose={setModalIsClosed} />}
            <ExecutorEditDialog
                title={editExecutorDialogTitle}
                onClose={setEditExecutorDialogClosed}
                open={editExecutorDialogOpen}
                requestRowModel={requestRowModel}
                setIsConfirm={setIsConfirm}
            />

            <Container maxWidth="lg">
                <Box pt={5.5} pb={15}>
                    <Box pb={5}>
                        <BreadcrumbsComponent breadcrumbsSettings={breadCrumbsSettings} />
                    </Box>
                    <Grid container direction="column" spacing={10}>
                        <Grid item container direction="row" justify="space-between">
                            <Grid item xs={6}>
                                <Typography variant="h1">
                                    <FormattedMessage id="campaignRequest.registryTitle" />
                                </Typography>
                            </Grid>
                            <Grid item xs={6} container spacing={2} justify="flex-end">
                                <Grid item>
                                    <Button
                                        variant="text"
                                        color="primary"
                                        startIcon={
                                            <SvgIcon>
                                                <ClearFilter />
                                            </SvgIcon>
                                        }
                                        onClick={handleClickClearFilterButton}
                                    >
                                        <FormattedMessage id="common.resetFilters" />
                                    </Button>
                                </Grid>
                                <AuthorizationCheck
                                    entityCode={entities.System}
                                    permCode={permissions.System.ExportCampRequestList}
                                >
                                    <Grid item>
                                        <Button
                                            variant="text"
                                            color="primary"
                                            startIcon={<GetApp />}
                                            onClick={exportListXls}
                                        >
                                            <FormattedMessage id="common.export" />
                                        </Button>
                                    </Grid>
                                </AuthorizationCheck>
                                {showCreateRequestButton && (
                                    <AuthorizationCheck
                                        entityCode={entities.System}
                                        permCode={permissions.System.AddCampaignRequest}
                                    >
                                        <Grid item>
                                            <Button color="primary" variant="contained" onClick={setModalIsOpen}>
                                                <FormattedMessage id="campaign.createRequest" />
                                            </Button>
                                        </Grid>
                                    </AuthorizationCheck>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item xs>
                            <CampaignRequestListFilterPanel isExecutorsAllowed={isExecutorsAllowed} model={model} />
                        </Grid>
                        <Grid item>
                            <TableContainer component={Paper}>
                                {model.isLoading && <LinearProgress />}
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <TableSortLabel
                                                    active={sorting.number.isActive}
                                                    direction={sorting.number.direction}
                                                    onClick={model.changeSorting(sorting.number)}
                                                    IconComponent={ExpandMoreIcon}
                                                >
                                                    <Typography>
                                                        <FormattedMessage id="campaignRequest.registryColumns.number" />
                                                    </Typography>
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>
                                                    <FormattedMessage id="campaignRequest.registryColumns.author" />
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <TableSortLabel
                                                    active={sorting.campaign.isActive}
                                                    direction={sorting.campaign.direction}
                                                    onClick={model.changeSorting(sorting.campaign)}
                                                    IconComponent={ExpandMoreIcon}
                                                >
                                                    <Typography>
                                                        <FormattedMessage id="campaignRequest.registryColumns.campaign" />
                                                    </Typography>
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell>
                                                <TableSortLabel
                                                    active={sorting.regForm.isActive}
                                                    direction={sorting.regForm.direction}
                                                    onClick={model.changeSorting(sorting.regForm)}
                                                    IconComponent={ExpandMoreIcon}
                                                >
                                                    <Typography>
                                                        <FormattedMessage id="campaignRequest.registryColumns.regForm" />
                                                    </Typography>
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>
                                                    <FormattedMessage id="campaignRequest.registryColumns.state" />
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <TableSortLabel
                                                    active={sorting.created.isActive}
                                                    direction={sorting.created.direction}
                                                    onClick={model.changeSorting(sorting.created)}
                                                    IconComponent={ExpandMoreIcon}
                                                >
                                                    <Typography>
                                                        <FormattedMessage id="campaignRequest.registryColumns.created" />
                                                    </Typography>
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell>
                                                <TableSortLabel
                                                    active={sorting.sent.isActive}
                                                    direction={sorting.sent.direction}
                                                    onClick={model.changeSorting(sorting.sent)}
                                                    IconComponent={ExpandMoreIcon}
                                                >
                                                    <Typography>
                                                        <FormattedMessage id="campaignRequest.registryColumns.sent" />
                                                    </Typography>
                                                </TableSortLabel>
                                            </TableCell>
                                            {isExecutorsAllowed && (
                                                <TableCell>
                                                    <Typography>
                                                        <FormattedMessage id="campaignRequest.registryColumns.executor" />
                                                    </Typography>
                                                </TableCell>
                                            )}
                                            <TableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className={classes.tableBody}>{renderRows()}</TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TableCell colSpan={12}>
                                                <TotTablePagination
                                                    count={model.rowsCount}
                                                    page={model.pageNumber}
                                                    onChangePage={onChangePage}
                                                    onChangeRowsPerPage={model.onChangePageSize}
                                                    rowsPerPage={model.pageSize}
                                                    rowsPerPageOptions={model.pageSizeOptions}
                                                    pagingInfoMessageId="campaignRequest.registryPagingInfo"
                                                    pagingInfoMessageIdIfNull="campaignRequest.registryPagingInfoIfNull"
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </React.Fragment>
    );
});
