import { action, observable } from 'mobx';

export class HeaderHeightStore {
    @observable headerHeight?: number;

    @action.bound
    setHeaderHeight(height: number): void {
        this.headerHeight = height;
    }
}
