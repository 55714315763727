import { ColumnData, RowsData, TTable, TTableProps, TTableQueryData } from '@platform/ttable';
import { observer } from 'mobx-react';
import React from 'react';
import { useParams } from 'react-router-dom';
import { entities, permissions } from '../../../authSchemeConfig';
import { AuthorizationCheck } from '../../../components';
import { useStore } from '../../../hooks';
import { RequestFormParams, RequestLogType } from '../../../types';

const registryCodeTTable = 'logging';

export type RequestLogTableProps = {
    requestNumber: string;
};

const fetchColumn = async (): Promise<ColumnData<RequestLogType>[]> => {
    return [
        {
            title: {
                ru: 'Дата и время',
                en: 'Created',
            },
            columnCode: 'created',
            columnType: 'datetime',
            disableSort: false,
            disableFilter: false,
            blockHide: true,
            defaultSort: 'desc',
            isLink: false,
        },
        {
            title: {
                ru: 'Событие',
                en: 'Event',
            },
            columnCode: 'changes',
            columnType: 'textfield',
            disableSort: true,
            disableFilter: true,
            blockHide: true,
            isLink: false,
        },
        {
            title: {
                ru: 'Тип события',
                en: 'Event type',
            },
            columnCode: 'eventType.title',
            columnType: 'select',
            disableSort: false,
            disableFilter: false,
            filterSource: {
                sourceType: 'URL',
                value: '/select/eventType',
            },
            blockHide: true,
            filterField: 'eventType.code',
            isLink: false,
        },
        {
            title: {
                ru: 'Логин пользователя',
                en: 'Login',
            },
            columnCode: 'userInfo.login',
            columnType: 'textfield',
            disableSort: false,
            disableFilter: false,
            blockHide: true,
            isLink: false,
        },
        {
            title: {
                ru: 'ФИО пользователя',
                en: 'Name',
            },
            columnCode: 'userInfo.fio',
            columnType: 'select',
            disableSort: false,
            disableFilter: false,
            filterSource: {
                sourceType: 'URL',
                value: '/select/users/v2',
            },
            blockHide: true,
            filterField: 'userInfo.id',
            isLink: false,
        },
    ];
};

const eventTypeFilter = {
    'eventType.title': {
        filterType: 'select',
        columnType: 'select',
        filterField: 'eventType.code',
        value: [
            'changeState',
            'failedCheckRequireDocuments',
            'failedCheckFLK',
            'failedCheckDrugCategory',
            'failedCheckAuthorityDocEndDate',
        ],
    },
};

const requestLogTabVisibilitySettings: TTableProps<RequestLogType>['visibleSettings'] = {
    toolbar: {
        isWithPresets: false,
        isWithAddCustomColumns: false,
        isWithFilters: false,
    },
    column: {
        isWithDraggableColumns: false,
        isWithColumnMenu: false,
    },
};

const emptyAsync = async () => {};

const richQuery = (query: TTableQueryData<RequestLogType>): TTableQueryData<RequestLogType> => {
    return {
        ...query,
        filter: eventTypeFilter,
    };
};

export const RequestLogTab = observer((props: RequestLogTableProps): JSX.Element => {
    const { requestStore, presetStore, intlStore, catalogStore, userStore } = useStore();
    const { requestNumber } = props;
    const { id, tabId, subTabId } = useParams<RequestFormParams>();
    const formId = subTabId || tabId;

    const registry = (
        registryCode: string,
        query: TTableQueryData<RequestLogType>,
    ): Promise<RowsData<RequestLogType>> => {
        return requestStore.registryRequestLog(registryCode, id, richQuery(query));
    };

    const upload = (query: TTableQueryData<RequestLogType>): Promise<void> => {
        return requestStore.uploadRequestLog(richQuery(query), registryCodeTTable, id, requestNumber);
    };

    return (
        <AuthorizationCheck
            entityCode={entities.RequestCustomForm}
            permCode={permissions.RequestCustomForm.ViewLogsInTab}
            entityId={formId}
        >
            <TTable<RequestLogType>
                registryCode={registryCodeTTable}
                fetchData={registry}
                fetchColumns={fetchColumn}
                fetchCatalog={catalogStore.getCatalog}
                presetsSetting={presetStore}
                visibleSettings={requestLogTabVisibilitySettings}
                fetchSelectDataByUrl={catalogStore.getSelectDataByUrl}
                upload={upload}
                lang={intlStore.locale}
                saveListPresets={emptyAsync}
                fetchUserRoleList={userStore.userRoleList}
                isAdmin={false}
            />
        </AuthorizationCheck>
    );
});
