import { Grid } from '@material-ui/core';
import React from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { clientRoute } from '../../../../clientRoute';
import { RouteParamsDefault } from '../../../../types';
import { CountryGroupsCancelButtonWithDialog } from './CountryGroupsCancelButtonWithDialog';
import { CountryGroupsSaveButton } from './CountryGroupsSaveButton';

export const CountryGroupsEditModeButtonsWithDialog = (): JSX.Element => {
    const history = useHistory();
    const { id } = useParams<RouteParamsDefault>();

    const goBack = (): void => {
        if (!id) {
            history.push(clientRoute.countryGroups);
        } else {
            history.push(generatePath(clientRoute.countryGroup, { id }));
        }
    };

    return (
        <React.Fragment>
            <Grid spacing={3} alignItems="center" justify="center" container>
                <Grid item>
                    <CountryGroupsSaveButton />
                </Grid>
                <Grid item>
                    <CountryGroupsCancelButtonWithDialog onConfirm={goBack} />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};
