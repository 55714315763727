import { useFeature } from 'feature-toggle-jsx';
import { observer } from 'mobx-react';
import React, { CSSProperties } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { entities, permissions } from './authSchemeConfig';
import { clientRoute } from './clientRoute';
import {
    AppContentContainer,
    Authentication,
    AuthorizationCheck,
    Footer,
    LoginRedirect,
    NotificationComponent,
    TotAppBar,
} from './components';
import { useStore } from './hooks';
import {
    AuthenticationInfoPage,
    AuthorityDocPages,
    CampaignPages,
    CampaignRequestPages,
    ConsolePage,
    EsiaPages,
    LicensePages,
    LoginDialog,
    Logout,
    NewPasswordPage,
    RecoveryPasswordPage,
    RegistrationConfirmPage,
    RegistrationPage,
    RequestLogPage,
    SamlPages,
    StartPage,
    UserPages,
    QuotasPages,
} from './pages';
import { CountryGroupsPages } from './pages/country-groups';
import { ErrorPage } from './pages/error-page';
import { MainPage } from './pages/main-page';
import { TotBackground } from './TotBackground';
import { ErrorCode } from './types';

const appContainerStyle: CSSProperties = { display: 'flex', flexDirection: 'column', height: '100%' };

export const App = observer((): JSX.Element => {
    const { personStore, intlStore: intl } = useStore();
    const [startPage] = useFeature('startPage');
    const [redirectToCampaignsAllUsers] = useFeature('redirectToCampaignsAllUsers');

    return (
        <div style={appContainerStyle}>
            <TotAppBar />
            <AppContentContainer>
                <Switch>
                    <Route path={clientRoute.licenses} key={clientRoute.licenses}>
                        <LicensePages />
                    </Route>
                    <Route path={clientRoute.login} key={clientRoute.login}>
                        <TotBackground>
                            <LoginDialog />
                        </TotBackground>
                    </Route>
                    <Route path={clientRoute.requestLog}>
                        <Authentication>
                            <RequestLogPage />
                        </Authentication>
                    </Route>
                    <Route path={clientRoute.authorityDocs}>
                        <AuthorityDocPages />
                    </Route>
                    <Route path={clientRoute.campaigns}>
                        <CampaignPages />
                    </Route>
                    <Route path={clientRoute.requests}>
                        <CampaignRequestPages />
                    </Route>
                    <Route path={clientRoute.quotas}>
                        <QuotasPages />
                    </Route>
                    <Route path={clientRoute.users}>
                        <UserPages />
                    </Route>
                    <Route path={clientRoute.countryGroups}>
                        <CountryGroupsPages />
                    </Route>
                    <Route path={clientRoute.console}>
                        <Authentication>
                            <AuthorizationCheck
                                entityCode={entities.System}
                                permCode={permissions.System.Administration}
                                isWithRedirect={true}
                            >
                                <ConsolePage />
                            </AuthorizationCheck>
                        </Authentication>
                    </Route>
                    <Route path={clientRoute.esia}>
                        <EsiaPages />
                    </Route>
                    <Route path={clientRoute.saml}>
                        <SamlPages />
                    </Route>
                    <Route path={clientRoute.newPassword}>
                        <NewPasswordPage />
                    </Route>
                    <Route path={clientRoute.recoveryPassword}>
                        <RecoveryPasswordPage />
                    </Route>
                    <Route path={clientRoute.authenticationInfo}>
                        <AuthenticationInfoPage />
                    </Route>
                    <Route path={clientRoute.registrationConfirm}>
                        <RegistrationConfirmPage />
                    </Route>
                    <Route path={clientRoute.registration}>
                        <RegistrationPage />
                    </Route>
                    <Route path={clientRoute.loginRedirect}>
                        <LoginRedirect />
                    </Route>
                    <Route path={clientRoute.logout}>
                        <Logout />
                    </Route>
                    <Route path={clientRoute.notFound}>
                        <ErrorPage
                            errorCode={ErrorCode.notFound}
                            errorText={intl.formatMessage('notFoundPage.notExist')}
                        />
                    </Route>
                    <Route path={clientRoute.notAllowed}>
                        <ErrorPage
                            errorCode={ErrorCode.notAllowed}
                            errorText={intl.formatMessage('common.actionNotAllowed')}
                        />
                    </Route>
                    <Route path={clientRoute.mainPage}>
                        <MainPage />
                    </Route>
                    <Route path={clientRoute.root} exact>
                        <TotBackground>
                            {startPage && <StartPage />}
                            {!startPage && (
                                <Authentication redirectUrl={clientRoute.loginRedirect}>
                                    <Redirect
                                        to={
                                            redirectToCampaignsAllUsers
                                                ? clientRoute.campaigns
                                                : personStore.redirectPath
                                        }
                                    />
                                </Authentication>
                            )}
                        </TotBackground>
                    </Route>
                    <Route>
                        <Redirect to={clientRoute.notFound} />
                    </Route>
                </Switch>
                <NotificationComponent />
            </AppContentContainer>
            <Footer />
        </div>
    );
});
