import { IconButton, MenuItem, SvgIcon } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router-dom';
import { entities, permissions } from '../../../authSchemeConfig';
import { clientRoute } from '../../../clientRoute';
import { ActionMenuItem, AuthorizationCheck, ConfirmationDialog, MenuButton } from '../../../components';
import { useStore } from '../../../hooks';
import { ReactComponent as DotMenu } from '../../../resources/images/icons/dot-menu.svg';
import { QuotaTableRowDTO } from '../../../types';

export type QuotaListActionsButtonsProps = {
    quotaRow: QuotaTableRowDTO;
    reloadData: () => void;
    isConfirm?: boolean;
    setIsConfirm?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const QuotaListActionsButtons = observer((props: QuotaListActionsButtonsProps): JSX.Element => {
    const { quotaRow, reloadData, isConfirm, setIsConfirm } = props;
    const { id } = quotaRow;

    const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] = useState<boolean>(false);

    const { quotasStore } = useStore();

    useEffect(() => {
        if (setIsConfirm && isConfirm) {
            setIsConfirm(false);
            reloadData();
        }
    }, [isConfirm, setIsConfirm, reloadData]);

    const deleteQuota = useCallback(async (): Promise<void> => {
        try {
            await quotasStore.deleteQuota(id).finally(reloadData);
        } catch (error) {
            console.error(error);
        }
    }, [reloadData, id, quotasStore]);

    const openDeleteConfirmationDialog = (): void => {
        setIsDeleteConfirmationDialogOpen(true);
    };

    const closeDeleteConfirmationDialog = (): void => {
        setIsDeleteConfirmationDialogOpen(false);
    };

    const onDeleteConfirm = async (): Promise<void> => {
        await deleteQuota();
        closeDeleteConfirmationDialog();
    };

    const onDeleteCancel = (): void => {
        closeDeleteConfirmationDialog();
    };

    const renderActionItems = useCallback((): ((hideMenu?: () => void) => ReactNode[]) => {
        const { id } = quotaRow;

        return (hideMenu): ReactNode[] => {
            const onDeleteClick = (): void => {
                hideMenu && hideMenu();
                openDeleteConfirmationDialog();
            };

            return [
                <ActionMenuItem messageId="common.edit" path={generatePath(clientRoute.quotasEdit, { id })} />,
                <MenuItem dense button={true} onClick={onDeleteClick}>
                    <FormattedMessage id="common.delete" />
                </MenuItem>,
            ];
        };
    }, [quotaRow]);

    const renderActionsButton = (onClick: (event: React.MouseEvent<HTMLButtonElement>) => void): JSX.Element => {
        return (
            <IconButton onClick={onClick}>
                <SvgIcon>
                    <DotMenu />
                </SvgIcon>
            </IconButton>
        );
    };

    return (
        <AuthorizationCheck
            key="edit"
            entityCode={entities.System}
            entityId={id}
            permCode={permissions.Quotas.ManageQuotas}
        >
            <React.Fragment>
                <MenuButton renderButton={renderActionsButton} renderMenuItems={renderActionItems()} />
                <ConfirmationDialog
                    id="delete"
                    open={isDeleteConfirmationDialogOpen}
                    title={<FormattedMessage id="common.confirmDeletion" />}
                    message={
                        <FormattedMessage
                            id="quotas.confirmDeletionQuotaInfoText"
                            values={{ title: quotaRow.customData.title }}
                        />
                    }
                    onConfirm={onDeleteConfirm}
                    onCancel={onDeleteCancel}
                    keepMounted
                />
            </React.Fragment>
        </AuthorizationCheck>
    );
});
