import { Link } from '@material-ui/core';
import { Preset, TTable, TTableQueryData, TTableRow } from '@platform/ttable';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, NavLink } from 'react-router-dom';
import { CellProps } from 'react-table';
import { entities, permissions } from '../../../authSchemeConfig';
import { clientRoute } from '../../../clientRoute';
import { AuthorizationCheck, ExportRegistryDialog } from '../../../components';
import { useStore } from '../../../hooks';
import { AuthorityDocRequestDTO, AuthorityDocTableRowDTO } from '../../../types';

export const AuthorityDocList = observer((): JSX.Element => {
    const { presetStore, intlStore, catalogStore, userStore, authorityDocStore } = useStore();

    const saveListPresets = <RowType extends TTableRow>(settings: Preset<RowType>[]): Promise<void> =>
        presetStore.saveListPresets(settings);

    const [openUploadDialog, setOpenUploadDialog] = useState<boolean>(false);
    const [uploadTableQuery, setUploadTableQuery] = useState<TTableQueryData<AuthorityDocTableRowDTO>>();

    const handleUpload = async (tableQuery: TTableQueryData<TTableRow>): Promise<void> => {
        setOpenUploadDialog(true);
        setUploadTableQuery(tableQuery);
    };

    const handleCloseUploadDialog = () => {
        setOpenUploadDialog(false);
        setUploadTableQuery(undefined);
    };

    const cellFormatters = {
        'customData.number': function number(
            p: React.PropsWithChildren<CellProps<AuthorityDocTableRowDTO, any>>,
        ): JSX.Element {
            return (
                <Link
                    component={NavLink}
                    underline="always"
                    to={generatePath(clientRoute.authorityDoc, { id: p.row.original.id })}
                >
                    {p.row.original.customData.number || <FormattedMessage id="campaignRequest.withoutNumber" />}
                </Link>
            );
        },
        'customData.requests': function linkedRequests(
            p: React.PropsWithChildren<CellProps<AuthorityDocTableRowDTO, any>>,
        ): JSX.Element[] {
            // TODO: это форматтер для столбца связанных лицензий. Из-за разделения роутинга /requests на отдельно /requests и /licenses нужно будет на бэке переименовать столбец в customData.licenses и засинхронить правки с фронтом
            const licenses = p.row.original.customData.requests;
            return !Array.isArray(licenses)
                ? []
                : licenses.map((license: AuthorityDocRequestDTO) => {
                      return (
                          <Link
                              component={NavLink}
                              underline="always"
                              to={generatePath(clientRoute.license, {
                                  id: license.id,
                              })}
                          >
                              {license.number}
                          </Link>
                      );
                  });
        },
    };

    return (
        <React.Fragment>
            <AuthorizationCheck entityCode={entities.System} permCode={permissions.System.ViewAuthorityDocs}>
                {(allowed: boolean): JSX.Element => (
                    <TTable<AuthorityDocTableRowDTO>
                        registryCode="authDocs"
                        fetchData={authorityDocStore.loadAuthorityDocs}
                        fetchColumns={authorityDocStore.loadAuthorityDocsColumns}
                        presetsSetting={presetStore}
                        fetchCatalog={catalogStore.getCatalog}
                        fetchSelectDataByUrl={catalogStore.getSelectDataByUrl}
                        upload={handleUpload}
                        lang={intlStore.locale}
                        cellFormatters={cellFormatters}
                        saveListPresets={saveListPresets}
                        fetchUserRoleList={userStore.userRoleList}
                        isAdmin={allowed}
                    />
                )}
            </AuthorizationCheck>
            <ExportRegistryDialog
                openUploadDialog={openUploadDialog}
                uploadTableQuery={uploadTableQuery}
                handleCloseExportDialog={handleCloseUploadDialog}
                exportXlsx={authorityDocStore.exportAuthorityDocXlsx}
                exportDoc={authorityDocStore.exportAuthorityDocDoc}
            />
        </React.Fragment>
    );
});
