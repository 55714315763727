import { Button, Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { useStore } from '../../../../../hooks';
import { CampaignRequestHeaderButtonWithDialog } from './CampaignRequestHeaderButtonWithDialog';

export type CampaignRequestHeaderAppointmentButtonsProps = {
    addActualAppointment: boolean;
    updateActualAppointment: boolean;
    handleConfirm: () => void;
    onModalOpen: () => void;
};

export const CampaignRequestHeaderAppointmentButtons = observer(
    (props: CampaignRequestHeaderAppointmentButtonsProps): JSX.Element => {
        const { addActualAppointment, handleConfirm, updateActualAppointment, onModalOpen } = props;
        const { intlStore: intl } = useStore();

        const chooseExecutor = intl.formatMessage('campaignRequest.chooseExecutor');
        const executorsTitle = intl.formatMessage('campaignRequest.dialog.executorsTitle');
        const executorsMessage = intl.formatMessage('campaignRequest.dialog.executorsMessage');

        return (
            <Grid item container wrap="nowrap" spacing={1}>
                {addActualAppointment && (
                    <Grid item>
                        <CampaignRequestHeaderButtonWithDialog
                            title={executorsTitle}
                            message={executorsMessage}
                            id="takeToWork"
                            onConfirm={handleConfirm}
                        />
                    </Grid>
                )}

                {addActualAppointment && updateActualAppointment && (
                    <Grid item>
                        <Button onClick={onModalOpen} color="secondary" size="small" variant="contained">
                            {chooseExecutor}
                        </Button>
                    </Grid>
                )}
            </Grid>
        );
    },
);
