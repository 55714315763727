import { AxiosError } from 'axios';

export enum ErrorCode {
    badRequest = 400,
    notFound = 404,
    notAllowed = 403,
    serverError = 500,
}

export type ShouldUseCustomErrorHandlerFunctionType = (shouldUseCustomHandler: boolean) => AxiosErrorHandler;
export type AxiosErrorHandler = (error: AxiosError) => Promise<AxiosError>;
