import { action, computed, observable } from 'mobx';
import { CodeTitle } from '../CodeTitle';
import { AccessPermission } from '../RequestFormSettingModel';
import { ChangeEvent } from 'react';
import { IntlStore } from '../../store';

export class CampaingPfTemplateTabModel {
    @observable intl: IntlStore;

    @observable permissions: AccessPermission[] = [];

    @observable permission: CodeTitle = { code: '', title: '' };
    @observable states: CodeTitle[] = [];
    @observable editPermission: boolean = false;

    @observable accessPermissionsValidationStarted: boolean = false;
    @observable validationStarted: boolean = false;

    @observable statesList: CodeTitle[] = [];
    @observable permissionsList: CodeTitle[] = [];

    constructor(intl: IntlStore) {
        this.intl = intl;
    }

    @action.bound
    addAccessPermission() {
        this.accessPermissionsValidationStarted = true;

        if (!this.isAccessPermissionsValid) {
            return;
        }

        this.permissions.push({
            permission: this.permission,
            states: this.states,
            edit: this.editPermission,
        });
        this.permission = { code: '', title: '' };
        this.states = [];
        this.editPermission = false;
        this.accessPermissionsValidationStarted = false;
    }

    @action.bound
    deleteAccessPermission(index: number): void {
        this.permissions.splice(index, 1);
    }

    @action.bound
    handleUpdatePermission(index: number, updatedPermission: AccessPermission): void {
        this.permissions[index] = updatedPermission;
    }

    @action.bound
    onChangePermission(e: ChangeEvent<{}>, value: CodeTitle | any): void {
        this.permission = value ?? { code: '', title: '' };
    }

    @action.bound
    onChangeStates(e: ChangeEvent<{}>, value: CodeTitle[] | any): void {
        this.states = value ?? [];
    }

    @action.bound
    onChangeEditPermission() {
        this.editPermission = !this.editPermission;
    }

    @computed
    get isAccessPermissionsValid(): boolean {
        return !!this.permission.code;
    }

    @computed
    get isValid(): boolean {
        if (this.isAccessPermissionsValid && !this.permissions.length) {
            return false;
        }

        return !!this.permissions.length;
    }

    @computed
    get requiredMessage(): string {
        return this.intl.formatMessage('validation.required');
    }

    @computed
    get permissionMessage(): string {
        const { accessPermissionsValidationStarted, permission, requiredMessage, validationStarted } = this;

        if (accessPermissionsValidationStarted && !permission.code) {
            return requiredMessage;
        }

        if (validationStarted && !this.permissions.length) {
            return requiredMessage;
        }

        return '';
    }
}
