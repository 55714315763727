export enum RequestCheckResult {
    passed = 'PASSED',
    failed = 'FAILED',
}

export type RequestCheckRowData = {
    rowIndex: number;
    dateTime: string; // Instant с бэка
    name: string;
    description: string;
    result: RequestCheckResult;
};

export type RequestChecks = Array<{ groupTitle: string; rows: RequestCheckRowData[] }>;
