import { FormDTO } from '@platform/formiojs-react';
import { ColumnData, RowsData, TTableQueryData, TTableRow } from '@platform/ttable';
import { History } from 'history';
import fileDownload from 'js-file-download';
import { action, computed, observable } from 'mobx';
import { apiConfigs } from '../apiConfigs';
import { clientRoute } from '../clientRoute';
import { AuthorityDocDTO, AuthorityDocTableRowDTO, AuthorityDocRequestDTO } from '../types';
import { handleAxiosErrorByResponseStatus } from '../utils';
import { ApiStore } from './ApiStore';
import { NotificationStore } from './NotificationStore';
import { RootStore } from './RootStore';

export class AuthorityDocStore {
    @observable protected rootStore: RootStore;
    @observable protected api: ApiStore;
    @observable protected notificationStore: NotificationStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.api = rootStore.api;
        this.notificationStore = rootStore.notificationStore;
    }

    @computed
    get history(): History {
        return this.rootStore.history;
    }

    @action.bound
    loadAuthorityDoc(authorityDocId: string): Promise<AuthorityDocDTO> {
        return this.api
            .client(apiConfigs.getAuthorityDoc(authorityDocId))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                    404: () => this.history.replace(clientRoute.notFound),
                }),
            );
    }

    @action.bound
    loadAuthorityDocForm(docId: string): Promise<FormDTO> {
        return this.api
            .client(apiConfigs.getAuthorityDocForm(docId))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                    404: () => this.history.replace(clientRoute.notFound),
                }),
            );
    }

    @action.bound
    loadAuthorityDocRequests(docId: string): Promise<AuthorityDocRequestDTO[]> {
        return this.api
            .client(apiConfigs.getAuthorityDocRequests(docId))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                    404: () => this.history.replace(clientRoute.notFound),
                }),
            );
    }

    @action.bound
    loadAuthorityDocLicenses(docId: string): Promise<AuthorityDocRequestDTO[]> {
        return this.api
            .client(apiConfigs.getAuthorityDocLicenses(docId))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                    404: () => this.history.replace(clientRoute.notFound),
                }),
            );
    }

    @action.bound
    loadAuthorityDocs(
        registryCode: string,
        query: TTableQueryData<TTableRow>,
    ): Promise<RowsData<AuthorityDocTableRowDTO>> {
        return this.api.client(apiConfigs.authorityDocs(registryCode, query)).then((r) => r.data);
    }

    @action.bound
    loadAuthorityDocsColumns(): Promise<ColumnData<AuthorityDocTableRowDTO>[]> {
        return this.api.client(apiConfigs.authorityDocsColumns()).then((r) => r.data);
    }

    @action.bound
    async exportAuthorityDocXlsx<RowType extends TTableRow>(tableQueryData: TTableQueryData<RowType>): Promise<void> {
        try {
            const { data } = await this.api.client(apiConfigs.exportAuthorityDocXlsx(tableQueryData));
            const filename = this.rootStore.intlStore.formatMessage('authorityDoc.plural');
            await fileDownload(data, `${filename}.xlsx`);
        } catch (error) {
            console.error(error);
        }
    }

    @action.bound
    async exportAuthorityDocDoc<RowType extends TTableRow>(tableQueryData: TTableQueryData<RowType>): Promise<void> {
        try {
            const { data } = await this.api.client(apiConfigs.exportAuthorityDocDoc(tableQueryData));
            const filename = this.rootStore.intlStore.formatMessage('authorityDoc.plural');
            await fileDownload(data, `${filename}.doc`);
        } catch (error) {
            console.error(error);
        }
    }
}
