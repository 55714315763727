import { TableCell, TextField } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { ChangeEventHandler, useEffect } from 'react';
import { useQuotaContext } from '../../../../../../hooks';
import { QuotaParticipant, QuotaParticipantVolume } from '../../../../../../models';

export type QuotaVTDRowVolumeCellProps = {
    volumeInstance: QuotaParticipantVolume;
    participant: QuotaParticipant;
};

export const QuotaVTDRowVolumeCell = observer((props: QuotaVTDRowVolumeCellProps): JSX.Element => {
    const { volumeInstance, participant } = props;
    const { volume, setVolume } = volumeInstance;
    const { quotasModel, isViewOnly } = useQuotaContext();
    const { quota } = quotasModel;
    const { countryGroups } = quota;
    const { updateCommonRowVolume } = participant;

    useEffect(() => {
        updateCommonRowVolume();
    }, [volume, countryGroups]);

    const handleVolumeChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        setVolume(e.target.value);
    };

    return (
        <TableCell>
            <TextField
                variant="outlined"
                fullWidth
                value={volume}
                onChange={handleVolumeChange}
                disabled={isViewOnly}
            />
        </TableCell>
    );
});
