import { Box, Grid, IconButton } from '@material-ui/core';
import { NotificationWidget } from '@platform/notification-widget';
import { useFeature } from 'feature-toggle-jsx';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { entities, permissions } from '../../../authSchemeConfig';
import { useStore } from '../../../hooks';
import { ReactComponent as BurgerIconOpen } from '../../../resources/images/icons/burger-icon-open.svg';
import { ReactComponent as BurgerIconClosed } from '../../../resources/images/icons/burger-icon.svg';
import { ReactComponent as QuestionIcon } from '../../../resources/images/icons/question-circle.svg';
import { AllowedLink, AppHeaderLinkData } from '../../../store';
import { AuthorizationCheck } from '../../AuthorizationCheck';
import { BurgerMenu } from '../BurgerMenu';
import { SettingsButton } from '../SettingsButton';
import { UserButton } from '../UserButton';
import { AppHeaderLinksList } from './AppHeaderLinksList';
import { HeaderLinksGridWrapper, HeaderLinksListWrapper } from './HeaderLinksWrappers';

export type AppHeaderLinksProps = {
    isOpenMenu: boolean;
    handleBurgerChange: () => void;
    isSmSize: boolean;
};

export const additionalLinksInj: AppHeaderLinkData[] = [];

export const AppHeaderLinks = observer((props: AppHeaderLinksProps): JSX.Element => {
    const { isSmSize, isOpenMenu, handleBurgerChange } = props;
    const { personStore, intlStore, lkNotificationStore, authorizationStore, headerLinksStore } = useStore();
    const { additionalLinks, mainLinks, avgLinkWidth } = headerLinksStore;
    const { loadNotifications, loadCountNotViewedMessages, changeViewed, deleteNotification } = lkNotificationStore;

    const [helpIcon] = useFeature('helpIcon');
    const [notificationIcon] = useFeature('notificationIcon');

    const [verifiedLinks, setVerifiedLinks] = useState<AppHeaderLinkData[]>([]);
    const [visibleLinks, setVisibleLinks] = useState<AppHeaderLinkData[]>([]);
    const [hiddenLinks, setHiddenLinks] = useState<AppHeaderLinkData[]>([]);

    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        personStore.getInfo();
    }, [personStore]);

    const updateNavLinks = useCallback(
        (linksCount: number): void => {
            if (isSmSize) {
                setVisibleLinks([]);
                setHiddenLinks([...verifiedLinks.slice(0)]);
                return;
            }
            setVisibleLinks([...verifiedLinks.slice(0, linksCount)]);
            setHiddenLinks([...verifiedLinks.slice(linksCount)]);
        },
        [verifiedLinks, isSmSize],
    );

    const handleNavbarChange = useCallback((): void => {
        const navbarWrapper = wrapperRef.current;
        if (!navbarWrapper) {
            return;
        }

        const wrapperWidth = navbarWrapper.clientWidth;

        const verifiedLinksCount = verifiedLinks.length;
        const maxLinksCount = Math.floor(wrapperWidth / avgLinkWidth);
        const linksCount = verifiedLinksCount < maxLinksCount ? verifiedLinksCount : maxLinksCount;

        updateNavLinks(linksCount);
    }, [verifiedLinks, avgLinkWidth, updateNavLinks]);

    const getQueriesArray = useCallback((): Promise<AllowedLink>[] => {
        const { check } = authorizationStore;
        const allLinks = [...additionalLinks, ...mainLinks];
        return allLinks.map((link: AppHeaderLinkData) => {
            if (link.query) {
                return check(link.query).then((allowed: boolean) => ({
                    link,
                    allowed,
                }));
            }
            return Promise.resolve({ link, allowed: true });
        });
    }, [authorizationStore, mainLinks, additionalLinks]);

    const checkLinksPermission = useCallback((): void => {
        Promise.all(getQueriesArray()).then((links: AllowedLink[]) => {
            const allowedLinks = links.filter(({ link, allowed }) => allowed);
            setVerifiedLinks(allowedLinks.map(({ link }) => link));
        });
    }, [getQueriesArray]);

    useEffect(() => {
        checkLinksPermission();
    }, [checkLinksPermission, personStore.user.id]);

    useEffect(() => {
        handleNavbarChange();
        window.addEventListener('resize', handleNavbarChange);

        return () => {
            window.removeEventListener('resize', handleNavbarChange);
        };
    }, [verifiedLinks, handleNavbarChange]);

    return (
        <Box pt={0.5}>
            <Grid container direction="row" justify="flex-end" spacing={3} wrap="nowrap">
                <HeaderLinksGridWrapper item>
                    <HeaderLinksListWrapper
                        container
                        direction="row"
                        alignItems="center"
                        justify={isSmSize ? 'center' : 'flex-end'}
                        spacing={3}
                        key={personStore.user.id}
                        ref={wrapperRef}
                    >
                        <AppHeaderLinksList elementsArray={visibleLinks} />
                    </HeaderLinksListWrapper>
                    <Box pt={3}>
                        <BurgerMenu isSmSize={isSmSize} isOpenMenu={isOpenMenu} hiddenLinksArray={hiddenLinks} />
                    </Box>
                </HeaderLinksGridWrapper>
                {!!hiddenLinks.length ? (
                    <Grid item>
                        <IconButton color="secondary" onClick={handleBurgerChange} className="t-toggleMenu-button">
                            {isOpenMenu ? <BurgerIconOpen /> : <BurgerIconClosed />}
                        </IconButton>
                    </Grid>
                ) : null}
                {helpIcon && (
                    <Grid item>
                        <IconButton color="secondary">
                            <QuestionIcon />
                        </IconButton>
                    </Grid>
                )}
                <SettingsButton />
                {notificationIcon && (
                    <AuthorizationCheck
                        key={personStore.user.id}
                        entityCode={entities.System}
                        permCode={permissions.System.ViewNotifications}
                    >
                        <Grid item>
                            <NotificationWidget
                                locale={intlStore.locale}
                                loadNotifications={loadNotifications}
                                loadCountNotViewedMessages={loadCountNotViewedMessages}
                                changeViewed={changeViewed}
                                deleteNotification={deleteNotification}
                                key={personStore.person.userId}
                            />
                        </Grid>
                    </AuthorizationCheck>
                )}
                <Grid item>
                    <UserButton />
                </Grid>
            </Grid>
        </Box>
    );
});
