import styled from 'styled-components';
import { Box, Typography, withTheme } from '@material-ui/core';
import React, { PropsWithChildren } from 'react';
import { ThemedComponentProps } from '@material-ui/core/styles/withTheme';

export const CardLabel = withTheme(({ theme, children }: PropsWithChildren<ThemedComponentProps>) => {
    return (
        <Box color={theme?.variables.palette.main}>
            <Typography variant="body1" component="span">
                {children}
            </Typography>
        </Box>
    );
});

export const CardValue = ({ children }: PropsWithChildren<{}>) => {
    return (
        <Typography variant="body2" component="span">
            {children}
        </Typography>
    );
};

export const CardTitle = styled(Typography)`
    font-size: 15px;
    line-height: 22px;
    font-weight: bold;
    word-break: break-word;
`;
