import { Box, Container, Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { StateBox, TotObjectHeader } from '../../../../components';
import { useObjectHeaderStyles } from '../../../../hooks';
import { AuthorityDocModel } from '../../../../models';

export type AuthorityDocHeaderProps = {
    model: AuthorityDocModel;
};

export const AuthorityDocHeader = observer((props: AuthorityDocHeaderProps): JSX.Element => {
    const { model } = props;
    const { state, number, date } = model;
    const classes = useObjectHeaderStyles();

    return (
        <TotObjectHeader>
            <Container maxWidth="lg">
                <Grid container spacing={10} alignItems="center">
                    <Grid item xs={8}>
                        <Grid container spacing={3} direction="column">
                            <Grid item>
                                <Typography variant="h1">
                                    <FormattedMessage id="authorityDoc.docN" values={{ number }} />
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle1" component="p">
                                    <Box display="flex" flexWrap="wrap" component="span">
                                        <span className={classes.lightText}>
                                            <FormattedMessage id="authorityDoc.date" />
                                        </span>
                                        <Box fontWeight={600} pl={2} component="span">
                                            {date ? (
                                                <FormattedDate value={date} />
                                            ) : (
                                                <FormattedMessage id="common.noData" />
                                            )}
                                        </Box>
                                    </Box>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid
                            container
                            spacing={3}
                            direction="column"
                            wrap="nowrap"
                            justify="center"
                            className={classes.rightBlockContainer}
                        >
                            <Grid item>
                                <Box>
                                    <Typography variant="body2">
                                        <FormattedMessage id="common.State" />
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item>
                                        <StateBox>{state}</StateBox>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </TotObjectHeader>
    );
});
