import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Box, CircularProgress, Paper, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { fromPromise } from 'mobx-utils';
import { useStore } from '../../../hooks';

export const EsiaGetAuthUrlPage = observer(
    (): JSX.Element => {
        const { authenticationStore } = useStore();
        const [getAuthUrl] = useState<Promise<string>>(authenticationStore.esiaGetAuthUrl());

        const redirectToAuthUrl = (authUrl: string): JSX.Element => {
            window.location.href = authUrl;
            return <React.Fragment />;
        };

        return fromPromise(getAuthUrl).case({
            pending: () => <CircularProgress />,
            fulfilled: redirectToAuthUrl,
            rejected: () => (
                <Paper elevation={0}>
                    <Box p={12}>
                        <Typography variant="h5">
                            <Box fontWeight="fontWeightBold">
                                <FormattedMessage id="authentication.error" />
                            </Box>
                        </Typography>
                    </Box>
                </Paper>
            ),
        });
    },
);
