import { ObjectRoutesContextType } from './types';

class ClientRoute {
    root = '/';
    notFound = '/404';
    notAllowed = '/403';
    logout = '/logout';

    mainPage = '/main-page';

    campaigns = '/campaigns';
    campaign = '/campaigns/:id';
    createCampaign = '/campaigns/:id/create';
    campaignSettings = '/campaigns/:id/settings';
    campaignSettingsEdit = '/campaigns/:id/settings/edit';
    campaignRegForms = '/campaigns/:id/settings/rf';
    campaignRegForm = '/campaigns/:id/settings/rf/:rfId/info';
    campaignRegFormExecutors = '/campaigns/:id/settings/rf/:rfId/executors';
    campaignRegFormTabsSettings = '/campaigns/:id/settings/rf/:rfId/tabsSettings';
    campaignRequestFormTabSetting = '/campaigns/:id/settings/rf/:rfId/tabsSettings/:settingId/edit';
    campaignRequestFormTabSettingCreate = '/campaigns/:id/settings/rf/:rfId/tabsSettings/:settingId/create';
    campaignRegFormTemplates = '/campaigns/:id/settings/rf/:rfId/templates';
    campaignRegFormTemplatesEdit = '/campaigns/:id/settings/rf/:rfId/templates/:templateId/settings/:settingsId/edit';
    campaignRegFormTemplatesCreate = '/campaigns/:id/settings/rf/:rfId/templates/settings/:settingsId/create';
    campaignRegFormTemplatesPermissionSettings =
        '/campaigns/:id/settings/rf/:rfId/templates/settings/:settingsId/permissionSettings';
    campaignRegFormCreate = '/campaigns/:id/settings/rf/:rfId/create';
    campaignRegFormEdit = '/campaigns/:id/settings/rf/:rfId/edit';
    campaignDocs = '/campaigns/:id/settings/docs';
    campaignManagement = '/campaigns/:id/settings/management';
    generalSettings = '/campaigns/:id/settings/general';

    regFormSelect = '/campaigns/:campaignId/regForms';

    requests = '/requests';
    requestsOld = '/requests/old';
    request = '/requests/:id';
    requestTab = '/requests/:id/tab/:tabId';
    requestSubTab = '/requests/:id/tab/:tabId/subTab/:subTabId';
    requestEdit = '/requests/:id/edit';
    requestTabEdit = '/requests/:id/tab/:tabId/edit';
    requestSubTabEdit = '/requests/:id/tab/:tabId/subTab/:subTabId/edit';
    requestApprovalSheets = '/requests/:id/approval';

    requestLog = '/request-log/:id';

    licenses = '/licenses';
    license = '/licenses/:id';
    licenseTab = '/licenses/:id/tab/:tabId';
    licenseSubTab = '/licenses/:id/tab/:tabId/subTab/:subTabId';
    licenseEdit = '/licenses/:id/edit';
    licenseTabEdit = '/licenses/:id/tab/:tabId/edit';
    licenseSubTabEdit = '/licenses/:id/tab/:tabId/subTab/:subTabId/edit';
    licenseApprovalSheets = '/licenses/:id/approval';

    users = '/users';
    user = '/users/:id';
    userEdit = '/users/:id/edit';

    console = '/console';

    login = '/log-in';
    loginRedirect = '/login';
    recoveryPassword = '/recovery';
    newPassword = '/recovery/:token';
    registration = '/registration';
    authenticationInfo = '/info';
    registrationInfoSuccess = '/info/registration/success';
    registrationInfoExpired = '/info/registration/expired';
    registrationInfoNotFound = '/info/registration/404';
    recoveryPasswordInfoSuccess = '/info/recovery-password/success';
    registrationConfirm = '/registration/confirm/:token';

    esia = '/esia';
    esiaGetAuthUrl = '/esia/auth-url';
    esiaLogin = '/esia/login';

    saml = '/saml';
    samlGetAuthUrl = '/saml/:provider/auth-url';

    authorityDocs = '/authority-docs';
    authorityDoc = '/authority-docs/:id';

    quotas = '/quotas';
    quotasCreate = '/quotas/new/create';
    quotasEdit = '/quotas/:id/edit';
    quotasQuota = '/quotas/:id';

    countryGroups = '/country-groups';
    countryGroupCreate = '/country-groups/new/create';
    countryGroupEdit = '/country-groups/:id/edit';
    countryGroup = '/country-groups/:id';

    filteringWithCampaign = (campaignId: string): string => this.requestsOld + `?campaignId=${campaignId}`;
}

export const clientRoute = new ClientRoute();

export const requestRoutes: ObjectRoutesContextType = {
    objects: clientRoute.requests,
    object: clientRoute.request,
    objectTab: clientRoute.requestTab,
    objectSubTab: clientRoute.requestSubTab,
    objectEdit: clientRoute.requestEdit,
    objectTabEdit: clientRoute.requestTabEdit,
    objectSubTabEdit: clientRoute.requestSubTabEdit,
    objectApprovalSheets: clientRoute.requestApprovalSheets,
};

export const licenseRoutes: ObjectRoutesContextType = {
    objects: clientRoute.licenses,
    object: clientRoute.license,
    objectTab: clientRoute.licenseTab,
    objectSubTab: clientRoute.licenseSubTab,
    objectEdit: clientRoute.licenseEdit,
    objectTabEdit: clientRoute.licenseTabEdit,
    objectSubTabEdit: clientRoute.licenseSubTabEdit,
    objectApprovalSheets: clientRoute.licenseApprovalSheets,
};
