import { AxiosError } from 'axios';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Transition } from '../../models';
import { ConfirmationDialog } from '../ConfirmationDialog';

export type LifeCycleTransitionConfirmationDialogProps = {
    objectId: string;
    isModalOpen: boolean;
    setModalIsClosed: () => void;
    transition: Transition;
    lifeCycleTransition: () => Promise<void>;
    handleSuccessTransition: () => void;
    handleErrorTransition: (error: AxiosError) => void;
};

export const LifeCycleTransitionConfirmationDialog = observer(
    (props: LifeCycleTransitionConfirmationDialogProps): JSX.Element => {
        const {
            transition,
            isModalOpen,
            setModalIsClosed,
            lifeCycleTransition,
            handleErrorTransition,
            handleSuccessTransition,
        } = props;

        const handleConfirm = (): Promise<void> => {
            return lifeCycleTransition()
                .then(handleSuccessTransition)
                .catch(handleErrorTransition)
                .finally(setModalIsClosed);
        };

        return (
            <ConfirmationDialog
                id="confirm-transition"
                onCancel={setModalIsClosed}
                onConfirm={handleConfirm}
                message={
                    <FormattedMessage id="common.lifeCycleConfirmText" values={{ toState: transition.toStateTitle }} />
                }
                open={isModalOpen}
                title={<FormattedMessage id="common.lifeCycleConfirmTitle" />}
                keepMounted={true}
                confirmText={transition.params.title}
            />
        );
    },
);
