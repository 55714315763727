import { action, computed, observable } from 'mobx';

export class PaginationModel<PaginationData extends {}> {
    @observable paginationData: PaginationData[] = [];
    @observable currentPage = 0;
    @observable pageSize = 0;

    @computed
    get pagesCount(): number {
        return Math.ceil(this.paginationData.length / this.pageSize);
    }

    @computed
    get renderItems(): PaginationData[] {
        const renderItems: PaginationData[] = [];
        const lengthItems = this.paginationData.length;
        if (this.currentPage !== 0) {
            const from = (this.currentPage - 1) * this.pageSize;
            const to = from + this.pageSize;
            for (let i = from; i < to && i < lengthItems; i++) {
                renderItems.push(this.paginationData[i]);
            }
        } else {
            for (let i = 0; i < this.pageSize && i < lengthItems; i++) {
                renderItems.push(this.paginationData[i]);
            }
        }
        return renderItems;
    }

    @action.bound
    setCurrentPage(currentPage: number): void {
        this.currentPage = currentPage;
    }

    @action.bound
    setPageSize(pageSize: number): void {
        this.pageSize = pageSize;
    }
}
