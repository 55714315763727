import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

const scriptSrc = 'https://gisp.gov.ru/gisplk/footer-v1/heade.js';

export const Footer = observer((): JSX.Element => {
    useEffect(() => {
        const script = document.createElement('script');

        script.src = scriptSrc;
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return <div id="footer-gisplk"></div>;
});
