import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@material-ui/core';
import { StyledCircularProgress } from '../../campaign-request-page-print-forms';
import { useAntiDoubleClick } from '../../../../../hooks/useAntiDoubleClick';

export type CampaignSaveButtonProps = {
    onSubmit: () => Promise<void>;
};

export const CampaignSaveButton = (props: CampaignSaveButtonProps): JSX.Element => {
    const { onSubmit } = props;
    const [isLoading, startIcon, onSubmitHandler] = useAntiDoubleClick(onSubmit);

    return (
        <Button
            onClick={onSubmitHandler}
            color="primary"
            size="small"
            variant="contained"
            disabled={isLoading}
            startIcon={startIcon}
        >
            <FormattedMessage id="campaignRequest.saveChanges" />
        </Button>
    );
};
