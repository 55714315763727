import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { Transition } from '../../models';
import { ErrorDialog } from '../ErrorDialog';
import { LicenseFileDialog } from './LicenseFileDialog';
import { LicenseSignatoryTransitionDialog } from './LicenseSignatoryTransitionDialog';
import { LifeCycleTransitionConfirmationDialog } from './LifeCycleTransitionConfirmationDialog';
import { ThirdCharDialog } from './ThirdCharDialog';

export interface TransitionDialogProps {
    objectId: string;
    isModalOpen: boolean;
    setModalIsClosed: () => void;
    transition: Transition;
    lifeCycleTransition: () => Promise<void>;
    handleSuccessTransition: () => void;
    handleErrorTransition: (error: AxiosError) => void;
    validateFunction: () => Promise<void>;
    errorMessage?: JSX.Element | string;
}

export const TransitionDialog = (props: TransitionDialogProps): JSX.Element => {
    const { isModalOpen, setModalIsClosed, validateFunction, transition, errorMessage } = props;
    const [isTransitionAllowed, setIsTransitionAllowed] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { dialogComponentCode, validate } = transition.params;

    useEffect(() => {
        if (validate) {
            setIsLoading(true);
            validateFunction()
                .then(() => {
                    setIsTransitionAllowed(true);
                })
                .finally(() => {
                    setIsLoading(false);
                });
            return;
        }

        setIsTransitionAllowed(true);
    }, [validateFunction, setIsTransitionAllowed, validate]);

    if (isLoading) {
        return <React.Fragment />;
    }

    if (isTransitionAllowed) {
        switch (dialogComponentCode) {
            case 'SetThirdCharLicenseNumberAndChangeState':
                return <ThirdCharDialog {...props} />;
            case 'SetLicenseSignatoryAndChangeState':
                return <LicenseSignatoryTransitionDialog {...props} />;
            case 'LicenseFile':
                return <LicenseFileDialog {...props} />;
            default:
                return <LifeCycleTransitionConfirmationDialog {...props} />;
        }
    }

    return <ErrorDialog message={errorMessage} open={isModalOpen} onClose={setModalIsClosed} />;
};
