import { Box, Container, Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { BreadcrumbsComponent, breadCrumbsSettings } from '../../../components';
import { useQuotaPageListStyles } from '../quota-styles';
import { QuotaList } from './quota-list';

export const QuotaListPage = observer((): JSX.Element => {
    const classes = useQuotaPageListStyles();

    return (
        <Container maxWidth="lg">
            <Box pt={5.5} pb={15}>
                <Box pb={5}>
                    <BreadcrumbsComponent breadcrumbsSettings={breadCrumbsSettings} />
                </Box>
                <Grid container direction="column">
                    <Grid item className={classes.tableWrapper}>
                        <QuotaList />
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
});
