import React from 'react';
import { Box, Link, makeStyles, Tooltip, Typography } from '@material-ui/core';
import { useStore } from '../hooks';

export type FileDownloadProps = {
    downloadFile: () => void;
    fileName?: string;
    title?: string;
    fontSize?: string;
    required?: boolean;
};

const useFileDownloadStyles = makeStyles((theme) => {
    return {
        link: {
            textAlign: 'left',
        },
        requiredField: {
            color: theme.palette.error.main,
            paddingLeft: theme.spacing(1),
            display: 'inline',
        },
    };
});

export const FileDownload = (props: FileDownloadProps): JSX.Element => {
    const { fileName, title, downloadFile, fontSize, required } = props;

    const classes = useFileDownloadStyles();
    const { intlStore: intl } = useStore();

    if (!fileName) {
        return (
            <React.Fragment>
                {title}
                {required && <span className={classes.requiredField}>*</span>}
            </React.Fragment>
        );
    }

    return (
        <Tooltip title={intl.formatMessage('common.downloadFile', { fileName })}>
            <Link className={classes.link} component="button" underline="none" onClick={downloadFile}>
                <Typography variant="subtitle2">
                    <Box {...(fontSize && { fontSize })}>
                        {title}
                        {required && <span className={classes.requiredField}>*</span>}
                    </Box>
                </Typography>
            </Link>
        </Tooltip>
    );
};
