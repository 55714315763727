import { Box, createStyles, Grid, TabsActions, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Location } from 'history';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { matchPath, Route, useLocation, useParams } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import { PageContentContainer, RouteLeavingGuard, Sidebar } from '../../../components';
import { useModal, useObjectRoutesContext, useReload, useStore } from '../../../hooks';
import { RequestModel } from '../../../models';
import { RequestFormParams } from '../../../types';
import { validationErrorLink } from '../../../utils';
import { CampaignRequestHeader, CampaignRequestResolutionHeader } from './campaign-request-headers';
import { RequestControlPanel } from './campaign-request-control-panel';
import { CampaignRequestTabs } from './campaign-request-tabs';
import { CampaignRequestRoutes } from './CampaignRequestRoutes';
import { ExecutorEditDialog } from './ExecutorEditDialog';

export const useCampaignRequestPageStyles = makeStyles((theme: Theme) => {
    const sidebarWidth = theme?.variables.sidebar.widthXl || 0;
    const collapseIconWidth = theme.variables.sidebar.collapseIconWidth;
    const gridItemPadding = theme ? theme.spacing(5) * 2 : 0;
    const formWidth = `calc(100% - ${sidebarWidth + gridItemPadding}px)`;

    return createStyles({
        wrapper: {
            flexGrow: 1,
            backgroundColor: theme?.variables.palette.mainContrastDarker,
            overflow: 'hidden',
        },
        container: {
            margin: '0 auto',
            transition: theme.transitions.create('all', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        containerLg: {
            maxWidth: theme.breakpoints.width('lg'),
        },
        containerFullWidth: {
            maxWidth: '100%',
        },
        sidebarGridWrapper: {
            paddingTop: `${theme.variables.requestPanel.tabsHeight}px !important`,
        },
        form: {
            width: formWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        fullWidthForm: {
            width: `calc(100% - ${collapseIconWidth + gridItemPadding}px)`,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        approvalContainer: {
            width: '100%',
        },
        approvalBoxContainer: {
            width: '100%',
            boxSizing: 'border-box',
        },
        approvalBox: {
            width: '100%',
            paddingBottom: 0,
            marginBottom: theme.spacing(5),
        },
    });
});

export const CampaignRequestPage = observer((): JSX.Element => {
    const { id } = useParams<RequestFormParams>();
    const classes = useCampaignRequestPageStyles();
    const objectRoutes = useObjectRoutesContext();

    const [reloadKey, reloadIncrement] = useReload();
    const [formIsChanged, setFormIsChanged] = useState<boolean>(false);
    const [open, setModalIsOpen, setModalIsClosed] = useModal();
    const [sidebarOpen, setSidebarOpen] = React.useState<boolean>(true);

    const rootStore = useStore();
    const { intlStore, history, requestStore, formioSidebarStore, approvalStore } = rootStore;
    const { locale } = intlStore;
    const [requestModel, setRequestModel] = useState<RequestModel>(() => new RequestModel(id, rootStore, objectRoutes));
    const { currentRequestTypeObjectRoutes, isResolution } = requestModel;
    const { items } = formioSidebarStore;
    const sidebarItems = items[requestModel.formName];

    const [editExecutorDialogTitle, setEditExecutorDialogTitle] = useState<string>();

    const location = useLocation();
    const pathList = location.pathname.split('/');
    const isApprovalPage = pathList[pathList.length - 1] === 'approval';
    const isFullWidth = !sidebarOpen || isApprovalPage;

    const tabsActions = useMemo(() => {
        return React.createRef<TabsActions>();
    }, []);

    useEffect(() => {
        if (currentRequestTypeObjectRoutes) {
            const match = matchPath(location.pathname, {
                path: currentRequestTypeObjectRoutes.object,
            });
            if (!match) {
                const extractedObjectName = location.pathname.split('/')[1];
                if (extractedObjectName) {
                    const routePartToReplace = `/${extractedObjectName}`;
                    const finalPath = location.pathname.replace(
                        routePartToReplace,
                        currentRequestTypeObjectRoutes.objects,
                    );
                    history.replace(finalPath);
                }
            }
        }
    }, [currentRequestTypeObjectRoutes]);

    useEffect(() => {
        setFormIsChanged(false);
    }, [locale]);

    useEffect(() => {
        const newRequestModel = new RequestModel(id, rootStore, objectRoutes);

        requestStore
            .getRequestDTO(id)
            .then(newRequestModel.load)
            .then(() => {
                if (!newRequestModel.formsTabs.length && !approvalStore.hasApprovalTab) {
                    history.replace(clientRoute.notAllowed);
                }
            });
        setRequestModel(newRequestModel);
    }, [requestStore, id, setRequestModel, rootStore, approvalStore, history]);

    const reloadModel = useCallback((): void => {
        const newRequestModel = new RequestModel(id, rootStore, objectRoutes);
        requestStore.getRequestDTO(id).then(newRequestModel.load);
        newRequestModel.updateCurrentTab();
        setRequestModel(newRequestModel);
        reloadIncrement();
    }, [requestStore, id, reloadIncrement, rootStore]);

    const reloadModelForModuleTab = useCallback((): void => {
        requestStore.getRequestDTO(id).then(requestModel.load);
    }, [requestStore, id, requestModel]);

    const errorMessage = useMemo((): JSX.Element => {
        return (
            <FormattedMessage
                id="campaignRequest.readonlyRequestValidationMsg"
                values={{
                    a: validationErrorLink(id),
                }}
            />
        );
    }, [validationErrorLink, id]);

    const shouldBlockNavigation = (location: Location): boolean => {
        return formIsChanged && !location.search.includes('prompt=false');
    };

    const leaveMessage = (
        <React.Fragment>
            <p>
                <FormattedMessage id="campaignRequest.dialog.leaveMessage" />
            </p>
            <p>
                <FormattedMessage id="campaignRequest.dialog.tooltipMessage" />
            </p>
        </React.Fragment>
    );

    const toggleSidebar = () => {
        setSidebarOpen((open) => !open);
        setTimeout(() => tabsActions.current?.updateScrollButtons(), 200);
    };

    const handleEditExecutorDialogOpen = useCallback(
        (title?: string): void => {
            setModalIsOpen();
            title && setEditExecutorDialogTitle(title);
        },
        [setModalIsOpen, setEditExecutorDialogTitle],
    );

    return (
        <React.Fragment>
            <RouteLeavingGuard
                key={location.pathname}
                when={formIsChanged}
                navigate={(path) => history.push(path)}
                title={<FormattedMessage id="campaignRequest.dialog.title" />}
                message={leaveMessage}
                shouldBlockNavigation={shouldBlockNavigation}
            />
            <ExecutorEditDialog
                title={editExecutorDialogTitle}
                onClose={setModalIsClosed}
                open={open}
                requestModel={requestModel}
            />
            <PageContentContainer container direction="column" wrap="nowrap">
                <Grid item>
                    {isResolution ? (
                        <CampaignRequestResolutionHeader
                            key={reloadKey}
                            reloadIncrement={reloadIncrement}
                            requestModel={requestModel}
                            onModalOpen={handleEditExecutorDialogOpen}
                        />
                    ) : (
                        <CampaignRequestHeader
                            key={reloadKey}
                            reloadIncrement={reloadIncrement}
                            requestModel={requestModel}
                            onModalOpen={handleEditExecutorDialogOpen}
                        />
                    )}
                </Grid>
                <Grid
                    item
                    className={classNames(classes.wrapper, {
                        [classes.approvalContainer]: isApprovalPage,
                    })}
                >
                    <Box
                        pt={5}
                        pl={5}
                        pr={5}
                        className={classNames(classes.container, {
                            [classes.containerLg]: sidebarOpen,
                            [classes.containerFullWidth]: isFullWidth,
                            [classes.approvalBoxContainer]: isApprovalPage,
                        })}
                    >
                        <Grid
                            container
                            spacing={7}
                            wrap="nowrap"
                            className={classNames({
                                [classes.approvalContainer]: isApprovalPage,
                            })}
                        >
                            <Grid
                                item
                                className={classNames({
                                    [classes.form]: sidebarOpen,
                                    [classes.fullWidthForm]: isFullWidth,
                                    [classes.approvalContainer]: isApprovalPage,
                                })}
                            >
                                <Box
                                    pb={9}
                                    className={classNames({
                                        [classes.approvalBox]: isApprovalPage,
                                    })}
                                >
                                    <Box pb={5}>
                                        <Grid container justify="center">
                                            <Grid item xs={12}>
                                                <CampaignRequestTabs model={requestModel} tabsActions={tabsActions} />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box width="100%">
                                        <CampaignRequestRoutes
                                            key={reloadKey + id + requestModel.firstTabId}
                                            locale={locale}
                                            requestModel={requestModel}
                                            setFormIsChanged={setFormIsChanged}
                                            requestNumber={requestModel.number}
                                            reloadModelForModuleTab={reloadModelForModuleTab}
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                            <Route
                                path={[
                                    objectRoutes.objectSubTab,
                                    objectRoutes.objectTab,
                                    objectRoutes.objectSubTabEdit,
                                    objectRoutes.objectTabEdit,
                                ]}
                            >
                                <Grid item className={classes.sidebarGridWrapper}>
                                    <Sidebar
                                        open={sidebarOpen}
                                        toggleSidebar={toggleSidebar}
                                        sidebarItems={sidebarItems}
                                    />
                                </Grid>
                            </Route>
                        </Grid>
                    </Box>
                </Grid>
                {!isApprovalPage && (
                    <Grid item>
                        <RequestControlPanel
                            model={requestModel}
                            key={reloadKey + id + requestModel.firstTabId}
                            reloadModel={reloadModel}
                            errorMessage={errorMessage}
                            formIsChanged={formIsChanged}
                        />
                    </Grid>
                )}
            </PageContentContainer>
        </React.Fragment>
    );
});
