import { Grid } from '@material-ui/core';
import { DatePickerView } from '@material-ui/pickers';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useQuotaContext } from '../../../../../hooks';
import { useQuotaPageStyles } from '../../../quota-styles';
import { QuotasDateTimePicker } from '../../../quota-utils';

const yearViews: DatePickerView[] = ['year'];

export const QuotaPeriodsDurationInputs = observer((): JSX.Element => {
    const classes = useQuotaPageStyles();
    const { quotasModel, isViewOnly } = useQuotaContext();
    const { quota } = quotasModel;
    const { setYear, setPeriodStart, setPeriodEnd, year, periodStart, periodEnd, isQuotaTypeYear } = quota;

    if (isQuotaTypeYear) {
        return (
            <Grid item>
                <QuotasDateTimePicker
                    views={yearViews}
                    label="Год действия квоты"
                    value={year}
                    onChangeYear={setYear}
                    fullWidth
                    error={!!quota.touched.year && !!quota.errors.year}
                    format="yyyy"
                    readOnly={isViewOnly}
                    disabled={isViewOnly}
                    className={classes.singleDatePicker}
                />
            </Grid>
        );
    }

    return (
        <Grid container>
            <QuotasDateTimePicker
                label="Период действия с"
                value={periodStart}
                onChange={setPeriodStart}
                error={!!quota.touched.periodStart && !!quota.errors.periodStart}
                readOnly={isViewOnly}
                disabled={isViewOnly}
                className={classes.datePickerLeft}
            />
            <QuotasDateTimePicker
                label="Период действия по"
                value={periodEnd}
                onChange={setPeriodEnd}
                error={!!quota.touched.periodEnd && !!quota.errors.periodEnd}
                readOnly={isViewOnly}
                disabled={isViewOnly}
                className={classes.datePickerRight}
            />
        </Grid>
    );
});
