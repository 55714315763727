import { Button, Grid } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { entities, permissions } from '../../../../../authSchemeConfig';
import { AuthorizationCheck } from '../../../../../components';
import { useStore } from '../../../../../hooks';
import { RequestModel } from '../../../../../models';
import { RequestFormParams } from '../../../../../types';

export type RequestDownloadAllDocsButtonProps = {
    model: RequestModel;
};

export const RequestDownloadAllDocsButton = observer((props: RequestDownloadAllDocsButtonProps): JSX.Element => {
    const { model } = props;
    const { number } = model;
    const { id } = useParams<RequestFormParams>();
    const { requestStore } = useStore();

    const downloadAllDocs = (): void => {
        requestStore.downloadAllDocsAsFile(id, number);
    };

    return (
        <AuthorizationCheck
            entityCode={entities.CampaignRequest}
            permCode={permissions.CampaignRequest.DownloadAllDocs}
            entityId={id}
        >
            <Grid item>
                <Button variant="contained" color="secondary" onClick={downloadAllDocs}>
                    <FormattedMessage id="campaignRequest.downloadAllDocs" />
                </Button>
            </Grid>
        </AuthorizationCheck>
    );
});
