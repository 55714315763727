import { makeStyles } from '@material-ui/core';

export const useQuotaDistribTablesStyles = makeStyles((theme) => {
    return {
        countryGroupTableContainer: {
            maxWidth: '770px',
            padding: `0 ${theme.spacing(10)}px`,
            paddingBottom: theme.spacing(4),
        },
        VTDTableContainer: {
            maxWidth: '100vw',
            padding: `0 ${theme.spacing(10)}px`,
            paddingBottom: theme.spacing(4),
        },
        tableTitle: {
            fontWeight: 700,
            fontSize: '16px',
            lineHeight: '20px',
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(4),
        },
        sumText: {
            fontWeight: 700,
            fontSize: '15px',
            lineHeight: '19px',
            color: theme.palette.secondary.contrastText,
        },
        paper: {
            overflowX: 'auto',
        },
    };
});
