import { NotificationDTO, NotificationsQueryData } from '@platform/notification-widget';
import { action, observable } from 'mobx';
import { apiConfigs } from '../apiConfigs';
import { ApiStore } from './ApiStore';
import { RootStore } from './RootStore';

export class LkNotificationStore {
    @observable private rootStore: RootStore;
    @observable private api: ApiStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.api = rootStore.api;
    }

    @action.bound
    loadNotifications(queryData: NotificationsQueryData): Promise<NotificationDTO[]> {
        return this.api.client(apiConfigs.loadNotifications(queryData)).then((res) => res.data);
    }

    @action.bound
    loadCountNotViewedMessages(): Promise<number> {
        return this.api.client(apiConfigs.loadCountNotViewedMessages()).then((res) => res.data);
    }

    @action.bound
    changeViewed(id: string, viewed: boolean): Promise<void> {
        return this.api.client(apiConfigs.changeViewed(id, viewed)).then((res) => res.data);
    }

    @action.bound
    deleteNotification(id: string): Promise<void> {
        return this.api.client(apiConfigs.deleteNotification(id)).then((res) => res.data);
    }
}
