import { Button, Grid, Typography } from '@material-ui/core';
import { Form, FormApi, FormModel } from '@platform/formiojs-react';
import { AxiosError } from 'axios';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, NavLink, Route, Switch, useHistory, useParams } from 'react-router-dom';
import { clientRoute } from '../../../../clientRoute';
import { ConfirmationDialog, ErrorDialog, ErrorMessage } from '../../../../components';
import { useError, useFormStyles, useModal, useStore } from '../../../../hooks';
import { CampaignRegFormTabs } from './components';
import { CampaignRegFormRouteParams } from './types';

export const CampaignRegForm = observer((): JSX.Element => {
    const classes = useFormStyles();
    const history = useHistory();
    const { id, rfId } = useParams<CampaignRegFormRouteParams>();
    const [open, setModalIsOpen, setModalIsClosed] = useModal();
    const { campaignsStore, intlStore, notificationStore } = useStore();
    const { locale } = intlStore;

    const [form, setForm] = useState<FormApi>();
    const model = useMemo(() => new FormModel(rfId), []);
    const { form: formFromModel, getLangSubmission } = model;

    useEffect(() => {
        campaignsStore.loadRegForm(rfId).then(model.load);
    }, [campaignsStore, rfId, model]);

    const onFormReady = (form: FormApi): void => {
        setForm(form);
    };

    const onSubmit = (): void => {
        if (form && form.validate()) {
            form.submit(false)
                .then(() => {
                    return campaignsStore.saveRegForm(rfId, form.getSubmission());
                })
                .then(() => {
                    history.push(generatePath(clientRoute.campaignRegForm, { id, rfId }));
                })
                .then(() => {
                    campaignsStore.loadRegForm(rfId).then(model.load);
                })
                .catch((error) => {
                    notificationStore.onError(error.response.data);
                    form.setSubmissionFromStartSubmission();
                });
        }
    };

    const onDeleteLinkClick = (): void => {
        setModalIsOpen();
    };

    const [isError, errorText, enableError, resetError] = useError();

    const handleErrorDeletion = (error: AxiosError): void => {
        const errorText = ErrorMessage(error);
        enableError(errorText);
    };

    const onConfirmDelete = async (): Promise<void> => {
        try {
            await campaignsStore.deleteRegForm(rfId);
            history.push(generatePath(clientRoute.campaignRegForms, { id, rfId }));
        } catch (error) {
            handleErrorDeletion(error);
        }
        setModalIsClosed();
    };

    const titleSubmission = useMemo((): string => {
        const submission = getLangSubmission(locale);
        return formFromModel && submission && submission.data && (submission.data as any).title;
    }, [formFromModel, getLangSubmission, locale]);

    return (
        <React.Fragment>
            <ErrorDialog message={errorText} open={isError} onClose={resetError} />
            <ConfirmationDialog
                id="confirm-delete-rf"
                keepMounted
                open={open}
                onConfirm={onConfirmDelete}
                onCancel={setModalIsClosed}
                title={<FormattedMessage id="campaign.deleteCampaignRegForm" />}
                message={
                    <FormattedMessage
                        id="campaign.confirmDeleteCampaignRegForm"
                        values={{ formTitle: titleSubmission }}
                    />
                }
            />

            <Switch>
                <Route
                    path={[
                        clientRoute.campaignRegForm,
                        clientRoute.campaignRegFormExecutors,
                        clientRoute.campaignRegFormTabsSettings,
                        clientRoute.campaignRequestFormTabSetting,
                        clientRoute.campaignRequestFormTabSettingCreate,
                        clientRoute.campaignRegFormTemplates,
                        clientRoute.campaignRegFormTemplatesCreate,
                        clientRoute.campaignRegFormTemplatesEdit,
                        clientRoute.campaignRegFormTemplatesPermissionSettings,
                    ]}
                    exact
                >
                    <Grid container direction="column" spacing={5}>
                        <Grid item container spacing={5}>
                            <Grid item>
                                <Typography variant="h1">{titleSubmission}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <CampaignRegFormTabs
                                model={model}
                                onFormReady={onFormReady}
                                onDeleteLinkClick={onDeleteLinkClick}
                            />
                        </Grid>
                    </Grid>
                </Route>

                <Route
                    path={[clientRoute.campaignRegFormCreate, clientRoute.campaignRegFormEdit]}
                    exact
                    key={clientRoute.campaignRegFormEdit}
                >
                    <Grid container direction="column" spacing={5}>
                        <Grid item>
                            <Typography variant="h1">
                                <Switch>
                                    <Route path={clientRoute.campaignRegFormCreate}>
                                        <FormattedMessage id="campaign.newCampaignRegForm" />
                                    </Route>
                                    <Route path={clientRoute.campaignRegFormEdit}>
                                        <FormattedMessage
                                            id="campaign.editCampaignRegForm"
                                            values={{ formTitle: titleSubmission }}
                                        />
                                    </Route>
                                </Switch>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Form
                                locale={locale}
                                className={classes.formWithNoOffset}
                                form={model}
                                onFormReady={onFormReady}
                            />
                            <Grid container spacing={5}>
                                <Grid item>
                                    <Button color="secondary" variant="contained" onClick={onSubmit}>
                                        <FormattedMessage id="common.save" />
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Switch>
                                        <Route path={clientRoute.campaignRegFormCreate}>
                                            <Button
                                                component={NavLink}
                                                variant="text"
                                                to={generatePath(clientRoute.campaignRegForms, { id })}
                                            >
                                                <FormattedMessage id="common.cancel" />
                                            </Button>
                                        </Route>
                                        <Route path={clientRoute.campaignRegFormEdit}>
                                            <Button
                                                component={NavLink}
                                                variant="text"
                                                to={generatePath(clientRoute.campaignRegForm, { id, rfId })}
                                            >
                                                <FormattedMessage id="common.cancel" />
                                            </Button>
                                        </Route>
                                    </Switch>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Route>
            </Switch>
        </React.Fragment>
    );
});
