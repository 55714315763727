import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';
import { Authentication } from '../../components';
import { QuotaListPage } from './quota-list-page';
import { QuotaPage } from './quota-page';

export const QuotasPages = (): JSX.Element => {
    return (
        <Authentication>
            <Switch>
                <Route path={[clientRoute.quotasCreate, clientRoute.quotasEdit, clientRoute.quotasQuota]} exact={true}>
                    <QuotaPage />
                </Route>
                <Route path={clientRoute.quotas} exact={true}>
                    <QuotaListPage />
                </Route>
            </Switch>
        </Authentication>
    );
};
