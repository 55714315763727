import React, { useState } from 'react';
import { StyledCircularProgress } from '../pages';

export type UseAntiDoubleClickType = [boolean, boolean | JSX.Element, () => void];

export function useAntiDoubleClick(actionFunc: () => Promise<void>): UseAntiDoubleClickType {
    const [isActionInProgress, setIsActionInProgress] = useState<boolean>(false);
    const endIcon = isActionInProgress && <StyledCircularProgress />;

    const actionHandler = () => {
        setIsActionInProgress(true);

        actionFunc().finally(() => {
            setIsActionInProgress(false);
        });
    };

    return [isActionInProgress, endIcon, actionHandler];
}
