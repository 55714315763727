import { Tab, TabProps } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { LinkProps } from 'react-router-dom';
import { AuthorizationCheck, AuthorizationCheckProps } from '../AuthorizationCheck';

export type AuthorizedTabProps = TabProps & LinkProps & AuthorizationCheckProps;

export const AuthorizedTab = observer((props: AuthorizedTabProps): JSX.Element => {
    const { permCode, entityCode, entityId, pendingElement, onAllowed, errorElement, ...restProps } = props;

    return (
        <AuthorizationCheck
            entityCode={entityCode}
            permCode={permCode}
            entityId={entityId}
            pendingElement={pendingElement}
            onAllowed={onAllowed}
            errorElement={errorElement}
        >
            <Tab {...restProps} />
        </AuthorizationCheck>
    );
});
