import { Button, Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { clientRoute } from '../../../../../../clientRoute';
import { CampaignRegFormRouteParams } from '../../types';

export const RequestFromTabsSettingsHeader = observer(
    (): JSX.Element => {
        const { id, rfId } = useParams<CampaignRegFormRouteParams>();
        const history = useHistory();

        const goToSettingDialog = useCallback(async (): Promise<void> => {
            history.push(
                generatePath(clientRoute.campaignRequestFormTabSettingCreate, {
                    id,
                    rfId,
                    settingId: '-',
                }),
            );
        }, [id, rfId, history]);

        return (
            <Grid container direction="row" justify="space-between">
                <Grid item xs={6}>
                    <Typography variant="h6">
                        <FormattedMessage id="campaign.requestFormSettingsTitle" />
                    </Typography>
                </Grid>
                <Grid item xs={6} container spacing={2} justify="flex-end">
                    <Grid item>
                        <Button color="primary" variant="contained" onClick={goToSettingDialog}>
                            <FormattedMessage id="campaign.createRequestFormSettings" />
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        );
    },
);
