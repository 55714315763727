import { CircularProgress, Grid } from '@material-ui/core';
import { Formik } from 'formik';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useState } from 'react';
import { generatePath, useHistory, useLocation, useParams } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import { CountryGroupsContext } from '../../../contexts';
import { useStore } from '../../../hooks';
import { CatalogsModel, CountryGroupsModel, yupCountryGroupSchema } from '../../../models';
import { CountryGroupDTO, FormikServiceCountryGroup, RouteParamsDefault } from '../../../types';
import { matchURL } from '../../../utils';
import { CountryGroupsControlPanel } from '../country-groups-control-panel';
import { useCountryGroupsPageStyles } from '../country-groups-styles';
import { CountryGroupsForm } from './components';

const emptyDefaultCountryGroup: FormikServiceCountryGroup = {
    archived: false,
    countries: [],
    formationPrinciple: null,
    name: '',
};

export const CountryGroupsPage = observer((): JSX.Element => {
    const classes = useCountryGroupsPageStyles();
    const { id } = useParams<RouteParamsDefault>();
    const history = useHistory();
    const location = useLocation();
    const { countryGroupsStore } = useStore();
    const { catalogStore } = countryGroupsStore;
    const catalogs = useMemo(() => new CatalogsModel(catalogStore), []);
    const { loadCountriesCatalog, loadFormationPrinciples } = catalogs;
    const {
        createCountryGroup,
        updateCountryGroup,
        mapCountryGroupDTOToFormikValues,
        isCountryGroupLoading,
        loadCountryGroup,
    } = useMemo(() => new CountryGroupsModel(countryGroupsStore), []);

    const isEditing = matchURL(location.pathname, clientRoute.countryGroupEdit);
    const isViewOnly = matchURL(location.pathname, clientRoute.countryGroup);
    const isCreating = matchURL(location.pathname, clientRoute.countryGroupCreate);

    const [countryGroup, setCountryGroup] = useState<FormikServiceCountryGroup>(emptyDefaultCountryGroup);

    const submitForm = async (values: FormikServiceCountryGroup): Promise<void> => {
        const newCountryGroup = isCreating ? await createCountryGroup(values) : await updateCountryGroup(values, id);
        if (newCountryGroup) {
            history.push(generatePath(clientRoute.countryGroup, { id: newCountryGroup.id }));
        }
    };

    const setFormikCountryGroup = (existingCountryGroup?: CountryGroupDTO): void => {
        if (existingCountryGroup) {
            setCountryGroup(mapCountryGroupDTOToFormikValues(existingCountryGroup));
        }
    };

    useEffect(() => {
        // Тут подгружаются справочники
        if (isEditing || isCreating) {
            loadCountriesCatalog();
            loadFormationPrinciples();
        }
    }, [isEditing, isCreating]);

    useEffect(() => {
        // Тут подгружается группа стран
        if (isViewOnly || isEditing) {
            loadCountryGroup(id).then(setFormikCountryGroup);
        }
    }, [isEditing, isViewOnly]);

    if (isCountryGroupLoading) {
        return (
            <Grid container className={classes.spinner}>
                <CircularProgress />
            </Grid>
        );
    }

    return (
        <CountryGroupsContext.Provider value={{ isViewOnly, isEditing, isCreating, catalogs }}>
            <Formik
                initialValues={countryGroup}
                onSubmit={submitForm}
                validationSchema={yupCountryGroupSchema}
                enableReinitialize
            >
                <Grid
                    container
                    direction="column"
                    className={isViewOnly ? classes.mainContainerViewOnly : classes.mainContainer}
                >
                    <CountryGroupsForm />
                    <CountryGroupsControlPanel />
                </Grid>
            </Formik>
        </CountryGroupsContext.Provider>
    );
});
