const startPage: Record<string, Record<string, string> | string> = {
    headerLinks: {
        about: 'O продукте',
        news: 'Новости',
        documents: 'Документы',
        resources: 'Другие ресурсы',
        contacts: 'Контакты',
        radar: 'Радар',
        login: 'Войти в систему',
        loginAnonymously: 'Войти без регистрации',
        loginAccount: 'Войти в личный кабинет',
    },
    productDescription: 'Настраиваемая информационная среда для управления интеллектуальным капиталом организации',
    logoAlt: 'ГИСП',
};

export default startPage;
