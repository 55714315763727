import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { Button } from '@material-ui/core';
export type CampaignCancelButtonProps = {
    path: string;
};

export const CampaignCancelButton = (props: CampaignCancelButtonProps): JSX.Element => {
    const { path } = props;

    return (
        <Button component={NavLink} to={path} color="secondary" size="small" variant="contained">
            <FormattedMessage id="common.discardChanges" />
        </Button>
    );
};
