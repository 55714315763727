import { Grid } from '@material-ui/core';
import React from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { useObjectRoutesContext } from '../../../../../hooks';
import { RequestFormParams } from '../../../../../types';
import { CampaignCancelButton } from './CampaignCancelButton';
import { CampaignCancelButtonWithDialog } from './CampaignCancelButtonWithDialog';
import { CampaignSaveButton } from './CampaignSaveButton';

export type CampaignEditModeButtonsWithDialogProps = {
    saveRequestForm: (formId: string, goBackPath: string) => () => Promise<void>;
    formIsChanged: boolean;
};

export const CampaignEditModeButtonsWithDialog = (props: CampaignEditModeButtonsWithDialogProps): JSX.Element => {
    const { saveRequestForm, formIsChanged } = props;

    const history = useHistory();

    const params = useParams<RequestFormParams>();
    const { subTabId, tabId } = params;
    const formId = subTabId || tabId;
    const objectRoutes = useObjectRoutesContext();

    const goBackPath = subTabId
        ? generatePath(objectRoutes.objectSubTab, params)
        : generatePath(objectRoutes.objectTab, params);

    const confirm = () => {
        history.push({
            pathname: goBackPath,
            search: '?prompt=false',
        });
    };

    const onSubmit = saveRequestForm(formId, goBackPath);

    return (
        <React.Fragment>
            <Grid item>
                <CampaignSaveButton onSubmit={onSubmit} />
            </Grid>
            <Grid item>
                {formIsChanged ? (
                    <CampaignCancelButtonWithDialog onConfirm={confirm} />
                ) : (
                    <CampaignCancelButton path={goBackPath} />
                )}
            </Grid>
        </React.Fragment>
    );
};
