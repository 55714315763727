import { CatalogItemDTO } from '@platform/multilevel-catalog';
import { SelectData } from '@platform/ttable';
import { AxiosRequestConfig } from 'axios';
import { action, observable } from 'mobx';
import { apiConfigs } from '../apiConfigs';
import { CodeTitle, IdTitle } from '../models';
import { FormationPrinciple } from '../types';
import { ApiStore } from './ApiStore';
import { IntlStore } from './IntlStore';
import { RootStore } from './RootStore';

export enum CatalogCodes {
    documentCategory = 'DocumentCategory',
    fileFormat = 'FileFormat',
    formationPrinciple = 'FormationPrinciple',
}

type CatalogDTO = {
    code: string;
    catalogCode: string;
    title: string;
};

export class CatalogStore {
    @observable rootStore: RootStore;
    @observable api: ApiStore;
    @observable intlStore: IntlStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.api = rootStore.api;
        this.intlStore = rootStore.intlStore;
    }

    @action.bound
    getSelectData(requestConfig: AxiosRequestConfig): Promise<IdTitle[]> {
        return this.api
            .client(requestConfig)
            .then((r) => r.data)
            .then((data: CodeTitle[]) => {
                return data.map(({ code, title }) => ({
                    id: code,
                    title,
                }));
            });
    }

    @action.bound
    getCatalog(catalogCode: string, lang: string): Promise<CodeTitle[]> {
        return this.api
            .client(apiConfigs.catalogItems(catalogCode, lang))
            .then((r) => r.data)
            .then((arr) =>
                arr.map((item: CatalogDTO) => ({ code: `${item.code}@${item.catalogCode}`, title: item.title })),
            );
    }

    @action.bound
    getCatalogItem<T>(catalogCode: string, lang: string): Promise<T[]> {
        return this.api.client(apiConfigs.catalogItems(catalogCode, lang)).then((r) => r.data);
    }

    @action.bound
    getSelectDataByUrl(url: string): Promise<SelectData> {
        return this.api
            .client({
                url: url,
                method: 'GET',
            })
            .then((r) => r.data);
    }

    @action.bound
    getMeasurementUnitCatalog(): Promise<CatalogItemDTO[]> {
        return this.api.client(apiConfigs.getDatamosOkeiCatalog()).then((r) => r.data);
    }

    @action.bound
    getCountriesCatalog(): Promise<CodeTitle[]> {
        return this.api.client(apiConfigs.getDatamosOKSMCatalog()).then((r) => r.data);
    }

    @action.bound
    getFormationPrinciplesCatalog(): Promise<FormationPrinciple[]> {
        const { intlStore } = this.rootStore;
        const { locale } = intlStore;
        return this.getCatalogItem<FormationPrinciple>(CatalogCodes.formationPrinciple, locale);
    }

    @action.bound
    getQuotaCategoryOptions(): Promise<CatalogItemDTO[]> {
        return this.api.client(apiConfigs.getQuotaCategoryOptions()).then((r) => r.data);
    }

    @action.bound
    getQuotaTypeOptions(): Promise<CatalogItemDTO[]> {
        return this.api.client(apiConfigs.getQuotaTypeOptions()).then((r) => r.data);
    }

    @action.bound
    getCNOptions(): Promise<CatalogItemDTO[]> {
        return this.api.client(apiConfigs.getCNOptions()).then((r) => r.data);
    }

    @action.bound
    getOperationOptions(productCategoryCode: string): Promise<CatalogItemDTO[]> {
        return this.api.client(apiConfigs.getOperationOptions(productCategoryCode)).then((r) => r.data);
    }

    @action.bound
    getQuotaMeasurementUnitOptions(productCategoryCode: string): Promise<CatalogItemDTO[]> {
        return this.api.client(apiConfigs.getQuotaMeasurementUnitOptions(productCategoryCode)).then((r) => r.data);
    }

    @action.bound
    getCountryGroupsOptions(): Promise<CodeTitle[]> {
        return this.api.client(apiConfigs.getCountryGroupsOptions).then((r) => r.data);
    }
}
