import { withTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import { UpperLink } from '../../components/links/UpperLink';

export const StartPageHeaderLink = withTheme(
    styled(UpperLink)`
        display: flex;
        align-items: center;
        font-family: 'PT Sans', sans-serif;
        font-size: 15px;
        font-weight: bold;
        line-height: 1.2;
        letter-spacing: 1.5px;
        min-height: 42px;
    `,
);
