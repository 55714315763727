import { Button, Grid, makeStyles, Theme } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { CSSProperties, ReactNode } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { clientRoute } from '../../../../clientRoute';
import { ActionMenuItem, MenuButton } from '../../../../components';
import { useObjectRoutesContext } from '../../../../hooks';
import { transitionDefault } from '../../../../themes';
import { RequestFormParams, TabDTO } from '../../../../types';
import { campaignRequestTabPadding, getActiveCampaignRequestItemStyles } from './campaignRequestStyles';

export type CampaignRequestTabLabelProps = {
    tabId: string;
    title: string;
    subTabs: TabDTO[];
};

const menuWidth = '33px';

type MakeStylesProps = {
    isWithSubTabs: boolean;
};

const useStyles = makeStyles<Theme, MakeStylesProps>((theme) => {
    const activeItemStyles: CSSProperties = getActiveCampaignRequestItemStyles(theme);

    return {
        wrapper: {
            paddingRight: (props) => (props.isWithSubTabs ? menuWidth : 0),
        },
        titleWrapper: {
            padding: campaignRequestTabPadding,
            transition: transitionDefault,

            '&:hover': {
                ...activeItemStyles,

                '& ~ [class*="MuiGrid-item"]': {
                    borderColor: theme.palette.common.white,
                },
            },
        },
        menuWrapper: {
            alignSelf: 'flex-end',
            position: 'absolute',
            right: 0,
            top: 0,
            bottom: 0,
            width: menuWidth,
            borderLeft: '1px solid #9b9b9b',
            transition: transitionDefault,

            '&:hover': {
                borderColor: theme.palette.common.white,
            },
        },
        menuButton: {
            height: '100%',
            width: '100%',
            minWidth: 'auto',
            padding: 0,
            borderRadius: 0,
            transition: transitionDefault,

            '& span': {
                height: '100%',
            },

            '&:hover': {
                ...activeItemStyles,
                background: activeItemStyles.backgroundColor
            },
        },
        menuItem: {
            padding: 0,
        },
        activeMenuItem: {
            ...activeItemStyles,
        },
    };
});

export const CampaignRequestTabLabel = observer((props: CampaignRequestTabLabelProps): JSX.Element => {
    const { tabId, title, subTabs } = props;

    const { id, subTabId } = useParams<RequestFormParams>();
    const isWithSubTabs = !!subTabs.length;
    const classes = useStyles({ isWithSubTabs });
    const objectRoutes = useObjectRoutesContext();

    const renderMenuItems = (hideMenu: () => void): ReactNode[] => {
        return subTabs.map((tab) => {
            const currentTabId = tab.id;
            const path = generatePath(objectRoutes.objectSubTab, { id, tabId, subTabId: currentTabId });
            const isActiveTab = subTabId && subTabId === currentTabId;

            return (
                <ActionMenuItem
                    isSubTabLink
                    className={classNames(classes.menuItem, { [classes.activeMenuItem]: isActiveTab })}
                    messageValue={tab.title}
                    onClick={hideMenu}
                    path={path}
                />
            );
        });
    };

    const renderButton = (onClick: (event: React.MouseEvent<HTMLButtonElement>) => void): JSX.Element => {
        return (
            <Button onClick={onClick} className={classes.menuButton}>
                <ArrowDropDownIcon />
            </Button>
        );
    };

    return (
        <Grid container alignItems="center" className={classes.wrapper}>
            <Grid item className={classes.titleWrapper}>
                {title}
            </Grid>
            {isWithSubTabs && (
                <Grid item className={classes.menuWrapper}>
                    <MenuButton renderButton={renderButton} renderMenuItems={renderMenuItems} />
                </Grid>
            )}
        </Grid>
    );
});
