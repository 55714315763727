import { AuthorizationCheckQuery } from './store/AuthorizationStore';

export const entities = {
    System: 'System',
    User: 'User',
    Campaign: 'Campaign',
    CampaignRegForm: 'CampaignRegForm',
    CampaignPfTemplate: 'CampaignPfTemplate',
    CampaignRequest: 'CampaignRequest',
    RequestCustomForm: 'RequestCustomForm',
};

export const permissions = {
    System: {
        Administration: 'Administration',
        AddCampaign: 'AddCampaign',
        ViewCampaignList: 'ViewCampaignList',
        ViewCampRequestList: 'ViewCampRequestList',
        ViewCampRequestRegistry: 'ViewCampRequestRegistry',
        ExportCampRequestList: 'ExportCampRequestList',
        ViewPersonList: 'ViewPersonList',
        ViewUserList: 'ViewUserList',
        AddCampaignRequest: 'AddCampaignRequest',
        ViewCampReqExecutorColumn: 'ViewCampReqExecutorColumn',
        ViewLogsRegistry: 'ViewLogsRegistry',
        Logout: 'Logout',
        ViewNotifications: 'ViewNotifications',
        ViewLicenseRegistry: 'ViewLicenseRegistry',
        LicenseRegistryAdministration: 'LicenseRegistryAdministration',
        ViewAuthorityDocs: 'ViewAuthorityDocs',
        ViewQuotas: 'ViewQuotas',
        ViewCountryGroup: 'ViewCountryGroup'
    },
    User: {
        LoginAs: 'LoginAs',
        Update: 'Update',
        UpdateForm: 'UpdateForm',
        View: 'View',
    },
    Campaign: {
        View: 'View',
        Edit: 'Edit',
        Delete: 'Delete',
        AddCampRequest: 'AddCampRequest',
        ApproveProvisionalAppointment: 'ApproveProvisionalAppointment',
    },
    CampaignRegForm: {
        Edit: 'Edit',
        Delete: 'Delete',
        EditCampRegFormSetting: 'EditCampRegFormSetting',
        DownloadDuplicateLicense: 'DownloadDuplicateLicense',
        AssignLicenseSignatory: 'AssignLicenseSignatory',
    },
    CampaignPfTemplate: {
        Edit: 'Edit',
        Delete: 'Delete',
    },
    CampaignRequest: {
        View: 'View',
        Edit: 'Edit',
        EditPf: 'EditPf',
        Delete: 'Delete',
        Send: 'Send',
        UpdateActualAppointment: 'UpdateActualAppointment',
        AddActualAppointment: 'AddActualAppointment',
        DownloadDuplicateLicense: 'DownloadDuplicateLicense',
        AssignLicenseSignatory: 'AssignLicenseSignatory',
        ChangeState: 'ChangeState',
        PublishFTS: 'PublishFTS',
        DownloadAllDocs: 'DownloadAllDocs',
    },
    RequestCustomForm: {
        ViewLogsInTab: 'ViewLogsInTab',
        ViewChecksInTab: 'ViewChecksInTab',
    },
    Quotas: {
        ManageQuotas: 'ManageQuotas',
    },
    CountryGroups: {
        ManageCountryGroup: 'ManageCountryGroup'
    }
};

export const permissionsConfig = {
    updateActualAppointment: (campaignRequestId: string): AuthorizationCheckQuery => ({
        permCode: permissions.CampaignRequest.UpdateActualAppointment,
        entityCode: entities.CampaignRequest,
        entityId: campaignRequestId,
    }),
    addActualAppointment: (campaignRequestId: string): AuthorizationCheckQuery => ({
        permCode: permissions.CampaignRequest.AddActualAppointment,
        entityCode: entities.CampaignRequest,
        entityId: campaignRequestId,
    }),
    editCampaignRequest: (campaignRequestId: string): AuthorizationCheckQuery => ({
        permCode: permissions.CampaignRequest.Edit,
        entityCode: entities.CampaignRequest,
        entityId: campaignRequestId,
    }),
    deleteCampaignRequest: (campaignRequestId: string): AuthorizationCheckQuery => ({
        permCode: permissions.CampaignRequest.Delete,
        entityCode: entities.CampaignRequest,
        entityId: campaignRequestId,
    }),
};

export const authSchemeConfig = { entities, permissions, permissionsConfig };
