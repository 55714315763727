import {
    Box,
    Button,
    Checkbox,
    Dialog,
    FormControlLabel,
    Grid,
    IconButton,
    makeStyles,
    TextField,
    Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { clientRoute } from '../../../../../../clientRoute';
import { Select } from '../../../../../../components';
import { useModal, useStore } from '../../../../../../hooks';
import { AccessPermission, RequestFormSettingModel } from '../../../../../../models';
import { autocompleteProps, textFieldProps } from '../../../../../campaign-request';
import { RequestFormPermissionSettingsTable } from '../RequestFormPermissionSettingsTable';
import { EditPermissionDialog } from './EditPermissionDialog';

const useStyles = makeStyles((theme) => ({
    checkboxFormContolLabel: {
        '& [class*=PrivateSwitchBase-root]': {
            padding: 0,
            paddingLeft: theme.spacing(1),
        },
    },
}));

export type RequestFormTabSettingsDTO = {
    title: string;
    identifier: string;
    parentTabId?: string;
    formCode: string;
    permissions: AccessPermission[];
    fileSize?: number;
    executorId?: string;
    openOnCreation: boolean;
};

type RouteParams = {
    id: string;
    rfId: string;
    settingId: string;
};

export type RequestFormSettingDialogProps = {
    updateSetting?: (settingId: string, dto: RequestFormTabSettingsDTO) => Promise<void | number>;
    createSetting?: (dto: RequestFormTabSettingsDTO) => Promise<void>;
};

export const RequestFormTabsSettingDialog = observer((props: RequestFormSettingDialogProps): JSX.Element => {
    const classes = useStyles();
    const { updateSetting, createSetting } = props;

    const { campaignsStore, notificationStore, intlStore: intl } = useStore();
    const { formatMessage } = useIntl();
    const { id, settingId, rfId } = useParams<RouteParams>();
    const history = useHistory();
    const settings = useMemo<RequestFormSettingModel>(
        () => new RequestFormSettingModel(settingId, rfId, campaignsStore, intl),
        [settingId, rfId, campaignsStore, intl],
    );

    const [currentPermission, setCurrentPermission] = useState<number>();
    const [editPermissionDialogOpen, setEditPermissionDialogOpen, setEditPermissionDialogClosed] = useModal();

    useEffect(() => {
        settings
            .loadData(rfId, updateSetting ? settingId : undefined)
            .finally(() => campaignsStore.getRequestFormSetting(settings, settingId));
    }, [campaignsStore, settings, settingId, rfId, updateSetting]);

    const closeDialog = useCallback(async (): Promise<void> => {
        history.push(generatePath(clientRoute.campaignRegFormTabsSettings, { id, rfId }));
    }, [id, rfId, history]);

    const submit = useCallback((): void => {
        settings.formValidationStarted = true;

        if (!settings.isValid) {
            return;
        }

        updateSetting &&
            updateSetting(settingId, settings.requestFormTabSettingsData)
                .then(closeDialog)
                .catch((err) => {
                    notificationStore.onError(err.response.data);
                });

        createSetting &&
            createSetting(settings.requestFormTabSettingsData)
                .then(closeDialog)
                .catch((err: any) => {
                    notificationStore.onError(err.response.data);
                });
        settings.formValidationStarted = false;
    }, [closeDialog, settingId, updateSetting, settings, notificationStore, createSetting]);

    const handleEditPermissionDialogOpen = useCallback(
        (index: number) => {
            setCurrentPermission(index);
            setEditPermissionDialogOpen();
        },
        [setCurrentPermission, setEditPermissionDialogOpen],
    );

    const formControlLabelCheckbox = (
        <Checkbox checked={settings.openOnCreation} onChange={settings.onChangeOpenOnCreation} />
    );

    return (
        <React.Fragment>
            {currentPermission !== undefined && editPermissionDialogOpen && (
                <EditPermissionDialog
                    open={editPermissionDialogOpen}
                    closeDialog={setEditPermissionDialogClosed}
                    currentPermission={currentPermission}
                    model={settings}
                />
            )}
            <Dialog maxWidth="md" fullWidth open={true} scroll="body">
                <Box pt={4} pr={4}>
                    <Grid container justify="flex-end">
                        <Grid item>
                            <IconButton onClick={closeDialog}>
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Box>
                <Box pl={12} pr={12} pb={12}>
                    <Grid container justify="center">
                        <Grid item>
                            <Typography variant="h5">
                                <Box fontWeight="fontWeightBold">
                                    <FormattedMessage id="campaign.requestFormSetting" />
                                </Box>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Box pt={8}>
                        <Grid container spacing={6} direction="column" justify="center">
                            <Grid item container spacing={4} direction="column" justify="center">
                                <Grid item>
                                    <TextField
                                        {...textFieldProps}
                                        label={formatMessage({ id: 'campaign.tabSettingForm.title' })}
                                        value={settings.title}
                                        onChange={settings.onChangeTitle}
                                        required
                                        error={!!settings.titleMessage}
                                        helperText={settings.titleMessage}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        {...textFieldProps}
                                        label={formatMessage({ id: 'campaign.tabSettingForm.identifier' })}
                                        value={settings.identifier}
                                        onChange={settings.onChangeIdentifier}
                                        required
                                        error={!!settings.identifierMessage}
                                        helperText={settings.identifierMessage}
                                    />
                                    <FormControlLabel
                                        control={formControlLabelCheckbox}
                                        label="Открывать при создании"
                                        className={classes.checkboxFormContolLabel}
                                    />
                                </Grid>
                                <Grid item>
                                    <Select
                                        multiple={false}
                                        values={settings.parentTabId}
                                        label={formatMessage({ id: 'campaign.tabSettingForm.parentTab' })}
                                        selectData={settings.parentTabsList}
                                        onChange={settings.onChangeParentTab}
                                        textFieldProps={textFieldProps}
                                        autoCompleteProps={autocompleteProps}
                                    />
                                </Grid>
                                <Grid item>
                                    <Select
                                        multiple={false}
                                        values={settings.formCode}
                                        label={formatMessage({ id: 'campaign.tabSettingForm.formCode' })}
                                        selectData={settings.formCodeList}
                                        onChange={settings.onChangeFormCode}
                                        textFieldProps={{
                                            ...textFieldProps,
                                            required: true,
                                            error: !!settings.formCodeMessage,
                                            helperText: settings.formCodeMessage,
                                        }}
                                        autoCompleteProps={autocompleteProps}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        {...textFieldProps}
                                        label={formatMessage({ id: 'campaign.tabSettingForm.fileSize' })}
                                        value={settings.fileSize}
                                        onChange={settings.onChangeFileSize}
                                        error={!!settings.fileSizeMessage}
                                        helperText={settings.fileSizeMessage}
                                    />
                                </Grid>
                                <Grid item>
                                    <Select
                                        multiple={false}
                                        values={settings.executorId}
                                        label={formatMessage({ id: 'campaign.tabSettingForm.executor' })}
                                        selectData={settings.executorsList}
                                        onChange={settings.onChangeExecutor}
                                        textFieldProps={textFieldProps}
                                        autoCompleteProps={autocompleteProps}
                                    />
                                </Grid>
                                <Grid item>
                                    <RequestFormPermissionSettingsTable
                                        model={settings}
                                        handleEditPermissionDialogOpen={handleEditPermissionDialogOpen}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Button color="primary" fullWidth size="large" variant="contained" onClick={submit}>
                                    <FormattedMessage id="common.save" />
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button onClick={closeDialog} fullWidth size="large" variant="contained">
                                    <FormattedMessage id="common.cancel" />
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Dialog>
        </React.Fragment>
    );
});
