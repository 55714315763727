import {
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Grid,
    TextField,
    Typography,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { DatePicker } from '@material-ui/pickers';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import queryString from 'query-string';
import React, { ChangeEvent, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { entities, permissions } from '../../../../authSchemeConfig';
import { AuthorizationCheck, Select } from '../../../../components';
import { pickerProps } from '../../../../constants';
import { useStore } from '../../../../hooks';
import { CampaignRequestListModel, InSetFilter } from '../../../../models';
import { IntlStore } from '../../../../store';
import { autocompleteProps, textFieldProps } from './CampaignRequestsFilterProps';
import { ClearFilterButton } from './ClearDateFilterButton';

export type CampaignRequestListFilterPanelProps = {
    model: CampaignRequestListModel;
    isExecutorsAllowed: boolean;
};

export type SelectRegFormProps = {
    model: CampaignRequestListModel;
    intl: IntlStore;
};

export const SelectRegForm = observer((props: SelectRegFormProps): JSX.Element => {
    const { model, intl } = props;
    const { filtering } = model;
    return (
        <Select
            multiple={true}
            textFieldProps={textFieldProps}
            selectData={model.regFormFilterData}
            onChange={filtering.regForm.onChange}
            values={filtering.regForm.values}
            label={<FormattedMessage id="campaignRequest.registryColumns.regForm" />}
            placeholder={intl.formatMessage('common.all')}
            autoCompleteProps={autocompleteProps}
        />
    );
});

export const CampaignRequestListFilterPanel = observer((props: CampaignRequestListFilterPanelProps): JSX.Element => {
    const { model, isExecutorsAllowed } = props;
    const location = useLocation();
    const { intlStore: intl } = useStore();

    useEffect(() => {
        const campaignId = queryString.parse(location.search).campaignId;
        if (campaignId) {
            model.filtering.campaign = new InSetFilter(observable.array([campaignId] as string[]));
        }
    }, [location.search, model]);

    const clearButtonHandler = (handler: (date: Date | null) => void): (() => void) => {
        return (): void => handler(null);
    };

    const { filtering } = model;

    const campaignFilterChange = (event: ChangeEvent<{}>, value: string[]): void => {
        model.getFilterData(value);
        filtering.campaign.onChange(event, value);
        if (!filtering.campaign.values.length) {
            filtering.regForm.onChange(event, []);
        }
    };

    return (
        <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                <Typography variant="subtitle2">
                    <FormattedMessage id="common.filters" />
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Grid container direction="column" spacing={6}>
                    <Grid item container spacing={6}>
                        <Grid item xs={4}>
                            <TextField
                                {...textFieldProps}
                                label={<FormattedMessage id="campaignRequest.registryColumns.number" />}
                                placeholder={intl.formatMessage('common.all')}
                                value={filtering.number.value}
                                onChange={filtering.number.onChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <SelectRegForm model={model} intl={intl} />
                        </Grid>
                        <Grid item xs={4}>
                            <Select
                                multiple={true}
                                values={filtering.state.values}
                                label={<FormattedMessage id="campaignRequest.registryColumns.state" />}
                                placeholder={intl.formatMessage('common.all')}
                                selectData={model.stateFilterData}
                                onChange={filtering.state.onChange}
                                textFieldProps={textFieldProps}
                                autoCompleteProps={autocompleteProps}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AuthorizationCheck
                                entityCode={entities.System}
                                permCode={permissions.System.ViewPersonList}
                            >
                                {(allowed): JSX.Element => (
                                    <Select
                                        multiple={true}
                                        values={filtering.author.values}
                                        label={<FormattedMessage id="campaignRequest.registryColumns.author" />}
                                        selectData={model.personsFilterData}
                                        onChange={filtering.author.onChange}
                                        placeholder={intl.formatMessage('common.all')}
                                        textFieldProps={textFieldProps}
                                        autoCompleteProps={{
                                            ...autocompleteProps,
                                            disabled: !allowed,
                                        }}
                                    />
                                )}
                            </AuthorizationCheck>
                        </Grid>
                        <Grid item xs={4}>
                            <Select
                                multiple={true}
                                textFieldProps={textFieldProps}
                                selectData={model.campaignFilterData}
                                onChange={campaignFilterChange}
                                values={filtering.campaign.values}
                                label={<FormattedMessage id="campaignRequest.registryColumns.campaign" />}
                                placeholder={intl.formatMessage('common.all')}
                                autoCompleteProps={autocompleteProps}
                            />
                        </Grid>
                        {isExecutorsAllowed && (
                            <Grid item xs={4}>
                                <Select
                                    multiple={true}
                                    textFieldProps={textFieldProps}
                                    selectData={model.employeeFilterData}
                                    onChange={filtering.executors.onChange}
                                    values={filtering.executors.values}
                                    label={<FormattedMessage id="campaignRequest.registryColumns.executor" />}
                                    placeholder={intl.formatMessage('common.all')}
                                    autoCompleteProps={autocompleteProps}
                                />
                            </Grid>
                        )}
                        <Grid item xs={4} container justify="space-between">
                            <Grid item xs={6}>
                                <DatePicker
                                    {...pickerProps}
                                    label={<FormattedMessage id="campaignRequest.registryColumns.created" />}
                                    placeholder={intl.formatMessage('common.from')}
                                    value={filtering.created.value.from}
                                    onChange={filtering.created.onFromChange}
                                    {...(filtering.created.value.to && { maxDate: filtering.created.value.to })}
                                    InputProps={{
                                        endAdornment: (
                                            <ClearFilterButton
                                                clear={clearButtonHandler(filtering.created.onFromChange)}
                                            />
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <DatePicker
                                    {...pickerProps}
                                    placeholder={intl.formatMessage('common.until')}
                                    value={filtering.created.value.to}
                                    onChange={filtering.created.onToChange}
                                    {...(filtering.created.value.from && { minDate: filtering.created.value.from })}
                                    InputProps={{
                                        endAdornment: (
                                            <ClearFilterButton
                                                clear={clearButtonHandler(filtering.created.onToChange)}
                                            />
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={4} container justify="space-between">
                            <Grid item xs={6}>
                                <DatePicker
                                    {...pickerProps}
                                    label={<FormattedMessage id="campaignRequest.registryColumns.sent" />}
                                    placeholder={intl.formatMessage('common.from')}
                                    value={filtering.sent.value.from}
                                    onChange={filtering.sent.onFromChange}
                                    {...(filtering.sent.value.to && { maxDate: filtering.sent.value.to })}
                                    InputProps={{
                                        endAdornment: (
                                            <ClearFilterButton
                                                clear={clearButtonHandler(filtering.sent.onFromChange)}
                                            />
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <DatePicker
                                    {...pickerProps}
                                    placeholder={intl.formatMessage('common.until')}
                                    value={filtering.sent.value.to}
                                    onChange={filtering.sent.onToChange}
                                    {...(filtering.sent.value.from && { minDate: filtering.sent.value.from })}
                                    InputProps={{
                                        endAdornment: (
                                            <ClearFilterButton clear={clearButtonHandler(filtering.sent.onToChange)} />
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
});
