import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Box, CircularProgress, Paper, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { fromPromise } from 'mobx-utils';
import { Redirect, useLocation } from 'react-router-dom';
import { useStore } from '../../../hooks';

export const EsiaLoginPage = observer(
    (): JSX.Element => {
        const { authenticationStore, personStore } = useStore();
        const location = useLocation();
        const [login] = useState<Promise<void>>(
            authenticationStore.esiaLogin(location.search).then(() => personStore.getInfo()),
        );

        return fromPromise(login).case({
            pending: () => <CircularProgress />,
            fulfilled: () => <Redirect to={personStore.redirectPath} />,
            rejected: () => (
                <Paper elevation={0}>
                    <Box p={12}>
                        <Typography variant="h5">
                            <Box fontWeight="fontWeightBold">
                                <FormattedMessage id="authentication.error" />
                            </Box>
                        </Typography>
                    </Box>
                </Paper>
            ),
        });
    },
);
