import axios, { AxiosInstance, AxiosPromise, AxiosRequestConfig } from 'axios';
import { action, observable } from 'mobx';
import { apiConfigs } from '../apiConfigs';
import { AuthStatus, AxiosErrorHandler, ResponseErrorDTO } from '../types';
import { createMainInfoErrorHandler } from '../utils';
import { NotificationStore } from './NotificationStore';
import { RootStore } from './RootStore';

export class ApiStore {
    @observable private rootStore: RootStore;
    @observable authStatus: AuthStatus = AuthStatus.pending;

    @observable client: AxiosInstance;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.client = axios.create({
            baseURL: this.rootStore.env.apiUrl,
            withCredentials: true,
        });
        this.client.interceptors.response.use((_) => _, this.authInterceptor);
    }

    private get notificationStore(): NotificationStore {
        return this.rootStore.notificationStore;
    }

    /**
     * Не делает редиректы.
     * Использует алерт для ошибок.
     */
    @action.bound
    userActionClient(
        config: AxiosRequestConfig,
        fieldsList: string[] = [],
        statusesToIgnore: number[] = [],
    ): AxiosPromise {
        return this.client(config).catch((error) =>
            this.userActionClientCatchHandler(error, fieldsList, statusesToIgnore),
        );
    }

    @action.bound
    userActionClientCatchHandler(error: any, fieldsList: string[] = [], statusesToIgnore: number[] = []): AxiosPromise {
        const responseError: string | ResponseErrorDTO[] | undefined = error?.response && error.response.data;
        const responseStatus: number | undefined = error?.response && error.response.status;
        const errorsList: Record<string, string> = {};

        if (!statusesToIgnore.includes(responseStatus as number)) {
            if (Array.isArray(responseError)) {
                responseError.map((errorDTO) => {
                    const errorField = errorDTO.field;

                    if (errorField && fieldsList.indexOf(errorField) !== -1) {
                        errorsList[errorField] = errorDTO.message;
                    } else {
                        this.notificationStore.onError(errorDTO);
                    }
                });
            } else {
                this.notificationStore.onError(responseError || error);
            }
        }

        const shouldRejectOriginalError = !Object.keys(errorsList).length;
        return Promise.reject(shouldRejectOriginalError ? error : errorsList);
    }

    /**
     * Делает редиректы.
     * Не использует алерт для ошибок.
     */
    @action.bound
    mainInfoClient(config: AxiosRequestConfig, isShouldUseCustomHandler = false): AxiosPromise {
        return this.client(config).catch(this.handleMainInfoClientError(isShouldUseCustomHandler)) as AxiosPromise;
    }

    @action.bound
    handleMainInfoClientError(isShouldUseCustomHandler = false): AxiosErrorHandler {
        const createdHandler = createMainInfoErrorHandler(this.rootStore.history, this.userActionClientCatchHandler);
        return createdHandler(isShouldUseCustomHandler);
    }

    @action.bound
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    authInterceptor(error: any): void {
        const response = error.response || {};
        if (response.status === 401) {
            this.authStatus = AuthStatus.unauthorized;
        }
        throw error;
    }

    @action.bound
    async authVerify(): Promise<void> {
        this.authStatus = AuthStatus.pending;
        await this.client(apiConfigs.authorizationCheck([])).then((r) => r);
        this.authStatus = AuthStatus.ok;
    }
}
