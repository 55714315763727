import { ContentErrorsModel } from '../models';

export enum ErrorEntityType {
    submission = 'submission',
    printForm = 'printForm',
}

export type ResponseErrorDTO = {
    message: string;
    statusCode: string;
    field?: string;
    stack: string[];
};

export type ContentErrorDTO = ResponseErrorDTO & {
    tabId?: string;
    entityType?: ErrorEntityType;
    entityId?: string;
};
