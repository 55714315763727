import React, { useEffect, useState } from 'react';
import { entities, permissions } from '../authSchemeConfig';
import ym, { YMInitializer } from 'react-yandex-metrika';
import { useStore } from '../hooks/useStore';
import { observer } from 'mobx-react';

// Компонент Яндекс Метрики, не работающий для админа.
export const YMetrika = observer(
    (): JSX.Element => {
        const { env, personStore, authorizationStore, history } = useStore();
        const [isAdmin, setIsAdmin] = useState<boolean>(true);

        useEffect(() => {
            const entityCode = entities.System;
            const permCode = permissions.System.Administration;

            if (!personStore.user.id || !env.yaMetrikaId) {
                return;
            }

            authorizationStore.check({ entityCode, permCode }).then((allowed) => {
                setIsAdmin(allowed);
                if (allowed) {
                    return;
                }

                history.listen((location) => {
                    ym('hit', location.pathname);
                });
            });
        }, [authorizationStore, env, history, personStore.user.id, setIsAdmin]);

        if (!isAdmin && env.yaMetrikaId && personStore.user.id) {
            return (
                <YMInitializer
                    accounts={[env.yaMetrikaId]}
                    options={{
                        defer: true,
                        clickmap: true,
                        trackLinks: true,
                        accurateTrackBounce: true,
                        webvisor: true,
                    }}
                    version="2"
                />
            );
        }
        return <React.Fragment />;
    },
);
