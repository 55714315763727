import React, { useEffect } from 'react';
import { useStore } from '../hooks';
import { useHistory } from 'react-router-dom';
import { clientRoute } from '../clientRoute';
import { observer } from 'mobx-react';

export const Logout = observer(
    (): JSX.Element => {
        const { authenticationStore } = useStore();
        const history = useHistory();

        useEffect(() => {
            authenticationStore.logout().then(() => {
                history.push(clientRoute.root);
            });
        }, [authenticationStore]);

        return <React.Fragment />;
    },
);
