import { FormatListNumbered } from '@material-ui/icons';
import { Location } from 'history';
import { computed } from 'mobx';
import React from 'react';
import { match as Match } from 'react-router-dom';
import { entities, permissions } from '../authSchemeConfig';
import { clientRoute } from '../clientRoute';
import { ReactComponent as FileIcon } from '../resources/images/icons/file-document-box-multiple-outline.svg';
import { ReactComponent as FolderIcon } from '../resources/images/icons/folder.svg';
import { AuthorizationCheckQuery } from './AuthorizationStore';

export type AppHeaderLinkData = {
    key: string;
    to: string;
    icon?: JSX.Element;
    messageID: string;
    query?: AuthorizationCheckQuery;
    isActiveCallback?: (match: Match, location: Location) => boolean;
};

export type AllowedLink = {
    link: AppHeaderLinkData;
    allowed: boolean;
};

const iconStyles: React.CSSProperties = { fontSize: '24px' };

export class HeaderLinksStore {
    @computed
    get mainLinks(): AppHeaderLinkData[] {
        return [
            {
                key: 'licenses',
                to: clientRoute.licenses,
                icon: <FormatListNumbered style={iconStyles} />,
                messageID: 'homePage.headerLinks.licenses',
                query: {
                    entityCode: entities.System,
                    permCode: permissions.System.ViewLicenseRegistry,
                },
            },
            {
                key: 'requests',
                to: clientRoute.requests,
                icon: <FileIcon />,
                messageID: 'homePage.headerLinks.requests',
                query: {
                    entityCode: entities.System,
                    permCode: permissions.System.ViewCampRequestRegistry,
                },
            },
            {
                key: 'authorityDocs',
                to: clientRoute.authorityDocs,
                icon: <FormatListNumbered style={iconStyles} />,
                messageID: 'homePage.headerLinks.authorityDocs',
                query: {
                    entityCode: entities.System,
                    permCode: permissions.System.ViewAuthorityDocs,
                },
            },
            {
                key: 'quotas',
                to: clientRoute.quotas,
                icon: <FormatListNumbered style={iconStyles} />,
                messageID: 'homePage.headerLinks.quotas',
                query: {
                    entityCode: entities.System,
                    permCode: permissions.System.ViewQuotas,
                },
            },
            {
                key: 'campaigns',
                to: clientRoute.campaigns,
                icon: <FolderIcon />,
                messageID: 'homePage.headerLinks.campaigns',
                query: {
                    entityCode: entities.System,
                    permCode: permissions.System.ViewCampaignList,
                },
            },
        ];
    }

    @computed
    get additionalLinks(): AppHeaderLinkData[] {
        return [];
    }

    // На случай если будут особенно длинные ссылки
    // добавляем возможность переопределить для того, чтобы отображение не ломалось
    @computed
    get avgLinkWidth(): number {
        return 155;
    }
}
