import { Box, Container, Grid, ThemeProvider, Typography } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import { useFeature } from 'feature-toggle-jsx';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { createLandingThemeCreator, LandingThemeCreator } from '../../createThemes';
import { useStore } from '../../hooks';

export const StartPageStyledContainer = withTheme(
    styled(Container)`
        text-align: center;
    `,
);

export const StartPageTotXTypography = withTheme(
    styled.h1`
        font-family: 'Roboto', sans-serif;
        margin: 0;
        font-size: 75px;
        line-height: 1.08;
        font-weight: 900;
        letter-spacing: normal;

        span:nth-child(2) {
            display: none;
        }

        &:hover {
            span:first-child {
                display: none;
            }

            span:nth-child(2) {
                display: inline;
            }
        }
    `,
);

export const StartPageICSTypography = withTheme(
    styled.h2`
        font-family: 'Roboto', sans-serif;
        margin: 0;
        font-size: 47px;
        line-height: 1.34;
        font-weight: 900;
        letter-spacing: normal;
    `,
);

export const StartPageLandingTypography = withTheme(
    styled(Box)`
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
        line-height: 1.67;
        font-weight: 300;
        letter-spacing: 0.27px;
    `,
);

export const StartPage = observer((): JSX.Element => {
    const rootStore = useStore();
    const { startPageText } = rootStore.env;
    const [startPageDefaultText] = useFeature('startPageDefaultText');
    const landingThemeCreator: LandingThemeCreator = createLandingThemeCreator(rootStore);

    return (
        <ThemeProvider theme={landingThemeCreator}>
            <Grid container item direction="column" alignItems="center" justify="center">
                <StartPageStyledContainer maxWidth={!startPageDefaultText ? 'sm' : 'xs'}>
                    <Box mr={6}>
                        {startPageDefaultText ? (
                            <React.Fragment>
                                <StartPageTotXTypography>
                                    TOT&nbsp;<span>X</span>
                                    <span>ICS</span>
                                </StartPageTotXTypography>
                                <StartPageICSTypography>Intellectual capital solution</StartPageICSTypography>
                                <StartPageLandingTypography mt={3} component="p">
                                    <FormattedMessage id="startPage.productDescription" />
                                </StartPageLandingTypography>
                            </React.Fragment>
                        ) : (
                            <Typography variant="h3" component="h1" color="primary">
                                {startPageText}
                            </Typography>
                        )}
                    </Box>
                </StartPageStyledContainer>
            </Grid>
        </ThemeProvider>
    );
});
