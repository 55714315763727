import { Button, Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { clientRoute } from '../../../../clientRoute';
import { Form, FormApi, FormModel } from '@platform/formiojs-react';
import { useStore, useFormStyles } from '../../../../hooks';

type RouteParams = {
    id: string;
};

export const CampaignGeneralSettings = observer((): JSX.Element => {
    const { campaignsStore } = useStore();
    const { id } = useParams<RouteParams>();
    const history = useHistory();
    const [model] = useState<FormModel>(() => campaignsStore.getFormForGeneralSettings(id));
    const [form, setForm] = useState<FormApi>();
    const { notificationStore } = useStore();
    const intl = useIntl();
    const { locale } = intl;
    const classes = useFormStyles();

    const onFormReady = (form: FormApi): void => {
        setForm(form);
    };

    const onSubmit = (): void => {
        if (form && form.validate()) {
            form.submit(false)
                .then(() => {
                    return campaignsStore.saveFormForGeneralSettings(id, form.getSubmission());
                })
                .then(() => {
                    history.push(generatePath(clientRoute.generalSettings, { id }));
                    notificationStore.onSuccess(intl.formatMessage({ id: 'notifications.success' }));
                })
                .catch((error) => {
                    notificationStore.onError(error.response.data);
                });
        }
    };

    return (
        <React.Fragment>
            <Form locale={locale} className={classes.formWithNoOffset} form={model} onFormReady={onFormReady} />
            <Grid item>
                <Button color="secondary" variant="contained" onClick={onSubmit}>
                    <FormattedMessage id="common.save" />
                </Button>
            </Grid>
        </React.Fragment>
    );
});
