import { Box, Button, Chip, Container, Grid, Tooltip, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Route } from 'react-router-dom';
import { entities, permissions } from '../../authSchemeConfig';
import { clientRoute } from '../../clientRoute';
import {
    AuthorizationCheck,
    IconButtonLarge,
    TotObjectHeader,
    TotObjectHeaderLabel,
    TotObjectHeaderValue,
} from '../../components';
import { useModal } from '../../hooks';
import { UserPersonModel } from '../../models';
import { ReactComponent as EditIcon } from '../../resources/images/icons/edit.svg';
import { getOrElse } from '../../utils';
import { UserEditDialog } from './UserEditDialog';

export type UserHeaderProps = {
    userPersonModel: UserPersonModel;
    loginAsUser: () => void;
};

export const UserHeader = observer((props: UserHeaderProps): JSX.Element => {
    const { userPersonModel, loginAsUser } = props;

    const [isModalOpen, setModalIsOpen, setModalIsClosed] = useModal();

    return (
        <TotObjectHeader>
            <Container maxWidth="lg">
                <Grid container spacing={3} direction="column">
                    <Grid item container justify="space-between">
                        <Grid item>
                            <Grid container direction="row" alignItems="center" spacing={2}>
                                <Grid item>
                                    <Typography variant="h1">
                                        {getOrElse(userPersonModel.login, userPersonModel.login, '-')}
                                    </Typography>
                                </Grid>
                                <Route path={clientRoute.user} exact>
                                    <AuthorizationCheck
                                        entityCode={entities.System}
                                        entityId={userPersonModel.id}
                                        permCode={permissions.System.Administration}
                                    >
                                        <Grid item>
                                            <Tooltip title={<FormattedMessage id="users.editUser" />}>
                                                <IconButtonLarge onClick={setModalIsOpen}>
                                                    <EditIcon />
                                                </IconButtonLarge>
                                            </Tooltip>
                                            <UserEditDialog
                                                userPersonModel={userPersonModel}
                                                isOpen={isModalOpen}
                                                close={setModalIsClosed}
                                            />
                                        </Grid>
                                    </AuthorizationCheck>
                                </Route>
                            </Grid>
                        </Grid>
                        <Route path={clientRoute.user} exact>
                            <Grid item>
                                <AuthorizationCheck
                                    entityCode={entities.User}
                                    permCode={permissions.User.LoginAs}
                                    entityId={userPersonModel.id}
                                >
                                    <Button onClick={loginAsUser} variant="contained" color="primary">
                                        <FormattedMessage id="users.loginAsUser" />
                                    </Button>
                                </AuthorizationCheck>
                            </Grid>
                        </Route>
                    </Grid>

                    <Grid item container spacing={6} direction="row">
                        <Grid item>
                            <Grid container direction="row" alignItems="baseline" spacing={2}>
                                <Grid item>
                                    <TotObjectHeaderLabel>
                                        <FormattedMessage id="users.fields.roles" />
                                    </TotObjectHeaderLabel>
                                </Grid>
                                <Grid item>
                                    <TotObjectHeaderValue>
                                        {userPersonModel.roles.map((r) => (
                                            <Box pr={1} component="span" key={r.code}>
                                                <Chip label={r.title} />
                                            </Box>
                                        ))}
                                    </TotObjectHeaderValue>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </TotObjectHeader>
    );
});
