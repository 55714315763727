import { IconButton, SvgIcon } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { ReactNode, useCallback, useEffect } from 'react';
import { generatePath } from 'react-router-dom';
import { clientRoute } from '../../../../clientRoute';
import { ActionMenuItem, ConfirmationDialog, MenuButton } from '../../../../components';
import { useModal, useStore, usePermissionsActionButtons } from '../../../../hooks';
import { CampaignRequestRow } from '../../../../models';
import { ReactComponent as DotMenu } from '../../../../resources/images/icons/dot-menu.svg';
import { ConfirmationButtons } from './confirmationButtons';
import { useSettingsConfirmDialog } from './useSettingsConfirmDialog';

export type CampaignRequestActionsBtnsProps = {
    campaignRow: CampaignRequestRow;
    reloadData: () => void;
    isConfirm?: boolean;
    setIsConfirm?: React.Dispatch<React.SetStateAction<boolean>>;
    handleEditExecutorDialogOpen: (campaignRow: CampaignRequestRow, title?: string) => void;
};

export const CampaignRequestActionsBtns = observer((props: CampaignRequestActionsBtnsProps): JSX.Element => {
    const { campaignRow, reloadData, isConfirm, setIsConfirm, handleEditExecutorDialogOpen } = props;
    const { id } = campaignRow;
    const { intlStore: intl } = useStore();

    const [open, setModalIsOpen, setModalIsClosed] = useModal();

    const [permissionsData, reloadPermission] = usePermissionsActionButtons({ rowId: id });
    const [addActualAppointment, updateActualAppointment, editCampaignRequest, deleteCampaignRequest] = permissionsData;

    const { requestStore } = useStore();

    useEffect(() => {
        if (setIsConfirm && isConfirm) {
            setIsConfirm(false);
            reloadData();
            reloadPermission();
        }
    }, [isConfirm, setIsConfirm]);

    const deleteCampaign = useCallback((): void => {
        try {
            requestStore.deleteRequest(id).finally(reloadData);
        } catch (error) {
            console.error(error);
        }
    }, [reloadData, id, requestStore]);

    const onDeleteConfirm = async (): Promise<void> => {
        await deleteCampaign();
        setModalIsClosed();
    };

    const handleConfirmAddExecutor = useCallback((): Promise<void> => {
        return requestStore.setCurrentUserAsExecutor(id).finally(() => {
            reloadPermission();
            reloadData();
            setModalIsClosed();
        });
    }, [reloadData, id, reloadPermission]);

    const [settings, setSettings] = useSettingsConfirmDialog({
        delete: onDeleteConfirm,
        addExecutor: handleConfirmAddExecutor,
    });

    const renderActionItems = useCallback((): ((hideMenu?: () => void) => ReactNode[]) => {
        return (hideMenu): ReactNode[] => {
            const onDeleteClick = (): void => {
                setSettings(ConfirmationButtons.delete);
                hideMenu && hideMenu();
                setModalIsOpen();
            };

            const onAddExecutorClick = (): void => {
                setSettings(ConfirmationButtons.addExecutor);
                hideMenu && hideMenu();
                setModalIsOpen();
            };

            const onAppointExecutorClick = (): void => {
                hideMenu && hideMenu();
                handleEditExecutorDialogOpen(campaignRow);
            };

            const onChangeExecutorClick = (): void => {
                hideMenu && hideMenu();
                handleEditExecutorDialogOpen(campaignRow, 'campaignRequest.dialog.changeExecutorTitle');
            };

            return [
                <React.Fragment key="actionBtns">
                    {addActualAppointment && updateActualAppointment && (
                        <ActionMenuItem
                            messageId="campaignRequest.actions.appointExecutor"
                            onClick={onAppointExecutorClick}
                        />
                    )}
                    {!addActualAppointment && updateActualAppointment && (
                        <ActionMenuItem
                            messageId="campaignRequest.actions.changeExecutor"
                            onClick={onChangeExecutorClick}
                        />
                    )}
                    {addActualAppointment && (
                        <ActionMenuItem
                            messageId="campaignRequest.actions.requestToWork"
                            onClick={onAddExecutorClick}
                        />
                    )}
                    {editCampaignRequest && (
                        <ActionMenuItem messageId="common.edit" path={generatePath(clientRoute.requestEdit, { id })} />
                    )}
                    {deleteCampaignRequest && <ActionMenuItem messageId="common.delete" onClick={onDeleteClick} />}
                </React.Fragment>,
            ];
        };
    }, [permissionsData, campaignRow, handleEditExecutorDialogOpen]);

    const renderActionsButton = (onClick: (event: React.MouseEvent<HTMLButtonElement>) => void): JSX.Element => {
        const atLeastOneAllowed = permissionsData.some((isPermissionAllowed) => isPermissionAllowed);
        return atLeastOneAllowed ? (
            <IconButton onClick={onClick}>
                <SvgIcon>
                    <DotMenu />
                </SvgIcon>
            </IconButton>
        ) : (
            <React.Fragment />
        );
    };

    return (
        <React.Fragment>
            <MenuButton renderButton={renderActionsButton} renderMenuItems={renderActionItems()} />
            <ConfirmationDialog
                id={settings.id}
                open={open}
                title={intl.formatMessage(settings.title)}
                message={intl.formatMessage(settings.message, { number: campaignRow.number })}
                onConfirm={settings.onConfirm}
                onCancel={setModalIsClosed}
                keepMounted
            />
        </React.Fragment>
    );
});
