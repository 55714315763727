import { Box, Container, Grid } from '@material-ui/core';
import React from 'react';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import { entities, permissions } from '../../../authSchemeConfig';
import { clientRoute } from '../../../clientRoute';
import { AuthorizationCheck, BreadcrumbsComponent } from '../../../components';
import { useStore } from '../../../hooks';
import { CampaignSettingTabs } from './CampaignSettingTabs';
import { CampaignForm } from './description';
import { CampaignGeneralSettings } from './general-settings';
import { CampaignManagement } from './management';
import { CampaignRegForm, CampaignRegFormsList } from './reg-form';

type RouteProps = {
    id: string;
};

export const CampaignSettingsPage = (): JSX.Element => {
    const { id } = useParams<RouteProps>();

    const { breadcrumbsStore } = useStore();
    const { breadCrumbsSettings } = breadcrumbsStore;

    return (
        <AuthorizationCheck entityCode={entities.Campaign} entityId={id} permCode={permissions.Campaign.Edit}>
            {(allowed: boolean): JSX.Element => {
                if (!allowed) {
                    return <Redirect to={clientRoute.notAllowed} />;
                }
                return (
                    <Container maxWidth="lg">
                        <Box pt={5.5} pb={20}>
                            <Box pl={3} pb={5}>
                                <BreadcrumbsComponent objectId={id} breadcrumbsSettings={breadCrumbsSettings} />
                            </Box>
                            <Grid container spacing={5}>
                                <Grid item xs={3}>
                                    <CampaignSettingTabs />
                                </Grid>
                                <Grid item xs={9}>
                                    <Container maxWidth="md">
                                        <Switch>
                                            <Route
                                                path={[clientRoute.campaignSettings, clientRoute.campaignSettingsEdit]}
                                                exact
                                            >
                                                <CampaignForm />
                                            </Route>
                                            <Route path={clientRoute.campaignRegForms} exact>
                                                <CampaignRegFormsList />
                                            </Route>
                                            <Route path={[clientRoute.campaignManagement]} exact>
                                                <CampaignManagement />
                                            </Route>
                                            <Route path={clientRoute.generalSettings} exact>
                                                <CampaignGeneralSettings />
                                            </Route>
                                            <Route
                                                path={[
                                                    clientRoute.campaignRegForm,
                                                    clientRoute.campaignRegFormExecutors,
                                                    clientRoute.campaignRegFormTabsSettings,
                                                    clientRoute.campaignRequestFormTabSetting,
                                                    clientRoute.campaignRequestFormTabSettingCreate,
                                                    clientRoute.campaignRegFormTemplates,
                                                    clientRoute.campaignRegFormTemplatesCreate,
                                                    clientRoute.campaignRegFormTemplatesEdit,
                                                    clientRoute.campaignRegFormTemplatesPermissionSettings,
                                                    clientRoute.campaignRegFormCreate,
                                                    clientRoute.campaignRegFormEdit,
                                                ]}
                                            >
                                                <CampaignRegForm />
                                            </Route>
                                        </Switch>
                                    </Container>
                                </Grid>
                            </Grid>
                        </Box>
                    </Container>
                );
            }}
        </AuthorizationCheck>
    );
};
