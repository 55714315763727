import { CodeTitle } from '../models';
import { FormationPrinciple, FormationPrincipleCodes } from './formationPrinciples';

export type CountryGroup = {
    name: string;
    countryCodes: string[];
    formationPrincipleCode: FormationPrincipleCodes;
    archived: boolean;
};

export type FormikServiceCountryGroup = {
    name: string;
    countries: CodeTitle[];
    formationPrinciple: FormationPrinciple | null;
    archived: boolean;
};

export type CountryGroupDTO = {
    id: string;
    name: string;
    formationPrinciple: FormationPrinciple;
    countries: CodeTitle[];
    archived: boolean;
};

export type CountryGroupCustomDataDTO = {
    name: string;
    countries: CodeTitle[];
    formationPrinciple: CodeTitle;
    archived: boolean;
};

export type CountryGroupTableRowDTO = {
    customData: CountryGroupCustomDataDTO;
    id: string;
    lang: string;
};

export enum CountryGroupsFieldNames {
    name = 'name',
    countries = 'countries',
    formationPrinciple = 'formationPrinciple',
    archived = 'archived',
}
