import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { ConfirmationDialog, ConfirmationDialogProps } from '../components';

export type WrappedComponentProps<P> = {
    openConfirmDialog: () => void;
} & P;

type DialogProps = Omit<ConfirmationDialogProps, 'open' | 'onConfirm' | 'onCancel' | 'keepMounted'>;

export type WithConfirmDialogProps<P> = DialogProps & {
    onConfirm: () => Promise<void> | void;
    onOpen?: () => void;
    onClose?: () => void;
    wrappedComponentProps?: P;
};

export function withConfirmDialog<P>(
    WrappedComponent: React.ComponentType<WrappedComponentProps<P>>,
): (props: WithConfirmDialogProps<P>) => JSX.Element {
    const ComponentWithConfirmDialog = observer((props: WithConfirmDialogProps<P>): JSX.Element => {
        const { onConfirm, onOpen, onClose, wrappedComponentProps, ...dialogProps } =
            props as WithConfirmDialogProps<P>;

        const [isOpen, setIsOpen] = useState<boolean>(false);

        const openConfirmDialog = (): void => {
            setIsOpen(true);
            onOpen && onOpen();
        };

        const closeConfirmDialog = (): void => {
            setIsOpen(false);
            onClose && onClose();
        };

        const handleConfirm = async (): Promise<void> => {
            await onConfirm();
            closeConfirmDialog();
        };

        return (
            <React.Fragment>
                <WrappedComponent openConfirmDialog={openConfirmDialog} {...(wrappedComponentProps as P)} />
                <ConfirmationDialog
                    open={isOpen}
                    onConfirm={handleConfirm}
                    onCancel={closeConfirmDialog}
                    keepMounted
                    {...dialogProps}
                />
            </React.Fragment>
        );
    });

    return ComponentWithConfirmDialog;
}
