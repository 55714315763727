import { Box, Container, Grid, makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { BreadcrumbsComponent, breadCrumbsSettings } from '../../../components';
import { AuthorityDocList } from './AuthorityDocList';

const useAuthorityDocListPageStyles = makeStyles(() => {
    return {
        tableWrapper: {
            width: '100%',
        },
    };
});

export const AuthorityDocListPage = observer((): JSX.Element => {
    const classes = useAuthorityDocListPageStyles();

    return (
        <Container maxWidth="lg">
            <Box pt={5.5} pb={15}>
                <Box pb={5}>
                    <BreadcrumbsComponent breadcrumbsSettings={breadCrumbsSettings} />
                </Box>
                <Grid container direction="column">
                    <Grid item className={classes.tableWrapper}>
                        <AuthorityDocList />
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
});
