import { action, computed, observable } from 'mobx';
import { CampaignRequestRow } from './list/CampaignRequestListModel';
import { EmployeeDTO } from '../types/stores/requestStore';

export class RequestRowModel {
    @observable id: string = '';
    @observable number: string = '';
    @observable authorUserId: string = '';
    @observable authorName: string = '';
    @observable campaignId: string = '';
    @observable campaignTitle: string = '';
    @observable regFormTitle: string = '';
    @observable state: string = '';
    @observable created: string = ''; //date
    @observable sent: string = ''; //date
    @observable executors: EmployeeDTO[] = [];

    @computed
    get executorsList(): string {
        if (!this.executors) {
            return '';
        }

        const executorsList = this.executors.map(
            (executor) => `${executor.person.lastName} ${executor.person.firstName}`,
        );
        return executorsList.join(', ');
    }

    @action.bound
    load(row: CampaignRequestRow) {
        this.id = row.id;
        this.number = row.number;
        this.authorUserId = row.authorUserId;
        this.authorName = row.authorName;
        this.campaignId = row.campaignId;
        this.campaignTitle = row.campaignTitle;
        this.regFormTitle = row.regFormTitle;
        this.state = row.state;
        this.created = row.created;
        this.sent = row.sent;
        this.executors = row.executors;
    }
}
