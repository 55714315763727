import { Box, Button, Grid, useTheme } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import { EllipsisBox, FileListComponent } from '../../../components';
import { useAntiDoubleClick } from '../../../hooks';
import { FileDTOWithId, FileDTOWithFileId } from '../../../models';
import { ReactComponent as CreateRequest } from '../../../resources/images/icons/done-outlined-icon.svg';
import { getOrElse } from '../../../utils';
import { CardLabel, CardTitle, CardValue } from './RegFormCard.styled';

export type RegFormCardProps = {
    index: number;
    titleRegForm: string;
    description: string;
    registrationStartDate?: Date;
    registrationEndDate?: Date;
    afterDeadline?: boolean;
    fileInstructions: Array<FileDTOWithId>;
    onCreate: () => Promise<void>;
    onDownloadFile: (file: FileDTOWithFileId) => Promise<void>;
};

export const RegFormCard = observer((props: RegFormCardProps): JSX.Element => {
    const {
        titleRegForm,
        description,
        registrationStartDate,
        registrationEndDate,
        afterDeadline,
        fileInstructions,
        onDownloadFile,
        onCreate,
        index,
    } = props;

    const theme = useTheme();
    const [isSending, endIcon, createHandler] = useAntiDoubleClick(onCreate);

    const backgroundColor = useMemo((): object => {
        return index % 2 === 0 ? { backgroundColor: theme?.variables.palette.hoverInLists } : {};
    }, [index, theme]);

    const files: FileDTOWithFileId[] = useMemo(() => {
        return fileInstructions.map((i) => {
            const { id, ...dto } = i;
            return {
                fileId: id,
                ...dto,
            };
        });
    }, [fileInstructions]);

    return (
        <Grid container alignItems="flex-start" style={backgroundColor}>
            <Grid item xs={2}>
                <Box pt={10} pl={10} pr={5}>
                    <CardTitle variant="h2">
                        <EllipsisBox text={titleRegForm} />
                    </CardTitle>
                </Box>
            </Grid>
            <Grid item xs={8}>
                <Box pt={10} pb={10}>
                    <Box pb={6}>
                        <CardValue>{getOrElse(description)}</CardValue>
                    </Box>
                    <Grid container justify="space-between">
                        <Grid item>
                            <CardLabel>
                                <FormattedMessage id="campaignRequest.regFormCard.registrationStartDate" />
                            </CardLabel>
                            <CardValue>
                                {getOrElse(
                                    registrationStartDate,
                                    <Grid container alignItems="center" spacing={2}>
                                        <Grid item>
                                            <FormattedDate value={registrationStartDate} />
                                        </Grid>
                                        <Grid item>
                                            <FormattedTime value={registrationStartDate} />
                                        </Grid>
                                    </Grid>,
                                    '-',
                                )}
                            </CardValue>
                        </Grid>
                        <Grid item>
                            <CardLabel>
                                <FormattedMessage id="campaignRequest.regFormCard.registrationEndDate" />
                            </CardLabel>
                            <CardValue>
                                {getOrElse(
                                    registrationEndDate,
                                    <Grid container alignItems="center" spacing={2}>
                                        <Grid item>
                                            <FormattedDate value={registrationEndDate} />
                                        </Grid>
                                        <Grid item>
                                            <FormattedTime value={registrationEndDate} />
                                        </Grid>
                                    </Grid>,
                                    '-',
                                )}
                            </CardValue>
                        </Grid>
                        <Grid item>
                            <CardLabel>
                                <FormattedMessage id="campaignRequest.regFormCard.afterDeadline" />
                            </CardLabel>
                            <CardValue>
                                {getOrElse(
                                    afterDeadline,
                                    <FormattedMessage id="common.yes" />,
                                    <FormattedMessage id="common.no" />,
                                )}
                            </CardValue>
                        </Grid>
                    </Grid>
                    <Box pt={6}>
                        <CardLabel>
                            <FormattedMessage id="campaignRequest.regFormCard.instruction" />
                        </CardLabel>
                        <FileListComponent
                            files={files}
                            downloadFile={onDownloadFile}
                            noDataText={
                                <CardValue>
                                    <FormattedMessage id="common.noData" />
                                </CardValue>
                            }
                        />
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={2} container justify="flex-end">
                <Box pt={6} pr={6}>
                    <Button
                        variant="text"
                        color="primary"
                        startIcon={<CreateRequest />}
                        onClick={createHandler}
                        disabled={isSending}
                        endIcon={endIcon}
                    >
                        <FormattedMessage id="campaignRequest.createRequest" />
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
});
