import { Link, LinkProps, SvgIcon } from '@material-ui/core';
import React, { ElementType, ReactNode } from 'react';

export type IconLinkProps = {
    icon: ReactNode;
    text: ReactNode;
    uppercase?: boolean;
    component?: ElementType;
    to?: string;
} & LinkProps;

export const IconLink = React.forwardRef((props: IconLinkProps, ref) => {
    const { icon, text, underline = 'none', color = 'textPrimary', uppercase = false, ...restProps } = props;
    return (
        <Link
            underline={underline}
            color={color}
            {...restProps}
            {...(uppercase && { style: { textTransform: 'uppercase' } })}
        >
            <SvgIcon viewBox="-2 -2 24 24">{icon}</SvgIcon>
            {text}
        </Link>
    );
});
