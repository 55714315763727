import { Grid, IconButton, TableCell, TableRow, TextField } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { AutocompleteRenderInputParams } from '@material-ui/lab';
import { CodeTitle } from '@platform/ttable';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { ChangeEventHandler, useEffect } from 'react';
import { AutocompleteMobx } from '../../../../../components';
import { useQuotaContext } from '../../../../../hooks';
import { QuotaCountryGroup, yupQuotaCountryRowSchema as validationSchema } from '../../../../../models';
import { getSelectedOption } from '../../../../../utils';
import { useQuotaCGTableStyles, useQuotaPageStyles } from '../../../quota-styles';
import { extractOptionLabel, returnLoaderOrIcon } from '../../../quota-utils';

export type QuotaCountryRowProps = {
    quotaCountryGroup: QuotaCountryGroup;
};

export const QuotaCountryRow = observer((props: QuotaCountryRowProps): JSX.Element => {
    const { quotaCountryGroup } = props;
    const classes = useQuotaCGTableStyles();
    const quotaPageClasses = useQuotaPageStyles();
    const { quotasModel, isViewOnly } = useQuotaContext();
    const { id, setFormik, setCountryGroup, setVolume, volume } = quotaCountryGroup;
    const { catalogs, quota } = quotasModel;
    const { deleteCountryGroup } = quota;
    const { loadCountryGroupOptions, isCountryGroupOptionsLoaded } = catalogs;

    const autocompleteClasses = {
        popupIndicator: quotaPageClasses.popupIndicator,
    };

    const countriesAutocompletePopupIcon = isViewOnly || returnLoaderOrIcon(isCountryGroupOptionsLoaded);

    useEffect(() => {
        loadCountryGroupOptions();
    }, []);

    const formik = useFormik({
        initialValues: quotaCountryGroup,
        validationSchema,
        onSubmit: () => {},
    });

    useEffect(() => {
        setFormik(formik);
    }, []);

    const deleteRow = (): void => {
        deleteCountryGroup(id);
    };

    const countryGroupHandler = (e: any, value: CodeTitle | null): void => {
        setCountryGroup(value);
    };

    const volumeInputHandler: ChangeEventHandler<HTMLInputElement> = (e): void => {
        setVolume(e.target.value);
    };

    const renderCountriesTextfield = (params: AutocompleteRenderInputParams): JSX.Element => {
        return (
            <TextField
                variant="outlined"
                {...params}
                label="Группа стран квотирования"
                fullWidth
                error={!!quotaCountryGroup.touched.countryGroup && !!quotaCountryGroup.errors.countryGroup}
                helperText={quotaCountryGroup.errors.countryGroup}
                required
            />
        );
    };

    return (
        <TableRow className={classes.tableRow}>
            <TableCell>
                <AutocompleteMobx
                    options={catalogs.countryGroupOptions}
                    getOptionLabel={extractOptionLabel}
                    size="medium"
                    onChange={countryGroupHandler}
                    value={quotaCountryGroup.countryGroup}
                    onOpen={loadCountryGroupOptions}
                    renderInput={renderCountriesTextfield}
                    getOptionSelected={getSelectedOption}
                    classes={autocompleteClasses}
                    popupIcon={countriesAutocompletePopupIcon}
                    disabled={isViewOnly || !isCountryGroupOptionsLoaded}
                />
            </TableCell>
            <TableCell>
                <TextField
                    value={volume}
                    fullWidth
                    variant="outlined"
                    onChange={volumeInputHandler}
                    error={!!quotaCountryGroup.touched.volume && !!quotaCountryGroup.errors.volume}
                    disabled={isViewOnly}
                    helperText={quotaCountryGroup.errors.volume}
                    required
                />
            </TableCell>
            <TableCell>
                {!isViewOnly && (
                    <Grid container alignItems="center" justify="center">
                        <IconButton onClick={deleteRow}>
                            <DeleteIcon />
                        </IconButton>
                    </Grid>
                )}
            </TableCell>
        </TableRow>
    );
});
