const digitalSignature: Record<string, Record<string, string> | string> = {
    form: {
        signatureEnabled: 'Enable use of electronic signature',
        signatureRequired: 'Signature is required',
        signatureVariants: 'Signing variants',
        signatureBrowserPlugin: 'Browser plugin',
        signatureUpload: 'Uploading a signature or a file with an attached signature',
    },
};

export default digitalSignature;
