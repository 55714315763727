import { rootElement } from '../rootElement';

export default class ScrollUtils {
    static scrollToElementById(id: string, headerHeight: number): void {
        const element: HTMLElement | null = document.querySelector('#' + id);
        if (element && rootElement) {
            const elementBoundingRect: DOMRect = element.getBoundingClientRect();
            rootElement.scrollBy({
                top: elementBoundingRect.top - headerHeight,
                left: 0,
                behavior: 'smooth',
            });
        }
    }
}
