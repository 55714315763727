const homePage: Record<string, Record<string, string> | string> = {
    headerLinks: {
        requests: 'Заявления',
        requestsOld: 'Мои заявления',
        campaigns: 'Услуги',
        licenses: 'Лицензии',
        users: 'Пользователи',
        console: 'Консоль',
        authorityDocs: 'Разрешительные документы',
        quotas: 'Квоты',
        сountryGroups: 'Группы стран',
    },
};

export default homePage;
