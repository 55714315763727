import { Button } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { withConfirmDialog } from '../../../../hocs';

export type QuotasCancelButtonDialogProps = {
    onCancel: () => void;
};

const CancelButton = (props: QuotasCancelButtonDialogProps): JSX.Element => {
    const { onCancel } = props;

    return (
        <Button onClick={onCancel} color="secondary" size="small" variant="contained">
            <FormattedMessage id="common.discardChanges" />
        </Button>
    );
};

export const QuotasButtonButtonWithDialog = withConfirmDialog(
    ({ openConfirmDialog }): JSX.Element => <CancelButton onCancel={openConfirmDialog} />,
);

export type QuotasCancelButtonPropsDialog = {
    onConfirm: () => void;
};

export const QuotasCancelButtonWithDialog = (props: QuotasCancelButtonPropsDialog): JSX.Element => {
    const { onConfirm } = props;

    return (
        <QuotasButtonButtonWithDialog
            id="delete-request"
            title={<FormattedMessage id="campaignRequest.dialog.title" />}
            message={<FormattedMessage id="campaignRequest.dialog.closeMessage" />}
            onConfirm={onConfirm}
        />
    );
};
