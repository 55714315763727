import { CatalogItemDTO } from '@platform/multilevel-catalog';
import { CodeTitle } from '@platform/ttable';
import { action, computed, observable, toJS } from 'mobx';
import { CatalogStore } from '../../store';
import { CatalogName, FormationPrinciple } from '../../types';

export class CatalogsModel {
    private catalogsStore: CatalogStore;
    @observable loadedCatalogs = new Set();

    //-------------------------------------------------
    /** Поля, куда сеттятся справочники. */
    @observable categoryOptions: CatalogItemDTO[] = [];
    @observable typeOptions: CatalogItemDTO[] = [];
    @observable CNOptions: CatalogItemDTO[] = [];
    @observable operationOptions: CatalogItemDTO[] = [];
    @observable measurementUnitOptions: CatalogItemDTO[] = [];
    @observable countries: CodeTitle[] = [];
    @observable formationPrinciples: FormationPrinciple[] = [];
    @observable countryGroupOptions: CodeTitle[] = [];
    //-------------------------------------------------

    constructor(catalogsStore: CatalogStore) {
        this.catalogsStore = catalogsStore;
    }

    @computed
    get isCategoryOptionsLoaded(): boolean {
        return this.loadedCatalogs.has(CatalogName.categoryOptions);
    }

    @computed
    get isCNOptionsLoaded(): boolean {
        return this.loadedCatalogs.has(CatalogName.CNOptions);
    }

    @computed
    get isTypeOptionsLoaded(): boolean {
        return this.loadedCatalogs.has(CatalogName.typeOptions);
    }

    @computed
    get isOperationOptionsLoaded(): boolean {
        return this.loadedCatalogs.has(CatalogName.operationOptions);
    }

    @computed
    get isMeasurementUnitOptionsLoaded(): boolean {
        return this.loadedCatalogs.has(CatalogName.measurementUnitOptions);
    }

    @computed
    get isCountriesLoaded(): boolean {
        return this.loadedCatalogs.has(CatalogName.countries);
    }

    @computed
    get isFormationPrinciplesLoaded(): boolean {
        return this.loadedCatalogs.has(CatalogName.formationPrinciples);
    }

    @computed
    get isCountryGroupOptionsLoaded(): boolean {
        return this.loadedCatalogs.has(CatalogName.countryGroup);
    }

    @action.bound
    async loadAll(): Promise<CatalogsModel> {
        await this.loadCategoryOptions();
        await this.loadQuotaTypeOptions();
        await this.loadOperationOptions();
        await this.loadCNOptions();
        return this;
    }

    @action.bound
    handleError(e: any) {
        console.error('CatalogsModel: ', e);
    }

    @action.bound
    async loadCategoryOptions(): Promise<void> {
        try {
            this.categoryOptions = await this.catalogsStore.getQuotaCategoryOptions();
        } catch (e) {
            this.handleError(e);
        } finally {
            this.loadedCatalogs.add(CatalogName.categoryOptions);
        }
    }

    @action.bound
    async loadQuotaTypeOptions(): Promise<void> {
        try {
            this.typeOptions = await this.catalogsStore.getQuotaTypeOptions();
        } catch (e) {
            this.handleError(e);
        } finally {
            this.loadedCatalogs.add(CatalogName.typeOptions);
        }
    }

    @action.bound
    async loadCNOptions(): Promise<void> {
        try {
            this.CNOptions = await this.catalogsStore.getCNOptions();
        } catch (e) {
            this.handleError(e);
        } finally {
            this.loadedCatalogs.add(CatalogName.CNOptions);
        }
    }

    @action.bound
    async loadOperationOptions(code?: string): Promise<CatalogItemDTO[]> {
        if (this.isOperationOptionsLoaded) {
            return this.operationOptions;
        }
        if (!code) {
            const errorMessage = 'No productCategoryCode in loadOperationOptions!';
            console.error(errorMessage);
            return [];
        }
        try {
            this.operationOptions = await this.catalogsStore.getOperationOptions(code);
        } catch (e) {
            this.handleError(e);
        } finally {
            this.loadedCatalogs.add(CatalogName.operationOptions);
        }
        return toJS(this.operationOptions);
    }

    @action.bound
    async loadQuotaMeasurementUnitOptions(productCategoryCode: string): Promise<void> {
        if (!productCategoryCode) {
            return Promise.reject();
        }
        try {
            this.measurementUnitOptions = await this.catalogsStore.getQuotaMeasurementUnitOptions(productCategoryCode);
        } catch (e) {
            this.handleError(e);
        } finally {
            this.loadedCatalogs.add(CatalogName.measurementUnitOptions);
        }
    }

    @action.bound
    async loadCountriesCatalog(): Promise<CodeTitle[]> {
        if (this.isCountriesLoaded) {
            return this.countries;
        }
        try {
            this.countries = await this.catalogsStore.getCountriesCatalog();
        } catch (e) {
            this.handleError(e);
        } finally {
            this.loadedCatalogs.add(CatalogName.countries);
        }
        return this.countries;
    }

    @action.bound
    async loadFormationPrinciples(): Promise<FormationPrinciple[]> {
        if (this.isFormationPrinciplesLoaded) {
            return this.formationPrinciples;
        }
        try {
            this.formationPrinciples = await this.catalogsStore.getFormationPrinciplesCatalog();
        } catch (e) {
            this.handleError(e);
        } finally {
            this.loadedCatalogs.add(CatalogName.formationPrinciples);
        }
        return this.formationPrinciples;
    }

    @action.bound
    async loadCountryGroupOptions(): Promise<CodeTitle[]> {
        if (this.isCountryGroupOptionsLoaded) {
            return this.countryGroupOptions;
        }
        try {
            this.countryGroupOptions = await this.catalogsStore.getCountryGroupsOptions();
        } catch (e) {
            this.handleError(e);
        } finally {
            this.loadedCatalogs.add(CatalogName.countryGroup);
        }
        return this.countryGroupOptions;
    }
}
