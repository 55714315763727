import { SignatureInfoDTO, SignatureStatus } from '@platform/crypto-ui';
import { action, observable } from 'mobx';
import { apiConfigs } from '../apiConfigs';
import { ApiStore } from './ApiStore';
import { RootStore } from './RootStore';

export class SignatureStore {
    @observable private rootStore: RootStore;
    @observable private api: ApiStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.api = rootStore.api;
    }

    @action.bound
    getFileByte(printFormId: string): () => Promise<ArrayBuffer> {
        return (): Promise<ArrayBuffer> => {
            return this.api.client(apiConfigs.getFileByte(printFormId)).then((r) => r.data);
        };
    }

    @action.bound
    uploadSignature(printFormId: string): (signature: string) => Promise<SignatureStatus> {
        return (signature: string): Promise<SignatureStatus> => {
            return this.api.client(apiConfigs.uploadSignature(printFormId, signature)).then((r) => r.data);
        };
    }

    @action.bound
    uploadSignatureFile(printFormId: string): (signatureFile: FormData) => Promise<SignatureStatus> {
        return (signatureFile: FormData): Promise<SignatureStatus> => {
            return this.api.client(apiConfigs.uploadSignatureFile(printFormId, signatureFile)).then((r) => r.data);
        };
    }

    @action.bound
    getSignatureInfo(printFormId: string): () => Promise<SignatureInfoDTO[]> {
        return (): Promise<SignatureInfoDTO[]> => {
            return this.api.client(apiConfigs.getSignatureInfo(printFormId)).then((r) => r.data);
        };
    }

    @action.bound
    downloadSignature(printFormId: string): (isWithFile: boolean) => Promise<Blob> {
        return (isWithFile: boolean): Promise<Blob> => {
            return this.api.client(apiConfigs.downloadSignature(printFormId, isWithFile)).then((r) => r.data);
        };
    }

    @action.bound
    deleteSignature(printFormId: string): () => Promise<SignatureStatus> {
        return (): Promise<SignatureStatus> => {
            return this.api.client(apiConfigs.deleteSignature(printFormId)).then((r) => r.data);
        };
    }
}
