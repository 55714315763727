const quotas: Record<string, string> = {
    noName: 'Without name',
    confirmDeletionQuotaInfoText: 'Are you sure you want to delete the quota "{title}"?',
    creating: 'Create quota',
    create: 'Create quota',
    editing: 'Edit quota',
    quota: 'Quota',
};

export default quotas;
