import { MenuList, Popover, PopoverOrigin } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { PropsWithChildren, ReactNode, useState } from 'react';

export type MenuButtonProps = PropsWithChildren<{
    renderButton(onClick: (event: React.MouseEvent<HTMLButtonElement>) => void): JSX.Element;
    renderMenuItems?(hideMenu: () => void): JSX.Element | JSX.Element[] | ReactNode[];
    handleClose?: () => void;
    disablePortal?: boolean;
    reloadKey?: string;
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
}>;

const anchorOriginDefault: PopoverOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
};

const transformOriginDefault: PopoverOrigin = {
    vertical: 'top',
    horizontal: 'right',
};

export const MenuButton = observer((props: MenuButtonProps): JSX.Element => {
    const [buttonRef, setButtonRef] = useState<null | HTMLButtonElement>(null);
    const {
        renderButton,
        renderMenuItems,
        children,
        disablePortal,
        handleClose,
        reloadKey,
        anchorOrigin = anchorOriginDefault,
        transformOrigin = transformOriginDefault,
    } = props;

    const hideMenu = (): void => {
        setButtonRef(null);
    };

    const closeMenu = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        handleClose && handleClose();
        hideMenu();
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        setButtonRef(event.currentTarget);
    };

    const isMenuOpen = !!buttonRef;

    return (
        <React.Fragment>
            {renderButton(handleClick)}
            <Popover
                open={isMenuOpen}
                anchorEl={buttonRef}
                disablePortal={disablePortal}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
                onClose={closeMenu}
            >
                <MenuList id="language-menu" role="menu" key={reloadKey}>
                    {renderMenuItems && renderMenuItems(hideMenu)}
                    {children}
                </MenuList>
            </Popover>
        </React.Fragment>
    );
});
