const startPage: Record<string, Record<string, string> | string> = {
    headerLinks: {
        about: 'About product',
        news: 'News',
        documents: 'Documents',
        resources: 'Other resources',
        contacts: 'Contacts',
        radar: 'Radar',
        login: 'Sign in',
        loginAnonymously: 'Login without registration',
        loginAccount: 'Login to your account',
    },
    productDescription:
        'A customizable information environment for managing the intellectual capital of an organization',
    logoAlt: 'GISP',
};

export default startPage;
