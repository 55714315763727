import { Grid, TextField } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { ChangeEventHandler } from 'react';
import { useQuotaContext } from '../../../../../hooks';
import { filterLetters } from '../../../../../utils';
import { QuotasAutocompleteMobxField, returnLoaderOrIcon } from '../../../quota-utils';

export const QuotaVolumeSettingsAutocompletes = observer((): JSX.Element => {
    const { quotasModel, isViewOnly } = useQuotaContext();
    const { quota, catalogs } = quotasModel;
    const { volume, units, setVolume, setUnits, productCategory } = quota;
    const { isMeasurementUnitOptionsLoaded, measurementUnitOptions } = catalogs;

    const measurementUnitOptionsPopupIcon =
        !!productCategory && returnLoaderOrIcon(isViewOnly || catalogs.isMeasurementUnitOptionsLoaded);

    const volumeInputHandler: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e): void => {
        setVolume(Number(filterLetters(e.target.value)));
    };

    return (
        <React.Fragment>
            <Grid item>
                <TextField
                    variant="outlined"
                    fullWidth
                    label="Выделенный объём"
                    value={volume}
                    onChange={volumeInputHandler}
                    error={!!quota.touched.volume && !!quota.errors.volume}
                    disabled={isViewOnly}
                    helperText={quota.errors.volume}
                    required
                />
            </Grid>
            <Grid item>
                <QuotasAutocompleteMobxField
                    options={measurementUnitOptions}
                    value={units}
                    size="medium"
                    onChange={setUnits}
                    popupIcon={measurementUnitOptionsPopupIcon}
                    disabled={isViewOnly || !isMeasurementUnitOptionsLoaded}
                    variant="outlined"
                    label="Единица измерения квоты"
                    error={!!quota.touched.units && !!quota.errors.units}
                    helperText={quota.errors.units}
                    required
                />
            </Grid>
        </React.Fragment>
    );
});
