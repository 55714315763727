import { Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { entities, permissions } from '../../../authSchemeConfig';
import { AuthorizationCheck, LicenseSignatoryDialog } from '../../../components';
import { useModal, useStore } from '../../../hooks';
import { ReactComponent as EditIcon } from '../../../resources/images/icons/edit.svg';

export type LicenseSignatoryProps = {
    requestId: string;
    licenseSignatoryId: string;
    onSuccessChange: (signatoryId: string) => void;
};

export const LicenseSignatory = observer((props: LicenseSignatoryProps): JSX.Element => {
    const { licenseSignatoryId, requestId, onSuccessChange } = props;

    const { requestStore } = useStore();
    const [isOpen, open, close] = useModal();
    const [signatoryId, setSignatoryId] = useState<string>(licenseSignatoryId);
    const [signatoryName, setSignatoryName] = useState<string>('');

    useEffect(() => {
        requestStore.licenseSignatory(requestId, signatoryId).then((signatory) => setSignatoryName(signatory.name));
    }, [requestId, signatoryId, requestStore]);

    const saveSignatory = (signatory: string): Promise<void> => {
        return requestStore
            .assignLicenseSignatory(requestId, signatory)
            .then(() => setSignatoryId(signatory))
            .then(() => onSuccessChange(signatory));
    };
    return (
        <React.Fragment>
            <Grid container direction="column">
                <Grid item>
                    <Typography variant="body2" noWrap>
                        <FormattedMessage id="campaignRequest.responsibleSignatory" />
                    </Typography>
                </Grid>
                <Grid item container wrap="nowrap" alignItems="center" spacing={1}>
                    <Grid item>
                        <Typography variant="body2">{signatoryName}</Typography>
                    </Grid>
                    <AuthorizationCheck
                        entityCode={entities.CampaignRequest}
                        entityId={requestId}
                        permCode={permissions.CampaignRequest.AssignLicenseSignatory}
                    >
                        <Grid item>
                            <Tooltip title={<FormattedMessage id="campaignRequest.openEditMode" />}>
                                <IconButton onClick={open}>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </AuthorizationCheck>
                </Grid>
            </Grid>
            {isOpen && (
                <LicenseSignatoryDialog
                    currentSignatory={signatoryId}
                    isOpen={isOpen}
                    close={close}
                    saveSignatory={saveSignatory}
                />
            )}
        </React.Fragment>
    );
});
