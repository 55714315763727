import { Button, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, Route, useParams } from 'react-router-dom';
import { entities, permissions } from '../../../../../authSchemeConfig';
import { clientRoute } from '../../../../../clientRoute';
import { AuthorizationCheck, AuthorizationCheckAll } from '../../../../../components';
import { withConfirmDialog } from '../../../../../hocs';
import { useStore } from '../../../../../hooks';
import { useObjectRoutesContext } from '../../../../../hooks';
import { RequestModel } from '../../../../../models';
import { AuthorizationCheckQuery } from '../../../../../store';
import { RequestFormParams } from '../../../../../types';
import { CampaignEditButton } from './CampaignEditButton';

export type CampaignViewModeButtonsProps = {
    model: RequestModel;
    editCheckQueries?: AuthorizationCheckQuery[];
};

export const DeleteRequestButton = withConfirmDialog(({ openConfirmDialog }): JSX.Element => {
    return (
        <Button onClick={openConfirmDialog} color="secondary" size="small" variant="contained">
            <FormattedMessage id="campaignRequest.deleteRequest" />
        </Button>
    );
});

export const CampaignViewModeButtons = (props: CampaignViewModeButtonsProps): JSX.Element => {
    const { model, editCheckQueries } = props;

    const params = useParams<RequestFormParams>();
    const { tabId, subTabId } = params;
    const formId = subTabId || tabId;

    const { id, goToRequestEditPageAndValidate, deleteRequest, number } = model;
    const [isEditAllowed, setIsEditAllowed] = useState<boolean>(false);
    const { requestStore } = useStore();
    const objectRoutes = useObjectRoutesContext();

    useEffect(() => {
        if (!formId) {
            return;
        }

        requestStore
            .requestFormCheckEditable(formId)
            .then(() => setIsEditAllowed(true))
            .catch(() => setIsEditAllowed(false));
    }, [requestStore, setIsEditAllowed, formId]);

    const onEdit =
        formId &&
        goToRequestEditPageAndValidate(
            subTabId
                ? generatePath(objectRoutes.objectSubTabEdit, params)
                : generatePath(objectRoutes.objectTabEdit, params),
        );

    return (
        <React.Fragment>
            {editCheckQueries && onEdit && (
                <AuthorizationCheckAll or queries={editCheckQueries}>
                    <Grid item>
                        <CampaignEditButton onEdit={onEdit} />
                    </Grid>
                </AuthorizationCheckAll>
            )}
            {formId && isEditAllowed && onEdit && (
                <Grid item>
                    <CampaignEditButton onEdit={onEdit} />
                </Grid>
            )}
            <Route path={[objectRoutes.object, objectRoutes.objectTab, objectRoutes.objectSubTab]} exact>
                <AuthorizationCheck
                    entityCode={entities.CampaignRequest}
                    entityId={id}
                    permCode={permissions.CampaignRequest.Delete}
                >
                    <Grid item>
                        <DeleteRequestButton
                            id="delete-request"
                            title={<FormattedMessage id="common.confirmDeletion" />}
                            message={
                                <FormattedMessage id="campaignRequest.confirmDeletionInfoText" values={{ number }} />
                            }
                            onConfirm={deleteRequest}
                        />
                    </Grid>
                </AuthorizationCheck>
            </Route>
        </React.Fragment>
    );
};
