import {
    Box,
    Button,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Link,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete, Value } from '@material-ui/lab';
import { observer } from 'mobx-react';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, NavLink, useParams } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import { renderAutoCompleteInput } from '../../../components';
import { useStore } from '../../../hooks';
import { useObjectRoutesContext } from '../../../hooks';
import { CampaignRequestRow, RequestModel } from '../../../models';
import { EmployeeDTO } from '../../../types';

export type ExecutorEditDialogProps = {
    onClose: () => void;
    open: boolean;
    setIsConfirm?: React.Dispatch<React.SetStateAction<boolean>>;
    requestModel?: RequestModel;
    requestRowModel?: CampaignRequestRow;
    title?: string;
    reloadModel?: () => void;
};

type RouteParams = {
    id: string;
};

const useStyles = makeStyles(() =>
    createStyles({
        typography: {
            fontWeight: 'bold',
        },
    }),
);

export const ExecutorEditDialog = observer((props: ExecutorEditDialogProps): JSX.Element => {
    const { onClose, open, requestRowModel, title, requestModel, setIsConfirm } = props;
    const { intlStore: intl, requestStore } = useStore();
    const classes = useStyles();

    const [employeesList, setEmployeesList] = useState<EmployeeDTO[]>([]);
    const [selectedExecutor, setSelectedExecutor] = useState<EmployeeDTO | null>(null);
    const [errorText, setErrorText] = useState<string>('');

    const { id } = useParams<RouteParams>();
    const requestId = requestRowModel ? requestRowModel.id : id;

    const objectRoutes = useObjectRoutesContext();

    useEffect(() => {
        requestStore.getRequestExecutorList(requestId).then(setEmployeesList);
    }, [requestId, requestStore, setEmployeesList]);

    useEffect(() => {
        open && requestId && requestStore.getExecutorSettings(requestId).then(setSelectedExecutor);
    }, [requestStore, setSelectedExecutor, requestId, open]);

    const handleExecutorChange = (
        event: ChangeEvent<{}>,
        value: Value<EmployeeDTO, undefined, undefined, undefined>,
    ): void => {
        setSelectedExecutor(value);
        setErrorText('');
    };

    const handleClose = (): void => {
        onClose();
        setErrorText('');
        setSelectedExecutor(null);
    };

    const handleConfirm = (): void => {
        if (!selectedExecutor) {
            setErrorText(intl.formatMessage('validation.required'));
            return;
        }

        requestStore.editExecutorSettings(requestId, selectedExecutor?.userId).then(() => {
            requestModel && requestModel.updateExecutors();

            if (requestRowModel) {
                requestRowModel.executors = [selectedExecutor];
            }

            if (setIsConfirm) {
                setIsConfirm(true);
            }

            handleClose();
        });
    };

    const executorEditDialogTitle = title ? title : 'campaignRequest.dialog.editExecutorTitle';

    const getOptionSelected = (option: EmployeeDTO, value: EmployeeDTO): boolean => {
        return option.userId === value.userId;
    };

    const getOptionLabel = (option: EmployeeDTO): string => {
        return `${option.person.lastName} ${option.person.firstName}`;
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                <FormattedMessage id={executorEditDialogTitle} />
            </DialogTitle>
            <DialogContent>
                {requestRowModel && (
                    <Box pb={4}>
                        <Grid container spacing={3}>
                            <Grid item container direction="column">
                                <Grid item>
                                    <Typography className={classes.typography} variant="body2">
                                        <FormattedMessage id="campaignRequest.registryColumns.number" />
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography>
                                        <Link
                                            component={NavLink}
                                            underline="none"
                                            to={generatePath(objectRoutes.object, { id: requestRowModel.id })}
                                        >
                                            {requestRowModel.number || (
                                                <FormattedMessage id="campaignRequest.withoutNumber" />
                                            )}
                                        </Link>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item container direction="column">
                                <Grid item>
                                    <Typography className={classes.typography} variant="body2">
                                        <FormattedMessage id="campaignRequest.registryColumns.campaign" />
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography>
                                        <Link
                                            component={NavLink}
                                            underline="none"
                                            to={generatePath(clientRoute.campaign, {
                                                id: requestRowModel.campaignId,
                                            })}
                                        >
                                            {requestRowModel.campaignTitle}
                                        </Link>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item container direction="column">
                                <Grid item>
                                    <Typography className={classes.typography} variant="body2">
                                        <FormattedMessage id="campaignRequest.registryColumns.author" />
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography>{requestRowModel.authorName}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                )}
                <Autocomplete
                    key={selectedExecutor?.userId}
                    onChange={handleExecutorChange}
                    getOptionSelected={getOptionSelected}
                    getOptionLabel={getOptionLabel}
                    options={employeesList}
                    defaultValue={selectedExecutor}
                    loading={false}
                    renderInput={renderAutoCompleteInput(
                        intl.formatMessage('campaign.campaignRegFormExecutorResponsibleExecutor'),
                        true,
                        errorText,
                    )}
                />
            </DialogContent>
            <DialogActions>
                <Grid container direction="column" spacing={3}>
                    <Grid item>
                        <Button onClick={handleConfirm} size="large" color="primary" variant="contained" fullWidth>
                            <FormattedMessage id="common.save" />
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={handleClose} size="large" color="primary" fullWidth>
                            <FormattedMessage id="common.cancel" />
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
});
