import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useQuotaContext } from '../../../../../hooks';
import { useQuotaPageStyles } from '../../../quota-styles';
import { QuotasDateTimePicker } from '../../../quota-utils';

export const QuotaValidityDurationInputs = observer((): JSX.Element => {
    const classes = useQuotaPageStyles();
    const { quotasModel, isViewOnly } = useQuotaContext()
    const { quota } = quotasModel;
    const {
        validityPeriodStart,
        validityPeriodEnd,
        licensingPeriodStart,
        licensingPeriodEnd,
        setValidityPeriodStart,
        setValidityPeriodEnd,
        setLicensingPeriodStart,
        setLicensingPeriodEnd,
    } = quota;

    return (
        <React.Fragment>
            <Grid container>
                <QuotasDateTimePicker
                    label="Период действия квоты с"
                    value={validityPeriodStart}
                    onChange={setValidityPeriodStart}
                    error={!!quota.touched.validityPeriodStart && !!quota.errors.validityPeriodStart}
                    readOnly={isViewOnly}
                    disabled={isViewOnly}
                    className={classes.datePickerLeft}
                />
                <QuotasDateTimePicker
                    label="Период действия квоты по"
                    value={validityPeriodEnd}
                    onChange={setValidityPeriodEnd}
                    error={!!quota.touched.validityPeriodEnd && !!quota.errors.validityPeriodEnd}
                    readOnly={isViewOnly}
                    disabled={isViewOnly}
                    className={classes.datePickerRight}
                />
            </Grid>
            <Grid container>
                <QuotasDateTimePicker
                    label="Период оформления лицензии с"
                    value={licensingPeriodStart}
                    onChange={setLicensingPeriodStart}
                    error={!!quota.touched.licensingPeriodStart && !!quota.errors.licensingPeriodStart}
                    readOnly={isViewOnly}
                    disabled={isViewOnly}
                    className={classes.datePickerLeft}
                />
                <QuotasDateTimePicker
                    label="Период оформления лицензии по"
                    value={licensingPeriodEnd}
                    onChange={setLicensingPeriodEnd}
                    error={!!quota.touched.licensingPeriodEnd && !!quota.errors.licensingPeriodEnd}
                    readOnly={isViewOnly}
                    disabled={isViewOnly}
                    className={classes.datePickerRight}
                />
            </Grid>
        </React.Fragment>
    );
});
