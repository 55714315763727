import { Box, Container, Grid, Link, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedDate } from 'react-intl';
import { generatePath, NavLink } from 'react-router-dom';
import { entities, permissions } from '../../../../authSchemeConfig';
import { clientRoute } from '../../../../clientRoute';
import { TotObjectHeader } from '../../../../components';
import { useObjectHeaderStyles, useStore } from '../../../../hooks';
import { AuthorizationCheckQuery } from '../../../../store';
import { CampaignRequestHeaderProps } from '../../../../types';
import { RequestDeadline } from '../RequestDeadline';
import {
    CampaignRequestHeaderRegisteredDate,
    CampaignRequestHeaderState,
    CampaignRequestHeaderUIN,
} from './components';

const queries = (id: string): AuthorizationCheckQuery[] => {
    return [
        {
            entityCode: entities.CampaignRequest,
            entityId: id,
            permCode: permissions.CampaignRequest.UpdateActualAppointment,
        },
        {
            entityCode: entities.CampaignRequest,
            entityId: id,
            permCode: permissions.CampaignRequest.AddActualAppointment,
        },
    ];
};

export const CampaignRequestResolutionHeader = observer((props: CampaignRequestHeaderProps): JSX.Element => {
    const { requestModel, onModalOpen, reloadIncrement } = props;
    const classes = useObjectHeaderStyles();

    const { requestStore, intlStore: intl, authorizationStore } = useStore();
    const { number, state, id, currentTab, relatedRequestId, relatedRequestNumber, uin, deadlines, cnCategory } =
        requestModel;
    const [checkedPermissions, setCheckedPermissions] = useState<boolean[]>([]);

    const { stateDeadline, entityDeadline } = deadlines;

    const [updateActualAppointment, addActualAppointment] = checkedPermissions;

    const currentTabExecutor = currentTab?.tabExecutor;

    const objectNumberMessageId = 'campaignRequest.ResolutionNHeader';

    const tabExecutor = useMemo(() => {
        return currentTabExecutor || requestModel.executorsList || '';
    }, [currentTabExecutor, requestModel.executorsList]);

    useEffect(() => {
        authorizationStore.checkAll(queries(id)).then(setCheckedPermissions);
    }, [authorizationStore, id, currentTabExecutor, requestModel.executors]);

    const handleConfirm = (): void => {
        requestStore.setCurrentUserAsExecutor(id).then(() => {
            requestModel.updateExecutors();
            reloadIncrement();
        });
    };

    const mainTitle = intl.formatMessage(objectNumberMessageId, { number });
    const resolutionIssueDate = intl.formatMessage('campaignRequest.resolutionIssueDate');
    const deadlinesTitle = intl.formatMessage('campaignRequest.deadlines.entity');
    const relatedResolution =
        relatedRequestId && relatedRequestNumber && intl.formatMessage('campaignRequest.relatedResolution');

    return (
        <TotObjectHeader>
            <Container maxWidth="lg">
                <Grid container spacing={10} alignItems="center">
                    <Grid item xs={8}>
                        <Grid container spacing={3} direction="column">
                            <Grid item container spacing={3}>
                                <Grid item>
                                    <Typography variant="h1">{mainTitle}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item>
                                {relatedRequestId && relatedRequestNumber && (
                                    <Typography variant="h3" component="p">
                                        <Box display="flex" flexWrap="wrap" component="span">
                                            {relatedResolution}
                                            <Box pl={2} component="span">
                                                <Link
                                                    component={NavLink}
                                                    underline="none"
                                                    to={generatePath(clientRoute.request, {
                                                        id: relatedRequestId,
                                                    })}
                                                >
                                                    <Typography variant="h3" component="span">
                                                        {relatedRequestNumber}
                                                    </Typography>
                                                </Link>
                                            </Box>
                                        </Box>
                                    </Typography>
                                )}
                            </Grid>
                            {requestModel.registered && (
                                <CampaignRequestHeaderRegisteredDate requestModel={requestModel} />
                            )}
                            <CampaignRequestHeaderUIN cnCategory={cnCategory} uin={uin} />
                            <RequestDeadline deadline={entityDeadline} title={deadlinesTitle} withBlock={true} />
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid
                            container
                            spacing={3}
                            direction="column"
                            wrap="nowrap"
                            justify="center"
                            className={classes.rightBlockContainer}
                        >
                            <CampaignRequestHeaderState
                                addActualAppointment={addActualAppointment}
                                handleConfirm={handleConfirm}
                                id={id}
                                onModalOpen={onModalOpen}
                                state={state}
                                tabExecutor={tabExecutor}
                                updateActualAppointment={updateActualAppointment}
                                stateDeadline={stateDeadline}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </TotObjectHeader>
    );
});
