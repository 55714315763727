import React from 'react';
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';

export const IconButtonLarge = styled(
    React.forwardRef((props: any, ref) => {
        return <IconButton {...props} />;
    }),
)`
    width: 48px !important;
    height: 48px !important;
`;
