import { action, observable } from 'mobx';
import { CampaignsStore, TemplateTabDTO } from '../../store/CampaignsStore';
import { IntlStore } from '../../store/IntlStore';
import { NotificationStore } from '../../store';
import { CampaignPfTemplateModel } from './CampaignPfTemplateModel';
import { IdTitle } from '../IdTitle';
import fileDownload from 'js-file-download';
import { RootStore } from '../../store/RootStore';
import { reorderList } from '../../utils/reorderList';

export type CampaignPfTemplateTabType = {
    settingsId: string;
    settingsTitle: string;
    templates: CampaignPfTemplateModel[];
};

export class CampaignPfTemplateListModel {
    campaignId = '';
    regFormId = '';
    @observable store: CampaignsStore;
    @observable intl: IntlStore;
    @observable notificationStore: NotificationStore;
    @observable rootStore: RootStore;
    @observable templatesTabs: CampaignPfTemplateTabType[] = [];

    @observable regFormsCatalog: IdTitle[] = [];

    constructor(campaignId: string, regFormId: string, rootStore: RootStore) {
        this.campaignId = campaignId;
        this.regFormId = regFormId;
        this.store = rootStore.campaignsStore;
        this.intl = rootStore.intlStore;
        this.notificationStore = rootStore.notificationStore;
        this.rootStore = rootStore;
    }

    @action.bound
    loadData(): void {
        this.store.loadRegForms(this.campaignId, true).then((regForms) => {
            this.regFormsCatalog = regForms;
        });
        this.store.loadTemplates(this.regFormId).then((templates) => {
            this.templatesTabs = templates.map(this.mapDTO);
        });
    }

    @action.bound
    newTemplate(settingsId: string): CampaignPfTemplateModel {
        return new CampaignPfTemplateModel(this.campaignId, settingsId, this.regFormsCatalog, this.rootStore);
    }

    @action.bound
    getTemplate(id: string, settingsId: string): CampaignPfTemplateModel {
        const model = this.newTemplate(settingsId);
        this.store.loadTemplate(id).then(model.load);
        return model;
    }

    @action.bound
    createTemplate(template: CampaignPfTemplateModel, settingsId: string): Promise<void> {
        template.setCorrectSignatureSettings();

        return this.store.createTemplate(this.regFormId, template).then((id) => {
            template.id = id;
            const currentTab = this.templatesTabs.find((templateTab) => templateTab.settingsId === settingsId);

            if (currentTab) {
                currentTab.templates.push(template);
            }
        });
    }

    @action.bound
    saveTemplate(template: CampaignPfTemplateModel, settingsId: string): Promise<void> {
        return this.store.saveTemplate(template).then(() => {
            const currentTab = this.templatesTabs.find((templateTab) => templateTab.settingsId === settingsId);

            if (currentTab) {
                const oldIndex = currentTab.templates.findIndex((t) => t.id === template.id);
                currentTab.templates[oldIndex] = template;
            }
        });
    }

    @action.bound
    deleteTemplate(template: CampaignPfTemplateModel): Promise<void> {
        return this.store
            .deleteTemplate(template.id)
            .then(() => {
                const currentTab = this.templatesTabs.find(
                    (templateTab) => templateTab.settingsId === template.settingsId,
                );

                if (currentTab) {
                    currentTab.templates = currentTab.templates.filter((item) => item.id !== template.id);
                }
            })
            .catch((err) => {
                this.notificationStore.onError(err.response.data);
            });
    }

    @action.bound
    changeTemplatePosition(id: string, newPosition: number): void {
        this.store.changeTemplatePosition(id, newPosition);
    }

    @action.bound
    reorderTemplatesList(startIndex: number, endIndex: number, settingsId: string): void {
        const currentTab = this.templatesTabs.find((templateTab) => templateTab.settingsId === settingsId);

        if (currentTab) {
            const reordered = reorderList<CampaignPfTemplateModel>(currentTab.templates, startIndex, endIndex);
            currentTab.templates = reordered;
        }
    }

    @action.bound
    downloadTemplateFile(template: CampaignPfTemplateModel): void {
        if (template.fileForUpload) {
            const file = template.fileForUpload;
            file.arrayBuffer().then((buff) => {
                fileDownload(buff, file.name, file.type);
            });
            return;
        }
        if (template.fileDTO) {
            this.store.downloadTemplateFile(template.fileDTO);
        }
    }

    @action.bound
    mapDTO(templateTabDTO: TemplateTabDTO): CampaignPfTemplateTabType {
        const settingsId = templateTabDTO.settingsId;

        return {
            settingsId: settingsId,
            settingsTitle: templateTabDTO.settingsTitle,
            templates: templateTabDTO.pfs.map((dto) => this.newTemplate(settingsId).load(dto)),
        };
    }
}
