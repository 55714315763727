import { withTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import { CircularProgress, Toolbar } from '@material-ui/core';

export const StyledCircularProgress = withTheme(
    styled(CircularProgress)`
        width: ${({ theme }) => theme.variables.requestPrintForms.actionIconSize}px !important;
        height: ${({ theme }) => theme.variables.requestPrintForms.actionIconSize}px !important;
    `,
);

export const ErrorIcon = withTheme(
    styled.div`
        color: ${({ theme }) => theme.palette.primary.contrastText};
        background-color: ${({ theme }) => theme.palette.error.main};
        border-radius: 50%;
        width: ${({ theme }) => theme.variables.requestPrintForms.actionIconSize}px;
        height: ${({ theme }) => theme.variables.requestPrintForms.actionIconSize}px;
        font-size: ${({ theme }) => theme.variables.requestPrintForms.actionIconFontSize}px !important;
        display: block;
        padding-left: ${({ theme }) => theme.spacing(0.25)}px;
        padding-top: ${({ theme }) => theme.spacing(0.25)}px;
        box-sizing: border-box;
    `,
);

export const TableToolbar = withTheme(
    styled(Toolbar)`
        border-bottom: 1px solid ${({ theme }) => theme.variables.palette.hoverInLists};
        padding-left: ${({ theme }) => theme.spacing(6)}px !important;
        padding-right: ${({ theme }) => theme.spacing(6)}px !important;
        min-height: 128px;
    `,
);
