import { Box, Button, Container, Grid, SvgIcon, Typography } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, useHistory } from 'react-router-dom';
import { entities, permissions } from '../../../authSchemeConfig';
import { clientRoute } from '../../../clientRoute';
import { AuthorizationCheck, BreadcrumbsComponent } from '../../../components';
import { useModal, useStore } from '../../../hooks';
import { CampaignListModel } from '../../../models';
import { ReactComponent as AddCampaign } from '../../../resources/images/icons/add-icon.svg';
import { CampaignCreateDialog } from '../campaign-create';
import { CampaignCard } from './campaign-card';

export const CampaignListPage = observer((): JSX.Element => {
    const { campaignsStore, api, env, breadcrumbsStore } = useStore();
    const [campaignListModel] = useState<CampaignListModel>(new CampaignListModel(api, env.apiUrl));
    const [open, setModalIsOpen, setModalIsClosed] = useModal();
    const history = useHistory();
    const pagesCount = campaignListModel.pagesCount;

    const { breadCrumbsSettings } = breadcrumbsStore;

    const createCampaign = async (lifecycleCode: string): Promise<void> => {
        const id = await campaignsStore.createCampaign(lifecycleCode);
        history.push(generatePath(clientRoute.createCampaign, { id }));
    };

    const changePage = (event: object, page: number): void => {
        campaignListModel.setCurrentPage(page);
    };

    const renderCampaignList = (): JSX.Element[] => {
        return campaignListModel.renderItems.map((campaign) => {
            return (
                <Grid key={campaign.id} item>
                    <CampaignCard campaign={campaign} />
                </Grid>
            );
        });
    };

    return (
        <React.Fragment>
            {open && <CampaignCreateDialog createCampaign={createCampaign} onClose={setModalIsClosed} />}
            <Container maxWidth="lg">
                <Box pt={5.5} pb={5.5}>
                    <Box pb={5}>
                        <BreadcrumbsComponent breadcrumbsSettings={breadCrumbsSettings} />
                    </Box>
                    <Grid item container alignItems="center">
                        <Grid item>
                            <Box pr={6.2}>
                                <Typography variant="h3">
                                    <FormattedMessage id="campaign.titleCampaignListPage" />
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item>
                            <AuthorizationCheck entityCode={entities.System} permCode={permissions.System.AddCampaign}>
                                <Button
                                    startIcon={
                                        <SvgIcon>
                                            <AddCampaign />
                                        </SvgIcon>
                                    }
                                    size="small"
                                    variant="text"
                                    color="primary"
                                    onClick={setModalIsOpen}
                                >
                                    <FormattedMessage id="campaign.create" />
                                </Button>
                            </AuthorizationCheck>
                        </Grid>
                    </Grid>
                </Box>

                <Box pb={12}>
                    {campaignListModel.paginationData.length === 0 ? (
                        <Typography variant="subtitle1" color="primary">
                            <FormattedMessage id="campaign.noCategoriesText" />
                        </Typography>
                    ) : (
                        <Grid container alignItems="center" spacing={10}>
                            {renderCampaignList()}
                        </Grid>
                    )}
                </Box>

                {pagesCount > 1 && (
                    <Box pb={12}>
                        <Pagination
                            count={pagesCount}
                            hidePrevButton
                            hideNextButton
                            size="large"
                            color="secondary"
                            onChange={changePage}
                        />
                    </Box>
                )}
            </Container>
        </React.Fragment>
    );
});
