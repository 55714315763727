import { Container, Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { StickyWrapper, TotObjectActionsBarStyled, TotObjectTransitions } from '../../../../components';
import { useObjectRoutesContext, useStore } from '../../../../hooks';
import { RequestModel } from '../../../../models';
import { CampaignEditModeButtonsWithDialog, CampaignViewModeButtons, RequestFTSExecutionInfoButton } from './buttons';
import { RequestDownloadAllDocsButton } from './buttons/RequestDownloadAllDocsButton';

export type RequestControlPanelProps = {
    model: RequestModel;
    reloadModel: () => void;
    formIsChanged: boolean;
    errorMessage: JSX.Element;
};

export const RequestControlPanel = observer((props: RequestControlPanelProps): JSX.Element => {
    const { model, reloadModel, formIsChanged, errorMessage } = props;
    const { id, transitionLifeCycle, saveRequestForm, validateReadonlyPage } = model;

    const { requestStore } = useStore();
    const objectRoutes = useObjectRoutesContext();

    return (
        <StickyWrapper mode="bottom">
            <TotObjectActionsBarStyled>
                <Container maxWidth="lg">
                    <Grid alignItems="center" justify="space-between" container>
                        <Grid item>
                            <Grid spacing={3} alignItems="center" justify="center" container>
                                <Switch>
                                    <Route key={objectRoutes.object} path={objectRoutes.object} exact>
                                        <CampaignViewModeButtons model={model} />
                                    </Route>
                                    <Route path={[objectRoutes.objectSubTab, objectRoutes.objectTab]} exact>
                                        <CampaignViewModeButtons model={model} />
                                    </Route>
                                    <Route path={[objectRoutes.objectSubTabEdit, objectRoutes.objectTabEdit]} exact>
                                        <CampaignEditModeButtonsWithDialog
                                            saveRequestForm={saveRequestForm}
                                            formIsChanged={formIsChanged}
                                        />
                                    </Route>
                                </Switch>
                                <RequestDownloadAllDocsButton model={model} />
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Grid container direction="row" alignItems="center" spacing={4}>
                                <Switch>
                                    <Route
                                        path={[objectRoutes.object, objectRoutes.objectTab, objectRoutes.objectSubTab]}
                                        exact
                                    >
                                        <Grid item>
                                            <RequestFTSExecutionInfoButton />
                                        </Grid>
                                        <Grid item>
                                            <TotObjectTransitions
                                                objectId={id}
                                                getTransitions={requestStore.getTransitionRequest}
                                                updateObjectPage={reloadModel}
                                                lifeCycleTransition={transitionLifeCycle}
                                                validateFunction={validateReadonlyPage}
                                                errorMessage={errorMessage}
                                            />
                                        </Grid>
                                    </Route>
                                </Switch>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </TotObjectActionsBarStyled>
        </StickyWrapper>
    );
});
