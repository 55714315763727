import { makeStyles } from '@material-ui/core/styles';

export const useObjectHeaderStyles = makeStyles((theme) => {
    return {
        rightBlockContainer: {
            height: '100%',
        },
        lightText: {
            color: theme.palette.primary.light,
        },
    };
});
