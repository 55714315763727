import { FormModel } from '@platform/formiojs-react';
import { action, observable } from 'mobx';
import { RequestFormDTO } from '../types';

export class RequestFormModel {
    @observable id: string;
    @observable title = '';
    @observable formModel: FormModel = new FormModel(this.id);
    @observable tabExecutor = '';

    constructor(id: string) {
        this.id = id;
        this.formModel = new FormModel(this.id);
    }

    @action.bound
    load(dto: RequestFormDTO): void {
        this.title = dto.title;
        this.formModel.load(dto.formInfo);
        this.tabExecutor = dto.tabExecutor;
    }
}
