import React from 'react';
import { generatePath, NavLink, useLocation } from 'react-router-dom';
import { AlertLink } from '../components';

export const validationErrorLink =
    (id: string) =>
    (...chunks: string[]): JSX.Element => {
        const location = useLocation();

        return (
            <AlertLink
                component={NavLink}
                to={generatePath(`${location.pathname}/edit`, { id })}
                style={{ display: 'inline' }}
            >
                {chunks}
            </AlertLink>
        );
    };
