import { SelectData, TTableFilterProps, TTableRow, TTableSelectForFilter } from '@platform/ttable';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useStore } from '../../../hooks';

export const RegFormFilter = <RowType extends TTableRow>(props: TTableFilterProps<RowType>): JSX.Element => {
    const { column, flatHeaders } = props;
    const { filterValue, setFilter, lang, columnType, filterField } = column;
    const [selectData, setSelectData] = useState<SelectData>([]);
    const filterType = 'select';

    const { campaignsStore } = useStore();

    // Достаём значение фильтра по категории
    const campaignFilterValue = useMemo(() => flatHeaders.find((_) => _.id === 'campaign.title')?.filterValue?.value, [
        flatHeaders,
    ]);

    useEffect(() => {
        campaignsStore.regFormsByCampaignsForSelect(campaignFilterValue).then((data) => setSelectData(data));
    }, [lang, setSelectData, campaignFilterValue]);

    const handleChange = useCallback(
        (e: ChangeEvent<{}>, value: string[]) => {
            let newValue;
            if (value.length !== 0) {
                newValue = filterField
                    ? { filterType, columnType, filterField, value: value }
                    : { filterType, columnType, value: value };
            }
            setFilter(newValue);
        },
        [setFilter],
    );

    return (
        <TTableSelectForFilter
            selectData={selectData}
            onChange={handleChange}
            values={!filterValue?.value ? [] : filterValue.value}
            label={column.shortHeader}
        />
    );
};
