import { Box, Button, Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useModal, useQuotaContext } from '../../../../../hooks';
import { useQuotaPageStyles } from '../../../quota-styles';
import { QuotaDistributionUploadingDialog } from '../quota-distribution-upload-dialog';

export const QuotaTitle = observer((): JSX.Element => {
    const classes = useQuotaPageStyles();
    const { quotasModel, isCreating, isEditing, isViewOnly } = useQuotaContext();
    const { quota, isLoading } = quotasModel;
    const { title, isThereAnyCountryGroups } = quota;
    const intl = useIntl();
    const { formatMessage } = intl;

    const [quotaTitleStatic, setQuotaTitleStatic] = useState<string>('');

    const [open, setModalIsOpen, setModalIsClosed] = useModal();

    useEffect(() => {
        // Если в статичном заголовке нет названия, сеттим его из модели.
        if (!isCreating && title) {
            !quotaTitleStatic && setQuotaTitleStatic(title);
        }
        // Если загружается, значит что-то обновляется или сохраняется.
        // Обновляем заголовок.
        if (isLoading) {
            setQuotaTitleStatic(title);
        }
    }, [title, isLoading]);

    const quotasCreatingTitle = formatMessage({ id: 'quotas.creating' });
    const quotasEditingTitle = formatMessage({ id: 'quotas.editing' });
    const quotaFallbackTitle = formatMessage({ id: 'quotas.quota' });

    return (
        <Grid item className={classes.headingTitleWrapper}>
            <Typography variant="h1">
                {quotaTitleStatic
                    ? quotaTitleStatic
                    : isCreating
                    ? quotasCreatingTitle
                    : isEditing
                    ? quotasEditingTitle
                    : quotaFallbackTitle}
            </Typography>
            {isViewOnly && isThereAnyCountryGroups && (
                <React.Fragment>
                    <Box className={classes.headingUploadDistributionLink}>
                        <Button variant="contained" onClick={setModalIsOpen}>
                            <Typography noWrap>Загрузить распределение по участникам ВТД</Typography>
                        </Button>
                    </Box>
                    <QuotaDistributionUploadingDialog open={open} onCancel={setModalIsClosed} />
                </React.Fragment>
            )}
        </Grid>
    );
});
