import { action, observable } from 'mobx';
import { apiConfigs } from '../../apiConfigs';
import { ApiStore, CampaignInfoForCardDTO } from '../../store';
import { DateUtils } from '../../utils';
import { PaginationModel } from '../pagination-model';

export type CampaignCardDTO = {
    id: string;
    title: string;
    avatarUrl?: string;
    to?: Date;
    from?: Date;
    requestCount: number;
};

export class CampaignListModel extends PaginationModel<CampaignCardDTO> {
    @observable readonly apiUrl?: string;
    @observable private api: ApiStore;

    constructor(api: ApiStore, apiUrl?: string) {
        super();
        this.apiUrl = apiUrl;
        this.api = api;
        this.setPageSize(8);
        this.getPaginationData();
    }

    @action.bound
    getPaginationData(): void {
        this.api
            .client(apiConfigs.campaigns)
            .then((r) => r.data)
            .then((data) => data.map(this.mapDTO))
            .then((data) => (this.paginationData = data));
    }

    @action.bound
    mapDTO(c: CampaignInfoForCardDTO): CampaignCardDTO {
        const to = new Date(c.to || '');
        const from = new Date(c.from || '');
        return {
            id: c.id,
            title: c.title,
            requestCount: c.requestCount,
            avatarUrl: c.avatarId && this.apiUrl && `${this.apiUrl}${apiConfigs.campaignFileUrl(c.avatarId)}`,
            to: c.to && DateUtils.isValidDate(to) ? to : undefined,
            from: c.from && DateUtils.isValidDate(from) ? from : undefined,
        };
    }
}
