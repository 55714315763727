import { SignatureInfoDTO, SignatureStatus } from '@platform/crypto-ui';
import { action, observable } from 'mobx';
import { apiConfigs } from '../apiConfigs';
import { ApiStore } from './ApiStore';
import { RootStore } from './RootStore';

export class FormioSignatureStore {
    @observable private rootStore: RootStore;
    @observable private api: ApiStore;
    @observable onSuccessForFormioInstance?: () => void;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.api = rootStore.api;
    }

    @action.bound
    setOnSuccessForFormioInstance(onSuccess: () => void): void {
        this.onSuccessForFormioInstance = onSuccess;
    }

    @action.bound
    getFileByte(url: string): () => Promise<ArrayBuffer> {
        return (): Promise<ArrayBuffer> => {
            return this.api.client(apiConfigs.getFormioFileByte(url)).then((r) => r.data);
        };
    }

    @action.bound
    uploadSignature(url: string): (signature: string) => Promise<SignatureStatus> {
        return (signature: string): Promise<SignatureStatus> => {
            return this.api.client(apiConfigs.uploadFormioSignature(url, signature)).then((r) => r.data);
        };
    }

    @action.bound
    uploadSignatureFile(url: string): (signatureFile: FormData) => Promise<SignatureStatus> {
        return (signatureFile: FormData): Promise<SignatureStatus> => {
            return this.api.client(apiConfigs.uploadFormioSignatureFile(url, signatureFile)).then((r) => r.data);
        };
    }

    @action.bound
    getSignatureInfo(url: string): () => Promise<SignatureInfoDTO[]> {
        return (): Promise<SignatureInfoDTO[]> => {
            return this.api.client(apiConfigs.getFormioSignatureInfo(url)).then((r) => r.data);
        };
    }

    @action.bound
    downloadSignature(url: string): (isWithFile: boolean) => Promise<Blob> {
        return (isWithFile: boolean): Promise<Blob> => {
            return this.api.client(apiConfigs.downloadFormioSignature(url, isWithFile)).then((r) => r.data);
        };
    }

    @action.bound
    deleteSignature(url: string): () => Promise<SignatureStatus> {
        return (): Promise<SignatureStatus> => {
            return this.api.client(apiConfigs.deleteFormioSignature(url)).then((r) => r.data);
        };
    }
}
