import { action, observable } from 'mobx';
import { apiConfigs } from '../apiConfigs';
import { fileContentType } from '../constants';
import { ConsoleResult } from '../types';
import { getFileName } from '../utils';
import { ApiStore } from './ApiStore';
import { RootStore } from './RootStore';

export class ConsoleStore {
    @observable private rootStore: RootStore;
    @observable private api: ApiStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.api = rootStore.api;
    }

    @action.bound
    runScript(script: string): Promise<ConsoleResult> {
        return this.api.client(apiConfigs.runScript(script)).then((r) => {
            const isFile = r.headers['content-type'] === fileContentType;
            const fileName = isFile ? getFileName(r.headers['content-disposition']) : '';

            return {
                blob: r.data,
                isFile,
                fileName,
            };
        });
    }
}
