import { action, observable } from 'mobx';
import { ContentErrorDTO, ErrorEntityType, ResponseErrorDTO } from '../types';

export class ContentErrorsModel {
    @observable errors: ContentErrorDTO[] = [];

    @action.bound
    getGeneralMessage(): string {
        let message = '';

        this.errors.forEach((error) => {
            const currentMessage = error.message;
            message = !!message ? `${message} \n${currentMessage}` : currentMessage;
        });

        return message;
    }

    @action.bound
    getMessages(): string[] {
        return this.errors.map((error) => error.message);
    }

    @action.bound
    isWithError(entityType: ErrorEntityType, entityId?: string): boolean {
        return this.errors.some((error) => {
            if (entityId) {
                return error.entityType === entityType && error.entityId === entityId;
            }
            return error.entityType === entityType;
        });
    }

    @action.bound
    removeError(entityType: ErrorEntityType, entityId?: string): void {
        const errors = this.errors.filter((error) => {
            if (entityId) {
                return error.entityType !== entityType && error.entityId !== entityId;
            }
            return error.entityType !== entityType;
        });

        this.setErrors(errors);
    }

    @action.bound
    addError(error: ResponseErrorDTO): void {
        this.errors.push(error);
    }

    @action.bound
    setErrors(errors: ResponseErrorDTO[]): void {
        this.errors = errors;
    }
}
