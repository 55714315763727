import { DatePicker, DatePickerProps } from '@material-ui/pickers';
import React from 'react';
import { pickerProps } from '../../../constants';
import { convertDateToISO } from '../../../utils';
import { dateTimePickersInputProps } from './QuotaUtils';

export type QuotasDateTimePickerProps = Omit<DatePickerProps, 'onChange'> & {
    onChangeYear?: (date: Date) => void;
    onChange?: (date: string) => void;
};

export const QuotasDateTimePicker = (props: QuotasDateTimePickerProps): JSX.Element => {
    const { onChange, onChangeYear } = props;

    const handleChange = (date: Date | null): void => {
        if (!date) {
            return;
        }
        if (onChangeYear) {
            onChangeYear(date);
        } else if (onChange) {
            onChange(convertDateToISO(date));
        }
    };

    return (
        <DatePicker
            {...pickerProps}
            {...props}
            size="medium"
            disableFuture={false}
            InputProps={dateTimePickersInputProps}
            required
            onChange={handleChange}
        />
    );
};
