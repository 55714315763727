import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Portal } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAntiDoubleClick, useStore } from '../hooks';
import { IdTitle } from '../models';
import { autocompleteProps, textFieldProps } from '../pages';
import { Select } from './selects';

export type LicenseSignatoryDialogProps = {
    isOpen: boolean;
    close: () => void;
    currentSignatory?: string;
    saveSignatory: (signatory: string) => Promise<void>;
};

const SelectTextFieldProps = {
    ...textFieldProps,
    required: true,
    size: undefined,
};

export const LicenseSignatoryDialog = observer((props: LicenseSignatoryDialogProps): JSX.Element => {
    const { isOpen, close, currentSignatory, saveSignatory } = props;

    const { requestStore } = useStore();

    const [signatories, setSignatories] = useState<IdTitle[]>([]);
    const [signatory, setSignatory] = useState<string>(currentSignatory || '');

    useEffect(() => {
        requestStore.listSignatories().then(setSignatories);
    }, []);

    const handleConfirm = async (): Promise<void> => {
        if (signatory) {
            return saveSignatory(signatory).finally(close);
        }
    };

    const handleChangeSignatory = (e: ChangeEvent<{}>, value: string | null): void => {
        setSignatory(value || '');
    };

    const [isSending, startIcon, confirmHandler] = useAntiDoubleClick(handleConfirm);

    const intl = useIntl();
    const selectLabel = intl.formatMessage({ id: 'campaignRequest.signatory' });

    return (
        <Portal>
            <Dialog fullWidth={true} maxWidth="xs" open={isOpen} keepMounted={true}>
                <DialogTitle>
                    <FormattedMessage id="campaignRequest.assignLicenseSignatory" />
                </DialogTitle>
                <DialogContent>
                    {signatories.length ? (
                        <Select
                            multiple={false}
                            values={signatory}
                            label={selectLabel}
                            selectData={signatories}
                            onChange={handleChangeSignatory}
                            textFieldProps={SelectTextFieldProps}
                            autoCompleteProps={autocompleteProps}
                        />
                    ) : null}
                </DialogContent>
                <DialogActions>
                    <Grid container direction="column" spacing={3}>
                        <Grid item>
                            <Button
                                size="large"
                                color="primary"
                                variant="contained"
                                fullWidth
                                onClick={confirmHandler}
                                disabled={isSending || !signatory}
                                startIcon={startIcon}
                            >
                                <FormattedMessage id="common.save" />
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={close} size="large" color="primary" fullWidth>
                                <FormattedMessage id="common.cancel" />
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </Portal>
    );
});
