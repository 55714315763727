import { makeStyles } from '@material-ui/core';

export const useQuotaCGTableStyles = makeStyles((theme) => {
    const rowStyles = {
        '& > *': {
            color: `${theme.palette.text.primary} !important`,
            width: '228px',
            boxSizing: 'border-box',
            '&:first-child': {
                width: '220px',
            },
            '&:nth-child(2)': {
                width: '448px',
            },
            '&:last-child': {
                padding: '0px',
                paddingRight: '5px !important',
                width: '70px',
                height: '78px',
                display: 'flex',
                alignItems: 'center',
            },
        },
    };
    //1 spacing unit = 4
    return {
        tableBodyViewOnly: {
            '& *': {
                '& [class*=Mui-disabled]': {
                    opacity: 1,
                    '& input': {
                        color: theme.palette.text.primary,
                    },
                },
                '& [class*=MuiAutocomplete-endAdornment], & [class*=MuiOutlinedInput-adornedEnd]': {
                    '& svg': {
                        display: 'none',
                    },
                },
            },
            '& > *': {
                backgroundColor: `${theme.palette.background.paper} !important`,
            },
        },
        tableBody: {
            '& > *': {
                backgroundColor: `${theme.palette.background.paper} !important`,
            },
        },
        tableHead: {
            ...rowStyles,
            '& > *': {
                height: '35px',
                color: `${theme.palette.text.primary} !important`,
                verticalAlign: 'middle',
                fontSize: '13px',
            },
        },
        tableRow: rowStyles,
        tableTitle: {
            fontWeight: 700,
            fontSize: '16px',
            lineHeight: '20px',
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(4),
        },
        sumCell: {
            verticalAlign: 'middle',
        },
        sumText: {
            fontWeight: 700,
            fontSize: '15px',
            lineHeight: '19px',
            color: theme.palette.secondary.contrastText,
        },
        tableCellWithControls: {
            display: 'flex',
        },
        saveButton: {
            marginLeft: '10px',
        },
    };
});
