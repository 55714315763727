import { Box, Container, createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TTableRow } from '@platform/ttable';
import { observer } from 'mobx-react';
import React, { useCallback, useMemo, useState } from 'react';
import { BreadcrumbsComponent } from '../../../components';
import { useModal, useStore } from '../../../hooks';
import { CampaignRequestRow, IdTitle } from '../../../models';
import { CampaignRequestRowNew } from '../../../types';
import { ExecutorEditDialog } from '../campaign-request-page';
import { CampaignRequestActionsBtns } from './campaign-request-action-buttons';
import { CampaignRequestTable } from './CampaignRequestTable';

export type RequestRow = TTableRow & {
    number: string;
    campaign: IdTitle;
};

const useCampaignRequestLisPageNewStyles = makeStyles(() =>
    createStyles({
        tableWrapper: {
            width: '100%',
        },
    }),
);

export const CampaignRequestListPageNew = observer((): JSX.Element => {
    const { requestStore, breadcrumbsStore } = useStore();
    const [requestRowModel, setRequestRowModel] = useState<CampaignRequestRow>();

    const { breadCrumbsSettings } = breadcrumbsStore;

    const [editExecutorDialogOpen, setEditExecutorDialogOpen, setEditExecutorDialogClosed] = useModal();
    const [editExecutorDialogTitle, setEditExecutorDialogTitle] = useState<string>();
    const [isConfirm, setIsConfirm] = useState<boolean>(false);

    const classes = useCampaignRequestLisPageNewStyles();

    const handleEditExecutorDialogOpen = useCallback(
        (campaignRow: CampaignRequestRow, title?: string): void => {
            setEditExecutorDialogOpen();
            setRequestRowModel(campaignRow);
            title && setEditExecutorDialogTitle(title);
        },
        [setEditExecutorDialogOpen, setRequestRowModel, setEditExecutorDialogTitle],
    );

    const setRowActions = useMemo(
        () =>
            (row: CampaignRequestRowNew, reloadData: () => void): JSX.Element => {
                // Здесь данные с нового реестра мапятся под старые (при переходе на вовые реестры стоит убрать)
                const correctRowData = requestStore.mapDtoToRow(row);

                return (
                    <CampaignRequestActionsBtns
                        campaignRow={correctRowData}
                        reloadData={reloadData}
                        handleEditExecutorDialogOpen={handleEditExecutorDialogOpen}
                        isConfirm={isConfirm}
                        setIsConfirm={setIsConfirm}
                    />
                );
            },
        [isConfirm],
    );

    return (
        <React.Fragment>
            <ExecutorEditDialog
                title={editExecutorDialogTitle}
                onClose={setEditExecutorDialogClosed}
                open={editExecutorDialogOpen}
                requestRowModel={requestRowModel}
                setIsConfirm={setIsConfirm}
            />

            <Container maxWidth="lg">
                <Box pt={5.5} pb={15}>
                    <Box pb={5}>
                        <BreadcrumbsComponent breadcrumbsSettings={breadCrumbsSettings} />
                    </Box>
                    <Grid container direction="column">
                        <Grid item className={classes.tableWrapper}>
                            <CampaignRequestTable setRowActions={setRowActions} />
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </React.Fragment>
    );
});
