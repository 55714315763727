import { ListItem, ListItemIcon, Typography } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import React from 'react';
import { useQuotaDistributionUploadingStyles } from '../../pages';

export type DialogContentListItemProps = {
    text: string;
};

export const DialogContentListItem = (props: DialogContentListItemProps): JSX.Element => {
    const { text } = props;
    const classes = useQuotaDistributionUploadingStyles();
    return (
        <ListItem dense disableGutters>
            <ListItemIcon>
                <FiberManualRecordIcon className={classes.icon} />
            </ListItemIcon>
            <Typography>{text}</Typography>
        </ListItem>
    );
};
