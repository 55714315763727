import { Grid } from '@material-ui/core';
import React from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { clientRoute } from '../../../../clientRoute';
import { useQuotaContext } from '../../../../hooks';
import { RouteParamsDefault } from '../../../../types';
import { QuotasCancelButtonWithDialog } from './QuotasCancelButtonWithDialog';
import { QuotasSaveButton } from './QuotasSaveButton';

export const QuotasEditModeButtonsWithDialog = (): JSX.Element => {
    const history = useHistory();
    const { id } = useParams<RouteParamsDefault>();
    const { isCreating, quotasModel } = useQuotaContext();
    const { quota } = quotasModel;

    const goBack = (): void => {
        if (isCreating) {
            history.push(clientRoute.quotas);
        } else {
            quotasModel
                // Перезагружаем квоту
                .getQuota(quota.id)
                // Редиректим на редактирование
                .then(() => history.push(generatePath(clientRoute.quotasQuota, { id })));
        }
    };

    return (
        <React.Fragment>
            <Grid spacing={3} alignItems="center" justify="center" container>
                <Grid item>
                    <QuotasSaveButton />
                </Grid>
                <Grid item>
                    <QuotasCancelButtonWithDialog onConfirm={goBack} />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};
