import { Box, createStyles, Grid, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormView } from '@platform/formiojs-react';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useStore } from '../../../../hooks';
import { AuthorityDocModel } from '../../../../models';

export const useAuthorityDocFormStyles = makeStyles((theme: Theme) => {
    const sidebarWidth = theme?.variables.sidebar.widthXl || 0;
    const gridItemPadding = theme ? theme.spacing(5) * 2 : 0;
    const formWidth = `calc(100% - ${sidebarWidth + gridItemPadding}px)`;

    return createStyles({
        form: {
            width: formWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
    });
});

export type AuthorityDocFormProps = {
    model: AuthorityDocModel;
};

export const AuthorityDocForm = observer((props: AuthorityDocFormProps): JSX.Element => {
    const { model } = props;
    const classes = useAuthorityDocFormStyles();

    const { intlStore } = useStore();
    const { locale } = intlStore;

    const { formModel, onFormReady, isStillNoForm } = model;

    return (
        <Grid item className={classes.form}>
            <Box pb={9}>
                <Box width="100%">
                    {formModel.loaded && isStillNoForm ? (
                        <FormattedMessage id="common.noData" />
                    ) : (
                        <FormView locale={locale} form={formModel} onFormReady={onFormReady} />
                    )}
                </Box>
            </Box>
        </Grid>
    );
});
