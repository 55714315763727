import { Box, Card, CardActions, CardContent, Grid, IconButton, SvgIcon, Typography } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { observer } from 'mobx-react';
import React from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { FormattedMessage } from 'react-intl';
import { generatePath, NavLink, useParams } from 'react-router-dom';
import { clientRoute } from '../../../../../clientRoute';
import { FileDownload } from '../../../../../components';
import { CampaignPfTemplateListModel, CampaignPfTemplateModel } from '../../../../../models';
import { ReactComponent as MoveIcon } from '../../../../../resources/images/icons/move.svg';
import { CampaignSettingsDeleteButton } from '../../CampaignSettingsDeleteButton';

export type CampaignPfTemplateCardProps = {
    model: CampaignPfTemplateModel;
    listModel: CampaignPfTemplateListModel;
    provided?: DraggableProvidedDragHandleProps;
};

type RouteParams = {
    id: string;
    rfId: string;
};

export const CampaignPfTemplateCard = observer((props: CampaignPfTemplateCardProps): JSX.Element => {
    const { model, listModel, provided } = props;
    const { id: campaignId, rfId } = useParams<RouteParams>();
    const { id, title, description, templateFileName: fileName, required } = model;

    const handleDownloadTemplateFile = (): void => {
        listModel.downloadTemplateFile(model);
    };

    const handleDeleteTemplate = (): Promise<void> => {
        return listModel.deleteTemplate(model);
    };

    return (
        <Card variant="outlined">
            <Grid container direction="row" justify="space-between" alignItems="center">
                <Grid item xs={10}>
                    <CardContent>
                        <Box mb={2} fontWeight="fontWeightBold">
                            <FileDownload
                                fileName={fileName}
                                title={title}
                                downloadFile={handleDownloadTemplateFile}
                                required={required}
                            />
                        </Box>
                        {description && (
                            <Box mb={1}>
                                <Typography component="p" variant="subtitle2">
                                    <FormattedMessage id="campaign.description" />
                                    <Typography component="span">{description}</Typography>
                                </Typography>
                            </Box>
                        )}
                    </CardContent>
                </Grid>
                <Grid item xs={2} container justify="center">
                    <CardActions>
                        <React.Fragment>
                            <IconButton {...provided}>
                                <SvgIcon viewBox="0 0 20 20">
                                    <MoveIcon />
                                </SvgIcon>
                            </IconButton>
                            <IconButton
                                component={NavLink}
                                to={generatePath(clientRoute.campaignRegFormTemplatesEdit, {
                                    id: campaignId,
                                    rfId,
                                    templateId: id,
                                    settingsId: model.settingsId,
                                })}
                                size="small"
                            >
                                <Edit />
                            </IconButton>
                        </React.Fragment>
                        <CampaignSettingsDeleteButton
                            id="confirm-delete-pf-template"
                            title={<FormattedMessage id="common.confirmDeletion" />}
                            message={
                                <FormattedMessage id="campaign.confirmDeletionTemplateInfoText" values={{ title }} />
                            }
                            onConfirm={handleDeleteTemplate}
                        />
                    </CardActions>
                </Grid>
            </Grid>
        </Card>
    );
});
