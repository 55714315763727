import { Location } from 'history';
import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { useModal } from '../hooks';
import { ConfirmationDialog } from './ConfirmationDialog';

export type RouteLeavingGuardProps = {
    when?: boolean;
    navigate: (path: string) => void;
    shouldBlockNavigation: (location: Location) => boolean;
    title: string | JSX.Element;
    message: string | JSX.Element;
};

// Компонент для подтверждения изменения роута
export const RouteLeavingGuard = (props: RouteLeavingGuardProps): JSX.Element => {
    const { when, navigate, shouldBlockNavigation, title, message } = props;

    const [isModalOpen, setModalIsOpen, setModalIsClosed] = useModal();
    const [lastLocation, setLastLocation] = useState<Location | null>(null);
    const [confirmedNavigation, setConfirmedNavigation] = useState<boolean>(false);

    const handleBlockedNavigation = (nextLocation: Location): boolean => {
        if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
            setModalIsOpen();
            setLastLocation(nextLocation);
            return false;
        }
        return true;
    };

    const handleConfirmNavigationClick = (): Promise<void> => {
        setModalIsOpen();
        setConfirmedNavigation(true);
        return Promise.resolve();
    };

    useEffect(() => {
        if (confirmedNavigation && lastLocation) {
            navigate(lastLocation.pathname);
        }
    }, [confirmedNavigation, lastLocation, navigate]);

    return (
        <React.Fragment>
            <Prompt when={when} message={handleBlockedNavigation} />
            <ConfirmationDialog
                id="prompt-dialog"
                open={isModalOpen}
                onConfirm={handleConfirmNavigationClick}
                onCancel={setModalIsClosed}
                title={title}
                message={message}
                keepMounted
            />
        </React.Fragment>
    );
};
