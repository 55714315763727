import { action, computed, observable } from 'mobx';
import { FileFormats } from '../types';

export type FileParams = {
    filename: string;
    size: number;
    mimeType: string;
};

export type FileDTOWithId = FileParams & {
    id: string;
};

export type FileDTOWithFileId = FileParams & {
    fileId: string;
};

export class FileModel {
    @observable file?: File;

    @observable acceptedFiles?: FileFormats[];

    constructor(acceptedFiles?: FileFormats[]) {
        this.acceptedFiles = acceptedFiles;
    }

    @computed
    get filename(): string | undefined {
        return this.file?.name;
    }

    @computed
    get isFileLoaded(): boolean {
        return !!this.file;
    }

    @computed
    get formData(): FormData | undefined {
        if (!this.file) {
            return undefined;
        }
        const formData = new FormData();
        formData.append('file', this.file);
        return formData;
    }

    @action.bound
    onChangeFile([file]: File[]): void {
        if (file) {
            this.file = file;
        }
    }

    @action.bound
    onDeleteFile(): void {
        this.file = undefined;
    }
}
