import { useState } from 'react';
import {
    ConfirmationButtons,
    settingsConfirmDialog,
    SettingsConfirmDialogType,
    SettingsPropsType,
} from './confirmationButtons';

export type UseSettingsConfirmDialog = [SettingsConfirmDialogType, (actionButton: ConfirmationButtons) => void];

export const useSettingsConfirmDialog = (props: SettingsPropsType): UseSettingsConfirmDialog => {
    const settings = settingsConfirmDialog(props);
    const [settingsConfirm, setSettingsConfirm] = useState<SettingsConfirmDialogType>(
        settings[ConfirmationButtons.delete],
    );

    const setSettings = (actionButton: ConfirmationButtons): void => {
        setSettingsConfirm(settings[actionButton]);
    };

    return [settingsConfirm, setSettings];
};
