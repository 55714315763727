const campaignRequest: Record<string, Record<string, string> | string> = {
    registryTitle: 'Заявления',
    withoutNumber: 'Без номера',
    registryColumns: {
        number: 'Номер заявления',
        regForm: 'Форма заявления',
        direction: 'Направление',
        institute: 'Институт',
        department: 'Кафедра',
        campaign: 'Услуга',
        executor: 'Ответственный эксперт',
        author: 'Автор',
        state: 'Состояние',
        created: 'Дата создания',
        sent: 'Дата подачи',
    },
    modalTitle: 'Изменение статуса',
    modalInfoText: 'Статус будет изменен на "{toState}"',
    registryPagingInfo:
        'Всего {count, number} заявлен{count, plural, one {ие} few {ия} other {ий}}. ' +
        'Отображены c {from, number} по {to, number}',
    readonlyRequestValidationMsg:
        'Форма заявления заполнена некорректно. Для исправления ошибок перейдите к <a>редактированию</a>.',
    invalidDateMessage: 'Недопустимый формат даты',
    maxDateMessage: 'Дата не может быть позже текущей даты',
    resultsOption: 'Результаты не найдены',
    printFormsTable: {
        loading: 'Пожалуйста подождите, идет подготовка к генерации печатных форм документов',
        title: 'Печатные формы документов',
        fileColumnName: 'Файл',
        authorColumnName: 'ФИО',
        dateColumnName: 'Дата',
        generateAction: 'Сгенерировать',
        updateAction: 'Обновить',
        cannotGenerateError: 'Невозможно сгенерировать печатную форму: форма заявления содержит ошибки валидации',
        cannotGenerateErrorWithLink:
            'Невозможно сгенерировать печатную форму: форма заявления содержит ошибки валидации. Для исправления ошибок перейдите к <a>редактированию</a>.',
        generationError: 'При генерации печатной формы произошла ошибка',
        downloadAllButton: 'Скачать все',
        taskStatus: 'Статус генерации',
    },
    taskStatus: {
        generating: 'Идет генерация',
        completed: 'Сгенерирован',
        failed: 'Ошибка генерации',
    },
    regFormCard: {
        description: 'Описание',
        registrationStartDate: 'Начало регистрации',
        registrationEndDate: 'Окончание регистрации',
        afterDeadline: 'Прием после дедлайна',
        instruction: 'Инструкция',
    },
    regFormsPageTitle: 'Выбор формы участия заявления',
    RequestN: 'Заявление № {number}',
    LicenseN: 'Лицензия № {number}',
    ResolutionNHeader: 'Разрешение № {number}',
    ResolutionN: 'Разрешение № ',
    relatedRequest: 'Заявление на лицензию № ',
    relatedLicense: 'Лицензия № ',
    relatedResolution: 'Заявление на разрешение  № ',
    creationDate: 'Дата создания:',
    licenseIssueDate: 'Дата выдачи лицензии:',
    resolutionIssueDate: 'Дата выдачи разрешения:',
    registered: 'Дата регистрации:',
    licenses: 'Лицензии',
    confirmDeletionInfoText: 'Вы действительно хотите удалить заявление № {number}?',
    createRequest: 'Создать заявление',
    newRequest: 'Новое заявление',
    dialog: {
        leaveMessage: 'Вы действительно хотите покинуть текущее заявление без сохранения изменений?',
        executorsMessage: 'Вы будете назначены ответственным экспертом по заявлению',
        editExecutorTitle: 'Назначить ответственного эксперта',
        changeExecutorTitle: 'Изменить ответственного эксперта',
        closeMessage: 'Вы действительно хотите закрыть режим редактирования без сохранения изменений?',
        tooltipMessage: ' Для сохранения внесенных изменений нажмите кнопку "Сохранить" внизу страницы',
        title: 'Подтверждение закрытия режима редактирования',
        confirm: 'Подтвердить',
        cancel: 'Отмена',
        executorsTitle: 'Подтверждение выбора ответственного эксперта',
    },
    registryPagingInfoIfNull: 'Всего 0 заявлений. Отображено 0 заявлений',
    deleteRequest: 'Удалить',
    allFiles: 'Все документы по {title}',
    takeToWork: 'Взять в работу',
    openEditMode: 'Редактировать',
    saveChanges: 'Сохранить',
    chooseExecutor: 'Назначить ответственного',
    actions: {
        appointExecutor: 'Назначить ответственного',
        changeExecutor: 'Изменить ответственного',
        requestToWork: 'Взять заявление в работу',
    },
    thirdChar: 'Введите третий знак лицензии для дополнительной классификации товаров',
    assignLicenseSignatory: 'Назначить подписанта лицензии',
    signatory: 'Подписант',
    responsibleSignatory: 'Ответственный подписант',
    uin: 'УИН:',
    requestLog: 'Журнал',
    licenseDuplicate: 'Дубликат лицензии № {number}',
    downloadLicenseDuplicate: 'Оформить дубликат',
    signingLicense: 'Подписание лицензии',
    licenseGenerationInProgress: 'Пожалуйста, подождите, идет генерация файла лицензии',
    expandWorkArea: 'Увеличить рабочую область',
    reduceWorkArea: 'Уменьшить рабочую область',
    deadlines: {
        entity: 'Общий срок завершения',
        state: 'Контрольный срок',
        error: 'Ошибка. Не удалось рассчитать срок',
        notRelevant: 'Не актуален в этом статусе',
        notRelevantTooltip:
            'Время нахождения в этом статусе не учитывается в общей длительности работ. Срок завершения будет пересчитан при переходе в другой статус',
    },
    checks: 'Проверки',
    checksTableHeaderTitles: {
        number: '№',
        dateAndTime: 'Дата и время',
        checkName: 'Название проверки',
        result: 'Результат',
    },
    requestFTSExecutionInfo: 'Запросить информацию по исполнению',
    downloadAllDocs: 'Выемка приложенных документов',
    downloadAllDocsArchiveName: 'Выемка приложенных документов по Лицензии № {number}',
    protectedPaperArchive: 'Архив защищённой бумаги'
};

export default campaignRequest;
