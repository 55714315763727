import { makeStyles } from '@material-ui/core';

export const useQuotaVTDTableStyles = makeStyles((theme) => {
    const rowStyles = {
        '& > *': {
            color: `${theme.palette.text.primary} !important`,
            overflow: 'hidden',
            '&:first-child': {
                width: '161px !important',
            },
            '&:nth-child(2)': {
                width: '280px',
            },
            '&:nth-last-child(2)': {
                width: '228px',
            },
            '&:last-child': {
                padding: '0px',
                paddingRight: '5px !important',
                width: '70px',
                height: '78px',
                display: 'flex',
                alignItems: 'center',
            },
            minWidth: '200px'
        },
    };

    //1 spacing unit = 4
    return {
        tableBodyViewOnly: {
            '& *': {
                '& [class*=Mui-disabled]': {
                    opacity: 1,
                    '& input': {
                        color: theme.palette.text.primary,
                    },
                },
                '& [class*=MuiAutocomplete-endAdornment], & [class*=MuiOutlinedInput-adornedEnd]': {
                    '& svg': {
                        display: 'none',
                    },
                },
            },
        },
        table: {
            display: 'block',
            width: 'max-content',
            overflowX: 'auto',
        },
        tableBody: {
            '& > *': {
                backgroundColor: `${theme.palette.background.paper} !important`,
            },
        },
        tableRowCountries: {
            '& > *': {
                '&:nth-child(2)': {
                    width: '50%',
                },
                '&:last-child': {
                    padding: '0px',
                    paddingRight: '5px !important',
                    width: '70px',
                    height: '78px',
                    display: 'flex',
                    alignItems: 'center',
                },
                width: '25%',
            },
        },
        tableRow: rowStyles,
        tableHead: {
            ...rowStyles,
            '& > *': {
                height: '35px',
                color: `${theme.palette.text.primary} !important`,
                verticalAlign: 'middle',
                fontSize: '13px',
            },
        },
        sumCell: {
            verticalAlign: 'middle',
        },
        sumText: {
            fontWeight: 700,
            fontSize: '15px',
            lineHeight: '19px',
            color: theme.palette.secondary.contrastText,
        },
        tableCell: {
            display: 'flex',
        },
    };
});
