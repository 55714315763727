import { ColumnData, RowsData, TTableQueryData, TTableRow } from '@platform/ttable';
import fileDownload from 'js-file-download';
import { action, observable } from 'mobx';
import { apiConfigs } from '../apiConfigs';
import { QuotaCreateDTO, QuotaDTO, QuotaParticipantDTO, QuotaTableRowDTO } from '../types';
import { ApiStore } from './ApiStore';
import { RootStore } from './RootStore';

export class QuotasStore {
    @observable protected rootStore: RootStore;
    @observable protected api: ApiStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.api = rootStore.api;
    }

    @action.bound
    updateQuota(quotaData: QuotaCreateDTO, quotaId: string): Promise<void> {
        return this.api.userActionClient(apiConfigs.updateQuota(quotaData, quotaId)).then((r) => r.data);
    }

    @action.bound
    deleteQuota(quotaId: string): Promise<void> {
        return this.api.userActionClient(apiConfigs.deleteQuota(quotaId)).then((r) => r.data);
    }

    @action.bound
    getQuota(quotaId: string): Promise<QuotaDTO> {
        return this.api.userActionClient(apiConfigs.getQuota(quotaId)).then((r) => r.data);
    }

    @action.bound
    postQuota(quotaData: QuotaCreateDTO): Promise<QuotaDTO> {
        return this.api.userActionClient(apiConfigs.postQuota(quotaData)).then((r) => r.data);
    }

    @action.bound
    getQuotasColumns(): Promise<ColumnData<QuotaTableRowDTO>[]> {
        return this.api.client(apiConfigs.getQuotasColumns()).then((r) => r.data);
    }

    @action.bound
    getQuotasList(registryCode: string, queryData: TTableQueryData<TTableRow>): Promise<RowsData<QuotaTableRowDTO>> {
        return this.api.client(apiConfigs.getQuotasList(registryCode, queryData)).then((r) => r.data);
    }

    @action.bound
    uploadQuotaParticipantsDistributionFile(quotaId: string, formData: FormData): Promise<QuotaParticipantDTO[]> {
        return this.api
            .userActionClient(apiConfigs.uploadQuotaParticipantsDistributionFile(quotaId, formData))
            .then((r) => r.data);
    }

    @action.bound
    async exportQuotasXlsx<RowType extends TTableRow>(tableQueryData: TTableQueryData<RowType>): Promise<void> {
        try {
            const { data } = await this.api.client(apiConfigs.exportQuotasXlsx(tableQueryData));
            const filename = this.rootStore.intlStore.formatMessage('homePage.headerLinks.quotas');
            await fileDownload(data, `${filename}.xlsx`);
        } catch (error) {
            console.error(error);
        }
    }

    @action.bound
    async exportQuotasDoc<RowType extends TTableRow>(tableQueryData: TTableQueryData<RowType>): Promise<void> {
        try {
            const { data } = await this.api.client(apiConfigs.exportQuotasDoc(tableQueryData));
            const filename = this.rootStore.intlStore.formatMessage('homePage.headerLinks.quotas');
            await fileDownload(data, `${filename}.doc`);
        } catch (error) {
            console.error(error);
        }
    }
}
