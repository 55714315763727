import { PersonModel, PersonDTO } from './PersonModel';
import { action, computed, observable } from 'mobx';
import { IdTitle } from '../IdTitle';

export interface UserNameDTO {
    userId: string;
    name: string;
    person: PersonDTO;
}

export class UserNameModel {
    @observable userId = '';
    @observable name = '';

    constructor() {
        return this;
    }

    @action.bound
    load(dto: UserNameDTO) {
        this.userId = dto.userId;
        const person: PersonModel = new PersonModel().load(dto.person);
        this.name = person.lastNameWithInitials;
        return this;
    }

    @computed
    get asIdTitle(): IdTitle {
        return {
            id: this.userId,
            title: this.name,
        };
    }
}
