import { Box, Grid, IconButton, Link, Tooltip, Typography } from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { observer } from 'mobx-react';
import React from 'react';
import { generatePath, NavLink } from 'react-router-dom';
import { entities, permissions } from '../../../../../authSchemeConfig';
import { clientRoute } from '../../../../../clientRoute';
import { AuthorizationCheck, StateBox } from '../../../../../components';
import { useStore } from '../../../../../hooks';
import { ReactComponent as EditIcon } from '../../../../../resources/images/icons/edit.svg';
import { DeadlineDTO } from '../../../../../types';
import { RequestDeadline } from '../../RequestDeadline';
import { CampaignRequestHeaderAppointmentButtons } from './CampaignRequestHeaderAppointmentButtons';

export type CampaignRequestHeaderStateProps = {
    state: string;
    id: string;
    stateDeadline?: DeadlineDTO;
    tabExecutor: string;
    addActualAppointment: boolean;
    updateActualAppointment: boolean;
    onModalOpen: (translationId?: string) => void;
    handleConfirm: () => void;
};

export const CampaignRequestHeaderState = observer((props: CampaignRequestHeaderStateProps): JSX.Element => {
    const {
        state,
        id,
        stateDeadline,
        tabExecutor,
        addActualAppointment,
        updateActualAppointment,
        onModalOpen,
        handleConfirm,
    } = props;
    const { intlStore: intl } = useStore();

    const commonState = intl.formatMessage('common.State');
    const requestLog = intl.formatMessage('campaignRequest.requestLog');
    const campaignDeadline = intl.formatMessage('campaignRequest.deadlines.state');
    const responsibleExecutor = intl.formatMessage('common.responsibleExecutor');
    const openEditMode = intl.formatMessage('campaignRequest.openEditMode');

    const openModal = (): void => {
        onModalOpen('campaignRequest.dialog.changeExecutorTitle');
    };

    return (
        <React.Fragment>
            <Grid item>
                <Box>
                    <Typography variant="body2">{commonState}</Typography>
                </Box>
            </Grid>
            <Grid item>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item>
                        <StateBox>{state}</StateBox>
                    </Grid>
                    <Grid item>
                        <AuthorizationCheck
                            entityCode={entities.System}
                            entityId={id}
                            permCode={permissions.System.ViewLogsRegistry}
                        >
                            <Tooltip title={requestLog}>
                                <Link
                                    component={NavLink}
                                    underline="none"
                                    to={generatePath(clientRoute.requestLog, { id })}
                                >
                                    <AssignmentIcon />
                                </Link>
                            </Tooltip>
                        </AuthorizationCheck>
                    </Grid>
                </Grid>
            </Grid>
            <RequestDeadline deadline={stateDeadline} title={campaignDeadline} />
            <Grid item>
                {tabExecutor && (
                    <Grid container direction="column">
                        <Grid item>
                            <Typography variant="body2" noWrap>
                                {responsibleExecutor}
                            </Typography>
                        </Grid>
                        <Grid item container wrap="nowrap" alignItems="center" spacing={1}>
                            <Grid item>
                                <Typography variant="body2">{tabExecutor}</Typography>
                            </Grid>
                            <CampaignRequestHeaderAppointmentButtons
                                addActualAppointment={addActualAppointment}
                                updateActualAppointment={updateActualAppointment}
                                handleConfirm={handleConfirm}
                                onModalOpen={onModalOpen}
                            />
                            {!addActualAppointment && updateActualAppointment && (
                                <Grid item>
                                    <Tooltip title={openEditMode}>
                                        <IconButton onClick={openModal}>
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </React.Fragment>
    );
});
