import { CircularProgress, Grid, Link, Paper, Table, TableCell, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import downloadFile from 'js-file-download';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { FormattedDate, FormattedTime } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useStore } from '../../../../hooks';
import { RequestCheckResult, RequestChecks, RequestFormParams } from '../../../../types';
import { RequestChecksTabAccordion } from './index';

export const useCampaignRequestChecksStyles = makeStyles((theme) => {
    const { spacing } = theme;
    return {
        paper: {
            paddingBottom: `${spacing(4)}px`,
            borderRadius: '10px',
        },
        loader: {
            paddingTop: `${spacing(4)}px`,
        },
        exportAsFileLinkWrapper: {
            padding: `${spacing(6)}px 0`,
        },
        exportAsFileLink: {
            paddingRight: `${spacing(3)}px`,
        },
        numberCell: {
            '&:first-child': {
                paddingLeft: `${theme.spacing(3)}px`,
                width: '6%',
            },
        },
        dateAndTimeCell: {
            width: '14%',
        },
        checkNameCell: {
            width: '40%',
        },
        resultNameCell: {
            width: '40%',
        },
        tableHead: {
            '& .MuiTableCell-root': {
                backgroundColor: theme.variables.palette.mainMiddleLight,
            },
        },
        commonTableCell: {
            padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
            verticalAlign: 'middle',
            '&:not(:last-child)': {
                paddingRight: `${theme.spacing(11)}px`,
            },
            fontSize: '14px',
            color: 'black',
        },
        commonTableRow: {
            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
            '&:last-child': {
                borderBottom: 'none',
            },
        },
        checkPassed: {
            '& .MuiTableCell-root': {
                backgroundColor: theme.variables.palette.checks.passed,
            },
        },
        checkFailed: {
            '& .MuiTableCell-root': {
                backgroundColor: theme.variables.palette.checks.failed,
            },
        },
        sectionTitleCell: {
            '&:first-child, &:last-child': {
                padding: `0 ${theme.spacing(3)}px`,
                paddingTop: `${theme.spacing(6.625)}px`,
                paddingBottom: `${theme.spacing(3.625)}px`,
            },
        },
        sectionTitle: {
            fontWeight: 700,
            fontSize: '16px',
            lineHeight: '19px',
            color: theme.variables.palette.textDark,
        },
    };
});

export const RequestChecksTabContent = observer((): JSX.Element => {
    const classes = useCampaignRequestChecksStyles();

    const { intlStore: intl, requestStore } = useStore();
    const [requestChecksData, setRequestChecksData] = useState<RequestChecks>([]);
    const [isRequestChecksDataLoading, setIsRequestChecksDataLoading] = useState<boolean>(true);
    const { id, tabId } = useParams<RequestFormParams>();

    useEffect(() => {
        requestStore
            .getRequestChecksData(tabId)
            .then(setRequestChecksData)
            .finally(() => {
                setIsRequestChecksDataLoading(false);
            });
    }, [id]);

    const exportAsFile = (): void => {
        requestStore.downloadRequestChecksAsFile(tabId);
    };

    const mapRequestCheckGroupToAccordion = (
        requestChecksGroup: RequestChecks[number],
        groupIndex: number,
    ): JSX.Element => {
        const { groupTitle, rows } = requestChecksGroup;

        return (
            <RequestChecksTabAccordion accordionTitle={groupTitle} key={groupIndex}>
                <Table>
                    {rows.map((rowData) => {
                        return (
                            <TableRow
                                key={rowData.rowIndex}
                                className={classNames(
                                    classes.commonTableRow,
                                    rowData.result === RequestCheckResult.passed
                                        ? classes.checkPassed
                                        : classes.checkFailed,
                                )}
                            >
                                <TableCell className={classNames(classes.commonTableCell, classes.numberCell)}>
                                    {rowData.rowIndex}
                                </TableCell>
                                <TableCell className={classNames(classes.commonTableCell, classes.dateAndTimeCell)}>
                                    <FormattedDate value={rowData.dateTime} />{' '}
                                    <FormattedTime value={rowData.dateTime} />
                                </TableCell>
                                <TableCell className={classNames(classes.commonTableCell, classes.checkNameCell)}>
                                    {rowData.name}
                                </TableCell>
                                <TableCell className={classNames(classes.commonTableCell, classes.resultNameCell)}>
                                    {rowData.description}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </Table>
            </RequestChecksTabAccordion>
        );
    };

    return (
        <Paper className={classes.paper}>
            {isRequestChecksDataLoading ? (
                <Grid container item className={classes.loader} justify="center" alignItems="center">
                    <CircularProgress />
                </Grid>
            ) : (
                <Grid container direction="column" wrap="nowrap">
                    <Grid item container justify="flex-end" alignItems="center">
                        <Grid item className={classes.exportAsFileLinkWrapper}>
                            <Link color="primary" onClick={exportAsFile} className={classes.exportAsFileLink}>
                                {intl.formatMessage('common.downloadAsFile')}
                            </Link>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Table>
                            <TableRow className={classes.tableHead}>
                                <TableCell className={classNames(classes.commonTableCell, classes.numberCell)}>
                                    {intl.formatMessage('campaignRequest.checksTableHeaderTitles.number')}
                                </TableCell>
                                <TableCell className={classNames(classes.commonTableCell, classes.dateAndTimeCell)}>
                                    {intl.formatMessage('campaignRequest.checksTableHeaderTitles.dateAndTime')}
                                </TableCell>
                                <TableCell className={classNames(classes.commonTableCell, classes.checkNameCell)}>
                                    {intl.formatMessage('campaignRequest.checksTableHeaderTitles.checkName')}
                                </TableCell>
                                <TableCell className={classNames(classes.commonTableCell, classes.resultNameCell)}>
                                    {intl.formatMessage('campaignRequest.checksTableHeaderTitles.result')}
                                </TableCell>
                            </TableRow>
                        </Table>
                        {requestChecksData.map(mapRequestCheckGroupToAccordion)}
                    </Grid>
                </Grid>
            )}
        </Paper>
    );
});
