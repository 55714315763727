const users: Record<string, string | Record<string, string | Record<string, string>>> = {
    listTitle: 'Пользователи',
    fields: {
        login: 'Логин',
        status: 'Статус',
        name: 'ФИО',
        roles: 'Роли',
        lang: 'Англоязычный пользователь',
    },
    actions: {
        block: 'Заблокировать',
        unblock: 'Активировать',
    },
    status: {
        blocked: 'Заблокирован',
        active: 'Активный',
        inactive: 'Неактивный',
    },
    loginAsUser: 'Авторизоваться под пользователем',
    editUser: 'Редактировать пользователя',
    registryPagingInfo:
        'Всего {count, number} пользовател{count, plural, one {ь} few {я} other {ей}}. ' +
        'Отображены c {from, number} по {to, number}',
    confirmBlock: 'Подтвердите блокирование',
    confirmBlockInfoText: 'Вы действительно хотите заблокировать {login}?',
    deleteUser: 'Удаление пользователя',
    deleteUserConfirmText: 'Пользователь будет удален. Подтвердить действие?',
    registryPagingInfoIfNull: 'Всего 0 пользователей. Отображено 0 пользователей',
    notAllowed: 'Невозможно произвести данное действие над своей учетной записью',
    profile: 'Профиль пользователя',
};

export default users;
