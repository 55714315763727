import React, { ReactNode, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Sticky from 'react-sticky-el';
import styled from 'styled-components';
import { RenderChildren } from '../tabs';

export type StickyWrapperProps = {
    mode: string;
    children: RenderChildren | ReactNode;
    stickyClassName?: string;
    topOffset?: number;
};

const StyledSticky = styled(Sticky)`
    background-color: transparent;
`;

const triggerScrollTimeoutOnLoad = 1000;
const triggerScrollTimeout = 200;

const stickyStyleDefault = { zIndex: 1 };

/*
 * Компонент обертка для Sticky
 * Добавляет фейковый вызов события 'scroll' с таймаутом; см componentDidMount
 * Нужен в случае mode='bottom', когда панель отрисовалась а остальной контент еще нет и по мере отрисовки увеличивает высоту страницы.
 */
export const StickyWrapper = (props: StickyWrapperProps): JSX.Element => {
    const root = document.getElementById('root') as HTMLElement;
    const location = useLocation();
    const { children, mode, stickyClassName, topOffset } = props;

    const onRootClick = (): void => {
        timeoutScrollHandler();
    };

    useEffect(() => {
        root.addEventListener('click', onRootClick);

        return () => root.removeEventListener('click', onRootClick);
    }, [root]);

    const timeoutScrollHandler = useCallback(() => {
        root.dispatchEvent(new Event('scroll'));
    }, [root]);

    const timeoutScrollEvent = useCallback(
        (timeout: number) => setTimeout(timeoutScrollHandler, timeout),
        [timeoutScrollHandler],
    );

    useEffect(() => {
        timeoutScrollEvent(triggerScrollTimeoutOnLoad);
        return () => clearTimeout(timeoutScrollEvent(triggerScrollTimeoutOnLoad));
    }, [timeoutScrollEvent]);

    useEffect(() => {
        timeoutScrollHandler();
        timeoutScrollEvent(triggerScrollTimeout);
        return () => clearTimeout(timeoutScrollEvent(triggerScrollTimeout));
    }, [location, timeoutScrollHandler, timeoutScrollEvent]);

    return (
        <StyledSticky
            stickyStyle={stickyStyleDefault}
            scrollElement="#root"
            mode={mode}
            stickyClassName={stickyClassName}
            topOffset={topOffset}
        >
            {children}
        </StyledSticky>
    );
};
