import React, { PropsWithChildren } from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { Link, LinkProps } from '@material-ui/core';

export type AppRouterLinkProps = PropsWithChildren<LinkProps & RouterLinkProps>;

export const AppRouterLink = (props: AppRouterLinkProps): JSX.Element => {
    const { children, ...restProps } = props;
    return (
        <Link component={RouterLink} {...restProps}>
            {children}
        </Link>
    );
};
