import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core';

export const useFormStyles = makeStyles(() =>
    createStyles({
        formWithNoOffset: {
            margin: '-20px -23px 0',
        },
    }),
);
