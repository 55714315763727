const validation: Record<string, string> = {
    required: 'Required field',
    isEmail: 'Enter correct email',
    minLength: 'Minimal length {length, number} {length, plural, one {symbol} other {symbols}}',
    maxLength: 'Maximum length {length, number} {length, plural, one {symbol} other {symbols}}',
    dropzoneError: 'File does not match type "doc" or "docx"',
    isNumber: 'Enter correct number',
    bannedSymbols: 'Symbols cannot be used in the field: « {symbols} »',
    withoutSignatureMethod: 'Choose at least one signing method',
    invalidForm: 'Errors were made while filling out the form'
};

export default validation;
