import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { AuthStatus } from '../types';
import { useStore } from './useStore';
import queryString from 'query-string';

export type UseLoginRedirect = [boolean, string];

export const useLoginRedirect = (): UseLoginRedirect => {
    const { api } = useStore();

    // проверка авторизации (для авторизованного должен быть редирект в систему)
    useEffect(() => {
        api.authVerify();
    }, [api]);

    const location = useLocation();
    const queries = queryString.parse(location.search);
    const backUrl = queries.backUrl as string;
    let isRedirect = api.authStatus === AuthStatus.ok;

    if (isRedirect && backUrl?.startsWith('http')) {
        window.location.replace(backUrl);
        isRedirect = false; // выставляем в false, чтобы не отрендерился Redirect и не попытался сгенерить ссылку внутрь модуля (падает стек, который не влияет ни на что, кроме задетого чувства прекрасного)
    }

    return [isRedirect, backUrl];
};
