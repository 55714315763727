const common: Record<string, string> = {
    edit: 'Edit',
    save: 'Save',
    delete: 'Delete',
    close: 'Close',
    error: 'Error',
    errorText: 'Something went wrong',
    cancel: 'Cancel',
    discardChanges: 'Discard changes',
    confirm: 'Confirm',
    noData: 'No data',
    from: 'from',
    to: 'to',
    until: 'until',
    State: 'State',
    result: 'Result',
    copy: 'Copy',
    clear: 'Clear',
    rowsPerPage: 'Rows per page:',
    pagingInfo: 'Displayed from {from, number} to {to, number}. Total {count, number} elements.',
    resetFilters: 'Reset',
    filters: 'Filters',
    all: 'All',
    actions: 'Actions',
    export: 'Export',
    downloadAsFile: 'Download as file',
    confirmDeletion: 'Confirm deletion',
    dropzoneText: 'Drag and drop a file here or click',
    pageNotFound: 'Page not found',
    actionNotAllowed: 'Action not allowed',
    downloadFile: 'Download {fileName}',
    defaultErrorMessage: 'Something went wrong',
    yes: 'Yes',
    no: 'No',
    lifeCycleConfirmTitle: 'Status change',
    lifeCycleConfirmText: 'Status will be changed to "{toState}"',
    tabExecutor: 'Responsible expert: {name}',
    responsibleExecutor: 'Responsible expert:',
    create: 'Create',
};

export default common;
