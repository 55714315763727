import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';
import { useStore } from '../../hooks';
import { StartPageHeaderLink } from './StartPageHeaderLink';
import { StartPageLoginLink } from './StartPageLoginLink';

export type StartAuthHeaderLinkProps = {
    anchorRef: React.RefObject<HTMLDivElement>;
    handleToggle: () => void;
};

export const StartAuthHeaderLink = observer((props: StartAuthHeaderLinkProps): JSX.Element => {
    const { anchorRef, handleToggle } = props;
    const { api, personStore } = useStore();

    useEffect(() => {
        personStore.getInfo().then(api.authVerify);
    }, [personStore, api]);

    switch (api.authStatus) {
        case 'ok':
            return (
                <StartPageHeaderLink
                    component={RouterLink}
                    to={clientRoute.campaigns}
                    color="secondary"
                    underline="none"
                >
                    {personStore.person.lastNameWithInitials}
                </StartPageHeaderLink>
            );
        default:
            return <StartPageLoginLink handleToggle={handleToggle} anchorRef={anchorRef} />;
    }
});
