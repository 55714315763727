const breadcrumbs: Record<string, string | Record<string, string>> = {
    campaigns: 'Services',
    campaign: 'Service',
    campaignSettings: 'Service settings',
    requests: 'Requests',
    requestsOld: 'Requests',
    licenses: 'Licenses',
    mainPage: 'Main page',
    regFormSelect: 'Selection of the application form',
    users: 'Users',
    authorityDocs: 'Authority documents'
};

export default breadcrumbs;
