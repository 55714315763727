import { IconButton, Link, SvgIcon, TableCell, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Edit } from '@material-ui/icons';
import React from 'react';
import { DraggableChildrenFn, DraggableProvided } from 'react-beautiful-dnd';
import { IntlShape } from 'react-intl';
import { generatePath, NavLink } from 'react-router-dom';
import { clientRoute } from '../../../../../../clientRoute';
import { ReactComponent as MoveIcon } from '../../../../../../resources/images/icons/move.svg';
import { RequestFormSettingListDTO } from '../../../../../../store';
import { Services } from '../../../../../../types';
import { CampaignSettingsDeleteButton } from '../../../CampaignSettingsDeleteButton';

export const useDraggableRequestFormTabsSettingsTableRowStyle = makeStyles(() => {
    return {
        settingTitleCell: {
            width: '30%',
        },
        formTitleCell: {
            width: '58%',
        },
        actionButtons: {
            width: '96px',
            minWidth: '96px',
        },
    };
});

export const renderDraggableRequestFormTabsSettingsTableRow = (
    setting: RequestFormSettingListDTO,
    id: string,
    rfId: string,
    deleteSetting: (settingId: string) => () => Promise<void>,
    intl: IntlShape,
    services: Services,
): DraggableChildrenFn => {
    const { id: settingId, title: settingTitle } = setting;

    return function DraggableRequestFormTabsSettingsTableRow(provided: DraggableProvided): JSX.Element {
        const classes = useDraggableRequestFormTabsSettingsTableRowStyle();

        return (
            <TableRow hover ref={provided.innerRef} {...provided.draggableProps}>
                <TableCell className={classes.settingTitleCell}>{settingTitle}</TableCell>
                <TableCell className={classes.formTitleCell}>
                    {setting.formInfo ? (
                        <Link target="_blank" href={services.formVersionLink(setting.formInfo.id)}>
                            {setting.formInfo.title || setting.formInfo.id}
                        </Link>
                    ) : (
                        '-'
                    )}
                </TableCell>
                <TableCell className={classes.actionButtons} align="right">
                    <IconButton {...provided.dragHandleProps}>
                        <SvgIcon viewBox="0 0 20 20">
                            <MoveIcon />
                        </SvgIcon>
                    </IconButton>
                    <IconButton
                        component={NavLink}
                        to={generatePath(clientRoute.campaignRequestFormTabSetting, {
                            id,
                            rfId,
                            settingId,
                        })}
                        size="small"
                    >
                        <Edit />
                    </IconButton>
                    <CampaignSettingsDeleteButton
                        id="confirm-delete"
                        title={intl.formatMessage({ id: 'common.confirmDeletion' })}
                        message={intl.formatMessage(
                            { id: 'campaign.confirmDeletionRequestFormSettingInfoText' },
                            { title: settingTitle },
                        )}
                        onConfirm={deleteSetting(settingId)}
                    />
                </TableCell>
            </TableRow>
        );
    };
};
