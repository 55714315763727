import { createContext } from 'react';
import { ObjectRoutesContextType } from '../types';

export const ObjectRoutesContextDefaultValue: ObjectRoutesContextType = {
    objects: '',
    object: '',
    objectTab: '',
    objectSubTab: '',
    objectEdit: '',
    objectTabEdit: '',
    objectSubTabEdit: '',
    objectApprovalSheets: '',
};

export const ObjectRoutesContext = createContext<ObjectRoutesContextType>(ObjectRoutesContextDefaultValue);
