import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CampaignRequestPfListModel, CampaignRequestPfModel } from '../models';
import { RequestFormParams } from '../types';
import { useObjectRoutesContext } from './useObjectRoutesContext';
import { useStore } from './useStore';

export type useFetchCampaignRequestPFsReturns = {
    isPFsFetching: boolean;
    printForms: CampaignRequestPfModel[];
    isFilesLoading: boolean;
    isFilesUpdated: boolean;
    pfListModel: CampaignRequestPfListModel;
    fetchPrintForms: () => void;
    fetchLicensePrintForm: () => void;
};

export const useFetchCampaignRequestPFs = (): useFetchCampaignRequestPFsReturns => {
    const rootStore = useStore();
    const { requestStore, env } = rootStore;

    const { tabId, subTabId, id } = useParams<RequestFormParams>();
    const objectRoutes = useObjectRoutesContext();

    const { getRequestModel } = requestStore;
    const requestModel = useMemo(() => getRequestModel(id, objectRoutes), [id, objectRoutes]);

    const { formApi } = requestModel;
    const formId = subTabId || tabId;

    const pfListModel = useMemo(
        () => new CampaignRequestPfListModel(rootStore, formId, formApi),
        [rootStore, formId, formApi],
    );
    const [isPFsFetching, setPFsFetching] = useState<boolean>(true);
    const [isFilesUpdated, setIsFilesUpdated] = useState<boolean>(false);
    const timerId = useRef<ReturnType<typeof setTimeout>>(0);
    const timerId2 = useRef<ReturnType<typeof setTimeout>>(0);

    const { printForms, isFilesLoading } = pfListModel;
    const { pfDelay } = env;

    const refetchPFs = (): void => {
        fetchPrintForms();
        setIsFilesUpdated(true);
    };

    const refetchLicensePF = (): void => {
        fetchLicensePrintForm();
        setIsFilesUpdated(true);
    };

    const fetchPrintForms = useCallback((): void => {
        requestStore
            // Получаем пф с бека.
            .loadPrintForms(formId)
            // Загружаем их в модель.
            .then(pfListModel.load)
            // При ошибке сбрасываем таймаут
            .catch(() => clearTimeout(timerId2.current))
            // Выключаем загрузку
            .finally(() => setPFsFetching(false));
        /** После ожидания в 30с. обновляем заново печатные формы со свежими данными */
        timerId2.current = setTimeout(refetchPFs, pfDelay);
    }, [requestStore, formId]);

    const fetchLicensePrintForm = useCallback((): void => {
        requestStore
            // Получаем пф с бека.
            .loadLicensePrintForm(requestModel.id)
            // Загружаем их в модель.
            .then(pfListModel.load)
            // При ошибке сбрасываем таймаут
            .catch(() => clearTimeout(timerId.current))
            // Выключаем загрузку
            .finally(() => setPFsFetching(false));
        /** После ожидания в 30с. обновляем заново печатные формы со свежими данными */
        timerId.current = setTimeout(refetchLicensePF, pfDelay);
    }, [requestStore, formId]);

    useEffect(() => {
        return () => {
            clearTimeout(timerId.current);
            clearTimeout(timerId2.current);
        };
    }, []);

    return {
        fetchPrintForms,
        fetchLicensePrintForm,
        isPFsFetching,
        printForms,
        isFilesLoading,
        isFilesUpdated,
        pfListModel,
    };
};
