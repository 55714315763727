import { Grid, TextField } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { ChangeEvent, ChangeEventHandler } from 'react';
import { useQuotaContext } from '../../../../../../hooks';

export const QuotaTitleInput = observer((): JSX.Element => {
    const { quotasModel, isViewOnly } = useQuotaContext();
    const { quota } = quotasModel;
    const { title, setTitle } = quota;

    const titleInputHandler: ChangeEventHandler<HTMLInputElement> = (e: ChangeEvent<HTMLInputElement>): void => {
        setTitle(e.target.value);
    };

    return (
        <Grid item>
            <TextField
                variant="outlined"
                label="Наименование квоты"
                fullWidth
                value={title}
                onChange={titleInputHandler}
                error={!!quota.touched.title && !!quota.errors.title}
                disabled={isViewOnly}
                helperText={quota.errors.title}
                required
            />
        </Grid>
    );
});
