import { Link, MenuItem, MenuItemProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { AtLeastOne } from '../types';

export type ActionMenuItemTitle = AtLeastOne<{
    messageId: string;
    messageValue: string;
}>;

export type ActionMenuItemProps = ActionMenuItemTitle & {
    path?: string;
    isSubTabLink?: boolean;
} & Omit<MenuItemProps, 'button'>;

const useActionMenuItemStyles = makeStyles((theme) => {
    return {
        link: { '&, &.active, &:hover': { color: 'inherit' } },
        subTabLink: {
            padding: `${theme.spacing(1.5)}px ${theme.spacing(4)}px`,
            width: '100%',
        },
    };
});

export const ActionMenuItem = (props: ActionMenuItemProps): JSX.Element => {
    const { path, messageId, messageValue, isSubTabLink = false, ...rest } = props;
    const classes = useActionMenuItemStyles();

    const titleValue = messageId ? <FormattedMessage id={messageId} /> : messageValue;

    return (
        <MenuItem dense button {...rest}>
            {path ? (
                <Link
                    component={NavLink}
                    className={classNames(classes.link, { [classes.subTabLink]: isSubTabLink })}
                    underline="none"
                    color="inherit"
                    to={path}
                >
                    {titleValue}
                </Link>
            ) : (
                titleValue
            )}
        </MenuItem>
    );
};
