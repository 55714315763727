import { makeStyles } from '@material-ui/core';

export const useQuotaDistributionUploadingStyles = makeStyles(() => {
    return {
        textBold: {
            fontWeight: 'bold',
        },
        listContainer: {
            maxHeight: '166px',
            overflowY: 'auto',
        },
        icon: {
            width: '10px',
            height: '10px',
        },
    };
});
