import { Box, createStyles, Grid, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { PageContentContainer } from '../../../components';
import { useStore } from '../../../hooks';
import { AuthorityDocModel } from '../../../models';
import { RouteParamsDefault } from '../../../types';
import { AuthorityDocForm, AuthorityDocHeader, AuthorityDocRequests } from './components';

export const useAuthorityDocPageStyles = makeStyles((theme: Theme) => {
    return createStyles({
        wrapper: {
            flexGrow: 1,
            backgroundColor: theme?.variables.palette.mainContrastDarker,
            overflow: 'hidden',
        },
        container: {
            margin: '0 auto',
            transition: theme.transitions.create('all', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            maxWidth: theme.breakpoints.width('lg'),
        },
    });
});

export const AuthorityDocPage = observer((): JSX.Element => {
    const { id } = useParams<RouteParamsDefault>();
    const classes = useAuthorityDocPageStyles();

    const rootStore = useStore();
    const model = useMemo<AuthorityDocModel>(
        (): AuthorityDocModel => new AuthorityDocModel(id, rootStore),
        [id, rootStore],
    );

    const { load } = model;

    useEffect((): void => {
        load();
    }, [id]);

    return (
        <PageContentContainer container direction="column" wrap="nowrap">
            <Grid item>
                <AuthorityDocHeader model={model} />
            </Grid>
            <Grid item className={classes.wrapper}>
                <Box pt={5} pl={5} pr={5} className={classes.container}>
                    <Grid container spacing={7} wrap="nowrap">
                        <AuthorityDocForm model={model} />
                        <AuthorityDocRequests model={model} />
                    </Grid>
                </Box>
            </Grid>
        </PageContentContainer>
    );
});
