import { Button, Grid, Link } from '@material-ui/core';
import { Preset, TTable, TTableProps, TTableQueryData, TTableRow } from '@platform/ttable';
import { observer } from 'mobx-react';
import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, NavLink } from 'react-router-dom';
import { CellProps } from 'react-table';
import { entities, permissions } from '../../../../authSchemeConfig';
import { clientRoute } from '../../../../clientRoute';
import { AuthorizationCheck, ExportRegistryDialog } from '../../../../components';
import { useStore } from '../../../../hooks';
import { QuotaTableRowDTO } from '../../../../types';
import { QuotaListActionsButtons } from '../../quota-list-action-buttons';

const tableVisibileSettings: TTableProps<QuotaTableRowDTO>['visibleSettings'] = {
    toolbar: {
        isWithAddCustomColumns: false,
    },
    column: {
        filters: {
            dateRange: {
                isWithLabel: false,
            },
        },
    },
};

export const QuotaList = observer((): JSX.Element => {
    const { presetStore, intlStore, catalogStore, userStore, quotasStore } = useStore();

    const saveListPresets = <RowType extends TTableRow>(settings: Preset<RowType>[]): Promise<void> =>
        presetStore.saveListPresets(settings);

    const [openUploadDialog, setOpenUploadDialog] = useState<boolean>(false);
    const [uploadTableQuery, setUploadTableQuery] = useState<TTableQueryData<QuotaTableRowDTO>>();

    const handleUpload = async (tableQuery: TTableQueryData<TTableRow>): Promise<void> => {
        setOpenUploadDialog(true);
        setUploadTableQuery(tableQuery);
    };

    const handleCloseUploadDialog = (): void => {
        setOpenUploadDialog(false);
        setUploadTableQuery(undefined);
    };

    const [isConfirm, setIsConfirm] = useState<boolean>(false);

    const setRowActions = useMemo(
        () =>
            (row: QuotaTableRowDTO, reloadData: () => void): JSX.Element => {
                return (
                    <QuotaListActionsButtons
                        quotaRow={row}
                        reloadData={reloadData}
                        isConfirm={isConfirm}
                        setIsConfirm={setIsConfirm}
                    />
                );
            },
        [isConfirm],
    );

    const cellFormatters = {
        'customData.title': function title(p: React.PropsWithChildren<CellProps<QuotaTableRowDTO, any>>): JSX.Element {
            return (
                <Link
                    component={NavLink}
                    underline="always"
                    to={generatePath(clientRoute.quotasQuota, { id: p.row.original.id })}
                >
                    {p.row.original.customData.title || <FormattedMessage id="quotas.noName" />}
                </Link>
            );
        },
    };

    return (
        <React.Fragment>
            <Grid container style={{ padding: '16px', flexDirection: 'row-reverse' }}>
                <Button
                    component={NavLink}
                    to={clientRoute.quotasCreate}
                    color="primary"
                    size="small"
                    variant="contained"
                >
                    <FormattedMessage id="quotas.create" />
                </Button>
            </Grid>
            <AuthorizationCheck entityCode={entities.System} permCode={permissions.System.ViewQuotas}>
                {(allowed: boolean): JSX.Element => (
                    <TTable<QuotaTableRowDTO>
                        registryCode="quotas"
                        fetchData={quotasStore.getQuotasList}
                        fetchColumns={quotasStore.getQuotasColumns}
                        presetsSetting={presetStore}
                        fetchCatalog={catalogStore.getCatalog}
                        fetchSelectDataByUrl={catalogStore.getSelectDataByUrl}
                        upload={handleUpload}
                        lang={intlStore.locale}
                        cellFormatters={cellFormatters}
                        saveListPresets={saveListPresets}
                        fetchUserRoleList={userStore.userRoleList}
                        isAdmin={allowed}
                        rowActions={setRowActions}
                        visibleSettings={tableVisibileSettings}
                    />
                )}
            </AuthorizationCheck>
            <ExportRegistryDialog
                openUploadDialog={openUploadDialog}
                uploadTableQuery={uploadTableQuery}
                handleCloseExportDialog={handleCloseUploadDialog}
                exportXlsx={quotasStore.exportQuotasXlsx}
                exportDoc={quotasStore.exportQuotasDoc}
            />
        </React.Fragment>
    );
});
