import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';
import { Authentication } from '../../components';
import { CountryGroupsListPage } from './country-groups-list-page';
import { CountryGroupsPage } from './country-groups-page';

export const CountryGroupsPages = (): JSX.Element => {
    return (
        <Authentication>
            <Switch>
                <Route
                    path={[clientRoute.countryGroupCreate, clientRoute.countryGroupEdit, clientRoute.countryGroup]}
                    exact={true}
                >
                    <CountryGroupsPage />
                </Route>
                <Route path={clientRoute.countryGroups} exact={true}>
                    <CountryGroupsListPage />
                </Route>
            </Switch>
        </Authentication>
    );
};
