import { Autocomplete } from '@material-ui/lab';
import { toJS } from 'mobx';
import React from 'react';

/**
 * Кастомизация автокомплита для mobx.
 * Нужен для правильной работы с observable значениями.
 * */
export const AutocompleteMobx: typeof Autocomplete = (props) => {
    return <Autocomplete {...props} value={toJS(props.value)} />;
};
