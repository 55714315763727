import { SignatureInfoDTO, SignatureStatus } from '@platform/crypto-ui';
import { FullSubmission } from '@platform/formiojs-react';
import { ColumnData, RowsData, TTableQueryData, TTableRow } from '@platform/ttable';
import { AxiosPromise } from 'axios';
import { History } from 'history';
import downloadFile from 'js-file-download';
import fileDownload from 'js-file-download';
import { action, computed, observable } from 'mobx';
import { apiConfigs } from '../apiConfigs';
import { clientRoute } from '../clientRoute';
import {
    CampaignRequestPfDTO,
    CampaignRequestRow,
    FileDTOWithId,
    IdTitle,
    RequestFormModel,
    RequestModel,
    TableQueryData,
    TransitionsDTO,
    UserNameModel,
} from '../models';
import {
    CampaignRequestRowNew,
    DeadlinesDTO,
    EmployeeDTO,
    MainPageStatsDTO,
    MainPageWidgetData,
    ObjectRoutesContextType,
    RequestChecks,
    RequestDTO,
    RequestFormDTO,
    RequestLogType,
} from '../types';
import { handleAxiosErrorByResponseStatus } from '../utils';
import { ApiStore } from './ApiStore';
import { NotificationStore } from './NotificationStore';
import { RootStore } from './RootStore';

export type FlkValidateDTO = {
    success: boolean;
    errors?: {
        fields: Record<string, any>;
        form: string[];
    };
};

export class RequestStore {
    @observable protected rootStore: RootStore;
    @observable protected api: ApiStore;
    @observable protected notificationStore: NotificationStore;
    @observable showValidation = false;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.api = rootStore.api;
        this.notificationStore = rootStore.notificationStore;
    }

    @computed
    get history(): History {
        return this.rootStore.history;
    }

    @action.bound
    getRequestModel(requestId: string, objectRoutes: ObjectRoutesContextType): RequestModel {
        const model = new RequestModel(requestId, this.rootStore, objectRoutes);
        this.getRequestDTO(requestId).then(model.load);
        return observable(model);
    }

    @action.bound
    getRequestDTO(requestId: string): Promise<RequestDTO> {
        return this.api
            .client(apiConfigs.getRequest(requestId))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                    404: () => this.history.replace(clientRoute.notFound),
                }),
            );
    }

    @action.bound
    getDeadlinesDTO(requestId: string): Promise<DeadlinesDTO> {
        return this.api.client(apiConfigs.getDeadlines(requestId)).then((r) => r.data);
    }

    @action.bound
    createRequest(regFormId: string): Promise<string> {
        return this.api.client(apiConfigs.createRequest(regFormId)).then((r) => r.data);
    }

    @action.bound
    getMainPageStats(): Promise<MainPageStatsDTO> {
        return this.api.client(apiConfigs.getMainPageStats()).then((r) => r.data);
    }

    @action.bound
    loadRequestForm(formId: string): Promise<RequestFormDTO> {
        return this.api
            .client(apiConfigs.loadRequestForm(formId))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                    404: () => this.history.replace(clientRoute.notFound),
                }),
            );
    }

    @action.bound
    requestFormCheckEditable(requestFormId: string): Promise<void> {
        return this.api.client(apiConfigs.requestFormCheckEditable(requestFormId)).then((r) => r.data);
    }

    @action.bound
    getRequestFormModel(formId: string): any {
        const model = new RequestFormModel(formId);
        return this.loadRequestForm(formId)
            .then(model.load)
            .then(() => {
                return observable(model);
            })
            .catch((error) => {
                this.notificationStore.onError(error.response.data);
                return Promise.reject(error);
            });
    }

    @action.bound
    saveRequestForm(id: string, submission: FullSubmission): Promise<void> {
        return this.api.client(apiConfigs.saveRequestForm(id, submission)).then((r) => r.data);
    }

    @action.bound
    deleteRequest(id: string): Promise<void> {
        return this.api.client(apiConfigs.deleteRequest(id)).then((r) => r.data);
    }

    @action.bound
    exportListXls(queryData: TableQueryData): void {
        const filename = this.rootStore.intlStore.formatMessage('campaignRequest.registryTitle');
        this.api
            .client(apiConfigs.requestListXls(queryData))
            .then((r) => r.data)
            .then((f) =>
                downloadFile(
                    f,
                    `${filename}.xlsx`,
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                ),
            );
    }

    @action.bound
    getRequestChecksData(tabId: string): Promise<RequestChecks> {
        return this.api.client(apiConfigs.getRequestChecksData(tabId)).then((r) => r.data);
    }

    @action.bound
    async downloadRequestChecksAsFile(tabId: string): Promise<void> {
        try {
            const { data } = await this.api.client(apiConfigs.downloadRequestChecksAsFile(tabId));
            const filename = this.rootStore.intlStore.formatMessage('campaignRequest.checks');
            await fileDownload(data, `${filename}.xlsx`);
        } catch (error) {
            console.error(error);
        }
    }

    @action.bound
    async downloadAllDocsAsFile(requestId: string, requestNumber: string): Promise<void> {
        try {
            const { data } = await this.api.client(apiConfigs.downloadRequestAllDocs(requestId));
            const filename = this.rootStore.intlStore.formatMessage('campaignRequest.downloadAllDocsArchiveName', {
                number: requestNumber,
            });
            await fileDownload(data, `${filename}.zip`);
        } catch (error) {
            console.error(error);
        }
    }

    @action.bound
    getTransitionRequest(requestId: string): Promise<TransitionsDTO> {
        return this.api.client(apiConfigs.transitionRequest(requestId)).then((r) => r.data);
    }

    @action.bound
    checkFTSButtonVisibility(requestId: string): Promise<boolean> {
        return this.api.client(apiConfigs.checkFTS(requestId)).then((r) => r.data);
    }

    @action.bound
    triggerRequestFTSExecutionInfo(requestId: string): Promise<void> {
        return this.api.client(apiConfigs.triggerRequestFTSExecutionInfo(requestId)).then((r) => r.data);
    }

    @action.bound
    transitionToNextLifeCycleStep(transitionId: string, requestId: string): Promise<void> {
        return this.api.client(apiConfigs.transitionToNextLifeCycleStep(transitionId, requestId)).then((r) => r.data);
    }

    @action.bound
    loadPrintForms(formId: string): Promise<CampaignRequestPfDTO[]> {
        return this.api.client(apiConfigs.requestPrintForms(formId)).then((r) => r.data);
    }

    @action.bound
    loadLicensePrintForm(requestId: string): Promise<CampaignRequestPfDTO[]> {
        return this.api.client(apiConfigs.requestLicensePrintForm(requestId)).then((r) => r.data);
    }

    @action.bound
    getPrintForm(templateId: string, formId: string): Promise<CampaignRequestPfDTO> {
        return this.api.client(apiConfigs.getPrintForm(templateId, formId)).then((r) => r.data);
    }

    @action.bound
    generatePrintForm(formId: string, templateId: string, submission?: FullSubmission): Promise<CampaignRequestPfDTO> {
        return this.api.client(apiConfigs.generatePrintForm(formId, templateId, submission)).then((r) => r.data);
    }

    @action.bound
    downloadPrintFormFile(fileDTO: FileDTOWithId): void {
        this.api
            .client(apiConfigs.downloadPrintFormFile(fileDTO.id))
            .then((r) => r.data)
            .then((f) => downloadFile(f, fileDTO.filename, fileDTO.mimeType));
    }

    @action.bound
    downloadAllPrintFormFiles(formId: string): AxiosPromise<Blob> {
        return this.api.client(apiConfigs.downloadAllPrintFormFiles(formId));
    }

    @action.bound
    getEmployeesList(): Promise<EmployeeDTO[]> {
        return this.api.client(apiConfigs.getAllActiveEmployees()).then((r) => r.data);
    }

    @action.bound
    getRequestExecutorList(requestId: string): Promise<EmployeeDTO[]> {
        return this.api.client(apiConfigs.getRequestExecutorList(requestId)).then((r) => r.data);
    }

    @action.bound
    setCurrentUserAsExecutor(requestId: string): Promise<void> {
        return this.api.client(apiConfigs.setCurrentUserAsExecutor(requestId)).then((r) => r.data);
    }

    @action.bound
    getExecutorSettings(requestId: string): Promise<EmployeeDTO> {
        return this.api.client(apiConfigs.getExecutorSettings(requestId)).then((r) => r.data);
    }

    @action.bound
    editExecutorSettings(requestId: string, userId: string): Promise<void> {
        return this.api.client(apiConfigs.editExecutorSettings(requestId, userId)).then((r) => r.data);
    }

    @action.bound
    registry(
        registryCode: string,
        query: TTableQueryData<CampaignRequestRowNew>,
    ): Promise<RowsData<CampaignRequestRowNew>> {
        return this.api
            .client(apiConfigs.registry(registryCode, query))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                }),
            );
    }

    @action.bound
    registryColumns(registryCode: string): Promise<ColumnData<CampaignRequestRowNew>[]> {
        return this.api
            .client(apiConfigs.registryColumns(registryCode))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                }),
            );
    }

    @action.bound
    registryRequestLog(
        registryCode: string,
        entityId: string,
        query: TTableQueryData<RequestLogType>,
    ): Promise<RowsData<RequestLogType>> {
        return this.api
            .client(apiConfigs.registryRequestLog(registryCode, entityId, query))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                }),
            );
    }

    @action.bound
    registryRequestLogColumns(registryCode: string): Promise<ColumnData<RequestLogType>[]> {
        return this.api
            .client(apiConfigs.registryRequestLogColumns(registryCode))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                }),
            );
    }

    @action.bound
    async uploadRequestLog<RowType extends TTableRow>(
        tableQueryData: TTableQueryData<RowType>,
        registryCode: string,
        entityId: string,
        entityNumber: string,
    ): Promise<void> {
        try {
            const { data } = await this.api.client(apiConfigs.uploadRequestLog(tableQueryData, registryCode, entityId));
            const filename = this.rootStore.intlStore.formatMessage('requestLog.title', { number: entityNumber });
            await fileDownload(data, `${filename}.xlsx`);
        } catch (error) {
            console.error(error);
        }
    }

    @action.bound
    mapDtoToRow(dto: CampaignRequestRowNew): CampaignRequestRow {
        return {
            id: dto.id,
            number: dto.customData.number,
            authorName: dto.customData.author.title,
            authorUserId: dto.customData.author.id,
            campaignId: dto.customData.campaign.id,
            campaignTitle: dto.customData.campaign.title,
            regFormTitle: dto.customData.regForm.title,
            created: dto.customData.created,
            sent: dto.customData.sent,
            state: dto.customData.state.id,
            executors: [],
        };
    }

    @action.bound
    async upload<RowType extends TTableRow>(tableQueryData: TTableQueryData<RowType>): Promise<void> {
        try {
            const { data } = await this.api.client(apiConfigs.upload(tableQueryData));
            const filename = this.rootStore.intlStore.formatMessage('homePage.headerLinks.requests');
            await fileDownload(data, `${filename}.xlsx`);
        } catch (error) {
            console.error(error);
        }
    }

    @action.bound
    checkEditablePf(customFormId: string): Promise<void> {
        return this.api.client(apiConfigs.checkEditablePf(customFormId)).then((r) => r.data);
    }

    @action.bound
    flkValidateFields(customFormId: string): Promise<FlkValidateDTO> {
        return this.api.client(apiConfigs.flkValidateFields(customFormId)).then((r) => r.data);
    }

    @action.bound
    async uploadRequestsDoc<RowType extends TTableRow>(tableQueryData: TTableQueryData<RowType>): Promise<void> {
        try {
            const { data } = await this.api.client(apiConfigs.uploadRequestsDoc(tableQueryData));
            const filename = this.rootStore.intlStore.formatMessage('homePage.headerLinks.requests');
            await fileDownload(data, `${filename}.doc`);
        } catch (error) {
            console.error(error);
        }
    }

    @action.bound
    async downloadLicenseDuplicate(id: string, number: string): Promise<void> {
        try {
            const { data } = await this.api.client(apiConfigs.downloadLicenseDuplicate(id));
            const filename = this.rootStore.intlStore.formatMessage('campaignRequest.licenseDuplicate', {
                number,
            });
            await fileDownload(data, `${filename}.pdf`);
        } catch (error) {
            console.error(error);
        }
    }

    @action.bound
    listSignatories(): Promise<IdTitle[]> {
        return this.api
            .client(apiConfigs.getSignatories())
            .then((r) => r.data)
            .then((users) => {
                return users.map((user: EmployeeDTO) => {
                    const signatory = new UserNameModel().load({ ...user, name: user.person.firstName });
                    return {
                        id: user.userId,
                        title: signatory.name,
                    };
                });
            });
    }

    @action.bound
    licenseSignatory(requestId: string, signatoryId: string): Promise<UserNameModel> {
        return this.api
            .client(apiConfigs.licenseSignatory(requestId, signatoryId))
            .then((r) => r.data)
            .then((user: EmployeeDTO) => new UserNameModel().load({ ...user, name: user.person.firstName }));
    }

    @action.bound
    assignLicenseSignatoryAndChangeState(transitionId: string, requestId: string, signatoryId: string): Promise<void> {
        return this.api
            .client(apiConfigs.assignLicenseSignatoryAndChangeState(transitionId, requestId, signatoryId))
            .then((r) => r.data);
    }

    @action.bound
    assignLicenseSignatory(requestId: string, signatoryId: string): Promise<void> {
        return this.api.client(apiConfigs.assignLicenseSignatory(requestId, signatoryId)).then((r) => r.data);
    }

    @action.bound
    downloadRequestFile(file: FileDTOWithId): Promise<void> {
        return this.api.client(apiConfigs.downloadRequestFile(file.id)).then(({ data }) => {
            fileDownload(data, file.filename);
        });
    }

    @action.bound
    getFileByte(fileId: string): () => Promise<ArrayBuffer> {
        return (): Promise<ArrayBuffer> => {
            return this.api.client(apiConfigs.getRequestFileByte(fileId)).then((r) => r.data);
        };
    }

    @action.bound
    uploadSignature(fileId: string): (signature: string) => Promise<SignatureStatus> {
        return (signature: string): Promise<SignatureStatus> => {
            return this.api.client(apiConfigs.uploadRequestSignature(fileId, signature)).then((r) => r.data);
        };
    }

    @action.bound
    uploadSignatureFile(fileId: string): (signatureFile: FormData) => Promise<SignatureStatus> {
        return (signatureFile: FormData): Promise<SignatureStatus> => {
            return this.api.client(apiConfigs.uploadRequestSignatureFile(fileId, signatureFile)).then((r) => r.data);
        };
    }

    @action.bound
    getSignatureInfo(fileId: string): () => Promise<SignatureInfoDTO[]> {
        return (): Promise<SignatureInfoDTO[]> => {
            return this.api.client(apiConfigs.getRequestSignatureInfo(fileId)).then((r) => r.data);
        };
    }

    @action.bound
    downloadSignature(fileId: string): (isWithFile: boolean) => Promise<Blob> {
        return (isWithFile: boolean): Promise<Blob> => {
            return this.api.client(apiConfigs.downloadRequestSignature(fileId, isWithFile)).then((r) => r.data);
        };
    }

    @action.bound
    deleteSignature(fileId: string): () => Promise<SignatureStatus> {
        return (): Promise<SignatureStatus> => {
            return this.api.client(apiConfigs.deleteRequestSignature(fileId)).then((r) => r.data);
        };
    }
}
