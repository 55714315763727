import { observer } from 'mobx-react';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { clientRoute, licenseRoutes } from '../../clientRoute';
import { Authentication } from '../../components';
import { ObjectRoutesContext } from '../../contexts';
import { CampaignRequestPage } from '../campaign-request';
import { LicensesListPage } from './list';

export const LicensePages = observer((): JSX.Element => {
    return (
        <Authentication>
            <ObjectRoutesContext.Provider value={licenseRoutes}>
                <Switch>
                    <Route path={clientRoute.licenses} exact>
                        <LicensesListPage />
                    </Route>
                    <Route path={[clientRoute.licenseSubTab, clientRoute.licenseTab, clientRoute.license]}>
                        <CampaignRequestPage />
                    </Route>
                </Switch>
            </ObjectRoutesContext.Provider>
        </Authentication>
    );
});
