import { observer } from 'mobx-react-lite';
import React from 'react';
import { useParams } from 'react-router-dom';
import { entities, permissions } from '../../../../authSchemeConfig';
import { AuthorizationCheck } from '../../../../components';
import { RequestFormParams } from '../../../../types';
import { RequestChecksTabContent } from './RequestChecksTabContent';

export const RequestChecksTab = observer((): JSX.Element => {
    const { tabId } = useParams<RequestFormParams>();
    return (
        <AuthorizationCheck
            entityCode={entities.RequestCustomForm}
            permCode={permissions.RequestCustomForm.ViewChecksInTab}
            entityId={tabId}
        >
            <RequestChecksTabContent />
        </AuthorizationCheck>
    );
});
