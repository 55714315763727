import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import { useFeature } from 'feature-toggle-jsx';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';
import { useStore } from '../../hooks';

export type StartPageMenuItemsProps = {
    open: boolean;
    anchorRef: React.RefObject<HTMLDivElement>;
    handleClose: (event: React.MouseEvent<Document, MouseEvent>) => void;
    handleToggle: () => void;
};

export const StartPageMenuItems = (props: StartPageMenuItemsProps): JSX.Element => {
    const { authenticationStore, api, personStore } = useStore();

    const [loginWithoutRegister] = useFeature('loginWithoutRegister');

    const history = useHistory();
    const loginAnonymously = async (): Promise<void> => {
        await authenticationStore.loginAnonymously();
        await api.authVerify();
        history.push(clientRoute.campaigns);
        await personStore.getInfo();
    };

    const { open, anchorRef, handleClose, handleToggle } = props;
    const location = useLocation();

    return (
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }): JSX.Element => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList id="split-button-menu">
                                {loginWithoutRegister && (
                                    <MenuItem onClick={loginAnonymously}>
                                        <FormattedMessage id="startPage.headerLinks.loginAnonymously" />
                                    </MenuItem>
                                )}
                                <MenuItem
                                    component={RouterLink}
                                    to={{ pathname: clientRoute.loginRedirect, search: location.search }}
                                    onClick={handleToggle}
                                >
                                    <FormattedMessage id="startPage.headerLinks.loginAccount" />
                                </MenuItem>
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    );
};
