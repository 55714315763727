import AllComponents, { withFunctionCallComponents } from '@platform/formio/components';
import Components from '@platform/formio/components/Components';
import { action, observable } from 'mobx';
import { RootStore } from './RootStore';

type Formio = {
    fetch<T>(url: string, options?: object): Promise<T>;
    // ... other
};

export class FormioStore {
    @observable private rootStore: RootStore;
    @observable private apiUrl?: string;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.apiUrl = rootStore.env.apiUrl;
        Components.setComponents(AllComponents);
        Components.addComponent(
            'select',
            withFunctionCallComponents.select({ baseUrl: this.apiUrl, catalogsUrl: this.apiUrl }),
        );

        this.hackFormioFetch();
    }

    @action.bound
    addComponentsWithMaterialTheme() {
        const fileProps = {
            baseUrl: this.apiUrl,
            catalogsUrl: this.apiUrl,
            materialUiTheme: this.rootStore.appTheme,
            intlStore: this.rootStore.intlStore,
            signatureStore: this.rootStore.formioSignatureStore,
        };

        const multilevelCatalogProps = {
            client: this.rootStore.api.client,
            intlStore: this.rootStore.intlStore,
            theme: this.rootStore.appTheme,
        };

        Components.addComponent('file', withFunctionCallComponents.styledFile(fileProps));
        Components.addComponent('categoryFile', withFunctionCallComponents.categoryFile(fileProps));
        Components.addComponent(
            'multilevelCatalog',
            withFunctionCallComponents.multilevelCatalog(multilevelCatalogProps),
        );
    }

    @action.bound
    hackFormioFetch(): void {
        const globalWithFormio = global as unknown as { Formio: Formio };
        const formio = globalWithFormio.Formio;
        if (formio && formio.fetch) {
            const formioFetch = formio.fetch;

            globalWithFormio.Formio.fetch = <T>(url: string, options?: object): Promise<T> => {
                let fetchOptions = { credentials: 'include' };
                if (!!options) {
                    fetchOptions = Object.assign(fetchOptions, options);
                }
                return formioFetch(url, fetchOptions);
            };
        }
    }
}
