import { toJS } from 'mobx';
import * as Yup from 'yup';

const schema = {
    title: Yup.string().required('Необходимо указать наименование квоты'),
    category: Yup.object().nullable().required('Необходимо выбрать категорию квоты'),
    productCategory: Yup.object().nullable().required('Необходимо выбрать категорию товара'),
    quotaType: Yup.object().nullable().required('Необходимо выбрать тип квоты'),
    year: Yup.date().required('Необходимо указать год действия квоты'),
    periodStart: Yup.string().required('Required'),
    periodEnd: Yup.string().required('Required'),
    volume: Yup.number().required('Необходимо указать выделенный объем'),
    units: Yup.object().nullable().required('Необходимо выбрать единицу измерения квоты'),
    validityPeriodStart: Yup.string().required('Required'),
    validityPeriodEnd: Yup.string().required('Required'),
    licensingPeriodStart: Yup.string().required('Required'),
    licensingPeriodEnd: Yup.string().required('Required'),
    operations: Yup.mixed().test('required', 'Необходимо выбрать хотя бы одно направление', (operations) => {
        operations = toJS(operations);
        return Array.isArray(operations) && operations.length > 0;
    }),
};

export const yupQuotaSchema = Yup.object().shape(schema);

export const yupQuotaSchemaWithCountriesVolume = Yup.object().shape({
    ...schema,
    countriesTableCommonVolume: Yup.number().test(
        'test',
        'Ваше распределение не соответствует выделенному объему',
        function (commonVolume) {
            const volume = this!.resolve(Yup.ref('volume'));
            return volume === toJS(commonVolume);
        },
    ),
});

export const yupQuotaSchemaWithBothTablesVolumes = Yup.object().shape({
    ...schema,
    countriesTableCommonVolume: Yup.number().test(
        'test',
        'Ваше распределение не соответствует выделенному объему',
        function (commonVolume) {
            const volume = this!.resolve(Yup.ref('volume'));
            return volume === toJS(commonVolume);
        },
    ),
    vtdTableCommonVolume: Yup.number().test(
        'test',
        'Сумма распределения не равна сумме выделенных квот',
        function (commonVolume) {
            const countriesTableCommonVolume = this!.resolve(Yup.ref('countriesTableCommonVolume'));
            return countriesTableCommonVolume === toJS(commonVolume);
        },
    ),
});

export const yupQuotaSchemaForTender = Yup.object().shape({
    ...schema,
    countriesTableCommonVolume: Yup.number().test(
        'test',
        'Ваше распределение не соответствует выделенному объему',
        function (commonVolume) {
            const volume = this!.resolve(Yup.ref('volume'));
            return volume === toJS(commonVolume);
        },
    ),
    vtdTableCommonVolume: Yup.number().test(
        'test',
        'Сумма распределения не должна быть больше суммы выделенных квот',
        function (commonVolume) {
            const countriesTableCommonVolume: number = this!.resolve(Yup.ref('countriesTableCommonVolume'));
            if (!commonVolume) {
                commonVolume = 0;
            }
            return countriesTableCommonVolume >= toJS(commonVolume);
        },
    ),
});
