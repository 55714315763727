import React, { MouseEventHandler } from 'react';
import { IconButton } from '@material-ui/core';
import { ReactComponent as AngleIcon } from '../../resources/images/icons/angle-down.svg';

export type AngleIconButtonProps = {
    isUp?: boolean;
    isActive?: boolean;
    onClick: MouseEventHandler;
};

export const AngleIconButton = (props: AngleIconButtonProps) => {
    const transform = props.isUp ? 'rotate(180deg)' : '';
    const className = props.isActive ? 'active' : '';
    const transition = 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms';
    return (
        <IconButton onClick={props.onClick} className={className}>
            <AngleIcon style={{ transform, transition }} />
        </IconButton>
    );
};
