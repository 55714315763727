import { withTheme } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { apiConfigs } from '../../apiConfigs';
import { clientRoute } from '../../clientRoute';
import { useStore } from '../../hooks';

const Header = withTheme(
    styled.div`
        .header {
            position: static !important;
            z-index: 2000;
        }

        .modal {
            height: 100vh !important;
        }

        .header__navigation {
            z-index: 1005;
        }

        .container {
            max-width: ${({ theme }) => theme.breakpoints.values.lg}px !important;

            &::before {
                display: none;
            }
        }
    `,
);

const scriptSrc = 'https://gisp.gov.ru/gisplk/header_distv2/gisp-header.js';
const catService = 'https://gisp.gov.ru/gisplk/v2/api/services-map';
const logout = 'https://gisp.gov.ru/gisplk/logout';

const USER_TOKEN = 'userToken';

function setUserToken(token: string = '') {
    localStorage.setItem(USER_TOKEN, token);
    window.dispatchEvent(new Event('storage'));
}

export const GispHeader = observer((): JSX.Element => {
    const { personStore, intlStore, api, authenticationStore } = useStore();

    const userName = personStore.person.lastName ? personStore.person.lastNameWithInitials : null;
    const email = personStore.user.email || null;

    const handleLogout = async (): Promise<void> => {
        await authenticationStore.logout().then((response) => {
            personStore.clearPerson();
            if (response.data.redirect) {
                window.location.href = response.data.redirect;
            }
        });
        await api.authVerify();
        await personStore.getInfo();
    };

    useEffect(() => {
        const script = document.createElement('script');
        script.src = scriptSrc;
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    useEffect(() => {
        api.client(apiConfigs.notificationToken)
            .then(({ data }) => {
                const { token } = data;
                if (token) {
                    setUserToken(token);
                } else {
                    setUserToken();
                }
            })
            .catch(() => {
                setUserToken();
            });

        const logout = document.getElementById('exit');
        logout?.addEventListener('click', handleLogout);

        return () => {
            logout?.removeEventListener('click', handleLogout);
        };
    }, [email]);

    return (
        <Header
            id="gisp-header"
            data-fio-user={userName}
            data-fio-email={email}
            data-login={clientRoute.saml}
            data-logout={logout}
            data-cat-service={catService}
            data-status={intlStore.locale}
            data-lang={intlStore.locale}
        />
    );
});
