import {
    Box,
    Button,
    ButtonProps,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    Portal,
    Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { DialogContentListItem, TotDropzoneArea } from '../../../../../components';
import { useQuotaContext, useStore } from '../../../../../hooks';
import { FileModel } from '../../../../../models';
import { FileFormats, ModalProps, RouteParamsDefault } from '../../../../../types';
import { useQuotaDistributionUploadingStyles } from '../../../quota-styles';

const buttonProps: ButtonProps = { size: 'small', variant: 'contained' };

export const QuotaDistributionUploadingDialog = observer((props: ModalProps): JSX.Element => {
    const { open, onCancel } = props;
    const classes = useQuotaDistributionUploadingStyles();
    const { csv, txt } = FileFormats;
    const file = useMemo(() => new FileModel([csv, txt]), []);
    const { isFileLoaded, filename, formData, acceptedFiles, onChangeFile, onDeleteFile } = file;
    const { quotasStore, notificationStore } = useStore();
    const { uploadQuotaParticipantsDistributionFile } = quotasStore;
    const { id } = useParams<RouteParamsDefault>();
    const { quotasModel } = useQuotaContext();
    const { quota } = quotasModel;
    const { setParticipants, countryGroups } = quota;
    const { onError } = notificationStore;
    const { formatMessage } = useIntl();

    const dropzoneText = formatMessage({ id: 'common.dropzoneText' });

    const uploadDistributionFile = (): void => {
        if (!formData) {
            return;
        }
        uploadQuotaParticipantsDistributionFile(id, formData)
            .then((participants) => {
                setParticipants(participants);
                onCancel();
            })
            .catch((e) => {
                onError(e?.response?.data || 'Произошла ошибка.');
            });
    };

    return (
        <Portal>
            <Dialog open={open}>
                <DialogTitle>Загрузка распределения по участникам ВТД</DialogTitle>
                <DialogContent>
                    {!isFileLoaded && (
                        <TotDropzoneArea
                            showAlerts={false}
                            useChipsForPreview={true}
                            showPreviewsInDropzone={false}
                            dropzoneText={dropzoneText}
                            acceptedFiles={acceptedFiles}
                            onChange={onChangeFile}
                            onDrop={onChangeFile}
                        />
                    )}
                    {filename && <Chip label={filename} onDelete={onDeleteFile} />}
                    <Box py={2}>
                        <Typography variant="h6">Формат</Typography>
                    </Box>
                    <Typography className={classes.textBold}>Порядок столбцов:</Typography>
                    <Box className={classes.listContainer}>
                        <List dense>
                            <DialogContentListItem text="ИНН" />
                            <DialogContentListItem text="Участник ВТД" />
                            {countryGroups.map((group) => {
                                const { countryGroup } = group;
                                return (
                                    countryGroup?.title && (
                                        <DialogContentListItem
                                            text={`Квота по: ${countryGroup.title}`}
                                            key={group.id}
                                        />
                                    )
                                );
                            })}
                        </List>
                    </Box>
                    <Typography className={classes.textBold}>
                        Файл с данными должен быть в текстовом формате (тип файла CSV или TXT). Разделители элементов —
                        точка с запятой. Первая строка не импортируется.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    {isFileLoaded && (
                        <Button {...buttonProps} color="secondary" onClick={uploadDistributionFile}>
                            Подтвердить
                        </Button>
                    )}
                    <Button {...buttonProps} color="primary" onClick={onCancel}>
                        Отменить
                    </Button>
                </DialogActions>
            </Dialog>
        </Portal>
    );
});
