import { Container, Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { entities, permissions } from '../../../authSchemeConfig';
import { AuthorizationCheck, StickyWrapper, TotObjectActionsBarStyled } from '../../../components';
import { CountryGroupsContext } from '../../../contexts';
import { RouteParamsDefault } from '../../../types';
import { CountryGroupsEditModeButtonsWithDialog, CountryGroupsViewModeButtons } from './buttons';

export const CountryGroupsControlPanel = observer((): JSX.Element => {
    const { isCreating, isEditing, isViewOnly } = useContext(CountryGroupsContext);
    const { id } = useParams<RouteParamsDefault>();
    return (
        <AuthorizationCheck
            entityCode={entities.System}
            entityId={id}
            permCode={permissions.CountryGroups.ManageCountryGroup}
        >
            <StickyWrapper mode="bottom">
                <TotObjectActionsBarStyled>
                    <Container maxWidth="lg">
                        <Grid alignItems="center" justify="space-between" container>
                            <Grid item>
                                {(isEditing || isCreating) && <CountryGroupsEditModeButtonsWithDialog />}
                                {isViewOnly && <CountryGroupsViewModeButtons />}
                            </Grid>
                        </Grid>
                    </Container>
                </TotObjectActionsBarStyled>
            </StickyWrapper>
        </AuthorizationCheck>
    );
});
