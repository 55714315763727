import React, { PropsWithChildren } from 'react';
import { Box } from '@material-ui/core';

export type TitleWrapperProps = PropsWithChildren<{
    isImage: boolean;
}>;

export const TitleWrapper = (props: TitleWrapperProps): JSX.Element => {
    const { isImage, children } = props;
    const styleTitleWrapper = isImage ? { zIndex: 1, position: 'absolute', top: 0 } : { position: 'static' };
    return (
        <Box {...styleTitleWrapper} padding="24px" height="204px" boxSizing="border-box">
            {children}
        </Box>
    );
};
