import { Box, createStyles, Grid, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router-dom';
import { clientRoute } from '../../../../clientRoute';
import { AppRouterLink, StickyWrapper } from '../../../../components';
import { useStore } from '../../../../hooks';
import { AuthorityDocModel } from '../../../../models';

type UseAuthorityDocRequestsStylesProps = {
    headerHeight: number;
};

export const useAuthorityDocRequestsStyles = makeStyles<Theme, UseAuthorityDocRequestsStylesProps>((theme: Theme) => {
    return createStyles({
        container: {
            width: '300px',
        },
        requestLink: {
            display: 'inline-block',
            fontSize: '14px',
        },
        requestsTitleTypography: {
            fontWeight: 'bold',
        },
        stickyState: {
            marginTop: (props) => props.headerHeight + theme.spacing(1),
        },
    });
});

export type AuthorityDocRequestsProps = {
    model: AuthorityDocModel;
};

export const AuthorityDocRequests = observer((props: AuthorityDocRequestsProps): JSX.Element => {
    const { model } = props;
    const { requests } = model;
    const { headerHeightStore } = useStore();
    const { headerHeight = 0 } = headerHeightStore;
    const classes = useAuthorityDocRequestsStyles({ headerHeight });

    return (
        <Grid item>
            <StickyWrapper mode="top" stickyClassName={classes.stickyState} topOffset={-headerHeight}>
                <Grid container direction="column" className={classes.container}>
                    <Grid item>
                        <Box mb={1}>
                            <Typography variant="body2" className={classes.requestsTitleTypography}>
                                <FormattedMessage id="authorityDoc.licenses" />
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item container direction="column">
                        {/* TODO: здесь в requests на самом деле лицензии, возможно придется на бэке еще порефакторить и засинхронить фронт (был разделен роутинг /requests на отдельно /requests и /licenses) */}
                        {requests.map((license) => {
                            const { id, number } = license;
                            return (
                                <Grid item key={id}>
                                    <AppRouterLink
                                        to={generatePath(clientRoute.license, { id })}
                                        className={classes.requestLink}
                                        underline="always"
                                    >
                                        {number}
                                    </AppRouterLink>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
            </StickyWrapper>
        </Grid>
    );
});
