import { action, observable } from 'mobx';
import { apiConfigs } from '../apiConfigs';
import { ApiStore, IntlStore } from '../store';

export interface ConfirmEmailDTO {
    token?: string;
    lang: string;
}

export class RegistrationConfirmModel {
    @observable api: ApiStore;
    @observable intl: IntlStore;
    @observable status?: 'pending' | 'success' | 'expired' | 'not-found';

    constructor(api: ApiStore, intlStore: IntlStore) {
        this.api = api;
        this.intl = intlStore;
    }

    @action.bound
    async confirmEmail(token?: string): Promise<void> {
        try {
            this.status = 'pending';
            const confirmEmailDTO = {
                token,
                lang: this.intl.locale,
            };
            await this.api.client(apiConfigs.confirmEmail(confirmEmailDTO));
            this.status = 'success';
        } catch (e) {
            if (e.response) {
                if (e.response.status === 410) {
                    this.status = 'expired';
                }
                if (e.response.status === 404) {
                    this.status = 'not-found';
                }
            }
        }
    }
}
