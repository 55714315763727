import { AxiosError } from 'axios';
import React, { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { getOrElse } from '../utils';

type FormattedErrorProps = {
    error: string;
};

const FormattedError = (props: FormattedErrorProps): JSX.Element => {
    const intl = useIntl();
    const { error } = props;
    const errorMessageId = `error.${error}`;
    if (!!intl.messages[errorMessageId]) {
        return <FormattedMessage id={errorMessageId} />;
    }
    return <React.Fragment>{error}</React.Fragment>;
};

export function ErrorMessage(error: AxiosError): string | ReactNode {
    if (typeof error?.response?.data === 'string') {
        return <FormattedError error={error.response.data} />;
    }
    return getOrElse(
        error.response && error.response.data,
        error.response?.data,
        <FormattedMessage id="common.errorText" />,
    );
}
