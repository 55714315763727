import { Box, Container, Grid, Link, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, NavLink, useParams } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';
import { PageContentContainer, TotObjectHeader } from '../../components';
import { useStore } from '../../hooks';
import { RequestLogTable } from './RequestLogTable';

export type RequestLogPageParams = {
    id: string;
};

export const RequestLogPage = (): JSX.Element => {
    const { requestStore } = useStore();
    const { id } = useParams<RequestLogPageParams>();

    const [requestNumber, setRequestNumber] = useState<string>('');

    useEffect((): void => {
        requestStore.getRequestDTO(id).then((data) => setRequestNumber(data.number));
    }, [requestStore]);

    return (
        <PageContentContainer container direction="column" wrap="nowrap">
            <Grid item>
                <TotObjectHeader>
                    <Container maxWidth="xl">
                        <Grid container spacing={3} direction="column" alignItems="flex-start">
                            <Grid item>
                                <Link
                                    component={NavLink}
                                    underline="none"
                                    to={generatePath(clientRoute.request, { id })}
                                >
                                    <FormattedMessage id="requestLog.backToRequest" />
                                </Link>
                            </Grid>
                            <Grid item>
                                <Typography variant="h1">
                                    <FormattedMessage id="requestLog.title" values={{ number: requestNumber }} />
                                </Typography>
                            </Grid>
                        </Grid>
                    </Container>
                </TotObjectHeader>
            </Grid>
            <Grid item>
                <Container maxWidth="xl">
                    <Box pb={10} pt={10}>
                        <RequestLogTable requestNumber={requestNumber} />
                    </Box>
                </Container>
            </Grid>
        </PageContentContainer>
    );
};
