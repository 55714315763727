import { ReactNode, useState } from 'react';

type UseErrorType = [boolean, string | ReactNode, (text: string | ReactNode) => void, () => void];

export function useError(): UseErrorType {
    const [isError, setError] = useState<boolean>(false);
    const [errorText, setErrorText] = useState<string | ReactNode>('');

    const enableError = (text: string | ReactNode): void => {
        setError(true);
        setErrorText(text);
    };

    const resetError = (): void => {
        setError(false);
        setErrorText('');
    };

    return [isError, errorText, enableError, resetError];
}
