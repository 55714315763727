import { Box, Container, Grid, Link, makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { BreadcrumbsComponent, breadCrumbsSettings } from '../../../components';
import { useModal, useStore } from '../../../hooks';
import { CampaignRequestRow } from '../../../models';
import { CampaignRequestRowNew } from '../../../types';
import { ExecutorEditDialog } from '../../campaign-request';
import { LicensesCampaignRequestActionsBtns } from './LicensesCampaignRequestActionsBtns';
import { LicensesCampaignRequestTable } from './LicensesCampaignRequestTable';

const useLicensesListPageStyles = makeStyles((theme) => {
    return {
        tableWrapper: {
            width: '100%',
        },
        breadcrumbsAndArchiveLinkContainer: {
            paddingBottom: theme.spacing(5),
        },
        breadcrumbsContainer: {
            paddingRight: theme.spacing(4),
        },
    };
});

export const LicensesListPage = observer((): JSX.Element => {
    const { requestStore } = useStore();
    const [requestRowModel, setRequestRowModel] = useState<CampaignRequestRow>();

    const [editExecutorDialogOpen, setEditExecutorDialogOpen, setEditExecutorDialogClosed] = useModal();
    const [editExecutorDialogTitle, setEditExecutorDialogTitle] = useState<string>();
    const [isConfirm, setIsConfirm] = useState<boolean>(false);

    const classes = useLicensesListPageStyles();

    const handleEditExecutorDialogOpen = useCallback(
        (campaignRow: CampaignRequestRow, title?: string): void => {
            setEditExecutorDialogOpen();
            setRequestRowModel(campaignRow);
            title && setEditExecutorDialogTitle(title);
        },
        [setEditExecutorDialogOpen, setRequestRowModel, setEditExecutorDialogTitle],
    );

    const setRowActions = useMemo(
        () =>
            (row: CampaignRequestRowNew, reloadData: () => void): JSX.Element => {
                const correctRowData = requestStore.mapDtoToRow(row);

                return (
                    <LicensesCampaignRequestActionsBtns
                        campaignRow={correctRowData}
                        reloadData={reloadData}
                        handleEditExecutorDialogOpen={handleEditExecutorDialogOpen}
                        isConfirm={isConfirm}
                        setIsConfirm={setIsConfirm}
                    />
                );
            },
        [isConfirm, handleEditExecutorDialogOpen, requestStore],
    );

    return (
        <Container maxWidth="lg">
            {requestRowModel && (
                <ExecutorEditDialog
                    title={editExecutorDialogTitle}
                    onClose={setEditExecutorDialogClosed}
                    open={editExecutorDialogOpen}
                    requestRowModel={requestRowModel}
                    setIsConfirm={setIsConfirm}
                />
            )}

            <Box pt={5.5} pb={15}>
                <Grid
                    container
                    alignItems="center"
                    justify="space-between"
                    wrap="nowrap"
                    className={classes.breadcrumbsAndArchiveLinkContainer}
                >
                    <Grid item className={classes.breadcrumbsContainer}>
                        <BreadcrumbsComponent breadcrumbsSettings={breadCrumbsSettings} />
                    </Grid>
                    <Grid item>
                        <Link href="/static/Бланки защищенной бумаги.rar" download>
                            <FormattedMessage id="campaignRequest.protectedPaperArchive" />
                        </Link>
                    </Grid>
                </Grid>
                <Grid container direction="column">
                    <Grid item className={classes.tableWrapper}>
                        <LicensesCampaignRequestTable setRowActions={setRowActions} />
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
});
