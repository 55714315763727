import {
    Box,
    Button,
    Checkbox,
    Container,
    FormControlLabel,
    Grid,
    Link,
    Paper,
    TextField,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink, Redirect } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';
import { EmailField, PasswordField } from '../../components';
import { useStore } from '../../hooks';
import { RegistrationModel } from '../../models';
import { TotBackgroundWithAvailableHeader } from '../../TotBackground';

const useStyles = makeStyles(() => ({
    termsOfServiceLink: {
        display: 'inline-block',
        textDecoration: 'underline',

        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));

export const RegistrationPage = observer((): JSX.Element => {
    const { authenticationStore, env } = useStore();
    const registrationModel = useMemo<RegistrationModel>(
        () => authenticationStore.registrationModel,
        [authenticationStore],
    );

    const { errorLastName, errorFirstName } = registrationModel;

    const termsOfService = env.termsOfServiceLink;
    const classes = useStyles();

    const renderTermsOfServiceLink =
        termsOfService && termsOfService !== '#' ? (
            <a target="_blank" href={termsOfService} className={classes.termsOfServiceLink}>
                <FormattedMessage id="authentication.termsLinkTitle" />
            </a>
        ) : (
            <FormattedMessage id="authentication.termsLinkTitle" />
        );

    return (
        <TotBackgroundWithAvailableHeader>
            <Grid container item direction="column" alignItems="center" justify="center" style={{ zIndex: 2 }}>
                <Container className="t-registration-page" maxWidth="sm">
                    <Paper elevation={0}>
                        <Box p={12}>
                            <Grid container justify="center">
                                <Grid item>
                                    <Typography variant="h5" className="t-registration-title">
                                        <Box fontWeight="fontWeightBold">
                                            <FormattedMessage id="authentication.registrationTitle" />
                                        </Box>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Box pt={8}>
                                <form noValidate onSubmit={registrationModel.register}>
                                    <Grid container spacing={6} direction="column">
                                        <Grid item>
                                            <TextField
                                                inputProps={{
                                                    className: 't-last-name-field',
                                                }}
                                                FormHelperTextProps={{
                                                    className: 't-last-name-field-help-text',
                                                }}
                                                required
                                                onChange={registrationModel.onChangeLastName}
                                                fullWidth
                                                label={<FormattedMessage id="authentication.lastName" />}
                                                variant="outlined"
                                                error={!!errorLastName}
                                                helperText={errorLastName}
                                            />
                                        </Grid>
                                        <Grid item container spacing={6} justify="space-between">
                                            <Grid item xs={6}>
                                                <TextField
                                                    inputProps={{
                                                        className: 't-first-name-field',
                                                    }}
                                                    FormHelperTextProps={{
                                                        className: 't-first-name-field-help-text',
                                                    }}
                                                    required
                                                    onChange={registrationModel.onChangeFirstName}
                                                    label={<FormattedMessage id="authentication.firstName" />}
                                                    variant="outlined"
                                                    error={!!errorFirstName}
                                                    helperText={errorFirstName}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    inputProps={{
                                                        className: 't-middle-name-field',
                                                    }}
                                                    onChange={registrationModel.onChangeMiddleName}
                                                    label={<FormattedMessage id="authentication.middleName" />}
                                                    fullWidth
                                                    variant="outlined"
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <EmailField model={registrationModel} />
                                        </Grid>
                                        <Grid item>
                                            <PasswordField model={registrationModel} />
                                        </Grid>
                                        {termsOfService && (
                                            <Grid item>
                                                <FormControlLabel
                                                    value="top"
                                                    control={
                                                        <Checkbox
                                                            className="t-terms-is-accepted"
                                                            color="primary"
                                                            onChange={registrationModel.onChangeTermsIsAccepted}
                                                        />
                                                    }
                                                    label={
                                                        <FormattedMessage
                                                            id="authentication.termsIsAccepted"
                                                            values={{
                                                                link: renderTermsOfServiceLink,
                                                            }}
                                                        />
                                                    }
                                                    labelPlacement="end"
                                                />
                                            </Grid>
                                        )}
                                        <Grid item>
                                            <Button
                                                className="t-register"
                                                fullWidth
                                                size="large"
                                                variant="contained"
                                                type="submit"
                                                disabled={!!termsOfService && !registrationModel.termsIsAccepted}
                                            >
                                                <FormattedMessage id="authentication.register" />
                                            </Button>
                                        </Grid>
                                        <Grid item container justify="center">
                                            <Grid item>
                                                <Link
                                                    className="t-login"
                                                    component={RouterLink}
                                                    to={clientRoute.loginRedirect}
                                                    underline="always"
                                                >
                                                    <FormattedMessage id="authentication.alreadyHaveAccount" />
                                                </Link>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Box>
                        </Box>
                    </Paper>
                    {registrationModel.registerSucceed && <Redirect to={clientRoute.registrationInfoSuccess} />}
                </Container>
            </Grid>
        </TotBackgroundWithAvailableHeader>
    );
});
