import { FormApi, FormDTO, FormModel } from '@platform/formiojs-react';
import { History } from 'history';
import { action, observable } from 'mobx';
import { AuthorityDocStore, NotificationStore, RootStore } from '../store';
import { AuthorityDocDetailsDTO, AuthorityDocDTO, AuthorityDocRequestDTO } from '../types';

export class AuthorityDocModel {
    @observable private authorityDocStore: AuthorityDocStore;
    @observable id: string;
    @observable number = '';
    @observable state = '';
    @observable date = '';
    @observable requests: AuthorityDocRequestDTO[] = [];

    @observable formApi?: FormApi;
    @observable isFormReady = false;
    @observable formModel: FormModel = new FormModel(this.id);
    @observable isStillNoForm: boolean = false;

    constructor(id: string, rootStore: RootStore) {
        this.authorityDocStore = rootStore.authorityDocStore;
        this.id = id;
    }

    @action.bound
    load(): void {
        this.authorityDocStore.loadAuthorityDoc(this.id).then(this.loadDTO);
        this.authorityDocStore.loadAuthorityDocForm(this.id).then(this.loadForm);
        this.authorityDocStore.loadAuthorityDocLicenses(this.id).then(this.loadRequests);
    }

    @action.bound
    loadDTO(dto: AuthorityDocDTO): void {
        this.number = dto.number;
        this.state = dto.state;
        if(dto.date) {
            this.date = dto.date;
        }
    }

    @action.bound
    loadForm(formDTO: FormDTO): void {
        !formDTO ? (this.formModel.loaded = true) : this.formModel.load(formDTO);
        this.isStillNoForm = !formDTO;
    }

    @action.bound
    loadRequests(requests: AuthorityDocRequestDTO[]): void {
        this.requests = requests;
    }

    @action.bound
    onFormReady(form: FormApi): void {
        this.formApi = form;
        this.isFormReady = true;
    }
}
