import { Button, Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { clientRoute } from '../../../../clientRoute';
import { RouteParamsDefault } from '../../../../types';
import { QuotasEditButton } from './QuotasEditButton';
import { withConfirmDialog } from '../../../../hocs';
import { useQuotaContext } from '../../../../hooks';

export const DeleteQuotasButton = withConfirmDialog(({ openConfirmDialog }): JSX.Element => {
    return (
        <Button onClick={openConfirmDialog} color="secondary" size="small" variant="contained">
            <FormattedMessage id="campaignRequest.deleteRequest" />
        </Button>
    );
});

export const QuotasViewModeButtons = observer((): JSX.Element => {
    const history = useHistory();
    const { id } = useParams<RouteParamsDefault>();
    const { quotasModel } = useQuotaContext();
    const { quota, deleteQuota } = quotasModel;

    const redirectToEditing = (): void => {
        history.push(
            generatePath(clientRoute.quotasEdit, {
                id,
            }),
        );
    };

    const deleteQuotaAndRedirect = async (): Promise<void> => {
        await deleteQuota();
        history.push(clientRoute.quotas);
    };

    const intl = useIntl();
    const message = intl.formatMessage({ id: 'quotas.confirmDeletionQuotaInfoText' }, { title: quota.title });

    return (
        <Grid spacing={3} alignItems="center" justify="center" container>
            <Grid item>
                <QuotasEditButton onEdit={redirectToEditing} />
            </Grid>

            <Grid item>
                <DeleteQuotasButton
                    id="delete-request"
                    title="Удалить квоту"
                    message={message}
                    onConfirm={deleteQuotaAndRedirect}
                />
            </Grid>
        </Grid>
    );
});
