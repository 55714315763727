import React from 'react';
import { MenuItem } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

export type DeleteActionMenuItemProps = {
    onClick: () => void;
    tabIndex?: number;
};

export const DeleteActionMenuItem = (props: DeleteActionMenuItemProps): JSX.Element => {
    const { tabIndex, onClick } = props;
    return (
        <MenuItem dense button={true} tabIndex={tabIndex} onClick={onClick}>
            <FormattedMessage id="common.delete" />
        </MenuItem>
    );
};
