import { IconButton } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import React from 'react';

export type ClearFilterButtonProps = { clear: () => void };

export const ClearFilterButton = (props: ClearFilterButtonProps): JSX.Element => {
    const { clear } = props;

    return (
        <IconButton
            onClick={(e): void => {
                e.stopPropagation();
                clear();
            }}
        >
            <Clear />
        </IconButton>
    );
};
