import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { AppHeaderLinkData } from '../../../store';

export type AppHeaderLinksListProps = {
    elementsArray: AppHeaderLinkData[];
};

export const AppHeaderLinksList = (props: AppHeaderLinksListProps): JSX.Element => {
    const { elementsArray } = props;
    return (
        <React.Fragment>
            {elementsArray.map(({ key, to, icon, messageID, isActiveCallback }) => (
                <Grid item key={key}>
                    <Button
                        component={NavLink}
                        to={to}
                        variant="text"
                        color="secondary"
                        isActive={isActiveCallback}
                        startIcon={icon}
                    >
                        <FormattedMessage id={messageID} />
                    </Button>
                </Grid>
            ))}
        </React.Fragment>
    );
};
