import { Breadcrumbs, Link, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { NavLink, useLocation } from 'react-router-dom';
import { useStore } from '../../hooks';
import { EllipsisBox } from '../EllipsisBox';

export type BreadcrumbsSettings = {
    names: Record<string, string>;
    mainRoutes: Record<string, string>;
    routesWithParams: Record<string, (objectId: string) => string>;
};

export type BreadcrumbsComponentProps = {
    pageTitle?: ReactNode;
    objectId?: string;
    breadcrumbsSettings: BreadcrumbsSettings;
};

export const renderLink = (to: string, last: boolean, linkText: string): ReactNode => {
    return last ? (
        <Typography color="textPrimary" key={to}>
            <EllipsisBox text={linkText} correctLength={35} />
        </Typography>
    ) : (
        linkText && (
            <Link component={NavLink} underline="none" to={to} key={to}>
                <EllipsisBox text={linkText} correctLength={35} />
            </Link>
        )
    );
};

export const BreadcrumbsComponent = observer((props: BreadcrumbsComponentProps): JSX.Element => {
    const { objectId = 'non-id', pageTitle, breadcrumbsSettings } = props;
    const { mainRoutes, names, routesWithParams } = breadcrumbsSettings;
    const location = useLocation();
    const intl = useIntl();
    const { breadcrumbsStore } = useStore();
    const { mainPageRoute } = breadcrumbsStore;

    const renderLinks = (): ReactNode[] => {
        const locationArray = location.pathname.split('/').filter((x) => x);
        const lastIndex = locationArray.length - 1;
        return locationArray.map((value, index) => {
            const to = `/${locationArray.slice(0, index + 1).join('/')}`;
            const routeWithParams = Object.keys(routesWithParams).find((r) => routesWithParams[r](objectId) === to);
            const routeWithoutParams = Object.keys(mainRoutes).find((r) => mainRoutes[r] === to);

            if (index !== lastIndex) {
                // на последней итерации to=nextTo и может вести к роуту из mainRoutes поэтому этот случай пропускаем

                // для остальных (всех кроме последней) итераций возвращаем пустую строку в случае если
                // nextTo ведет к роуту из mainRoutes, так как nextTo должен вести к вложенным роутам
                const nextTo = `/${locationArray.slice(0, index + 2).join('/')}`;
                const nextRouteWithoutParams = Object.keys(mainRoutes).find((r) => mainRoutes[r] === nextTo);
                if (nextRouteWithoutParams) {
                    return '';
                }
            }

            if (routeWithoutParams && names[routeWithoutParams]) {
                const linkText = intl.formatMessage({ id: names[routeWithoutParams] });
                return renderLink(to, index === lastIndex, linkText);
            } else if (routeWithParams && names[routeWithParams]) {
                const linkText = intl.formatMessage({ id: names[routeWithParams] });
                return renderLink(to, index === lastIndex, linkText);
            } else if ((routeWithParams || routeWithoutParams) && pageTitle) {
                const linkText = pageTitle as string;
                return renderLink(to, index === lastIndex, linkText);
            } else {
                return '';
            }
        });
    };

    return (
        <Breadcrumbs separator="<">
            <Link component={NavLink} underline="none" to={mainPageRoute}>
                <FormattedMessage id="breadcrumbs.mainPage" />
            </Link>
            {renderLinks()}
        </Breadcrumbs>
    );
});
