import { Button, Table, TableBody, TableCell, TableRow, TextField, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useQuotaContext } from '../../../../hooks';
import { useQuotaCGTableStyles } from '../../quota-styles';
import { QuotaCountryRow } from './quota-country-row';

export type QuotaCountryRowObject = {
    id: string;
    component: JSX.Element;
};

export const QuotaCountryGroupTable = observer((): JSX.Element => {
    const classes = useQuotaCGTableStyles();

    const { quotasModel, isViewOnly } = useQuotaContext();

    const { quota } = quotasModel;
    const {
        isThereAnyCountryGroups,
        countryGroups,
        addNewCountryGroup,
        saveCountryGroups,
        countriesTableCommonVolume,
    } = quota;

    const commonValue = countryGroups.reduce((acc, curr) => acc + curr.volume, 0);

    useEffect(() => {
        quota.setCountriesTableCommonValue(commonValue);
    }, [commonValue]);

    const countryRows: JSX.Element[] = countryGroups.map((countryGroup) => {
        return <QuotaCountryRow key={countryGroup.id} quotaCountryGroup={countryGroup} />;
    });

    return (
        <Table>
            <TableBody className={isViewOnly ? classes.tableBodyViewOnly : classes.tableBody}>
                {isThereAnyCountryGroups && (
                    <TableRow className={classes.tableHead}>
                        <TableCell>Группа стран квотирования</TableCell>
                        <TableCell>Объём</TableCell>
                        <TableCell />
                    </TableRow>
                )}
                {countryRows}
                {!isViewOnly && (
                    <TableRow>
                        <TableCell className={classes.tableCellWithControls}>
                            <Button color="secondary" size="small" variant="contained" onClick={addNewCountryGroup}>
                                Добавить
                            </Button>
                            <Button
                                color="primary"
                                size="small"
                                variant="contained"
                                onClick={saveCountryGroups}
                                className={classes.saveButton}
                                disabled={!isThereAnyCountryGroups}
                            >
                                Сохранить
                            </Button>
                        </TableCell>
                    </TableRow>
                )}
                {isThereAnyCountryGroups && (
                    <TableRow className={classes.tableRow}>
                        <TableCell className={classes.sumCell}>
                            <Typography className={classes.sumText}>Сумма</Typography>
                        </TableCell>
                        <TableCell>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={countriesTableCommonVolume}
                                error={
                                    !!quota.touched.countriesTableCommonVolume &&
                                    !!quota.errors.countriesTableCommonVolume
                                }
                                disabled={!quota.errors.countriesTableCommonVolume}
                                helperText={quota.errors.countriesTableCommonVolume}
                            />
                        </TableCell>
                        <TableCell />
                    </TableRow>
                )}
            </TableBody>
        </Table>
    );
});
