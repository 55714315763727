import { Grid, Paper, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { useQuotaContext } from '../../../hooks';
import { useQuotaDistribTablesStyles } from '../quota-styles';
import { QuotaCountryGroupTable } from './quota-country-group-table';
import { QuotaVTDTable } from './quota-vtd-table';

export const QuotaDistributionTables = observer((): JSX.Element => {
    const classes = useQuotaDistribTablesStyles();
    const { quotasModel, isViewOnly } = useQuotaContext();
    const { quota } = quotasModel;
    const { isThereAnyCountryGroups, isThereAnyGroupsAndParticipants } = quota;

    const isCountryGroupTableShouldBeShown = isViewOnly ? isThereAnyCountryGroups : true;

    /** Если просматриваем и есть учатники ВТД */
    const isViewOnlyWithData = isViewOnly && isThereAnyGroupsAndParticipants;
    /** Если редактируем/создаем и есть распр. по странам */
    const isNotViewOnlyWithCountries = !isViewOnly && isThereAnyCountryGroups;
    /**
     * Таблица видна если:
     * 1) квота не типа "контрагента", и мы в режиме просмотра, и у нас есть данные
     * по ВТД + распределения по странам.
     * 2) квота не типа "контрагента", и мы создаем или редактируем, и у нас
     * есть распределения по странам.
     */
    const isVTDTableShouldBeShown = !quota.isCounterparty && (isViewOnlyWithData || isNotViewOnlyWithCountries);

    return (
        <React.Fragment>
            <Grid className={classes.countryGroupTableContainer}>
                {isCountryGroupTableShouldBeShown && (
                    <React.Fragment>
                        <Grid item>
                            <Typography className={classes.tableTitle}>
                                Распределение по группам стран квотирования
                            </Typography>
                        </Grid>
                        <Paper className={classes.paper}>
                            <QuotaCountryGroupTable />
                        </Paper>
                    </React.Fragment>
                )}
            </Grid>
            <Grid className={classes.VTDTableContainer}>
                {isVTDTableShouldBeShown && (
                    <React.Fragment>
                        <Grid item>
                            <Typography className={classes.tableTitle}>Распределение по участникам ВТД</Typography>
                        </Grid>
                        <Paper className={classes.paper}>
                            <QuotaVTDTable />
                        </Paper>
                    </React.Fragment>
                )}
            </Grid>
        </React.Fragment>
    );
});
