export default {
    startPageLinks: false,
    startPage: false,
    loginWithoutRegister: false,
    redirectToCampaignsAllUsers: false,
    logo: {
        isEnabled: false,
        locales: {
            ru: {},
        },
        style: { width: '100%', maxWidth: '450px' },
        withText: false,
        mdLogo: false,
    },
    customBackground: {
        isEnabled: true,
    },
    helpIcon: false,
    notificationIcon: true,
    changeLanguage: false,
    phoneNumber: true,
    startPageDefaultText: false,
    esiaLogin: false,
    showCreateRequestButton: true,
    showRequestsLamp: true,
    loginDialogClose: false,
    loginDialogAlert: false,
    yaMetrika: true,
    modularNavigation: false,
    stickyHeader: true,
    samlAuth: {
        isEnabled: true,
        providers: [
            {
                code: 'gisp',
                title: 'ГИСП',
            },
        ],
    },
};
