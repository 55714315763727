import { FormikErrors, FormikTouched, FormikValues } from 'formik';
import { action, computed, observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import {
    CodeTitleNull,
    QuotaCategoryCodes,
    QuotaCreateDTO,
    QuotaDTO,
    QuotaFields,
    QuotaParticipantDTO,
    QuotaType,
    QuotaTypesCodes,
    useFormikReturns,
} from '../../types';
import {
    createCustom1stJanuary,
    createCustom31stDecember,
    cutTimeFromISODate,
    decemberThirtyFirstString as december31stISOStringWithoutTime,
    today,
    tomorrow,
} from '../../utils';
import { CodeTitle } from '../CodeTitle';
import { PersonDTO } from '../person';
import { QuotaCountryGroup } from './QuotaCountryGroup';
import { QuotaParticipant } from './QuotaParticipant';
import { QuotaParticipantVolume } from './QuotaParticipantVolume';
import {
    yupQuotaSchema,
    yupQuotaSchemaForTender,
    yupQuotaSchemaWithBothTablesVolumes,
    yupQuotaSchemaWithCountriesVolume,
} from './yup';

const tomorrowISOStringWithoutTime = cutTimeFromISODate(tomorrow.toISOString());

export class Quota implements QuotaType {
    @observable title: string = '';
    @observable year?: Date;
    @observable periodStart: string = '';
    @observable periodEnd: string = '';
    @observable volume: number = 0;
    @observable validityPeriodStart: string = '';
    @observable validityPeriodEnd: string = '';
    @observable licensingPeriodStart: string = '';
    @observable licensingPeriodEnd: string = '';
    @observable created: string = '';
    @observable id: string = '';

    @observable category: CodeTitleNull = null;
    @observable productCategory: CodeTitleNull = null;
    @observable quotaType: CodeTitleNull = null;
    @observable units: CodeTitleNull = null;
    @observable operations: CodeTitle[] = [];
    @observable author: PersonDTO | null = null;

    @observable participants: QuotaParticipant[] = [];
    @observable countryGroups: QuotaCountryGroup[] = [];

    @observable countriesTableCommonVolume: number = 0;
    @observable vtdTableCommonVolume: number = 0;
    @observable volumesByCountryGroups: Record<string, QuotaParticipantVolume[]> = {};

    @observable formik?: useFormikReturns<Quota>;
    @observable errors: FormikErrors<FormikValues> = {};
    @observable touched: FormikTouched<FormikValues> = {};
    @observable isValid: boolean = false;

    constructor(quotaDTO?: QuotaDTO) {
        if (!quotaDTO) {
            return;
        }
        this.title = quotaDTO.title;
        this.category = quotaDTO.category;
        this.productCategory = quotaDTO.productCategory;
        this.quotaType = quotaDTO.quotaType;
        this.year = this.makeDateFromYear(quotaDTO.year);
        this.periodStart = quotaDTO.periodStart;
        this.periodEnd = quotaDTO.periodEnd;
        this.volume = quotaDTO.volume;
        this.units = quotaDTO.units;
        this.validityPeriodStart = quotaDTO.validityPeriodStart;
        this.validityPeriodEnd = quotaDTO.validityPeriodEnd;
        this.licensingPeriodStart = quotaDTO.licensingPeriodStart;
        this.licensingPeriodEnd = quotaDTO.licensingPeriodEnd;
        this.operations = quotaDTO.operations;
        this.created = quotaDTO.created;
        this.author = quotaDTO.author;
        this.id = quotaDTO.id;
        this.setCountryGroups(quotaDTO.countryGroups.map((group) => new QuotaCountryGroup({ ...group, id: uuidv4() })));
        this.setParticipants(quotaDTO.participants);
        this.onInit();
    }

    @action.bound
    private async onInit(): Promise<void> {
        await this.validateAllCountryGroups();
        this.updateParticipantsVolumes();
        this.updateParticipantsRowCommonVolumes();
        this.setVolumesByCountryGroupsDict();
    }

    // Getters
    // ----------------------------------------------------------
    @computed
    get isTender(): boolean {
        return this.category?.code === QuotaCategoryCodes.tender;
    }

    @computed
    get isCounterparty(): boolean {
        return this.category?.code === QuotaCategoryCodes.counterparty;
    }

    @computed
    get isDistributed(): boolean {
        return this.category?.code === QuotaCategoryCodes.distributed;
    }

    @computed
    get isQuotaTypeYear(): boolean {
        return this.quotaType?.code === QuotaTypesCodes.year;
    }

    @computed
    get isSomeCountryGroupsValid(): boolean {
        return this.countryGroups.some((group) => group.isValid);
    }

    @computed
    get isThereAnyCountryGroups(): boolean {
        return !!this.countryGroups.length;
    }

    @computed
    get isThereAnyParticipants(): boolean {
        return !!this.participants.length;
    }

    @computed
    get isThereAnyGroupsAndParticipants(): boolean {
        return !!this.countryGroups.length && !!this.participants.length;
    }

    @action.bound
    getQuotaCreateDTO(): QuotaCreateDTO {
        const year = this.makeYearFromDate(this.year);
        return {
            title: this.title,
            categoryCode: this.category?.code || '',
            productCategoryCode: this.productCategory?.code || '',
            quotaTypeCode: this.quotaType?.code || '',
            year: year,
            periodStart: this.isQuotaTypeYear
                ? cutTimeFromISODate(createCustom1stJanuary(year!).toISOString())
                : cutTimeFromISODate(this.periodStart),
            periodEnd: this.isQuotaTypeYear
                ? cutTimeFromISODate(createCustom31stDecember(year!).toISOString())
                : cutTimeFromISODate(this.periodEnd),
            volume: this.volume,
            unitsCode: this.units?.code || '',
            validityPeriodStart: cutTimeFromISODate(this.validityPeriodStart),
            validityPeriodEnd: cutTimeFromISODate(this.validityPeriodEnd),
            licensingPeriodStart: cutTimeFromISODate(this.licensingPeriodStart),
            licensingPeriodEnd: cutTimeFromISODate(this.licensingPeriodEnd),
            operations: this.operations.map((op) => op.code),
            participants:
                this.isCounterparty || !this.isThereAnyCountryGroups
                    ? []
                    : this.participants.map((participant) => participant.getDTO()),
            countryGroups: this.countryGroups.map((group) => group.getDTO()),
        };
    }
    // ----------------------------------------------------------

    // Updaters
    // ----------------------------------------------------------

    /**
     * Обновляет значения самой правой клеточки в ряде таблицы
     * распределения по участникам ВТД.
     * Считает volumes в participant
     */
    @action.bound
    updateParticipantsRowCommonVolumes(): void {
        this.participants.forEach((participant) => participant.updateCommonRowVolume());
    }

    @action.bound
    addNewParticipant(): void {
        const id = uuidv4();
        this.participants = [...this.participants, new QuotaParticipant({ id, countryGroups: this.countryGroups })];
        this.updateParticipantsVolumes();
    }

    @action.bound
    deleteParticipant(id: string): void {
        this.participants = this.participants.filter((participant) => participant.id !== id);
        this.updateParticipantsVolumes();
    }

    @action.bound
    addNewCountryGroup(): void {
        const id = uuidv4();
        this.setCountryGroups([...this.countryGroups, new QuotaCountryGroup({ id })]);
    }

    @action.bound
    deleteCountryGroup(id: string): void {
        this.setCountryGroups(this.countryGroups.filter((group) => group.id !== id));
        this.updateParticipantsVolumes();
        this.updateParticipantsRowCommonVolumes();
    }

    /**
     * Обновляет объемы распределения по участникам ВТД
     * Связывает каждый participant.volumes[n] с соответсв. группой по айди.
     * */
    @action.bound
    updateParticipantsVolumes(): void {
        // Перебираем каждого участника
        this.participants.map(
            (participant) =>
                // Сеттим в объемы значения по группам стран
                (participant.volumes = this.countryGroups
                    // Фильтруем неправильные группы
                    .filter((group) => group.isValid)
                    .map((group) => {
                        // Находим существующую
                        const existingVolume = participant.volumes.find(
                            (volume) => group.countryGroup?.code === volume.countryGroupId,
                        );
                        return (
                            // Если есть такой объем, то он правильный, ведь неправильные мы отсеяли.
                            // Если нет - создай новый с этой группой.
                            existingVolume || new QuotaParticipantVolume(0, uuidv4(), group.countryGroup?.code)
                        );
                    })),
        );
    }

    // ----------------------------------------------------------

    // Formik & Validations
    // ----------------------------------------------------------
    @action.bound
    setFormik(formik: any): void {
        this.formik = formik;
        this.touched = formik.touched;
    }

    @action.bound
    setFieldUntouched(key: QuotaFields): void {
        this.touched[key] = undefined;
    }

    @action.bound
    async validate(): Promise<boolean> {
        const errors = await this.formik?.validateForm(this);
        this.errors = errors || {};
        this.touched = this.errors as FormikTouched<Quota>;
        this.isValid = !Object.keys(this.errors).length;
        return this.isValid;
    }

    @action.bound
    async validateAllCountryGroups(): Promise<boolean> {
        const validityStatuses: boolean[] = await Promise.all(
            this.countryGroups.map(async (group) => await group.validate()),
        );
        if (validityStatuses.length < 1) {
            return !!validityStatuses.length;
        }
        const somethingIsWrong = validityStatuses.some((status) => status === false);
        this.updateParticipantsVolumes();
        return !somethingIsWrong;
    }

    @action.bound
    async validateAllParticipants(): Promise<boolean> {
        const validityStatuses: boolean[] = await Promise.all(
            this.participants.map(async (participant) => await participant.validate()),
        );
        if (validityStatuses.length < 1) {
            return !!validityStatuses.length;
        }
        const somethingIsWrong = validityStatuses.some((status) => status === false);
        return !somethingIsWrong;
    }

    @action.bound
    chooseValidationSchema(): Yup.AnyObjectSchema {
        let validationSchema = yupQuotaSchema;

        if (this.isDistributed) {
            if (this.isThereAnyCountryGroups) {
                validationSchema = yupQuotaSchemaWithCountriesVolume;
            }
            if (this.isThereAnyGroupsAndParticipants) {
                validationSchema = yupQuotaSchemaWithBothTablesVolumes;
            }
        }

        if (this.isTender) {
            if (this.isThereAnyCountryGroups) {
                validationSchema = yupQuotaSchemaWithCountriesVolume;
            }
            if (this.isThereAnyGroupsAndParticipants) {
                validationSchema = yupQuotaSchemaForTender;
            }
        }

        if (this.isCounterparty) {
            if (this.isThereAnyCountryGroups) {
                validationSchema = yupQuotaSchemaWithCountriesVolume;
            }
            if (!this.isThereAnyCountryGroups) {
                validationSchema = yupQuotaSchema;
            }
        }

        return validationSchema;
    }
    // ----------------------------------------------------------

    // Utils
    // ----------------------------------------------------------
    @action.bound
    private makeDateFromYear(year?: number): Date {
        return year ? createCustom1stJanuary(year) : today;
    }

    @action.bound
    private makeYearFromDate(date?: Date): number | undefined {
        return date ? date.getFullYear() : undefined;
    }

    @action.bound
    async saveCountryGroups(): Promise<void> {
        await this.validate();
        await this.validateAllCountryGroups();
        this.setVolumesByCountryGroupsDict();
    }
    // ----------------------------------------------------------

    // Setters
    // ----------------------------------------------------------
    @action.bound
    setDefaultDates(): void {
        this.created = new Date().toISOString();
        this.periodStart = tomorrowISOStringWithoutTime;
        this.periodEnd = december31stISOStringWithoutTime;
        this.validityPeriodStart = tomorrowISOStringWithoutTime;
        this.validityPeriodEnd = december31stISOStringWithoutTime;
        this.licensingPeriodStart = tomorrowISOStringWithoutTime;
        this.licensingPeriodEnd = december31stISOStringWithoutTime;
        this.year = this.makeDateFromYear();
    }

    @action.bound
    setVolumesByCountryGroupsDict(): void {
        // Словарь, в котором собираются объемы по каждой группе стран
        let volumesByGroups: Record<string, QuotaParticipantVolume[]> = {};

        // Перебираем каждую группу стран
        this.countryGroups.forEach((group) => {
            // На каждой группе стран перебираем участников
            this.participants.forEach((participant) => {
                // В кажом участнике смотрим объем распределения
                participant.volumes.forEach((volumeInstance) => {
                    // Если находим по id группы такой объект с объемом
                    if (group.countryGroup?.code === volumeInstance.countryGroupId) {
                        // Если ещё в нашем словаре нет поля с таким id
                        if (!volumesByGroups[group.id]) {
                            // Создать поле с таким id
                            volumesByGroups[group.id] = [];
                        }

                        // Удалить дубликат
                        volumesByGroups[group.id] = volumesByGroups[group.id].filter(
                            (volume) => volume.id !== volumeInstance.id,
                        );

                        // Добавить в массив значений такого поля новый соответствующий
                        // условиям объект с объемом
                        volumesByGroups[group.id].push(volumeInstance);
                    }
                });
            });
        });

        this.volumesByCountryGroups = volumesByGroups;
    }

    @action.bound
    setCountryGroups(countryGroups: QuotaCountryGroup[]): void {
        this.countryGroups = countryGroups;
        this.setFieldUntouched(QuotaFields.countryGroups);
    }

    @action.bound
    setParticipants(participants: QuotaParticipantDTO[]): void {
        this.participants = participants.map(
            (participant) => new QuotaParticipant({ ...participant, id: uuidv4(), countryGroups: this.countryGroups }),
        );
        this.setFieldUntouched(QuotaFields.participants);
    }

    @action.bound
    setCountriesTableCommonValue(value: number): void {
        this.countriesTableCommonVolume = value;
        this.setFieldUntouched(QuotaFields.countriesTableCommonVolume);
    }

    @action.bound
    setVTDTableCommonVolume(value: number): void {
        this.vtdTableCommonVolume = value;
        this.setFieldUntouched(QuotaFields.vtdTableCommonVolume);
    }

    @action.bound
    setTitle(value: string): void {
        this.title = value;
        this.setFieldUntouched(QuotaFields.title);
    }

    @action.bound
    setYear(value: Date): void {
        this.year = value;
        this.setFieldUntouched(QuotaFields.year);
    }

    @action.bound
    setPeriodStart(value: string): void {
        this.periodStart = value;
        this.setFieldUntouched(QuotaFields.periodStart);
    }

    @action.bound
    setPeriodEnd(value: string): void {
        this.periodEnd = value;
        this.setFieldUntouched(QuotaFields.periodEnd);
    }

    @action.bound
    setVolume(value: number): void {
        this.volume = value;
        this.setFieldUntouched(QuotaFields.volume);
        this.setFieldUntouched(QuotaFields.countriesTableCommonVolume);
    }

    @action.bound
    setValidityPeriodStart(value: string): void {
        this.validityPeriodStart = value;
        this.setFieldUntouched(QuotaFields.validityPeriodStart);
    }

    @action.bound
    setValidityPeriodEnd(value: string): void {
        this.validityPeriodEnd = value;
        this.setFieldUntouched(QuotaFields.validityPeriodEnd);
    }

    @action.bound
    setLicensingPeriodStart(value: string): void {
        this.licensingPeriodStart = value;
        this.setFieldUntouched(QuotaFields.licensingPeriodStart);
    }

    @action.bound
    setLicensingPeriodEnd(value: string): void {
        this.licensingPeriodEnd = value;
        this.setFieldUntouched(QuotaFields.licensingPeriodEnd);
    }

    @action.bound
    setCreated(value: string): void {
        this.created = value;
    }

    @action.bound
    setId(value: string): void {
        this.id = value;
    }

    @action.bound
    setCategory(value: CodeTitleNull): void {
        this.category = value;
        this.setFieldUntouched(QuotaFields.category);
    }

    @action.bound
    setProductCategory(value: CodeTitleNull): void {
        this.productCategory = value;
        this.setFieldUntouched(QuotaFields.productCategory);
    }

    @action.bound
    setQuotaType(value: CodeTitleNull): void {
        this.quotaType = value;
        this.setFieldUntouched(QuotaFields.quotaType);
    }

    @action.bound
    setUnits(value: CodeTitleNull): void {
        this.units = value;
        this.setFieldUntouched(QuotaFields.units);
    }

    @action.bound
    setOperations(value: CodeTitle[]): void {
        this.operations = value;
        this.setFieldUntouched(QuotaFields.operations);
    }
    // ------------------------------------------------
}
