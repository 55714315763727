import React from 'react';
import { withConfirmDialog } from '../../../hocs';
import { IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons';

export const CampaignSettingsDeleteButton = withConfirmDialog(({ openConfirmDialog }) => (
    <IconButton size="small" onClick={openConfirmDialog}>
        <Delete />
    </IconButton>
));
