import { CodeTitle, PersonDTO } from '../models';
import { CodeTitleNull } from './withTitle';

export enum QuotaFields {
    title = 'title',
    categoryCode = 'categoryCode',
    productCategoryCode = 'productCategoryCode',
    quotaTypeCode = 'quotaTypeCode',
    year = 'year',
    periodStart = 'periodStart',
    periodEnd = 'periodEnd',
    volume = 'volume',
    unitsCode = 'unitsCode',
    validityPeriodStart = 'validityPeriodStart',
    validityPeriodEnd = 'validityPeriodEnd',
    licensingPeriodStart = 'licensingPeriodStart',
    licensingPeriodEnd = 'licensingPeriodEnd',
    operations = 'operations',
    countryGroups = 'countryGroups',
    participants = 'participants',
    category = 'category',
    productCategory = 'productCategory',
    quotaType = 'quotaType',
    units = 'units',
    countriesTableCommonVolume = 'countriesTableCommonVolume',
    vtdTableCommonVolume = 'vtdTableCommonVolume',
    volumesByCountryGroups = 'volumesByCountryGroups',
    author = 'author',
}

export enum QuotaCountryGroupFields {
    name = 'name',
    countryGroup = 'countryGroup',
    volume = 'volume',
    columnSum = 'columnSum',
}

export enum QuotaParticipantFields {
    inn = 'inn',
    name = 'name',
    volumes = 'volumes',
}

export enum QuotaCategoryCodes {
    tender = 'tender',
    counterparty = 'counterparty',
    distributed = 'distributed',
}

export enum QuotaTypesCodes {
    year = 'year',
    period = 'period',
}

export enum QuotaOperationCodes {
    import = 'импорт',
    export = 'экспорт',
}

export type QuotaCreateCountryGroup = {
    id: string;
    volume: number;
    countryGroupId?: string;
};

export type QuotaCountryGroupDTO = {
    volume: number;
    countryGroup?: CodeTitle;
};

export type QuotaParticipantVolumeDTO = {
    countryGroupId?: string;
    volume: number;
};

export type QuotaParticipantDTO = {
    inn: string;
    name: string;
    volumes: QuotaParticipantVolumeDTO[];
};

export type QuotaCreateDTO = {
    title: string;
    categoryCode: string;
    productCategoryCode: string;
    quotaTypeCode: string;
    year?: number;
    periodStart?: string;
    periodEnd?: string;
    volume: number;
    unitsCode: string;
    validityPeriodStart: string;
    validityPeriodEnd: string;
    licensingPeriodStart: string;
    licensingPeriodEnd: string;
    operations: string[];
    countryGroups: QuotaCreateCountryGroup[];
    participants: QuotaParticipantDTO[];
};

export type QuotaDTO = {
    id: string;
    title: string;
    category: CodeTitle;
    productCategory: CodeTitle;
    quotaType: CodeTitle;
    year?: number;
    periodStart: string;
    periodEnd: string;
    volume: number;
    units: CodeTitle;
    validityPeriodStart: string;
    validityPeriodEnd: string;
    licensingPeriodStart: string;
    licensingPeriodEnd: string;
    author: PersonDTO;
    created: string;
    modified?: string;
    operations: CodeTitle[];
    countryGroups: QuotaCountryGroupDTO[];
    participants: QuotaParticipantDTO[];
};

export type QuotaType = Omit<QuotaDTO, 'author' | 'category' | 'productCategory' | 'quotaType' | 'units' | 'year'> & {
    category: CodeTitleNull;
    productCategory: CodeTitleNull;
    quotaType: CodeTitleNull;
    units: CodeTitleNull;
    author: PersonDTO | null;
    year?: string | number | Date;
};

export type QuotaRequestDTO = {
    id: string;
    number: string;
};

export type QuotaTableRowDTO = {
    customData: QuotaCreateDTO & {
        quotaType: CodeTitle;
    };
    id: string;
    lang: string;
};
