import { TextFieldProps } from '@material-ui/core/TextField/TextField';
import React from 'react';
import { CheckboxProps } from '@material-ui/core';
import { ReactComponent as CheckboxDefault } from '../../../../resources/images/icons/checkbox-default.svg';
import { ReactComponent as CheckboxChecked } from '../../../../resources/images/icons/checkbox-checked.svg';

export const textFieldProps: TextFieldProps = {
    variant: 'outlined',
    fullWidth: true,
    InputLabelProps: {
        shrink: true,
    },
    size: 'small',
};

export const autocompleteProps = {
    disableClearable: true,
    freeSolo: false,
    fullWidth: true,
};

export const checkboxProps: CheckboxProps = {
    icon: <CheckboxDefault />,
    checkedIcon: <CheckboxChecked />,
    color: 'primary',
};
