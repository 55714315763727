import { TableCell, TextField } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useQuotaContext } from '../../../../../hooks';
import { QuotaCountryGroup, QuotaGroupColumnSumValidation } from '../../../../../models';

export type QuotaVTDColumnSumProps = {
    group: QuotaCountryGroup;
};

/** Клетка суммы колонки таблицы ВТД */
export const QuotaVTDColumnSum = observer((props: QuotaVTDColumnSumProps): JSX.Element => {
    const { group } = props;
    const { quotasModel } = useQuotaContext();
    const { quota } = quotasModel;
    const { volumesByCountryGroups } = quota;

    /** Считаем сумму колонки, получая поля по имени группы и суммируя их объемы. */
    const columnSum = volumesByCountryGroups[group.id]?.reduce((acc, curr) => acc + curr.volume, 0) || 0;

    useEffect(() => {
        /** Обновляем сумму колонки */
        group.setColumnSum(columnSum);
    }, [columnSum]);

    const validation: QuotaGroupColumnSumValidation = group.validateColumnSum(quota);

    return (
        <TableCell>
            <TextField
                variant="outlined"
                fullWidth
                value={group.columnSum}
                disabled={validation.valid}
                error={!validation.valid}
                helperText={validation.message}
            />
        </TableCell>
    );
});
