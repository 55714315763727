import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { clientRoute } from '../clientRoute';

const loginUrl = process.env.REACT_APP_GISP_LOGIN_URL || clientRoute.login;

export const LoginRedirect = observer((): JSX.Element => {
    useEffect(() => {
        window.location.replace(loginUrl);
    }, []);
    return <React.Fragment />;
});
