import { AxiosResponse } from 'axios';
import { action, computed, observable } from 'mobx';
import { asyncAction } from 'mobx-utils';
import { apiConfigs } from '../apiConfigs';
import { clientRoute } from '../clientRoute';
import { PersonDTO, PersonModel, UserModel } from '../models';
import { ApiStore } from './ApiStore';
import { IntlStore } from './IntlStore';
import { RootStore } from './RootStore';

interface PersonInfo {
    user: UserModel;
    person: PersonDTO;
    roles: string[];
}

export class PersonStore {
    @observable rootStore: RootStore;
    @observable intlStore: IntlStore;
    @observable api: ApiStore;

    @observable user: UserModel = new UserModel();
    @observable person: PersonModel = new PersonModel();
    @observable roles: string[] = [];

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.intlStore = rootStore.intlStore;
        this.api = rootStore.api;
    }

    @asyncAction
    @action.bound
    async getInfo(): Promise<void> {
        const response: AxiosResponse<PersonInfo> = await this.api.client(apiConfigs.person(this.intlStore.locale));
        const info: PersonInfo = response.data;
        this.user = info.user;
        this.person.load(info.person);
        this.roles = info.roles;
    }

    @action.bound
    clearPerson() {
        this.user = new UserModel();
        this.person = new PersonModel();
        this.roles = [];
    }

    @computed
    get redirectPath(): string {
        return clientRoute.mainPage;
    }
}
