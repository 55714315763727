import { AppBar, Box, Container, createStyles, Grid, Link, makeStyles, useMediaQuery } from '@material-ui/core';
import { ModularNavigationWidget } from '@platform/modular-navigation-widget';
import { useFeature } from 'feature-toggle-jsx';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { Link as RouterLink, Route, Switch } from 'react-router-dom';
import { RenderPropSticky } from 'react-sticky-el';
import { clientRoute } from '../../clientRoute';
import { useStore } from '../../hooks';
import { StartPageHeaderLinks } from '../../pages';
import { AppTheme } from '../../themes';
import { AppHeaderLinks } from './app-header-links';
import { GispHeader } from './GispHeader';
import { LanguageButton } from './LanguageButton';
import { LogoConfig, LogoImg } from './LogoImg';

type RenderPropStickyChildFunctionProps = {
    // в библиотеке нет типизации для компоненты RenderPropSticky
    isFixed: boolean;
    wrapperStyles: React.CSSProperties;
    wrapperRef: React.RefObject<HTMLDivElement>;
    holderStyles: React.CSSProperties;
    holderRef: React.RefObject<HTMLDivElement>;
};

const useTotAppBarStyles = makeStyles(() =>
    createStyles({
        wrapper: {
            position: 'relative',
            zIndex: 1000,
        },
    }),
);

export const TotAppBar = observer((): JSX.Element => {
    const { intlStore, modulesStore, personStore, headerHeightStore } = useStore();
    const { loadModulesList } = modulesStore;
    const userId = personStore.user.id;

    const [modularNavigation] = useFeature('modularNavigation');
    const [stickyHeader] = useFeature('stickyHeader');
    const [startPage] = useFeature('startPage');
    const [logo, logoConfig] = useFeature('logo');
    const [changeLanguage] = useFeature('changeLanguage');

    const { setHeaderHeight } = headerHeightStore;

    const classes = useTotAppBarStyles();

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const isSmSize = useMediaQuery((theme: AppTheme) => theme.breakpoints.down('sm'));

    const headerObserverRef = useRef(
        new ResizeObserver((entries) => {
            const headerEntry = entries[0];
            if (headerEntry) {
                setHeaderHeight(headerEntry.contentRect.height);
            }
        }),
    );
    const headerContentWrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (headerContentWrapperRef.current) {
            headerObserverRef.current.observe(headerContentWrapperRef.current);
        }
        return (): void => {
            headerObserverRef.current.disconnect();
        };
    }, [headerContentWrapperRef]);

    const handleBurgerChange = (): void => {
        setIsMenuOpen((isOpenMenu) => !isOpenMenu);
    };

    return (
        <RenderPropSticky scrollElement="#root" disabled={!stickyHeader}>
            {({
                wrapperStyles,
                wrapperRef,
                holderStyles,
                holderRef,
            }: RenderPropStickyChildFunctionProps): JSX.Element => (
                <div ref={holderRef} style={holderStyles}>
                    <div ref={wrapperRef} className={classes.wrapper} style={{ ...wrapperStyles }}>
                        <div ref={headerContentWrapperRef}>
                            <GispHeader />
                            <AppBar color="default" position="static" elevation={0}>
                                <Container maxWidth="lg">
                                    <Box pt={3.75} pb={3.75}>
                                        <Grid
                                            container
                                            direction="row"
                                            alignItems="flex-start"
                                            justify="space-between"
                                            wrap="nowrap"
                                        >
                                            <Grid item style={{ flexShrink: 0 }}>
                                                <Grid container direction="row" alignItems="center">
                                                    <Grid item>
                                                        <Link
                                                            component={RouterLink}
                                                            to={clientRoute.root}
                                                            underline="none"
                                                        >
                                                            <Grid container direction="row" alignItems="center">
                                                                <LogoImg logoConfig={logoConfig as LogoConfig} />
                                                            </Grid>
                                                        </Link>
                                                    </Grid>
                                                    {modularNavigation && userId && (
                                                        <Grid item>
                                                            <ModularNavigationWidget
                                                                key={userId}
                                                                loadModulesList={loadModulesList}
                                                                locale={intlStore.locale}
                                                            />
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid item style={{ flexGrow: 1 }}>
                                                <Box mr={3} ml={3} pt={2.5}>
                                                    <Switch>
                                                        <Route exact path={[clientRoute.root, clientRoute.login]}>
                                                            {startPage && <StartPageHeaderLinks />}
                                                        </Route>
                                                        <Route
                                                            path={[
                                                                clientRoute.notAllowed,
                                                                clientRoute.notFound,
                                                                clientRoute.recoveryPassword,
                                                                clientRoute.registration,
                                                                clientRoute.authenticationInfo,
                                                            ]}
                                                        />
                                                        <Route>
                                                            <AppHeaderLinks
                                                                isSmSize={isSmSize}
                                                                isOpenMenu={isMenuOpen}
                                                                handleBurgerChange={handleBurgerChange}
                                                            />
                                                        </Route>
                                                    </Switch>
                                                </Box>
                                            </Grid>
                                            <Grid item>
                                                <Box pt={3}>
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justify="center"
                                                        alignItems="center"
                                                        spacing={3}
                                                        wrap="nowrap"
                                                    >
                                                        {changeLanguage && (
                                                            <Grid item>
                                                                <LanguageButton />
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Container>
                            </AppBar>
                        </div>
                    </div>
                </div>
            )}
        </RenderPropSticky>
    );
});
