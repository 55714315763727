import { CircularProgress } from '@material-ui/core';
import { DateTimePickerProps } from '@material-ui/pickers';
import { CodeTitle } from '@platform/ttable';
import React from 'react';
import { ReactComponent as Calendar } from '../../../resources/images/icons/calendar.svg';
import { ReactComponent as ChevronDown } from '../../../resources/images/icons/chevron-down.svg';

export const extractOptionLabel = (option: CodeTitle | null): string => {
    return option?.title || "";
};

export const dateTimePickersInputProps: DateTimePickerProps['InputProps'] = {
    endAdornment: <Calendar style={{ pointerEvents: 'none' }} />,
};

export const returnLoaderOrIcon = (icon: boolean): JSX.Element => {
    return icon ? <ChevronDown /> : <CircularProgress color="inherit" size={20} />;
};
