import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';
import { Authentication } from '../../components';
import { RegFormSelectPage } from '../campaign-request';
import { CreateCampaignPage } from './campaign-create';
import { CampaignListPage } from './campaign-list-page';
import { CampaignPage } from './campaign-page';
import { CampaignSettingsPage } from './campaign-settings';

export const CampaignPages = (): JSX.Element => {
    return (
        <Authentication>
            <Switch>
                <Route path={clientRoute.regFormSelect}>
                    <RegFormSelectPage />
                </Route>
                <Route path={clientRoute.campaignSettings}>
                    <CampaignSettingsPage />
                </Route>
                <Route exact path={clientRoute.campaign}>
                    <CampaignPage />
                </Route>
                <Route exact path={clientRoute.createCampaign}>
                    <CreateCampaignPage />
                </Route>
                <Route exact route={clientRoute.campaigns}>
                    <CampaignListPage />
                </Route>
            </Switch>
        </Authentication>
    );
};
