import React from 'react';
import { Button } from '@material-ui/core';
import { useStore } from '../../../../hooks';

export type CountryGroupsEditButtonProps = {
    onEdit: () => void;
};

export const CountryGroupsEditButton = (props: CountryGroupsEditButtonProps): JSX.Element => {
    const { onEdit } = props;
    const { intlStore: intl } = useStore();
    const openEditMode = intl.formatMessage('campaignRequest.openEditMode');

    return (
        <Button onClick={onEdit} color="primary" size="small" variant="contained">
            {openEditMode}
        </Button>
    );
};
