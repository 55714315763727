import { Tab, TabsActions, withTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ApprovalTabLabel } from '@platform/approval-ui';
import { observer } from 'mobx-react';
import React, { CSSProperties } from 'react';
import { generatePath, NavLink, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { clientRoute } from '../../../../clientRoute';
import { OuterTabsProps, RoutedTabs, RouteSetting } from '../../../../components';
import { useStore } from '../../../../hooks';
import { useObjectRoutesContext } from '../../../../hooks';
import { RequestModel } from '../../../../models';
import { transitionDefault } from '../../../../themes';
import { campaignRequestTabPadding, getActiveCampaignRequestItemStyles } from './campaignRequestStyles';
import { CampaignRequestTabLabel } from './CampaignRequestTabLabel';

export const CampaignRequestStyledTab = withTheme(styled(Tab)`
    background-color: ${({ theme }) => `${theme.variables.palette.tab.backgroundColor} !important`};
    color: ${({ theme }) => `${theme.variables.palette.tab.color} !important`};
    margin-right: 12px !important;
    min-height: 29px !important;
    border-radius: 4px !important;
    font-size: 11px !important;
    line-height: 13px;
    font-weight: 700 !important;
    opacity: 1 !important;
    padding: 0;
    min-width: auto;

    &.MuiTab-textColorInherit.Mui-selected [class*='MuiGrid-item']:last-child {
        border-color: ${({ theme }) => theme.variables.palette.tab.hoverColor};
    }

    & > .MuiTab-wrapper {
        display: block;

        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
        & > svg {
            margin-bottom: 0 !important;
            margin-right: 10px !important;
        }
    }
`);

export const useTabStyles = makeStyles((theme) => {
    const activeItemStyles: CSSProperties = getActiveCampaignRequestItemStyles(theme);
    return {
        scroller: {
            display: 'flex',
            alignItems: 'center',
        },
        commonTab: {
            maxWidth: 'none',
            '&.MuiTab-textColorInherit.Mui-selected': {
                '& [class*="MuiGrid-item"], [class*="MuiGrid-item"] [class*="MuiButton"]': activeItemStyles,
            },
        },
        approvalTab: {
            padding: campaignRequestTabPadding,
            transition: transitionDefault,

            '&:hover, &.MuiTab-textColorInherit.active': activeItemStyles,
        },
    };
});

export const tabsProps: OuterTabsProps = {
    TabIndicatorProps: {
        style: { display: 'none' },
    },
    variant: 'scrollable',
    scrollButtons: 'auto',
};

export type CampaignRequestTabsProps = {
    model: RequestModel;
    tabsActions: React.Ref<TabsActions>;
};

export const CampaignRequestTabs = observer((props: CampaignRequestTabsProps): JSX.Element => {
    const { model, tabsActions } = props;
    const { id } = model;

    const classes = useTabStyles();
    const objectRoutes = useObjectRoutesContext();

    const settings: RouteSetting[] = model.formsTabs.map((tab, index) => {
        const tabId = tab.id;

        return {
            tab: index,
            path: generatePath(objectRoutes.objectTab, { id, tabId }),
        };
    });

    const settingsLength = settings.length;
    const approvalListPath = generatePath(objectRoutes.objectApprovalSheets, { id });

    const { approvalStore, intlStore } = useStore();
    const { hasApprovalTab } = approvalStore;
    if (hasApprovalTab) {
        settings.push({
            tab: settingsLength,
            path: approvalListPath,
        });
    }
    const approvalTabLabel = <ApprovalTabLabel locale={intlStore.locale} />;

    return (
        <RoutedTabs customTabsActions={tabsActions} tabsProps={{ ...tabsProps, classes }} settings={settings}>
            {model.formsTabs.map((formInfo, index) => {
                const tabId = formInfo.id;
                const formTitle = formInfo.title;

                const label = (
                    <CampaignRequestTabLabel tabId={tabId} title={formInfo.title} subTabs={formInfo.subTabs || []} />
                );

                return (
                    <CampaignRequestStyledTab
                        key={tabId}
                        value={index}
                        title={formTitle}
                        label={label}
                        component={NavLink}
                        className={classes.commonTab}
                        to={generatePath(objectRoutes.objectTab, { id, tabId })}
                        disableRipple={true}
                        disableFocusRipple={true}
                    />
                );
            })}
            {hasApprovalTab && (
                <CampaignRequestStyledTab
                    key="approvalTab"
                    value={settingsLength}
                    label={approvalTabLabel}
                    component={NavLink}
                    className={classes.approvalTab}
                    to={approvalListPath}
                    disableRipple={true}
                    disableFocusRipple={true}
                />
            )}
        </RoutedTabs>
    );
});
