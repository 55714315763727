// bootstrap panel используется в formio
export default class BootstrapPanelUtils {
    // Panel содержит 2 дочерних элемента:
    // 1 - шапка (заголовок), 2 - тело
    // Данная ф-я раскрывает panel, если она закрыта (тело скрыто)
    static openPanelById(id: string): void {
        const parentPanelId = document.querySelector(`#${id}`)?.parentElement?.parentElement?.getAttribute('id');
        if (parentPanelId) {
            const parentPanelBody = document.querySelector(`#${parentPanelId} > *:nth-child(2)`);
            if (parentPanelBody && parentPanelBody.getAttribute('hidden')) {
                this.openPanelById(parentPanelId);
            }
        }
        const panelBody = document.querySelector(`#${id} > *:nth-child(2)`);
        if (panelBody && panelBody.getAttribute('hidden')) {
            const panelTitle: HTMLElement | null = document.querySelector(`#${id} > *:nth-child(1)`);
            if (panelTitle && typeof panelTitle.click === 'function') {
                panelTitle.click();
            }
        }
    }
}
