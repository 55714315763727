import { action, observable } from 'mobx';
import { NotificationListModel } from '../models';
import { ResponseErrorDTO } from '../types';

export type SeverityType = 'error' | 'warning' | 'info' | 'success';

export const severityVariants: Record<string, SeverityType> = {
    success: 'success',
    info: 'info',
    warning: 'warning',
    error: 'error',
};

export class NotificationStore {
    @observable model: NotificationListModel = new NotificationListModel();

    @action.bound
    onSuccess(text: string): void {
        this.model.pushNotification(text, severityVariants.success);
    }

    @action.bound
    onError(text: ResponseErrorDTO | string): void {
        const errorText = text ? (typeof text === 'object' ? text.message : text.toString()) : 'Что-то пошло не так';
        this.model.pushNotification(errorText, severityVariants.error);
    }

    @action.bound
    onInfo(text: string): void {
        this.model.pushNotification(text, severityVariants.info);
    }

    @action.bound
    onWarning(text: string): void {
        this.model.pushNotification(text, severityVariants.warning);
    }
}
