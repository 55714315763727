import { Grid, Tab } from '@material-ui/core';
import { FormApi, FormModel } from '@platform/formiojs-react';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, NavLink, Route, Switch, useParams } from 'react-router-dom';
import { clientRoute } from '../../../../../clientRoute';
import { RoutedTabs } from '../../../../../components';
import { CampaignRegFormRouteParams } from '../types';
import { CampaignPfTemplateTab } from './CampaignPfTemplateTab';
import { CampaignRegFormCard } from './CampaignRegFormCard';
import { RequestFormTabsSettings } from './request-form-tabs-settings';

export type CampaignRegFormTabsProps = {
    model: FormModel;
    onFormReady: (form: FormApi) => void;
    onDeleteLinkClick: () => void;
};

export const CampaignRegFormTabs = observer((props: CampaignRegFormTabsProps): JSX.Element => {
    const { model, onFormReady, onDeleteLinkClick } = props;

    const { id, rfId } = useParams<CampaignRegFormRouteParams>();

    const campaignRegFormTabs = [
        {
            title: <FormattedMessage id="campaign.regFormInfo" />,
            path: clientRoute.campaignRegForm,
        },
        {
            title: <FormattedMessage id="campaign.tabsSettings" />,
            path: clientRoute.campaignRegFormTabsSettings,
        },
        {
            title: <FormattedMessage id="campaign.pfTemplates" />,
            path: clientRoute.campaignRegFormTemplates,
        },
    ];

    return (
        <React.Fragment>
            <Grid container direction="column" spacing={5}>
                <Grid item>
                    <RoutedTabs
                        tabsProps={{ indicatorColor: 'primary' }}
                        settings={campaignRegFormTabs.map((t, index) => ({
                            tab: index,
                            path: t.path,
                        }))}
                    >
                        {campaignRegFormTabs.map((p, index) => (
                            <Tab
                                key={index}
                                tabIndex={index}
                                value={index}
                                component={NavLink}
                                to={generatePath(p.path, {
                                    id,
                                    rfId,
                                })}
                                label={p.title}
                            />
                        ))}
                    </RoutedTabs>
                </Grid>
                <Grid item>
                    <Switch>
                        <Route path={clientRoute.campaignRegForm} exact>
                            <CampaignRegFormCard
                                model={model}
                                onFormReady={onFormReady}
                                onDeleteLinkClick={onDeleteLinkClick}
                            />
                        </Route>
                        <Route
                            path={[
                                clientRoute.campaignRegFormTabsSettings,
                                clientRoute.campaignRequestFormTabSetting,
                                clientRoute.campaignRequestFormTabSettingCreate,
                            ]}
                            exact
                        >
                            <RequestFormTabsSettings />
                        </Route>
                        <Route
                            path={[
                                clientRoute.campaignRegFormTemplates,
                                clientRoute.campaignRegFormTemplatesCreate,
                                clientRoute.campaignRegFormTemplatesEdit,
                                clientRoute.campaignRegFormTemplatesPermissionSettings,
                            ]}
                            exact
                        >
                            <CampaignPfTemplateTab />
                        </Route>
                    </Switch>
                </Grid>
            </Grid>
        </React.Fragment>
    );
});
