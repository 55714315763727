import { CodeTitle } from '@platform/ttable';
import { FormikErrors, FormikTouched, FormikValues } from 'formik';
import { action, observable, toJS } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { QuotaCountryGroupFields, QuotaCountryGroupDTO, QuotaCreateCountryGroup, useFormikReturns } from '../../types';
import { filterLetters } from '../../utils';
import { Quota } from './Quota';

export type QuotaGroupColumnSumValidation = {
    valid: boolean;
    message: string;
};

export class QuotaCountryGroup implements QuotaCountryGroupDTO {
    @observable countryGroup?: CodeTitle;
    @observable volume: number;
    @observable id: string;
    @observable formik?: useFormikReturns<QuotaCountryGroup>;
    @observable errors: FormikErrors<FormikValues> = {};
    @observable touched: FormikTouched<FormikValues> = {};
    @observable isValid: boolean = false;
    @observable columnSum: number = 0;

    constructor(quotaCountryGroup: Partial<QuotaCountryGroupDTO> & { id: string }) {
        this.volume = quotaCountryGroup.volume || 0;
        this.countryGroup = quotaCountryGroup?.countryGroup;
        this.id = quotaCountryGroup?.id || uuidv4();
    }

    // Formik & Validations
    // ----------------------------------------------------------
    @action.bound
    setFormik(formik: useFormikReturns<QuotaCountryGroup>): void {
        this.formik = formik;
    }

    @action.bound
    setFieldUntouched(key: QuotaCountryGroupFields): void {
        this.touched[key] = undefined;
    }

    @action.bound
    async validate(): Promise<boolean> {
        const errors = await this.formik?.validateForm(this);
        this.errors = errors || {};
        this.touched = this.errors as FormikTouched<QuotaCountryGroup>;
        this.isValid = !Object.keys(this.errors).length;
        return this.isValid;
    }

    @action.bound
    validateColumnSum(quota: Quota): QuotaGroupColumnSumValidation {
        const isTender = quota.isTender;
        const valid = isTender ? this.columnSum <= this.volume : this.columnSum === this.volume;
        let message = '';
        if (!valid) {
            isTender
                ? (message = 'Сумма распределения по группе превышает выделенный объем')
                : (message = 'Сумма распределения по группе не равна выделенному объему');
        }

        return {
            valid,
            message,
        };
    }
    // ----------------------------------------------------------

    @action.bound
    getDTO(): QuotaCreateCountryGroup {
        return toJS({
            id: this.id,
            volume: this.volume,
            countryGroupId: this.countryGroup?.code,
        });
    }

    @action.bound
    getValues(): QuotaCountryGroupDTO {
        return toJS({
            countryGroup: this.countryGroup,
            volume: this.volume,
        });
    }

    @action.bound
    setVolume(eventTargetValue: string): void {
        this.volume = Number(filterLetters(eventTargetValue));
        this.setFieldUntouched(QuotaCountryGroupFields.volume);
    }

    @action.bound
    setColumnSum(value: number): void {
        this.columnSum = value;
        this.setFieldUntouched(QuotaCountryGroupFields.columnSum);
    }

    @action.bound
    setCountryGroup(value: CodeTitle | null): void {
        this.countryGroup = value || undefined;
        this.setFieldUntouched(QuotaCountryGroupFields.countryGroup);
    }
}
