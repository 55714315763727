import { withConfirmDialog } from '../../../hocs';
import { IconButton, Tooltip } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import React from 'react';
import { WithAllowedProps } from './UserListBlockButton';

export const UserListDeleteButton = withConfirmDialog<WithAllowedProps>(
    (props): JSX.Element => {
        return (
            <Tooltip
                title={
                    props.allowed ? <FormattedMessage id="common.delete" /> : <FormattedMessage id="users.notAllowed" />
                }
            >
                <span>
                    <IconButton disabled={!props.allowed} onClick={props.openConfirmDialog}>
                        <DeleteForeverIcon />
                    </IconButton>
                </span>
            </Tooltip>
        );
    },
);
