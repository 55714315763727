import { Box, Divider, IconButton, MenuItem } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, useHistory } from 'react-router-dom';
import { MenuButton } from '../';
import { entities, permissions } from '../../authSchemeConfig';
import { clientRoute } from '../../clientRoute';
import { useStore } from '../../hooks';
import { ReactComponent as UserIcon } from '../../resources/images/icons/user-plus.svg';
import { AuthorizationCheck } from '../AuthorizationCheck';

export const UserButton = observer((): JSX.Element => {
    const { authenticationStore, personStore, api } = useStore();
    const history = useHistory();

    const handleLogout = (hideMenu: () => void) => async (): Promise<void> => {
        await authenticationStore
            .logout()
            .then((response) => {
                personStore.clearPerson();
                if (response.data.redirect) {
                    window.location.href = response.data.redirect;
                }
            })
            .catch(hideMenu);
        await api.authVerify();
        await personStore.getInfo();
    };

    const renderMenuItems =
        (withProfile: boolean) =>
        (hideMenu: () => void): ReactNode[] => {
            const onProfileClick = (): void => {
                hideMenu();
                history.push(
                    generatePath(clientRoute.user, {
                        id: personStore.person.userId,
                    }),
                );
            };

            return [
                <Box mb={2} key={'person'}>
                    <MenuItem dense button={false} tabIndex={-1}>
                        <Box pb={2} fontWeight="fontWeightBold">
                            {personStore.person.lastNameWithInitials}
                        </Box>
                    </MenuItem>
                    {withProfile && (
                        <MenuItem dense tabIndex={0} onClick={onProfileClick}>
                            <FormattedMessage id="users.profile" />
                        </MenuItem>
                    )}
                </Box>,
                <Box key={'divider'} mb={2}>
                    <Divider />
                </Box>,
                <AuthorizationCheck entityCode={entities.System} permCode={permissions.System.Logout}>
                    <MenuItem dense key={'logout'} button={true} onClick={handleLogout(hideMenu)} tabIndex={0}>
                        <FormattedMessage id="authentication.logout" />
                    </MenuItem>
                </AuthorizationCheck>,
            ];
        };

    const renderButton = (onClick: (event: React.MouseEvent<HTMLButtonElement>) => void): JSX.Element => {
        return (
            <IconButton color="secondary" onClick={onClick}>
                <UserIcon />
            </IconButton>
        );
    };

    return (
        <React.Fragment>
            {personStore.person.userId && (
                <AuthorizationCheck
                    entityCode={entities.User}
                    permCode={permissions.User.View}
                    entityId={personStore.person.userId}
                >
                    {(allowed) => (
                        <MenuButton
                            reloadKey={personStore.person.lastNameWithInitials}
                            renderButton={renderButton}
                            renderMenuItems={renderMenuItems(allowed)}
                        />
                    )}
                </AuthorizationCheck>
            )}
        </React.Fragment>
    );
});
