import { FormikProps } from 'formik';
import { FormikServiceCountryGroup } from '../../../types';

export const handleFormikRadioButtonChange = (
    fieldName: keyof FormikServiceCountryGroup,
    props: FormikProps<FormikServiceCountryGroup>,
    checked: boolean,
): (() => void) => {
    return () => {
        props.setFieldValue(fieldName, checked);
    };
};
