import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useQuotaContext } from '../../../../../hooks';
import { CodeTitleNull } from '../../../../../types';
import { QuotasAutocompleteMobxField, returnLoaderOrIcon } from '../../../quota-utils';

export const QuotaSettingsAutocompletes = observer((): JSX.Element => {
    const { quotasModel, isViewOnly } = useQuotaContext();
    const { quota, catalogs } = quotasModel;
    const { setCategory, setProductCategory, setQuotaType, category, productCategory, quotaType } = quota;
    const {
        categoryOptions,
        CNOptions,
        typeOptions,
        isCategoryOptionsLoaded,
        isCNOptionsLoaded,
        isTypeOptionsLoaded,
        loadQuotaMeasurementUnitOptions,
    } = catalogs;

    const categoryOptionsPopupIcon = returnLoaderOrIcon(isViewOnly || isCategoryOptionsLoaded);
    const CNOptionsPopupIcon = returnLoaderOrIcon(isViewOnly || isCNOptionsLoaded);
    const typeOptionsPopupIcon = returnLoaderOrIcon(isViewOnly || isTypeOptionsLoaded);

    useEffect(() => {
        if (productCategory) {
            loadQuotaMeasurementUnitOptions(productCategory.code);
        }
    }, [productCategory]);

    return (
        <React.Fragment>
            <Grid item>
                <QuotasAutocompleteMobxField<CodeTitleNull>
                    options={categoryOptions}
                    value={category}
                    size="medium"
                    onChange={setCategory}
                    popupIcon={categoryOptionsPopupIcon}
                    disabled={isViewOnly || !isCategoryOptionsLoaded}
                    label="Категория квоты"
                    error={!!quota.touched.category && !!quota.errors.category}
                    helperText={quota.errors.category}
                    required
                    variant="outlined"
                />
            </Grid>
            <Grid item>
                <QuotasAutocompleteMobxField<CodeTitleNull>
                    options={CNOptions}
                    value={productCategory}
                    size="medium"
                    onChange={setProductCategory}
                    popupIcon={CNOptionsPopupIcon}
                    disabled={isViewOnly || !isCNOptionsLoaded}
                    variant="outlined"
                    label="Категория товара"
                    error={!!quota.touched.productCategory && !!quota.errors.productCategory}
                    helperText={quota.errors.productCategory}
                    required
                />
            </Grid>
            <Grid item>
                <QuotasAutocompleteMobxField<CodeTitleNull>
                    options={typeOptions}
                    value={quotaType}
                    size="medium"
                    onChange={setQuotaType}
                    popupIcon={typeOptionsPopupIcon}
                    disabled={isViewOnly || !isTypeOptionsLoaded}
                    variant="outlined"
                    label="Тип квоты"
                    error={!!quota.touched.quotaType && !!quota.errors.quotaType}
                    helperText={quota.errors.quotaType}
                    required
                />
            </Grid>
        </React.Fragment>
    );
});
