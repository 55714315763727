import { Box, Button, CardMedia, Container, Grid, List, ListItem, Typography, useTheme } from '@material-ui/core';
import DOMPurify from 'dompurify';
import { useFeature } from 'feature-toggle-jsx';
import { observer } from 'mobx-react';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { generatePath, NavLink, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { entities, permissions } from '../../../authSchemeConfig';
import { clientRoute } from '../../../clientRoute';
import { AuthorizationCheck, FileListComponent, IconLink } from '../../../components';
import { useAntiDoubleClick, useStore } from '../../../hooks';
import { CampaignInfoModel } from '../../../models';
import { ReactComponent as Files } from '../../../resources/images/icons/files.svg';
import { ReactComponent as Setting } from '../../../resources/images/icons/setting.svg';
import { AuthorizationCheckQuery } from '../../../store';

type RouteParams = {
    id: string;
};

export type CampaignHeadWithBackgroundProps = {
    bgcolor?: string;
};

export const CampaignHeadWithBackground = styled(({ backgroundColor, ...props }) => <Box {...props} />)`
    position: relative;
    background-color: ${(props: CampaignHeadWithBackgroundProps) => props.bgcolor};
`;

const AvatarGridItem = styled(Grid)`
    position: absolute;
    right: 0;
    width: 100%;
    height: 100%;
`;

const AvatarCardMedia = styled(CardMedia)`
    height: 100%;
`;

const queries = (id: string): AuthorizationCheckQuery[] => {
    return [
        {
            entityCode: entities.System,
            permCode: permissions.System.ViewCampRequestList,
        },
        {
            entityCode: entities.Campaign,
            entityId: id,
            permCode: permissions.Campaign.Edit,
        },
    ];
};

export const CampaignPage = observer((): JSX.Element => {
    const { campaignsStore, env, authorizationStore } = useStore();
    const { downloadFile, createRequest } = campaignsStore;

    const [showCampaignPeriod] = useFeature('showCampaignPeriod');

    const [model, setModel] = useState<CampaignInfoModel>(new CampaignInfoModel(env.apiUrl));

    const theme = useTheme();
    const { id } = useParams<RouteParams>();

    const [sidebarItemsPermissions, setSidebarItemsPermissions] = useState<boolean[]>([]);
    const [viewCampRequestList, campaignEdit] = sidebarItemsPermissions;

    const createFunc = (): Promise<void> => {
        return createRequest(id);
    };

    const [isSending, endIcon, createHandler] = useAntiDoubleClick(createFunc);

    useEffect(() => {
        setModel(campaignsStore.getCampaignInfoModel(id));
    }, [campaignsStore, id]);

    useEffect(() => {
        authorizationStore.checkAll(queries(id)).then(setSidebarItemsPermissions);
    }, [authorizationStore, id, setSidebarItemsPermissions]);

    const { from, title, to, isCollectionCompleted, avatarUrl, campaignNotStarted, files } = model;

    const isSidebarVisible = useMemo(() => {
        return viewCampRequestList || campaignEdit;
    }, [viewCampRequestList, campaignEdit]);

    const renderCampaignPeriod = (): ReactNode => {
        if (from && to && !isCollectionCompleted) {
            return (
                <Typography variant="body2" display="inline">
                    <FormattedMessage id="campaign.campaignPeriod" />
                    &nbsp;
                    {campaignNotStarted && (
                        <React.Fragment>
                            <FormattedMessage id="common.from" />
                            &nbsp;
                            <Typography variant="body2" component="span">
                                <FormattedDate value={from} />
                            </Typography>
                            &nbsp;
                        </React.Fragment>
                    )}
                    <FormattedMessage id="common.to" />
                    &nbsp;
                    <Typography variant="body2" component="span">
                        <FormattedDate value={to} />
                    </Typography>
                </Typography>
            );
        } else if (isCollectionCompleted) {
            return (
                <Typography variant="body2" component="span" color="error" style={{ textTransform: 'uppercase' }}>
                    <FormattedMessage id="campaign.collectionCompleted" />
                </Typography>
            );
        }

        return null;
    };

    const renderAnnotation = (): ReactNode => {
        const { annotation } = model;
        if (annotation) {
            return <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(annotation) }} />;
        }
        return (
            <Typography variant="caption">
                <FormattedMessage id="common.noData" />
            </Typography>
        );
    };

    return (
        <Box width="100%">
            <CampaignHeadWithBackground bgcolor={theme?.variables.palette.card.header.main}>
                <Container maxWidth="lg">
                    <Grid container>
                        <Grid item xs={7}>
                            <Box pb={6} pt={6} color={theme?.variables.palette.card.header.color}>
                                <Grid container spacing={5} direction="column">
                                    <Grid item>
                                        <Typography variant="h1">{title}</Typography>
                                    </Grid>
                                    {showCampaignPeriod && <Grid item>{renderCampaignPeriod()}</Grid>}
                                    <Grid item>
                                        <AuthorizationCheck
                                            entityCode={entities.Campaign}
                                            permCode={permissions.Campaign.AddCampRequest}
                                            entityId={id}
                                        >
                                            <Button
                                                color="primary"
                                                size="large"
                                                variant="contained"
                                                type="submit"
                                                disabled={isSending}
                                                onClick={createHandler}
                                                endIcon={endIcon}
                                            >
                                                <FormattedMessage id="campaign.createRequest" />
                                            </Button>
                                        </AuthorizationCheck>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <AvatarGridItem item xs={5}>
                            {avatarUrl && <AvatarCardMedia image={avatarUrl} />}
                        </AvatarGridItem>
                    </Grid>
                </Container>
            </CampaignHeadWithBackground>

            <Container maxWidth="lg">
                <Box pt={20} pb={20}>
                    <Grid container spacing={2}>
                        {isSidebarVisible && (
                            <Grid item xs={3}>
                                <Typography variant="h3">
                                    <FormattedMessage id="campaign.sections" />
                                </Typography>

                                <Box mt={5} fontWeight="bold">
                                    <List>
                                        {viewCampRequestList && (
                                            <ListItem disableGutters>
                                                <IconLink
                                                    uppercase={true}
                                                    component={NavLink}
                                                    to={clientRoute.filteringWithCampaign(id)}
                                                    icon={<Files />}
                                                    text={<FormattedMessage id="campaign.campaignRequests" />}
                                                />
                                            </ListItem>
                                        )}
                                        {campaignEdit && (
                                            <ListItem disableGutters>
                                                <IconLink
                                                    uppercase={true}
                                                    component={NavLink}
                                                    to={generatePath(clientRoute.campaignSettings, { id })}
                                                    icon={<Setting />}
                                                    text={<FormattedMessage id="campaign.campaignSettings" />}
                                                />
                                            </ListItem>
                                        )}
                                    </List>
                                </Box>
                            </Grid>
                        )}
                        <Grid item xs={isSidebarVisible ? 9 : 12}>
                            <Typography variant="h3">
                                <FormattedMessage id="campaign.description" />
                            </Typography>
                            <Box mt={5} mb={9}>
                                {renderAnnotation()}
                            </Box>
                            <Typography variant="h3">
                                <FormattedMessage id="campaign.files" />
                            </Typography>
                            <Box mt={5}>
                                <FileListComponent
                                    files={files}
                                    downloadFile={downloadFile}
                                    noDataText={
                                        <Typography variant="caption">
                                            <FormattedMessage id="common.noData" />
                                        </Typography>
                                    }
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
    );
});
