import { FormikErrors, FormikTouched, FormikValues } from 'formik';
import { action, observable, toJS } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { QuotaParticipantFields, QuotaParticipantDTO, useFormikReturns } from '../../types';
import { filterLetters } from '../../utils';
import { QuotaCountryGroup } from './QuotaCountryGroup';
import { QuotaParticipantVolume } from './QuotaParticipantVolume';

/** Каждый participant = VTDrow */
export class QuotaParticipant implements QuotaParticipantDTO {
    @observable inn: string;
    @observable name: string;
    @observable volumes: QuotaParticipantVolume[] = [];
    @observable id: string;
    @observable formik?: useFormikReturns<QuotaParticipant>;
    @observable errors: FormikErrors<FormikValues> = {};
    @observable touched: FormikTouched<FormikValues> = {};
    @observable isValid: boolean = false;
    @observable commonRowVolume: number = 0;

    constructor(participant?: Partial<QuotaParticipantDTO> & { id: string; countryGroups: QuotaCountryGroup[] }) {
        this.inn = participant?.inn || '';
        this.name = participant?.name || '';
        this.volumes =
            participant?.volumes?.map((volumeInstance) => {
                return new QuotaParticipantVolume(volumeInstance.volume, uuidv4(), volumeInstance.countryGroupId);
            }) || [];
        this.id = participant?.id || uuidv4();
    }

    @action.bound
    getVolumeById(id: string): QuotaParticipantVolume | undefined {
        return this.volumes.find((volume) => volume.id === id);
    }

    @action.bound
    getDTO(): QuotaParticipantDTO {
        return toJS({
            inn: this.inn,
            name: this.name,
            volumes: this.volumes.map((v) => toJS(v)),
        });
    }

    // Formik & Validations
    // ----------------------------------------------------------
    @action.bound
    setFormik(formik: useFormikReturns<QuotaParticipant>): void {
        this.formik = formik;
    }

    @action.bound
    setFieldUntouched(key: QuotaParticipantFields): void {
        this.touched[key] = undefined;
    }

    @action.bound
    async validate(): Promise<boolean> {
        const errors = await this.formik?.validateForm(this);
        this.errors = errors || {};
        this.touched = this.errors as FormikTouched<QuotaParticipant>;
        this.isValid = !Object.keys(this.errors).length;
        return this.isValid;
    }
    // ----------------------------------------------------------

    /** Каждый participant = VTDrow. Считает сумму всех participant. */
    @action.bound
    updateCommonRowVolume(): void {
        this.commonRowVolume = this.volumes.reduce((acc, curr) => (acc += curr.volume), 0);
    }

    @action.bound
    setINN(value: string): void {
        this.inn = filterLetters(value);
        this.setFieldUntouched(QuotaParticipantFields.inn);
    }

    @action.bound
    setName(value: string): void {
        this.name = value;
        this.setFieldUntouched(QuotaParticipantFields.name);
    }
}
