import { Button } from '@material-ui/core';
import React from 'react';
import { withConfirmDialog } from '../../../../hocs';
import { useStore } from '../../../../hooks';

export type CountryGroupsCancelButtonDialogProps = {
    onCancel: () => void;
};

const CancelButton = (props: CountryGroupsCancelButtonDialogProps): JSX.Element => {
    const { onCancel } = props;
    const { intlStore: intl } = useStore();
    const discardChanges = intl.formatMessage('common.discardChanges');

    return (
        <Button onClick={onCancel} color="secondary" size="small" variant="contained">
            {discardChanges}
        </Button>
    );
};

export const CountryGroupsButtonWithDialog = withConfirmDialog(
    ({ openConfirmDialog }): JSX.Element => <CancelButton onCancel={openConfirmDialog} />,
);

export type CountryGroupsCancelButtonPropsDialog = {
    onConfirm: () => void;
};

export const CountryGroupsCancelButtonWithDialog = (props: CountryGroupsCancelButtonPropsDialog): JSX.Element => {
    const { onConfirm } = props;
    const { intlStore: intl } = useStore();
    const title = intl.formatMessage('campaignRequest.dialog.title');
    const closeMessage = intl.formatMessage('campaignRequest.dialog.closeMessage');

    return (
        <CountryGroupsButtonWithDialog id="delete-request" title={title} message={closeMessage} onConfirm={onConfirm} />
    );
};
