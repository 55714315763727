import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { Box, CircularProgress, Paper, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { fromPromise } from 'mobx-utils';
import { useStore } from '../../../hooks';
import { useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';

type RouteParams = {
    provider: string;
};

export const SamlGetAuthUrlPage = observer(
    (): JSX.Element => {
        const { provider } = useParams<RouteParams>();
        const location = useLocation();
        const queries = queryString.parse(location.search);
        const backUrl = queries.backUrl as string;
        const absoluteBackUrl = backUrl ? window.location.origin + backUrl : '';

        const { authenticationStore } = useStore();
        const getAuthUrl = useMemo<Promise<string>>(() => {
            return authenticationStore.samlGetAuthUrl(provider, absoluteBackUrl);
        }, [provider, absoluteBackUrl]);

        const redirectToAuthUrl = (authUrl: string): JSX.Element => {
            window.location.href = authUrl;
            return <React.Fragment />;
        };

        return fromPromise(getAuthUrl).case({
            pending: () => <CircularProgress />,
            fulfilled: redirectToAuthUrl,
            rejected: () => (
                <Paper elevation={0}>
                    <Box p={12}>
                        <Typography variant="h5">
                            <Box fontWeight="fontWeightBold">
                                <FormattedMessage id="authentication.error" />
                            </Box>
                        </Typography>
                    </Box>
                </Paper>
            ),
        });
    },
);
