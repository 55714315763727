import { Box, Button, Container, Grid, Link, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedDate, useIntl } from 'react-intl';
import { generatePath, NavLink } from 'react-router-dom';
import { entities, permissions } from '../../../../authSchemeConfig';
import { clientRoute } from '../../../../clientRoute';
import { AuthorizationCheck, TotObjectHeader } from '../../../../components';
import { useObjectHeaderStyles, useStore } from '../../../../hooks';
import { AuthorizationCheckQuery } from '../../../../store';
import { CampaignRequestHeaderProps, RequestType } from '../../../../types';
import { LicenseSignatory } from '../LicenseSignatory';
import { RequestDeadline } from '../RequestDeadline';
import {
    CampaignRequestHeaderRegisteredDate,
    CampaignRequestHeaderState,
    CampaignRequestHeaderUIN,
} from './components';

const relatedHeadersTranslationIds: Record<RequestType, string> = {
    [RequestType.license]: 'campaignRequest.relatedLicense',
    [RequestType.resolution]: 'campaignRequest.ResolutionN',
    [RequestType.request]: 'campaignRequest.relatedRequest',
};

const queries = (id: string): AuthorizationCheckQuery[] => {
    return [
        {
            entityCode: entities.CampaignRequest,
            entityId: id,
            permCode: permissions.CampaignRequest.UpdateActualAppointment,
        },
        {
            entityCode: entities.CampaignRequest,
            entityId: id,
            permCode: permissions.CampaignRequest.AddActualAppointment,
        },
    ];
};

export const CampaignRequestHeader = observer((props: CampaignRequestHeaderProps): JSX.Element => {
    const { requestModel, onModalOpen, reloadIncrement } = props;
    const classes = useObjectHeaderStyles();
    const { requestStore, authorizationStore } = useStore();
    const {
        number,
        state,
        id,
        currentTab,
        isLicense,
        isRequest,
        relatedRequestId,
        relatedRequestNumber,
        uin,
        deadlines,
        licenceDate,
        cnCategory,
        licenseSignatoryId,
        relatedRequestType,
        requestType,
        externalCreated,
        registered,
    } = requestModel;
    const [checkedPermissions, setCheckedPermissions] = useState<boolean[]>([]);
    const { stateDeadline, entityDeadline } = deadlines;
    const [updateActualAppointment, addActualAppointment] = checkedPermissions;
    const currentTabExecutor = currentTab?.tabExecutor;
    const objectNumberMessageId = isLicense ? 'campaignRequest.LicenseN' : 'campaignRequest.RequestN';
    const intl = useIntl();

    const tabExecutor = useMemo(() => {
        if (currentTabExecutor) {
            return currentTabExecutor;
        } else if (requestModel.executorsList) {
            return requestModel.executorsList;
        }
        return '';
    }, [currentTabExecutor, requestModel.executorsList]);

    useEffect(() => {
        authorizationStore.checkAll(queries(id)).then(setCheckedPermissions);
    }, [authorizationStore, setCheckedPermissions, id, currentTabExecutor, requestModel.executors]);

    const handleConfirm = (): void => {
        requestStore.setCurrentUserAsExecutor(id).then(() => {
            requestModel.updateExecutors();
            reloadIncrement();
        });
    };

    const handleChangeLicenseSignatory = (signatory: string): void => {
        requestModel.licenseSignatoryId = signatory;
        reloadIncrement();
    };

    const downloadLicenseDuplicate = (): Promise<void> => {
        return requestStore.downloadLicenseDuplicate(id, number);
    };

    const relatedHeaderId = relatedRequestType
        ? relatedHeadersTranslationIds[relatedRequestType]
        : requestType
        ? relatedHeadersTranslationIds[requestType]
        : '';

    const mainTitle = intl.formatMessage({ id: objectNumberMessageId }, { number });
    const downloadLicenseMessage = intl.formatMessage({ id: 'campaignRequest.downloadLicenseDuplicate' });
    const creationDate = intl.formatMessage({ id: 'campaignRequest.creationDate' });
    const licenseIssueDate = intl.formatMessage({ id: 'campaignRequest.licenseIssueDate' });
    const deadlinesTitle = intl.formatMessage({ id: 'campaignRequest.deadlines.entity' });
    const relatedRequestHeader =
        relatedRequestId && relatedRequestNumber ? intl.formatMessage({ id: relatedHeaderId }) : '';

    return (
        <TotObjectHeader>
            <Container maxWidth="lg">
                <Grid container spacing={10} alignItems="center">
                    <Grid item xs={8}>
                        <Grid container spacing={3} direction="column">
                            <Grid item container spacing={3}>
                                <Grid item>
                                    <Typography variant="h1">{mainTitle}</Typography>
                                </Grid>
                                {isLicense && (
                                    <AuthorizationCheck
                                        entityCode={entities.CampaignRequest}
                                        permCode={permissions.CampaignRequest.DownloadDuplicateLicense}
                                        entityId={id}
                                    >
                                        <Grid item>
                                            <Button
                                                onClick={downloadLicenseDuplicate}
                                                color="primary"
                                                size="small"
                                                variant="contained"
                                            >
                                                {downloadLicenseMessage}
                                            </Button>
                                        </Grid>
                                    </AuthorizationCheck>
                                )}
                            </Grid>
                            <Grid item>
                                {relatedRequestId && relatedRequestNumber && (
                                    <Typography variant="h3" component="p">
                                        <Box display="flex" flexWrap="wrap" component="span">
                                            {relatedRequestHeader}
                                            <Box pl={2} component="span">
                                                <Link
                                                    component={NavLink}
                                                    underline="none"
                                                    to={generatePath(
                                                        isLicense ? clientRoute.request : clientRoute.license,
                                                        {
                                                            id: relatedRequestId,
                                                        },
                                                    )}
                                                >
                                                    <Typography variant="h3" component="span">
                                                        {relatedRequestNumber}
                                                    </Typography>
                                                </Link>
                                            </Box>
                                        </Box>
                                    </Typography>
                                )}
                            </Grid>
                            {isLicense && licenceDate && (
                                <Grid item>
                                    <Typography variant="subtitle1" component="p">
                                        <Box display="flex" flexWrap="wrap" component="span">
                                            <span className={classes.lightText}>{licenseIssueDate}</span>
                                            <Box fontWeight={600} pl={2} component="span">
                                                {licenceDate}
                                            </Box>
                                        </Box>
                                    </Typography>
                                </Grid>
                            )}
                            {isRequest && externalCreated && (
                                <Grid item>
                                    <Typography variant="subtitle1" component="p">
                                        <Box display="flex" flexWrap="wrap" component="span">
                                            <span className={classes.lightText}>{creationDate}</span>
                                            <Box fontWeight={600} pl={2} component="span">
                                                <FormattedDate value={externalCreated} />
                                            </Box>
                                        </Box>
                                    </Typography>
                                </Grid>
                            )}
                            {registered && <CampaignRequestHeaderRegisteredDate requestModel={requestModel} />}
                            <CampaignRequestHeaderUIN cnCategory={cnCategory} uin={uin} />
                            <RequestDeadline deadline={entityDeadline} title={deadlinesTitle} withBlock={true} />
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid
                            container
                            spacing={3}
                            direction="column"
                            wrap="nowrap"
                            justify="center"
                            className={classes.rightBlockContainer}
                        >
                            <CampaignRequestHeaderState
                                addActualAppointment={addActualAppointment}
                                handleConfirm={handleConfirm}
                                id={id}
                                onModalOpen={onModalOpen}
                                state={state}
                                tabExecutor={tabExecutor}
                                updateActualAppointment={updateActualAppointment}
                                stateDeadline={stateDeadline}
                            />
                            <Grid item>
                                {isLicense && licenseSignatoryId && (
                                    <LicenseSignatory
                                        requestId={id}
                                        licenseSignatoryId={licenseSignatoryId}
                                        onSuccessChange={handleChangeLicenseSignatory}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </TotObjectHeader>
    );
});
