import { Grid, IconButton, TableCell, TableRow, TextField } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { ChangeEventHandler, useEffect } from 'react';
import { useQuotaContext } from '../../../../../hooks';
import { QuotaParticipant, yupQuotaVTDRowSchema } from '../../../../../models';
import { useQuotaVTDTableStyles } from '../../../quota-styles';
import { QuotaVTDRowVolumeCell } from './quota-vtd-common-volume-cell';

const innTextFieldInputProps = { maxLength: 12 };

export type QuotaVTDRowProps = {
    participant: QuotaParticipant;
};

export const QuotaVTDRow = observer((props: QuotaVTDRowProps): JSX.Element => {
    const { participant } = props;
    const classes = useQuotaVTDTableStyles();
    const { id, inn, name, commonRowVolume, setINN } = participant;

    const { quotasModel, isViewOnly } = useQuotaContext();

    const { quota } = quotasModel;
    const { deleteParticipant } = quota;

    const formik = useFormik({
        initialValues: participant,
        validationSchema: yupQuotaVTDRowSchema,
        onSubmit: () => {},
    });

    useEffect(() => {
        participant?.setFormik(formik);
    }, []);

    const deleteRow = (): void => {
        deleteParticipant(id);
    };

    const innInputHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
        setINN(e.target.value);
    };

    const nameInputHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
        participant.setName(e.target.value);
    };

    const rowCellsOfVolumes = participant.volumes.map((volume) => {
        return <QuotaVTDRowVolumeCell volumeInstance={volume} key={volume.id} participant={participant} />;
    });
    
    return (
        <TableRow className={classes.tableRow}>
            <TableCell>
                <TextField
                    fullWidth
                    variant="outlined"
                    label="ИНН"
                    value={inn}
                    onChange={innInputHandler}
                    inputProps={innTextFieldInputProps}
                    disabled={isViewOnly}
                    error={!!participant.errors.inn}
                    helperText={participant.errors.inn}
                    required
                />
            </TableCell>
            <TableCell>
                <TextField
                    fullWidth
                    variant="outlined"
                    label="Участник ВТД"
                    value={name}
                    onChange={nameInputHandler}
                    disabled={isViewOnly}
                    error={!!participant.errors.name}
                    helperText={participant.errors.name}
                    required
                />
            </TableCell>
            {rowCellsOfVolumes}
            <TableCell>
                <TextField fullWidth disabled variant="outlined" value={commonRowVolume} />
            </TableCell>
            <TableCell>
                {!isViewOnly && (
                    <Grid container alignItems="center" justify="center">
                        <IconButton onClick={deleteRow}>
                            <DeleteIcon />
                        </IconButton>
                    </Grid>
                )}
            </TableCell>
        </TableRow>
    );
});
