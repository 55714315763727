import {
    Box,
    Container,
    IconButton,
    LinearProgress,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@material-ui/core';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { AxiosError } from 'axios';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, NavLink } from 'react-router-dom';
import { entities, permissions } from '../../../authSchemeConfig';
import { clientRoute } from '../../../clientRoute';
import {
    AuthorizationCheck,
    BreadcrumbsComponent,
    ErrorDialog,
    ErrorMessage,
    TotTablePagination,
} from '../../../components';
import { useError, useStore } from '../../../hooks';
import { UserListModel, UserRowDTO, UserStatusCode } from '../../../models';
import { UserListBlockButton } from './UserListBlockButton';
import { UserListDeleteButton } from './UserListDeleteButton';
import { UserListFilterPanel } from './UserListFilterPanel';

export const UserListPage = observer((): JSX.Element => {
    const [isError, errorText, enableError, resetError] = useError();
    const { userStore, breadcrumbsStore } = useStore();
    const { breadCrumbsSettings } = breadcrumbsStore;
    const [model] = useState<UserListModel>(() => new UserListModel(userStore));

    const handleErrorDeletion = (error: AxiosError): void => {
        const errorText = ErrorMessage(error);
        enableError(errorText);
    };

    const blockUser = (id: string): (() => Promise<void>) => {
        return () => model.blockUser(id)().catch(handleErrorDeletion);
    };

    const unblockUser = (id: string): (() => Promise<void>) => {
        return () => model.unblockUser(id)().catch(handleErrorDeletion);
    };

    const deleteUser = (id: string): (() => Promise<void>) => {
        return () => model.deleteUser(id)().catch(handleErrorDeletion);
    };

    const renderBlockUnblock = (user: UserRowDTO, allowed: boolean): JSX.Element => {
        const { id, title } = user.login;
        return user.status === 'blocked' ? (
            <Tooltip
                title={
                    allowed ? (
                        <FormattedMessage id="users.actions.unblock" />
                    ) : (
                        <FormattedMessage id="users.notAllowed" />
                    )
                }
            >
                <span>
                    <IconButton disabled={!allowed} onClick={unblockUser(id)}>
                        <PersonAddIcon />
                    </IconButton>
                </span>
            </Tooltip>
        ) : (
            <UserListBlockButton
                id="block-user"
                title={<FormattedMessage id="users.confirmBlock" />}
                message={<FormattedMessage id="users.confirmBlockInfoText" values={{ login: title }} />}
                onConfirm={blockUser(id)}
                wrappedComponentProps={{ allowed }}
            />
        );
    };

    const renderBody = (): JSX.Element[] => {
        return model.rows.map((user) => {
            const { id, title } = user.login;
            return (
                <TableRow key={id} hover>
                    <TableCell>
                        <Link component={NavLink} underline="always" to={generatePath(clientRoute.user, { id })}>
                            {title}
                        </Link>
                    </TableCell>
                    <TableCell>
                        <FormattedMessage id={statusTextId(user.status)} />
                    </TableCell>
                    <TableCell>{user.name}</TableCell>
                    <TableCell>{user.roles}</TableCell>
                    <TableCell>
                        <AuthorizationCheck entityId={id} entityCode={entities.User} permCode={permissions.User.Update}>
                            {(allowed) => (
                                <Box style={{ whiteSpace: 'nowrap' }}>
                                    {renderBlockUnblock(user, allowed)}
                                    <UserListDeleteButton
                                        id="delete-user"
                                        title={<FormattedMessage id="users.deleteUser" />}
                                        message={
                                            <FormattedMessage
                                                id="users.deleteUserConfirmText"
                                                values={{ login: title }}
                                            />
                                        }
                                        onConfirm={deleteUser(id)}
                                        wrappedComponentProps={{ allowed }}
                                    />
                                </Box>
                            )}
                        </AuthorizationCheck>
                    </TableCell>
                </TableRow>
            );
        });
    };

    const statusTextId = (statusCode: UserStatusCode): string => {
        switch (statusCode) {
            case 'active':
                return 'users.status.active';
            case 'blocked':
                return 'users.status.blocked';
            case 'inactive':
                return 'users.status.inactive';
        }
    };

    useEffect(() => {
        return model.dispose;
    }, [model]);

    return (
        <React.Fragment>
            <ErrorDialog message={errorText} open={isError} onClose={resetError} />
            <Container maxWidth="lg">
                <Box pt={5.5} pb={15}>
                    <Box pb={5}>
                        <BreadcrumbsComponent breadcrumbsSettings={breadCrumbsSettings} />
                    </Box>
                    <Box pb={10}>
                        <Typography variant="h1">
                            <FormattedMessage id="users.listTitle" />
                        </Typography>
                    </Box>
                    <Box pb={10}>
                        <UserListFilterPanel model={model} />
                    </Box>
                    <Box>
                        <TableContainer component={Paper}>
                            {model.isLoading && <LinearProgress />}
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ width: '23%' }}>
                                            <Typography>
                                                <FormattedMessage id="users.fields.login" />
                                            </Typography>
                                        </TableCell>
                                        <TableCell style={{ width: '23%' }}>
                                            <Typography>
                                                <FormattedMessage id="users.fields.status" />
                                            </Typography>
                                        </TableCell>
                                        <TableCell style={{ width: '23%' }}>
                                            <Typography>
                                                <FormattedMessage id="users.fields.name" />
                                            </Typography>
                                        </TableCell>
                                        <TableCell style={{ width: '23%' }}>
                                            <Typography>
                                                <FormattedMessage id="users.fields.roles" />
                                            </Typography>
                                        </TableCell>
                                        <TableCell />
                                    </TableRow>
                                </TableHead>
                                <TableBody>{renderBody()}</TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TableCell colSpan={100}>
                                            <TotTablePagination
                                                count={model.rowsCount}
                                                page={model.pageNumber}
                                                onChangePage={model.onChangePage}
                                                onChangeRowsPerPage={model.onChangePageSize}
                                                rowsPerPage={model.pageSize}
                                                rowsPerPageOptions={model.pageSizeOptions}
                                                pagingInfoMessageId="users.registryPagingInfo"
                                                pagingInfoMessageIdIfNull="users.registryPagingInfoIfNull"
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            </Container>
        </React.Fragment>
    );
});
