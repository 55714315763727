import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { Transition, TransitionsDTO } from '../../models';

export const renderTransitions = (
    data: TransitionsDTO,
    onClickModalOpen: (transition: Transition) => () => void,
): Array<JSX.Element> => {
    return data.map(
        (transition: Transition): JSX.Element => {
            return (
                <Grid item key={transition.id}>
                    <Button
                        onClick={onClickModalOpen(transition)}
                        color={transition.params.btnType}
                        size="medium"
                        variant="contained"
                        type="button"
                    >
                        {transition.title}
                    </Button>
                </Grid>
            );
        },
    );
};
