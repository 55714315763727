const breadcrumbs: Record<string, string | Record<string, string>> = {
    mainPage: 'Главная страница',
    campaigns: 'Услуги',
    campaign: 'Услуга',
    campaignSettings: 'Настройки услуги',
    requests: 'Заявления',
    requestsOld: 'Заявления',
    licenses: 'Лицензии',
    regFormSelect: 'Выбор формы участия заявления',
    users: 'Пользователи',
    authorityDocs: 'Разрешительные документы'
};

export default breadcrumbs;
