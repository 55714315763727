import { DatePickerProps } from '@material-ui/pickers/DatePicker/DatePicker';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const pickerProps: Partial<DatePickerProps> = {
    autoOk: true,
    variant: 'inline',
    inputVariant: 'outlined',
    format: 'dd.MM.yyyy',
    disableFuture: true,
    size: 'small',
    InputLabelProps: {
        shrink: true,
    },
    invalidDateMessage: <FormattedMessage id={'campaignRequest.invalidDateMessage'} />,
    maxDateMessage: <FormattedMessage id={'campaignRequest.maxDateMessage'} />,
};
