const validation: Record<string, string> = {
    required: 'Обязательное поле',
    isEmail: 'Введите корректный email',
    minLength: 'Минимальная длина {length, number} {length, plural, one {символ} few {символа} other {символов}}',
    maxLength: 'Максимальная длина {length, number} {length, plural, one {символ} few {символа} other {символов}}',
    dropzoneError: 'Файл не соответствует типу "doc" или "docx"',
    isNumber: 'Введите корректное число',
    bannedSymbols: 'В названии нельзя использовать символы: « {symbols} »',
    withoutSignatureMethod: 'Выберите минимум один способ подписания',
    invalidForm: 'При заполнении формы были допущены ошибки'
};

export default validation;
