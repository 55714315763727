import { Box, Button, Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { FormattedMessage } from 'react-intl';
import { generatePath, NavLink, Route, Switch, useParams } from 'react-router-dom';
import { clientRoute } from '../../../../../clientRoute';
import { useStore } from '../../../../../hooks';
import { CampaignPfTemplateListModel } from '../../../../../models';
import { AddPfTemplateButton } from './AddPfTemplateButton';
import { CampaignPfTemplateCard } from './CampaignPfTemplateCard';
import { CampaignPfTemplateFormDialog, TemplatePermissionSettingsDialog } from './dialogs';

type RouteParams = {
    id: string;
    rfId: string;
};

export const CampaignPfTemplateTab = observer((): JSX.Element => {
    const { id: campaignId, rfId } = useParams<RouteParams>();
    const rootStore = useStore();
    const [model] = useState<CampaignPfTemplateListModel>(new CampaignPfTemplateListModel(campaignId, rfId, rootStore));

    const { changeTemplatePosition, reorderTemplatesList } = model;

    useEffect(() => {
        model.loadData();
    }, [model]);

    const onDragEnd = (result: DropResult, settingsId: string): void => {
        if (!result.destination) {
            return;
        }

        changeTemplatePosition(result.draggableId, result.destination.index + 1);
        reorderTemplatesList(result.source.index, result.destination.index, settingsId);
    };

    return (
        <React.Fragment>
            <Switch>
                <Route path={clientRoute.campaignRegFormTemplatesCreate}>
                    <CampaignPfTemplateFormDialog
                        title={<FormattedMessage id="campaign.addTemplate" />}
                        listModel={model}
                        saveModel={model.createTemplate}
                    />
                </Route>
                <Route path={clientRoute.campaignRegFormTemplatesEdit}>
                    <CampaignPfTemplateFormDialog
                        title={<FormattedMessage id="campaign.editTemplate" />}
                        listModel={model}
                        saveModel={model.saveTemplate}
                    />
                </Route>
                <Route path={clientRoute.campaignRegFormTemplatesPermissionSettings}>
                    <TemplatePermissionSettingsDialog />
                </Route>
            </Switch>

            <Grid container direction="column" spacing={5}>
                {model.templatesTabs.map((templateTab) => {
                    return (
                        <Grid container item direction="column" spacing={3}>
                            <Grid container item alignItems="center" justify="space-between">
                                <Grid item>
                                    <Typography variant="subtitle1" component="h3">
                                        {templateTab.settingsTitle}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Grid container alignItems="center">
                                        <Grid item>
                                            <AddPfTemplateButton
                                                campaignId={campaignId}
                                                rfId={rfId}
                                                settingsId={templateTab.settingsId}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                component={NavLink}
                                                to={generatePath(
                                                    clientRoute.campaignRegFormTemplatesPermissionSettings,
                                                    {
                                                        id: campaignId,
                                                        rfId,
                                                        settingsId: templateTab.settingsId,
                                                    },
                                                )}
                                                variant="text"
                                                color="primary"
                                                size="small"
                                            >
                                                <FormattedMessage id="campaign.editPermission" />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <DragDropContext onDragEnd={(result) => onDragEnd(result, templateTab.settingsId)}>
                                <Droppable droppableId={'droppable'} key={templateTab.templates.length}>
                                    {(droppableProvided): JSX.Element => (
                                        <Grid
                                            container
                                            item
                                            direction="column"
                                            spacing={3}
                                            {...droppableProvided.droppableProps}
                                            ref={droppableProvided.innerRef}
                                        >
                                            {templateTab.templates.length ? (
                                                <React.Fragment>
                                                    {templateTab.templates.map((template, index) => {
                                                        return (
                                                            <Draggable
                                                                draggableId={template.id}
                                                                index={index}
                                                                key={template.id}
                                                            >
                                                                {(provided): JSX.Element => (
                                                                    <Grid
                                                                        item
                                                                        {...provided.draggableProps}
                                                                        ref={provided.innerRef}
                                                                    >
                                                                        <CampaignPfTemplateCard
                                                                            model={template}
                                                                            listModel={model}
                                                                            provided={provided.dragHandleProps}
                                                                        />
                                                                    </Grid>
                                                                )}
                                                            </Draggable>
                                                        );
                                                    })}
                                                    {droppableProvided.placeholder}
                                                </React.Fragment>
                                            ) : (
                                                <Typography variant="subtitle2" component="p">
                                                    <Box p="10px 30px" fontWeight="400">
                                                        <FormattedMessage id="campaign.noTemplates" />
                                                    </Box>
                                                </Typography>
                                            )}
                                        </Grid>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </Grid>
                    );
                })}
            </Grid>
        </React.Fragment>
    );
});
