import { createStyles, Link, makeStyles } from '@material-ui/core';
import { Preset, TTable, TTableQueryData, TTableRow } from '@platform/ttable';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, NavLink } from 'react-router-dom';
import { entities, permissions } from '../../../authSchemeConfig';
import { clientRoute } from '../../../clientRoute';
import { AuthorizationCheck, ExportRegistryDialog } from '../../../components';
import { useStore } from '../../../hooks';
import { CampaignRequestRowNew } from '../../../types';
import { RegFormFilter } from './RegFormFilter';

export type CampaignRequestTableProps = {
    setRowActions: (row: CampaignRequestRowNew, reloadData: () => void) => JSX.Element;
};

export const useCampaignRequestTableStyles = makeStyles(() =>
    createStyles({
        link: {
            display: 'inline',
        },
    }),
);

export const CampaignRequestTable = observer((props: CampaignRequestTableProps): JSX.Element => {
    const classes = useCampaignRequestTableStyles();

    const { setRowActions } = props;
    const { requestStore, presetStore, intlStore, catalogStore, userStore } = useStore();

    const saveListPresets = <RowType extends TTableRow>(settings: Preset<RowType>[]): Promise<void> =>
        presetStore.saveListPresets(settings);

    const [openUploadDialog, setOpenUploadDialog] = useState<boolean>(false);
    const [uploadTableQuery, setUploadTableQuery] = useState<TTableQueryData<CampaignRequestRowNew>>();

    const handleUpload = async (tableQuery: TTableQueryData<TTableRow>): Promise<void> => {
        setOpenUploadDialog(true);
        setUploadTableQuery(tableQuery);
    };

    const handleCloseUploadDialog = () => {
        setOpenUploadDialog(false);
        setUploadTableQuery(undefined);
    };

    return (
        <React.Fragment>
            <AuthorizationCheck entityCode={entities.System} permCode={permissions.System.Administration}>
                {(allowed: boolean): JSX.Element => (
                    <TTable<CampaignRequestRowNew>
                        registryCode="ics-requests"
                        fetchData={requestStore.registry}
                        fetchColumns={requestStore.registryColumns}
                        presetsSetting={presetStore}
                        fetchCatalog={catalogStore.getCatalog}
                        fetchSelectDataByUrl={catalogStore.getSelectDataByUrl}
                        upload={handleUpload}
                        lang={intlStore.locale}
                        cellFormatters={{
                            'customData.number': function number(p): JSX.Element {
                                return (
                                    <Link
                                        className={classes.link}
                                        component={NavLink}
                                        underline="always"
                                        to={generatePath(clientRoute.request, { id: p.row.original.id })}
                                    >
                                        {p.row.original.customData.number || (
                                            <FormattedMessage id="campaignRequest.withoutNumber" />
                                        )}
                                    </Link>
                                );
                            },
                            'customData.campaign.title': function campaignTitle(p): JSX.Element {
                                return (
                                    <Link
                                        className={classes.link}
                                        component={NavLink}
                                        underline="always"
                                        to={generatePath(clientRoute.campaign, {
                                            id: p.row.original.customData.campaign.id,
                                        })}
                                    >
                                        {p.row.original.customData.campaign.title}
                                    </Link>
                                );
                            },
                        }}
                        customFilters={{ 'customData.regForm.title': RegFormFilter }}
                        saveListPresets={saveListPresets}
                        fetchUserRoleList={userStore.userRoleList}
                        rowActions={setRowActions}
                        isAdmin={allowed}
                    />
                )}
            </AuthorizationCheck>
            <ExportRegistryDialog
                openUploadDialog={openUploadDialog}
                uploadTableQuery={uploadTableQuery}
                handleCloseExportDialog={handleCloseUploadDialog}
                exportXlsx={requestStore.upload}
                exportDoc={requestStore.uploadRequestsDoc}
            />
        </React.Fragment>
    );
});
