import React from 'react';
import { withConfirmDialog } from '../../../hocs';
import { IconButton, Tooltip } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';

export type WithAllowedProps = {
    allowed?: boolean;
};

export const UserListBlockButton = withConfirmDialog<WithAllowedProps>(
    (props): JSX.Element => (
        <Tooltip
            title={
                props.allowed ? (
                    <FormattedMessage id="users.actions.block" />
                ) : (
                    <FormattedMessage id="users.notAllowed" />
                )
            }
        >
            <span>
                <IconButton disabled={!props.allowed} onClick={props.openConfirmDialog}>
                    <PersonAddDisabledIcon />
                </IconButton>
            </span>
        </Tooltip>
    ),
);
