import { FormioSidebarStore } from '@platform/formiojs-react';
import { History } from 'history';
import { action, observable } from 'mobx';
import { asyncAction } from 'mobx-utils';
import { apiConfigs } from '../apiConfigs';
import { clientRoute } from '../clientRoute';
import en from '../resources/locales/en';
import ru from '../resources/locales/ru';
import { AppTheme, ThemeOverrides } from '../themes';
import { Env, RootStoreProps, Services } from '../types';
import { handleAxiosErrorByResponseStatus } from '../utils';
import { ApiStore } from './ApiStore';
import { ApprovalStore } from './ApprovalStore';
import { AuthenticationStore } from './AuthenticationStore';
import { AuthorityDocStore } from './AuthorityDocStore';
import { AuthorizationStore } from './AuthorizationStore';
import { BreadcrumbsStore } from './BreadcrumbsStore';
import { CampaignsStore } from './CampaignsStore';
import { CatalogStore } from './CatalogStore';
import { ConsoleStore } from './ConsoleStore';
import { CountryGroupsStore } from './CountryGroupsStore';
import { FormioSignatureStore } from './FormioSignatureStore';
import { FormioStore } from './FormioStore';
import { HeaderHeightStore } from './HeaderHeightStore';
import { HeaderLinksStore } from './HeaderLinksStore';
import { IntlStore, Locales } from './IntlStore';
import { LicenseStore } from './LicenseStore';
import { LkNotificationStore } from './LkNotificationStore';
import { ModulesStore } from './ModulesStore';
import { NotificationStore } from './NotificationStore';
import { PersonStore } from './PersonStore';
import { PresetStore } from './PresetStore';
import { QuotasStore } from './QuotaStore';
import { RequestStore } from './RequestStore';
import { SignatureStore } from './SignatureStore';
import { UserStore } from './UserStore';

export class RootStore {
    @observable history: History;

    @observable api: ApiStore;
    @observable authenticationStore: AuthenticationStore;
    @observable authorizationStore: AuthorizationStore;
    @observable intlStore: IntlStore;
    @observable userStore: UserStore;
    @observable personStore: PersonStore;
    @observable formioStore: FormioStore;
    @observable campaignsStore: CampaignsStore;
    @observable requestStore: RequestStore;
    @observable formioSidebarStore: FormioSidebarStore;
    @observable catalogStore: CatalogStore;
    @observable notificationStore: NotificationStore;
    @observable presetStore: PresetStore;
    @observable lkNotificationStore: LkNotificationStore;
    @observable modulesStore: ModulesStore;
    @observable signatureStore: SignatureStore;
    @observable formioSignatureStore: FormioSignatureStore;
    @observable approvalStore: ApprovalStore;
    @observable breadcrumbsStore: BreadcrumbsStore;
    @observable consoleStore: ConsoleStore;
    @observable licenseStore: LicenseStore;
    @observable authorityDocStore: AuthorityDocStore;
    @observable quotasStore: QuotasStore;
    @observable countryGroupsStore: CountryGroupsStore;

    @observable features: RootStoreProps['features'] = {};
    @observable env: Env = {} as Env;
    @observable themeOverrides: ThemeOverrides = {} as ThemeOverrides;
    @observable appTheme?: AppTheme;

    @observable headerHeightStore: HeaderHeightStore;
    @observable headerLinksStore: HeaderLinksStore;

    @observable campaignId: string = '-';

    services: Services = {
        formVersionLink: (formVersionId: string) => {
            return `${this.env.formServiceUrl}/form/${formVersionId}`;
        },
    };

    constructor(props: RootStoreProps) {
        this.setObservables(props);
        this.api = new ApiStore(this);
        this.authenticationStore = new AuthenticationStore(this);
        this.authorizationStore = new AuthorizationStore(this);

        const { locales, history } = props;
        const messages: Locales = {
            ru: { ...ru, ...locales.ru },
            en: { ...en, ...locales.en },
        };
        this.intlStore = new IntlStore(this, messages);
        this.history = history;
        this.userStore = new UserStore(this);
        this.personStore = new PersonStore(this);
        this.formioStore = new FormioStore(this);
        this.campaignsStore = new CampaignsStore(this);
        this.notificationStore = new NotificationStore();
        this.requestStore = new RequestStore(this);
        this.formioSidebarStore = new FormioSidebarStore();
        this.catalogStore = new CatalogStore(this);
        this.presetStore = new PresetStore(this);
        this.lkNotificationStore = new LkNotificationStore(this);
        this.modulesStore = new ModulesStore(this);
        this.signatureStore = new SignatureStore(this);
        this.formioSignatureStore = new FormioSignatureStore(this);
        this.headerHeightStore = new HeaderHeightStore();
        this.headerLinksStore = new HeaderLinksStore();
        this.approvalStore = new ApprovalStore(this);
        this.breadcrumbsStore = new BreadcrumbsStore(this);
        this.consoleStore = new ConsoleStore(this);
        this.licenseStore = new LicenseStore(this);
        this.authorityDocStore = new AuthorityDocStore(this);
        this.quotasStore = new QuotasStore(this);
        this.countryGroupsStore = new CountryGroupsStore(this);

        this.loadCampaign();
    }

    @action.bound
    setObservables(props: RootStoreProps) {
        const { env, features, themeOverrides } = props;
        this.env = env;
        this.features = features;
        this.themeOverrides = themeOverrides;
    }

    @action.bound
    setAppTheme(appTheme: AppTheme) {
        this.appTheme = appTheme;
        this.formioStore.addComponentsWithMaterialTheme();
    }

    @asyncAction
    @action.bound
    async loadCampaign(): Promise<void> {
        try {
            const response = await this.api.client(apiConfigs.campaigns);
            this.campaignId = response.data[0]?.id;
        } catch (e) {
            handleAxiosErrorByResponseStatus({
                403: () => this.history.replace(clientRoute.notAllowed),
                404: () => this.history.replace(clientRoute.notFound),
            })(e as any).then(() => {});
        }
    }
}
