import { FormApi, FormDTO } from '@platform/formiojs-react';
import { History } from 'history';
import { action, computed, observable } from 'mobx';
import { generatePath } from 'react-router-dom';
import { apiConfigs } from '../apiConfigs';
import { clientRoute } from '../clientRoute';
import { CodeTitle, RowsData, TableQueryData, UserPersonModel, UserRowDTO } from '../models';
import { handleAxiosErrorByResponseStatus } from '../utils';
import { ApiStore } from './ApiStore';
import { RootStore } from './RootStore';

export interface UserDTO {
    userId: string;
    lang: string;
    login: string;
    roles: CodeTitle[];
    formInfo: FormDTO;
}

export type UserUpdateBody = {
    email: string;
    lang: string;
    roles: string[];
};

export class UserStore {
    @observable private rootStore: RootStore;
    @observable private api: ApiStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.api = rootStore.api;
    }

    @computed
    get history(): History {
        return this.rootStore.history;
    }

    @action.bound
    loginAsUser(id: string): Promise<void> {
        return this.api.client(apiConfigs.loginAsUser(id)).then((r) => r.data);
    }

    @action.bound
    loadUser(id: string): Promise<UserDTO> {
        return this.api
            .client(apiConfigs.loadUser(id))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    404: () => this.history.replace(clientRoute.notFound),
                    403: () => this.history.replace(clientRoute.notAllowed),
                }),
            );
    }

    @action.bound
    getUserPersonModel(id: string): UserPersonModel {
        const model = new UserPersonModel(id);
        this.loadUser(id).then(model.load);
        return model;
    }

    @action.bound
    saveUser(id: string, formApi: FormApi): Promise<void> {
        return formApi
            .submit(false)
            .then(() => {
                return this.api.client(apiConfigs.saveUser(id, formApi.getSubmission()));
            })
            .then((r) => r.data)
            .then(() => {
                this.history.push(generatePath(clientRoute.user, { id }));
            });
    }

    @action.bound
    saveUserHead(user: UserPersonModel): Promise<void> {
        const { id, roles, login, lang } = user;
        return this.api
            .client(
                apiConfigs.saveUserHead(id, {
                    email: login,
                    lang,
                    roles: roles.map((_) => _.code),
                }),
            )
            .then((r) => r.data);
    }

    @action.bound
    userRoleList(): Promise<CodeTitle[]> {
        return this.api.client(apiConfigs.userRoleList).then((r) => r.data);
    }

    @action.bound
    loadList(queryData: TableQueryData): Promise<RowsData<UserRowDTO>> {
        return this.api
            .client(apiConfigs.userList(queryData))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                }),
            );
    }

    @action.bound
    blockUser(id: string): Promise<void> {
        return this.api.client(apiConfigs.blockUser(id)).then((r) => r.data);
    }

    @action.bound
    unblockUser(id: string): Promise<void> {
        return this.api.client(apiConfigs.unblockUser(id)).then((r) => r.data);
    }

    @action.bound
    deleteUser(id: string): Promise<void> {
        return this.api.client(apiConfigs.deleteUser(id)).then((r) => r.data);
    }
}
