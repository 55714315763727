import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@material-ui/core';
import { generatePath, useHistory } from 'react-router-dom';
import { clientRoute } from '../../../../clientRoute';
import { QuotaDTO } from '../../../../types';
import { useQuotaContext } from '../../../../hooks';

export const QuotasSaveButton = (): JSX.Element => {
    const history = useHistory();
    const { quotasModel, isEditing } = useQuotaContext();
    const { quota, updateQuota, createQuota } = quotasModel;

    const updateQuotaAndRedirect = async (): Promise<void> => {
        try {
            await updateQuota();
            // Нужно, чтобы избежать "промаргивания" на низких
            // скоростях интернета. (И далее по коду)
            // --------
            quotasModel.setIsLoading(true);
            // --------
            history.push(
                generatePath(clientRoute.quotasQuota, {
                    id: quota.id,
                }),
            );
        } catch (e) {
            console.error(e);
        } finally {
            quotasModel.setIsLoading(false);
        }
    };

    const createQuotaAndRedirect = async (): Promise<void> => {
        try {
            const newQuota: QuotaDTO | void = await createQuota();
            if (newQuota) {
                quotasModel.setIsLoading(true);
                history.push(
                    generatePath(clientRoute.quotasQuota, {
                        id: newQuota.id,
                    }),
                );
            }
        } catch (e) {
            console.error(e);
        } finally {
            quotasModel.setIsLoading(false);
        }
    };

    return (
        <Button
            color="primary"
            size="small"
            variant="contained"
            onClick={isEditing ? updateQuotaAndRedirect : createQuotaAndRedirect}
        >
            <FormattedMessage id="campaignRequest.saveChanges" />
        </Button>
    );
};
