import { Box, Button, CircularProgress, Container, Grid, Link, Paper, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink, Redirect, useParams } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';
import { PasswordField } from '../../components';
import { useStore } from '../../hooks';
import { NewPasswordModel } from '../../models';
import { TotBackgroundWithAvailableHeader } from '../../TotBackground';

type RouteParams = {
    token: string | undefined;
};

export const NewPasswordPage = observer((): JSX.Element => {
    const { token } = useParams<RouteParams>();
    const { personStore, authenticationStore } = useStore();
    const newPasswordModel = useMemo<NewPasswordModel>(() => {
        const newModel = authenticationStore.newPasswordModel;
        newModel.token = token;
        return newModel;
    }, [token]);

    const { failedMessage, isTokenValid } = newPasswordModel;

    useEffect(() => {
        newPasswordModel.validateToken();
    }, []);

    return (
        <TotBackgroundWithAvailableHeader>
            <Grid container item direction="column" alignItems="center" justify="center">
                <Container className="t-new-password-page" maxWidth="xs">
                    <Paper elevation={0}>
                        {isTokenValid ? (
                            <Box p={12}>
                                <Grid container justify="center">
                                    <Grid item>
                                        <Typography variant="h5" className="t-new-password-title">
                                            <Box fontWeight="fontWeightBold">
                                                <FormattedMessage id="authentication.newPasswordTitle" />
                                            </Box>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Box pt={8}>
                                    <form noValidate onSubmit={newPasswordModel.updatePassword}>
                                        <Grid container spacing={6} direction="column">
                                            {failedMessage && (
                                                <Grid item>
                                                    <Typography className="t-failed-message" color="error">
                                                        {failedMessage}
                                                    </Typography>
                                                </Grid>
                                            )}
                                            <Grid item>
                                                <PasswordField
                                                    label={<FormattedMessage id="authentication.newPassword" />}
                                                    model={newPasswordModel.newPasswordModel}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <PasswordField
                                                    label={<FormattedMessage id="authentication.repeatPassword" />}
                                                    model={newPasswordModel.repeatPasswordModel}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    className="t-confirm"
                                                    fullWidth
                                                    size="large"
                                                    variant="contained"
                                                    type="submit"
                                                >
                                                    <FormattedMessage id="authentication.confirm" />
                                                </Button>
                                            </Grid>
                                            <Grid item container justify="center">
                                                <Link
                                                    className="t-login"
                                                    component={RouterLink}
                                                    to={clientRoute.loginRedirect}
                                                >
                                                    <Typography variant="caption">
                                                        <FormattedMessage id="authentication.login" />
                                                    </Typography>
                                                </Link>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Box>
                            </Box>
                        ) : isTokenValid === false ? (
                            <Box p={12}>
                                <Typography align="center" className="t-failed-message" color="error">
                                    <FormattedMessage id="authentication.linkInvalidOrExpired" />
                                </Typography>
                            </Box>
                        ) : (
                            <Box p={6}>
                                <Grid container justify="center">
                                    <Grid item>
                                        <CircularProgress />
                                    </Grid>
                                </Grid>
                            </Box>
                        )}
                    </Paper>
                    {newPasswordModel.changedSucceed && <Redirect to={personStore.redirectPath} />}
                </Container>
            </Grid>
        </TotBackgroundWithAvailableHeader>
    );
});
