import { FormDTO } from '@platform/formiojs-react';
import { IdTitle, PersonDTO } from '../../models';

export enum RequestType {
    license = 'LICENSE',
    request = 'REQUEST',
    resolution = 'RESOLUTION',
}

export type RequestDTO = {
    id: string;
    number: string;
    regFormTitle: string;
    campaign: IdTitle;
    state: string;
    customTabs: TabWithSubTabs[];
    executors: EmployeeDTO[];
    requestType?: RequestType;
    relatedRequestType?: RequestType;
    relatedRequestId?: string;
    relatedRequestNumber?: string;
    externalCreated?: string; //date
    licenceDate?: string; //date
    registered?: string; //date
    uin?: string;
    cnCategory?: string;
    licenseSignatoryId?: string;
    validate: boolean;
};

export type TabDTO = {
    id: string;
    title: string;
    openOnCreation: boolean;
};
export type TabWithSubTabs = TabDTO & {
    subTabs: TabDTO[];
};

export interface RequestFormDTO {
    id: string;
    title: string;
    formInfo: FormDTO;
    tabExecutor: string;
}

export type EmployeeDTO = {
    userId: string;
    person: PersonDTO;
};

export enum DeadlineStatus {
    VALID = 'valid',
    WARNING = 'warning',
    INVALID = 'invalid',
    ERROR = 'error',
}

export type DeadlineDTO = {
    date?: Date;
    status: DeadlineStatus;
    delay: boolean;
};

export type DeadlinesDTO = {
    entityDeadline?: DeadlineDTO;
    stateDeadline?: DeadlineDTO;
};

export type TabWithPossibleParent = TabDTO & {
    subTabs?: TabDTO[];
    parent?: TabWithSubTabs;
};
