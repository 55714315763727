import { useState } from 'react';

type UseModal = [boolean, () => void, () => void];

export function useModal(): UseModal {
    const [open, setOpen] = useState<boolean>(false);

    const setModalIsOpen = (): void => {
        setOpen(true);
    };

    const setModalIsClosed = (): void => {
        setOpen(false);
    };

    return [open, setModalIsOpen, setModalIsClosed];
}
