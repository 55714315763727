const authentication: Record<string, string> = {
    loginTitle: 'Вход в кабинет',
    email: 'Email',
    password: 'Пароль',
    rememberMe: 'Запомнить меня',
    login: 'Войти',
    forgotPassword: 'Забыли пароль?',
    register: 'Зарегистрироваться',
    loginFailed: 'Неверный email или пароль',
    registrationTitle: 'Регистрация',
    firstName: 'Имя',
    middleName: 'Отчество',
    lastName: 'Фамилия',
    termsOfService: 'Пользовательское соглашение',
    termsIsAccepted: 'Я принимаю условия {link}',
    termsLinkTitle: 'Политики использования данных',
    emailAlreadyExists: 'Такой email уже зарегистрирован',
    emailNotExist: 'Пользователь с таким email не найден',
    emailNotConfirmed: 'Email не подтвержден',
    userBlocked: 'Ваша учетная запись недоступна',
    confirmingEmailReminder:
        'На указанный email уже отправлена ссылка для подтверждения. Пожалуйста, перейдите по ней (время действия ссылки – 15 минут), или попробуйте зарегистрироваться позже',
    confirmingEmailSent: 'На указанный email отправлено письмо для подтверждения.',
    confirmingEmailExpired:
        'К сожалению, срок действия вашей ссылки истёк. Мы уже отправили вам новую, пожалуйста, перейдите по ней (время действия новой ссылки – 15 минут).',
    confirmingEmailNotFound:
        'Ссылка, по которой был осуществлен переход, некорректна или уже была использована для перехода ранее.',
    recoveryTitle: 'Восстановление пароля',
    goBackToSignIn: 'Вернуться ко входу в Систему',
    alreadyHaveAccount: 'Уже есть учетная запись? Войти',
    sendToken: 'Отправить',
    recoveryInfo: 'На указанный email придет письмо со ссылкой для восстановления пароля',
    recoveryLinkSent: 'На указанный email отправлено письмо со ссылкой для восстановления пароля',
    recoveryPasswordFailed: 'Такой email не зарегистрирован или попытки восстановления были слишком частыми',
    newPasswordTitle: 'Смена пароля',
    newPassword: 'Новый пароль',
    repeatPassword: 'Повторите пароль',
    confirm: 'Подтвердить',
    passwordsMustMatch: 'Пароли должны совпадать',
    linkInvalidOrExpired: 'Ссылка неверная или срок её действия истёк',
    logout: 'Выход',
    loginEsia: 'Войти через Госуслуги',
    samlAuth: 'Войти через {title}',
    error: 'Произошла ошибка',
    alertInfo:
        'Для корректной работы функций портала рекомендуется использовать браузеры: Mozilla Firefox версии 72 и выше, Google Chrome версии 78 и выше, Opera версии от 63 и выше',
};

export default authentication;
