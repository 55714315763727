import { FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useFormikContext } from 'formik';
import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { CountryGroupsContext } from '../../../../../contexts';
import { useStore } from '../../../../../hooks';
import { CountryGroupsFieldNames, FormikServiceCountryGroup } from '../../../../../types';
import {
    getSelectedOption,
    getTextFieldRenderFunction,
    handleFormikAutocompleteChange,
    handleFormikRadioButtonChange,
} from '../../../../../utils';
import { extractOptionLabel } from '../../../../quotas';
import { useCountryGroupsPageStyles } from '../../../country-groups-styles';

const formControlLabelRadio = <Radio />;

export const CountryGroupsForm = observer((): JSX.Element => {
    const classes = useCountryGroupsPageStyles();
    const { intlStore: intl } = useStore();
    const { catalogs, isViewOnly } = useContext(CountryGroupsContext);
    const {
        isFormationPrinciplesLoaded,
        isCountriesLoaded,
        formationPrinciples,
        countries: countriesCatalog,
    } = catalogs;
    const formik = useFormikContext<FormikServiceCountryGroup>();
    const { touched, errors, values, handleChange, handleBlur, initialValues } = formik;
    const { name, countries, formationPrinciple, archived } = CountryGroupsFieldNames;

    const countryGroupsLabel = intl.formatMessage('countryGroups.countries');
    const formationPrinciplesLabel = intl.formatMessage('countryGroups.formationPrinciples');
    const nameLabel = intl.formatMessage('countryGroups.name');
    const archivedLabel = intl.formatMessage('countryGroups.archived');
    const activeLabel = intl.formatMessage('countryGroups.notArchived');

    const isNameWrong = !!touched[name] && !!errors[name];
    const isCountryGroupsWrong = !!touched[countries] && !!errors[countries];
    const isFormationPrinciplesWrong = !!touched[formationPrinciple] && !!errors[formationPrinciple];

    const renderCountryGroupsTextField = getTextFieldRenderFunction({
        label: countryGroupsLabel,
        error: isCountryGroupsWrong,
        helperText: !!touched[countries] && errors[countries],
        name: countries,
        required: true,
    });

    const renderFormationPrinciplesTextField = getTextFieldRenderFunction({
        label: formationPrinciplesLabel,
        error: isFormationPrinciplesWrong,
        helperText: !!touched[formationPrinciple] && errors[formationPrinciple],
        name: formationPrinciple,
        required: true,
    });

    const countriesCatalogHanlder = handleFormikAutocompleteChange(countries, formik);
    const formationPrinciplesCatalogHandler = handleFormikAutocompleteChange(formationPrinciple, formik);
    const archivedRadioButtonsHandler = handleFormikRadioButtonChange(archived, formik, !values[archived]);

    return (
        <React.Fragment>
            <Grid item className={classes.headingTitleWrapper}>
                <Typography variant="h1">{initialValues[name] || 'Создание группы стран'}</Typography>
            </Grid>
            <Grid container direction="column" className={classes.formContainer}>
                <TextField
                    variant="outlined"
                    label={nameLabel}
                    fullWidth
                    value={values[name]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={isNameWrong}
                    disabled={isViewOnly}
                    helperText={!!touched[name] && errors[name]}
                    name={name}
                    required
                />
                <Autocomplete
                    options={countriesCatalog}
                    getOptionSelected={getSelectedOption}
                    getOptionLabel={extractOptionLabel}
                    disabled={isViewOnly}
                    onChange={countriesCatalogHanlder}
                    onBlur={handleBlur}
                    value={values[countries]}
                    renderInput={renderCountryGroupsTextField}
                    multiple
                    loading={!isCountriesLoaded}
                />
                <Autocomplete
                    options={formationPrinciples}
                    getOptionSelected={getSelectedOption}
                    getOptionLabel={extractOptionLabel}
                    disabled={isViewOnly}
                    onChange={formationPrinciplesCatalogHandler}
                    onBlur={handleBlur}
                    value={values[formationPrinciple]}
                    renderInput={renderFormationPrinciplesTextField}
                    loading={!isFormationPrinciplesLoaded}
                />
                <RadioGroup
                    row
                    name={archived}
                    value={values[archived]}
                    onChange={archivedRadioButtonsHandler}
                    onBlur={handleBlur}
                >
                    <FormControlLabel
                        control={formControlLabelRadio}
                        label={archivedLabel}
                        checked={values[archived]}
                        disabled={isViewOnly}
                    />
                    <FormControlLabel
                        control={formControlLabelRadio}
                        label={activeLabel}
                        checked={!values[archived]}
                        disabled={isViewOnly}
                    />
                </RadioGroup>
            </Grid>
        </React.Fragment>
    );
});
