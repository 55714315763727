import { Button, Grid, Link } from '@material-ui/core';
import { Preset, TTable, TTableProps, TTableQueryData, TTableRow } from '@platform/ttable';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { generatePath, NavLink } from 'react-router-dom';
import { CellProps } from 'react-table';
import { entities, permissions } from '../../../../authSchemeConfig';
import { clientRoute } from '../../../../clientRoute';
import { AuthorizationCheck, ExportRegistryDialog } from '../../../../components';
import { useStore } from '../../../../hooks';
import { CountryGroupTableRowDTO, RegistryCodes } from '../../../../types';

const tableVisibileSettings: TTableProps<CountryGroupTableRowDTO>['visibleSettings'] = {
    toolbar: {
        isWithAddCustomColumns: false,
    },
};

const containerStyles: React.CSSProperties = { padding: '16px', flexDirection: 'row-reverse' };

export const CountryGroupsList = observer((): JSX.Element => {
    const { presetStore, intlStore, catalogStore, userStore, countryGroupsStore } = useStore();

    const saveListPresets = <RowType extends TTableRow>(settings: Preset<RowType>[]): Promise<void> =>
        presetStore.saveListPresets(settings);

    const [openUploadDialog, setOpenUploadDialog] = useState<boolean>(false);
    const [uploadTableQuery, setUploadTableQuery] = useState<TTableQueryData<CountryGroupTableRowDTO>>();

    const handleUpload = async (tableQuery: TTableQueryData<TTableRow>): Promise<void> => {
        setOpenUploadDialog(true);
        setUploadTableQuery(tableQuery);
    };

    const handleCloseUploadDialog = (): void => {
        setOpenUploadDialog(false);
        setUploadTableQuery(undefined);
    };

    const cellFormatters = {
        'customData.countries': function countries(
            p: React.PropsWithChildren<CellProps<CountryGroupTableRowDTO, any>>,
        ): JSX.Element {
            return <span>{p.row.original.customData.countries.map((country) => country.title).join(', ')}</span>;
        },
        'customData.archived': function archived(
            p: React.PropsWithChildren<CellProps<CountryGroupTableRowDTO, any>>,
        ): JSX.Element {
            return <span>{p.row.original.customData.archived ? 'Архивная' : 'Активная'}</span>;
        },
        'customData.name': function formationPrinciple(
            p: React.PropsWithChildren<CellProps<CountryGroupTableRowDTO, any>>,
        ): JSX.Element {
            return (
                <Link
                    component={NavLink}
                    underline="always"
                    to={generatePath(clientRoute.countryGroup, { id: p.row.original.id })}
                >
                    {p.row.original.customData.name || 'Без имени'}
                </Link>
            );
        },
    };

    return (
        <React.Fragment>
            <Grid container style={containerStyles}>
                <Button
                    component={NavLink}
                    to={clientRoute.countryGroupCreate}
                    color="primary"
                    size="small"
                    variant="contained"
                >
                    Создать группу стран
                </Button>
            </Grid>
            <AuthorizationCheck entityCode={entities.System} permCode={permissions.System.ViewCountryGroup}>
                {(allowed: boolean): JSX.Element => (
                    <TTable<CountryGroupTableRowDTO>
                        registryCode={RegistryCodes.countryGroup}
                        fetchData={countryGroupsStore.getCountryGroupsTable}
                        fetchColumns={countryGroupsStore.getCountryGroupsColumns}
                        presetsSetting={presetStore}
                        fetchCatalog={catalogStore.getCatalog}
                        fetchSelectDataByUrl={catalogStore.getSelectDataByUrl}
                        upload={handleUpload}
                        lang={intlStore.locale}
                        cellFormatters={cellFormatters}
                        saveListPresets={saveListPresets}
                        fetchUserRoleList={userStore.userRoleList}
                        isAdmin={allowed}
                        visibleSettings={tableVisibileSettings}
                    />
                )}
            </AuthorizationCheck>
            <ExportRegistryDialog
                openUploadDialog={openUploadDialog}
                uploadTableQuery={uploadTableQuery}
                handleCloseExportDialog={handleCloseUploadDialog}
                exportXlsx={countryGroupsStore.exportCountryGroupsXlsx}
                exportDoc={countryGroupsStore.exportCountryGroupsDoc}
            />
        </React.Fragment>
    );
});
