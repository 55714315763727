const users: Record<string, string | Record<string, string | Record<string, string>>> = {
    listTitle: 'Users',
    fields: {
        login: 'Login',
        status: 'Status',
        name: 'Full name',
        roles: 'Roles',
        lang: 'English speaking user',
    },
    status: {
        blocked: 'Blocked',
        active: 'Active',
        inactive: 'Inactive',
    },
    actions: {
        block: 'Block',
        unblock: 'Unblock',
    },
    loginAsUser: 'Login as user',
    editUser: 'Edit user',
    registryPagingInfo: 'Total {count, number} users. Displayed from {from, number} to {to, number}',
    confirmBlock: 'Confirm block',
    confirmBlockInfoText: 'Are you sure you want to block {login}?',
    deleteUser: 'Delete user',
    deleteUserConfirmText: 'The user will be deleted. Confirm action?',
    registryPagingInfoIfNull: 'Total 0 users. Displayed 0 users',
    notAllowed: 'It is impossible to perform this action on your account',
    profile: 'User profile',
};

export default users;
