import { Box, Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { StateBox } from '../../../../../components';
import { useStore } from '../../../../../hooks';

export type CampaignRequestHeaderUINProps = {
    cnCategory?: string;
    uin?: string;
};

export const CampaignRequestHeaderUIN = observer((props: CampaignRequestHeaderUINProps): JSX.Element => {
    const { cnCategory, uin } = props;
    const { intlStore: intl } = useStore();

    const uinTitle = intl.formatMessage('common.responsibleExecutor');

    return (
        <Grid item>
            <Grid container justify="space-between">
                {cnCategory && (
                    <Grid item>
                        <Box maxWidth={600}>
                            <StateBox isBold={false}>{cnCategory}</StateBox>
                        </Box>
                    </Grid>
                )}
                {uin && (
                    <Grid item wrap="nowrap">
                        <Box component="span" pr={2}>
                            {uinTitle}
                        </Box>
                        <Typography component="span" variant="body2">
                            {uin}
                        </Typography>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
});
