import { Grid } from '@material-ui/core';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import { TotBackground } from '../../../TotBackground';
import { SamlGetAuthUrlPage } from './SamlGetAuthUrlPage';

export const SamlPages = (): JSX.Element => {
    return (
        <TotBackground>
            <Grid container item direction="column" alignItems="center" justify="center">
                <Switch>
                    <Route path={clientRoute.samlGetAuthUrl}>
                        <SamlGetAuthUrlPage />
                    </Route>
                </Switch>
            </Grid>
        </TotBackground>
    );
};
