import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Link,
    Portal,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { DigitalSignature } from '@platform/crypto-ui';
import { SignatureStatus } from '@platform/crypto-ui/dist/common/types';
import { AxiosError } from 'axios';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAntiDoubleClick, useFetchCampaignRequestPFs, useStore } from '../../hooks';
import { FileDTOWithId, Transition } from '../../models';
import { LicensePrintFormList } from '../../pages';
import { FileDownload } from '../FileDownload';

export type LicenseFileDialogProps = {
    objectId: string;
    isModalOpen: boolean;
    setModalIsClosed: () => void;
    transition: Transition;
    lifeCycleTransition: () => Promise<void>;
    handleSuccessTransition: () => void;
    handleErrorTransition: (error: AxiosError) => void;
};

const signatureSetting = {
    signatureEnabled: true,
    signatureRequired: true,
    signatureBrowserPlugin: true,
    signatureUpload: false,
};

export const LicenseFileDialog = observer((props: LicenseFileDialogProps): JSX.Element => {
    const { transition, isModalOpen, setModalIsClosed, handleErrorTransition, handleSuccessTransition, objectId } =
        props;
    const rootStore = useStore();
    const { licenseStore, requestStore, intlStore } = rootStore;

    const { printForms, isFilesUpdated, fetchLicensePrintForm } = useFetchCampaignRequestPFs();

    useEffect(() => {
        fetchLicensePrintForm();
    }, []);

    const {
        getFileByte,
        uploadSignature,
        uploadSignatureFile,
        getSignatureInfo,
        downloadSignature,
        deleteSignature,
        downloadRequestFile,
    } = requestStore;

    const handleConfirm = (): Promise<void> => {
        return licenseStore
            .submitSignatureAndChangeState(transition.id, objectId)
            .then(handleSuccessTransition)
            .catch(handleErrorTransition)
            .finally(setModalIsClosed);
    };

    const [file, setFile] = useState<FileDTOWithId | null>(null);
    const fileId = file?.id || '';
    const fileName = file?.filename || '';
    const downloadFile = (): void => {
        if (!!file) {
            downloadRequestFile(file).then(() => {});
        }
    };

    useEffect(() => {
        licenseStore.licenseFile(objectId).then(setFile).catch(setModalIsClosed);
    }, [objectId, licenseStore]);

    const [isSending, startIcon, confirmHandler] = useAntiDoubleClick(handleConfirm);

    return (
        <Portal>
            <Dialog fullWidth={true} maxWidth="xs" open={isModalOpen} keepMounted={true}>
                <DialogTitle>
                    <FormattedMessage id="campaignRequest.signingLicense" />
                </DialogTitle>
                <DialogContent>
                    {!!file ? (
                        <Grid container direction="row" alignItems="center" justify="flex-start" spacing={2}>
                            <Grid item>
                                <Tooltip
                                    title={
                                        <FormattedMessage id="common.downloadFile" values={{ fileName: fileName }} />
                                    }
                                >
                                    <Link component="button" onClick={downloadFile}>
                                        <Typography>{fileName}</Typography>
                                    </Link>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <DigitalSignature
                                    locale={intlStore.locale}
                                    isView={false}
                                    fileTitle={fileName}
                                    fileTitleWithExtension={fileName}
                                    signatureSetting={signatureSetting}
                                    signatureStatus={SignatureStatus.WITHOUT}
                                    fileDownloadComponent={
                                        <FileDownload
                                            fileName={fileName}
                                            title={fileName}
                                            downloadFile={downloadFile}
                                            fontSize="16px"
                                        />
                                    }
                                    getFileByte={getFileByte(fileId)}
                                    uploadSignature={uploadSignature(fileId)}
                                    uploadSignatureFile={uploadSignatureFile(fileId)}
                                    getSignatureInfo={getSignatureInfo(fileId)}
                                    downloadSignature={downloadSignature(fileId)}
                                    deleteSignature={deleteSignature(fileId)}
                                    onSuccess={confirmHandler}
                                />
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container justify="center" direction="column" alignItems="center">
                            <Grid item>
                                <CircularProgress />
                            </Grid>
                            <Grid item>
                                <Typography>
                                    <FormattedMessage id="campaignRequest.licenseGenerationInProgress" />
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                    {!isFilesUpdated ? (
                        <Grid container justify="center">
                            <CircularProgress />
                        </Grid>
                    ) : (
                        <LicensePrintFormList printForms={printForms} />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        autoFocus
                        onClick={setModalIsClosed}
                        disabled={isSending}
                        startIcon={startIcon}
                        color="primary"
                    >
                        <FormattedMessage id="common.cancel" />
                    </Button>
                    {/*<Button
                            onClick={confirmHandler}
                            color="secondary"
                            variant="contained"
                            disabled={isSending}
                            startIcon={startIcon}
                        >
                            <FormattedMessage id="common.confirm" />
                        </Button>*/}
                </DialogActions>
            </Dialog>
        </Portal>
    );
});
