import { action, observable } from 'mobx';
import { UserNameModel, UserNameDTO } from '../person';
import { FileDTOWithId } from '../FileModel';
import { FormApi, FullSubmission } from '@platform/formiojs-react';
import { RequestStore, RootStore } from '../../store';
import { SignatureSettings, SignatureStatus } from '@platform/crypto-ui';

export type TaskStatus = 'COMPLETED' | 'NEW' | 'ATTEMPT_FAILED' | 'FAILED';

type DelayedTask = {
    taskStatus: TaskStatus;
};

export type CampaignRequestPfDTO = {
    templateId: string;
    templateTitle: string;
    docCategory?: string;
    author?: UserNameDTO;
    created?: string; // date
    pf?: FileDTOWithId;
    delayedTask?: DelayedTask;
    signatureSettings: SignatureSettings;
    signatureStatus: SignatureStatus;
    required: boolean;
};

export class CampaignRequestPfModel {
    formId = '';
    @observable templateId = '';
    @observable templateTitle = '';
    @observable authorId?: string;
    @observable docCategory?: string;
    @observable authorName?: string;
    @observable created?: string; // date
    @observable pf?: FileDTOWithId;
    @observable delayedTask?: DelayedTask;
    @observable formApi?: FormApi;
    @observable signatureStatus: SignatureStatus = SignatureStatus.WITHOUT;
    @observable signatureSettings: SignatureSettings = {
        signatureEnabled: false,
        signatureBrowserPlugin: false,
        signatureRequired: false,
        signatureUpload: false,
    };
    @observable required: boolean = false;

    @observable requestStore: RequestStore;

    @observable isGenerating = false;
    @observable errorCode = '';

    constructor(rootStore: RootStore, formId: string, formApi?: FormApi) {
        this.requestStore = rootStore.requestStore;
        this.formApi = formApi;
        this.formId = formId;
    }

    @action.bound
    load(dto: CampaignRequestPfDTO): CampaignRequestPfModel {
        const { author, ...restDto } = dto;
        Object.assign(this, restDto);

        const taskStatus = restDto.delayedTask?.taskStatus;
        this.isGenerating = taskStatus === 'NEW' || taskStatus === 'ATTEMPT_FAILED';

        if (author) {
            const authorModel: UserNameModel = new UserNameModel().load(author);
            this.authorId = authorModel.userId;
            this.authorName = authorModel.name;
        }
        return this;
    }

    @action.bound
    generatePrintForm(
        validateFunction: (onSuccess?: () => Promise<void>, withoutSignatureValidate?: boolean) => Promise<void>,
        isEditPage: boolean,
    ): void {
        this.isGenerating = true;

        validateFunction(async () => {}, true)
            .then(() => {
                this.successValidate();
            })
            .catch(() => {
                this.setValidateErrorCode(isEditPage);
                this.stopIsGenerating();
            });
    }

    @action.bound
    successValidate(): void {
        const submission: FullSubmission | undefined = this.formApi?.getSubmission();
        this.delayedTask = {
            taskStatus: 'NEW',
        };

        this.requestStore
            .generatePrintForm(this.formId, this.templateId, submission)
            .then(() => {
                this.dropErrorCode();
            })
            .catch(() => {
                this.setGenerateErrorCode();
            });
    }

    @action.bound
    updatePf(): void {
        this.requestStore.getPrintForm(this.templateId, this.formId).then((dto: CampaignRequestPfDTO) => {
            this.load(dto);
        });
    }

    @action.bound
    downloadPf(): void {
        this.pf && this.requestStore.downloadPrintFormFile(this.pf);
    }

    @action.bound
    setGenerateErrorCode(): void {
        this.errorCode = 'campaignRequest.printFormsTable.generationError';
        this.delayedTask = {
            taskStatus: 'FAILED',
        };
    }

    @action.bound
    setValidateErrorCode(isEditPage: boolean): void {
        this.errorCode = isEditPage
            ? 'campaignRequest.printFormsTable.cannotGenerateError'
            : 'campaignRequest.printFormsTable.cannotGenerateErrorWithLink';
    }

    @action.bound
    dropErrorCode(): void {
        this.errorCode = '';
    }

    @action.bound
    stopIsGenerating(): void {
        this.isGenerating = false;
    }
}
