import React from 'react';
import { IconButton, MenuItem } from '@material-ui/core';
import { Language } from '@material-ui/icons';
import { observer } from 'mobx-react';
import { MenuButton } from '../buttons/MenuButton';
import { useStore } from '../../hooks';

export const LanguageButton = observer(
    (): JSX.Element => {
        const { intlStore } = useStore();

        const handleClose = (locale: string) => (): void => {
            intlStore.changeLocale(locale);
        };

        const renderMenuItems = (hideMenu: () => void): JSX.Element[] => {
            return intlStore.locales.map((l) => {
                const handleSelect = (): void => {
                    handleClose(l)();
                    hideMenu();
                };
                return (
                    <MenuItem
                        dense
                        key={l}
                        role="menuitem"
                        button={true}
                        selected={intlStore.locale === l}
                        onClick={handleSelect}
                        tabIndex={0}
                    >
                        {l}
                    </MenuItem>
                );
            });
        };

        const renderButton = (onClick: (event: React.MouseEvent<HTMLButtonElement>) => void): JSX.Element => {
            return (
                <IconButton color="secondary" onClick={onClick}>
                    <Language />
                </IconButton>
            );
        };

        return (
            <MenuButton
                renderButton={renderButton}
                handleClose={handleClose(intlStore.locale)}
                renderMenuItems={renderMenuItems}
            />
        );
    },
);
