import { Box, Grid, Tooltip, Typography } from '@material-ui/core';
import { useTheme, withTheme } from '@material-ui/core/styles';
import React, { ReactNode } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { DeadlineDTO, DeadlineStatus } from '../../../types';

export type RequestDeadlineBoxProps = {
    backgroundColor: string;
};

export const RequestDeadlineBox = withTheme(
    styled(Box)<RequestDeadlineBoxProps>`
        background-color: ${({ backgroundColor }) => `${backgroundColor}`};
        border-radius: 4px;
        padding: ${({ theme }) =>
            `${theme.spacing(2.25)}px ${theme.spacing(4.25)}px ${theme.spacing(2.5)}px ${theme.spacing(4)}px`};
        display: inline-block;
    `,
);

export type RequestDeadlineProps = {
    deadline: DeadlineDTO | undefined;
    title: ReactNode;
    withBlock?: boolean;
};

export const RequestDeadline = (props: RequestDeadlineProps): JSX.Element => {
    const theme = useTheme();
    const { deadline, title, withBlock } = props;
    let color: string | undefined;

    if (deadline) {
        switch (deadline.status) {
            case DeadlineStatus.WARNING: {
                color = theme.variables.palette.deadline.warning;
                break;
            }
            case DeadlineStatus.INVALID: {
                color = theme.variables.palette.deadline.error;
                break;
            }
            case DeadlineStatus.ERROR: {
                color = theme.variables.palette.deadline.error;
                break;
            }
            default:
                color = theme.variables.palette.deadline.valid;
        }
    }

    return (
        <React.Fragment>
            {deadline && (
                <Grid item>
                    <RequestDeadlineBox backgroundColor={color}>
                        {deadline.date ? (
                            <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                                {title}: <FormattedDate value={deadline.date} />
                                {withBlock && deadline.delay && (
                                    <Tooltip
                                        title={<FormattedMessage id="campaignRequest.deadlines.notRelevantTooltip" />}
                                    >
                                        <span>
                                            (
                                            <FormattedMessage id="campaignRequest.deadlines.notRelevant" />)
                                        </span>
                                    </Tooltip>
                                )}
                            </Typography>
                        ) : (
                            <React.Fragment>
                                {deadline.status === DeadlineStatus.ERROR && (
                                    <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                                        <FormattedMessage id="campaignRequest.deadlines.error" />
                                    </Typography>
                                )}
                            </React.Fragment>
                        )}
                    </RequestDeadlineBox>
                </Grid>
            )}
        </React.Fragment>
    );
};
