import { TextField } from '@material-ui/core';
import { AutocompleteRenderInputParams } from '@material-ui/lab';
import { CodeTitle } from '@platform/ttable';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { AutocompleteMobx } from '../../../../../components';
import { useQuotaContext } from '../../../../../hooks';
import { getSelectedOption } from '../../../../../utils';
import { useQuotaPageStyles } from '../../../quota-styles';
import { extractOptionLabel, returnLoaderOrIcon } from '../../../quota-utils';

export const QuotaOperationOptionsAutocomplete = observer((): JSX.Element => {
    const classes = useQuotaPageStyles();
    const { quotasModel, isViewOnly, isEditing } = useQuotaContext();
    const { quota, catalogs, setDefaultOperation } = quotasModel;
    const { operations, productCategory, setOperations } = quota;
    const { operationOptions, isOperationOptionsLoaded, loadOperationOptions } = catalogs;

    const autocompleteClasses = {
        popupIndicator: classes.popupIndicator,
    };

    const shouldShowOperationsOptionPopupIcon = quota.productCategory && !isViewOnly;

    const operationOptionsPopupIcon = shouldShowOperationsOptionPopupIcon
        ? returnLoaderOrIcon(catalogs.isOperationOptionsLoaded)
        : null;

    useEffect(() => {
        // Здесь подгружается справочник с операциями.
        // Этот справочник не подгружается без V этого V кода
        const productCategoryCode = productCategory?.code;
        if (isViewOnly || isEditing) {
            // Мы либо смотрим, либо редактируем
            // Загружай справочник
            loadOperationOptions(productCategoryCode);
        } else {
            // Мы создаем
            // Загружай справочник
            loadOperationOptions(productCategoryCode)
                // Устанавливай справочникa деф. значение
                .then(setDefaultOperation);
        }
    }, [productCategory]);

    const operationsInputHandler = (e: any, value: CodeTitle[]): void => {
        setOperations(value);
    };

    const loadOperationOptionsCatalog = (): void => {
        loadOperationOptions(productCategory?.code);
    };

    const renderOperationsTextfield = (params: AutocompleteRenderInputParams): JSX.Element => {
        return (
            <TextField
                {...params}
                variant="outlined"
                label="Операция"
                error={!!quota.touched.operations && !!quota.errors.operations}
                helperText={quota.errors.operations}
                required
            />
        );
    };

    return (
        <AutocompleteMobx
            options={operationOptions}
            getOptionLabel={extractOptionLabel}
            renderInput={renderOperationsTextfield}
            multiple
            size="medium"
            onChange={operationsInputHandler}
            value={operations}
            popupIcon={operationOptionsPopupIcon}
            classes={autocompleteClasses}
            disabled={!shouldShowOperationsOptionPopupIcon || !isOperationOptionsLoaded}
            getOptionSelected={getSelectedOption}
            onOpen={loadOperationOptionsCatalog}
        />
    );
});
