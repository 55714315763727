import { Backdrop, CircularProgress } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { ReactNode, useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { clientRoute } from '../clientRoute';
import { useStore } from '../hooks';

export type AuthenticationProps = {
    children: ReactNode;
    redirectUrl?: string;
};

export const Authentication = observer((props: AuthenticationProps): JSX.Element => {
    const { children, redirectUrl = clientRoute.loginRedirect } = props;
    const { api } = useStore();
    const location = useLocation();
    const backUrl =
        location.search.indexOf('backUrl') !== -1 ? location.search : `?backUrl=${location.pathname}${location.search}`;

    useEffect(() => {
        api.authVerify().then((r) => r);
    }, [api]);

    const renderComponent = (status: 'ok' | 'pending' | 'unauthorized'): JSX.Element => {
        switch (status) {
            case 'ok':
                return children as JSX.Element;
            case 'unauthorized':
                return <Redirect to={{ pathname: redirectUrl, search: backUrl }} />;
            default:
                return (
                    <Backdrop open>
                        <CircularProgress />
                    </Backdrop>
                );
        }
    };

    return renderComponent(api.authStatus);
});
