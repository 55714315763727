import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { clientRoute, requestRoutes } from '../../clientRoute';
import { Authentication } from '../../components';
import { ObjectRoutesContext } from '../../contexts';
import { CampaignRequestListPage, CampaignRequestListPageNew } from './campaign-request-list-page';
import { CampaignRequestPage } from './campaign-request-page';

export const CampaignRequestPages = (): JSX.Element => {
    return (
        <Authentication>
            <ObjectRoutesContext.Provider value={requestRoutes}>
                <Switch>
                    <Route path={clientRoute.requestsOld}>
                        <CampaignRequestListPage />
                    </Route>
                    <Route path={[clientRoute.requestSubTab, clientRoute.requestTab, clientRoute.request]}>
                        <CampaignRequestPage />
                    </Route>
                    <Route path={clientRoute.requests}>
                        <CampaignRequestListPageNew />
                    </Route>
                </Switch>
            </ObjectRoutesContext.Provider>
        </Authentication>
    );
};
