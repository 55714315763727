import { List, ListItem } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { ReactNode } from 'react';
import { FileDTOWithFileId } from '../models';
import { FileComponent } from './FileComponent';

export type FileListComponentProps = {
    files: FileDTOWithFileId[];
    downloadFile: (file: FileDTOWithFileId) => Promise<void>;
    noDataText: ReactNode | string;
};

export const FileListComponent = observer((props: FileListComponentProps): JSX.Element => {
    const { files, downloadFile, noDataText } = props;
    return (
        <List disablePadding>
            {files && files.length > 0
                ? files.map((f) => {
                      return (
                          <ListItem key={f.fileId} disableGutters>
                              <FileComponent file={f} onDownloadFile={downloadFile} />
                          </ListItem>
                      );
                  })
                : noDataText}
        </List>
    );
});
