import { Grid, ThemeProvider } from '@material-ui/core';
import { useFeature } from 'feature-toggle-jsx';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createLandingThemeCreator, LandingThemeCreator } from '../../createThemes';
import { useStore } from '../../hooks';
import { StartAuthHeaderLink } from './StartAuthHeaderLink';
import { StartPageHeaderLink as HeaderLink } from './StartPageHeaderLink';
import { StartPageMenuItems } from './StartPageMenuItems';

const preventDefault = (event: React.SyntheticEvent): void => event.preventDefault();

export const StartPageHeaderLinks = (): JSX.Element => {
    const rootStore = useStore();
    const landingThemeCreator: LandingThemeCreator = createLandingThemeCreator(rootStore);
    const [open, setOpen] = React.useState<boolean>(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);

    const [startPageLinks] = useFeature('startPageLinks');

    const handleToggle = (): void => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: React.MouseEvent<Document, MouseEvent>): void => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };

    return (
        <Grid container direction="row" alignItems="center" justify={startPageLinks ? 'space-around' : 'flex-end'}>
            <ThemeProvider theme={landingThemeCreator}>
                {startPageLinks && (
                    <React.Fragment>
                        <Grid item>
                            <HeaderLink href="#" onClick={preventDefault} color="secondary" underline="none">
                                <FormattedMessage id="startPage.headerLinks.about" />
                            </HeaderLink>
                        </Grid>
                        <Grid item>
                            <HeaderLink href="#" onClick={preventDefault} color="secondary" underline="none">
                                <FormattedMessage id="startPage.headerLinks.news" />
                            </HeaderLink>
                        </Grid>
                        <Grid item>
                            <HeaderLink href="#" onClick={preventDefault} color="secondary" underline="none">
                                <FormattedMessage id="startPage.headerLinks.documents" />
                            </HeaderLink>
                        </Grid>
                        <Grid item>
                            <HeaderLink href="#" onClick={preventDefault} color="secondary" underline="none">
                                <FormattedMessage id="startPage.headerLinks.resources" />
                            </HeaderLink>
                        </Grid>
                        <Grid item>
                            <HeaderLink href="#" onClick={preventDefault} color="secondary" underline="none">
                                <FormattedMessage id="startPage.headerLinks.contacts" />
                            </HeaderLink>
                        </Grid>
                        <Grid item>
                            <HeaderLink
                                href="http://radar-dev.tot-dev.ru/"
                                target="_blank"
                                color="secondary"
                                underline="none"
                            >
                                <FormattedMessage id="startPage.headerLinks.radar" />
                            </HeaderLink>
                        </Grid>
                    </React.Fragment>
                )}

                <Grid item>
                    <StartAuthHeaderLink handleToggle={handleToggle} anchorRef={anchorRef} />
                </Grid>
            </ThemeProvider>
            <StartPageMenuItems
                open={open}
                anchorRef={anchorRef}
                handleClose={handleClose}
                handleToggle={handleToggle}
            />
        </Grid>
    );
};
