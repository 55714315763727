import { CircularProgress, Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { clientRoute } from '../../../clientRoute';
import { QuotasContext } from '../../../contexts';
import { useStore } from '../../../hooks';
import { QuotasModel, yupQuotaSchema } from '../../../models';
import { matchURL } from '../../../utils';
import { QuotaDistributionTables } from '../quota-distribution-tables';
import { useQuotaPageStyles } from '../quota-styles';
import { QuotasDateTimePicker } from '../quota-utils';
import { QuotasControlPanel } from '../quotas-control-panel';
import {
    QuotaOperationOptionsAutocomplete,
    QuotaSettingsAutocompletes,
    QuotaTitleInput,
    QuotaTitle,
    QuotaVolumeSettingsAutocompletes,
    QuotaPeriodsDurationInputs,
    QuotaValidityDurationInputs,
} from './components';

type RouteParams = {
    id: string;
};

export const QuotaPage = observer((): JSX.Element => {
    const classes = useQuotaPageStyles();
    const location = useLocation();
    const { quotasStore, catalogStore } = useStore();

    const quotasModel = useMemo(() => new QuotasModel(quotasStore, catalogStore), []);

    const { id } = useParams<RouteParams>();
    const { quota, catalogs, getQuota } = quotasModel;
    const { countryGroups, participants, category } = quota;
    const { isCategoryOptionsLoaded, isTypeOptionsLoaded } = catalogs;

    const isEditing = matchURL(location.pathname, clientRoute.quotasEdit);
    const isViewOnly = matchURL(location.pathname, clientRoute.quotasQuota);
    const isCreating = matchURL(location.pathname, clientRoute.quotasCreate);

    const [validationSchema, setValidationSchema] = useState<Yup.AnyObjectSchema>(yupQuotaSchema);

    useEffect(() => {
        setValidationSchema(quota.chooseValidationSchema());
    }, [countryGroups, participants, category]);

    const formik = useFormik({
        initialValues: quota,
        validationSchema,
        onSubmit: () => {},
    });

    useEffect(() => {
        if (!quota.formik) {
            quota.setFormik(formik);
        }
    }, [formik]);

    useEffect(() => {
        /** Это отправная точка. Здесь всё загружается. */
        if (isEditing || isViewOnly) {
            // Мы либо редактируем, либо смотрим.
            // Получаем квоту
            getQuota(id)
                // Загружаем все справочники
                .then(catalogs.loadAll)
                // Валидируем квоту
                .then(quota.validate)
                // Валидируем таблицы
                .then(quota.validateAllCountryGroups);
        } else {
            // Мы создаем.
            // Устанавливаем квоте дефолтные даты
            quota.setDefaultDates();
            // Не загружаем квоту.
            catalogs
                // Загружаем все справочники.
                .loadAll();
        }
    }, [id]);

    useEffect(() => {
        // Загружаем в автокомп. дефолт. значения справочников.
        if (isCreating && isCategoryOptionsLoaded) {
            quotasModel.setDefaultCategory();
        }
        if (isCreating && isTypeOptionsLoaded) {
            quotasModel.setDefaultQuotaType();
        }
    }, [isCategoryOptionsLoaded, isTypeOptionsLoaded]);

    if (quotasModel.isLoading) {
        return (
            <Grid container className={classes.spinner}>
                <CircularProgress />
            </Grid>
        );
    }

    return (
        <QuotasContext.Provider value={{ quotasModel, isEditing, isCreating, isViewOnly }}>
            <Grid
                container
                direction="column"
                className={isViewOnly ? classes.mainContainerViewOnly : classes.mainContainer}
            >
                <QuotaTitle />
                <Grid container direction="column" className={classes.formContainer}>
                    <Grid item>
                        <QuotasDateTimePicker
                            label="Дата создания квоты"
                            value={quota.created}
                            fullWidth
                            error={!!quota.errors.created}
                            disabled
                            readOnly={isViewOnly}
                            className={classes.singleDatePicker}
                            helperText={quota.errors.created}
                        />
                    </Grid>
                    <QuotaTitleInput />
                    <QuotaSettingsAutocompletes />
                    <QuotaPeriodsDurationInputs />
                    <QuotaOperationOptionsAutocomplete />
                    <QuotaVolumeSettingsAutocompletes />
                    <QuotaValidityDurationInputs />
                </Grid>
            </Grid>
            <QuotaDistributionTables />
            <QuotasControlPanel />
        </QuotasContext.Provider>
    );
});
