import { Button, Grid } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { Form, FormApi, FormModel } from '@platform/formiojs-react';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath, NavLink, useParams } from 'react-router-dom';
import { entities, permissions } from '../../../../../authSchemeConfig';
import { clientRoute } from '../../../../../clientRoute';
import { AuthorizationCheck } from '../../../../../components';
import { useFormStyles } from '../../../../../hooks';
import { CampaignRegFormRouteParams } from '../types';

export type CampaignRegFormCardProps = {
    model: FormModel;
    onFormReady: (form: FormApi) => void;
    onDeleteLinkClick: () => void;
};

export const CampaignRegFormCard = (props: CampaignRegFormCardProps): JSX.Element => {
    const { model, onFormReady, onDeleteLinkClick } = props;
    const { id, rfId } = useParams<CampaignRegFormRouteParams>();
    const classes = useFormStyles();
    const { locale } = useIntl();

    return (
        <Grid container direction="column" spacing={5}>
            <Grid item container justify="flex-end" spacing={5}>
                <AuthorizationCheck
                    entityCode={entities.CampaignRegForm}
                    entityId={rfId}
                    permCode={permissions.CampaignRegForm.Edit}
                >
                    <Grid item>
                        <Button
                            variant="text"
                            color="primary"
                            startIcon={<Edit />}
                            component={NavLink}
                            to={generatePath(clientRoute.campaignRegFormEdit, { id, rfId })}
                        >
                            <FormattedMessage id="common.edit" />
                        </Button>
                    </Grid>
                </AuthorizationCheck>
                <AuthorizationCheck
                    entityCode={entities.CampaignRegForm}
                    entityId={rfId}
                    permCode={permissions.CampaignRegForm.Delete}
                >
                    <Grid item>
                        <Button
                            aria-haspopup="true"
                            aria-controls="confirm-delete-rf"
                            variant="text"
                            color="primary"
                            startIcon={<Delete />}
                            onClick={onDeleteLinkClick}
                        >
                            <FormattedMessage id="common.delete" />
                        </Button>
                    </Grid>
                </AuthorizationCheck>
            </Grid>
            <Grid item>
                <Form
                    locale={locale}
                    className={classes.formWithNoOffset}
                    form={model}
                    readOnly
                    onFormReady={onFormReady}
                />
            </Grid>
        </Grid>
    );
};
