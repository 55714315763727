import React from 'react';
import { CatalogsModel } from '../models';

export type CountryGroupsContextType = {
    isEditing: boolean;
    isCreating: boolean;
    isViewOnly: boolean;
    catalogs: CatalogsModel;
};

export const CountryGroupsContext = React.createContext<CountryGroupsContextType>({} as CountryGroupsContextType);
