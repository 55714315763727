import { IconButton, MenuItem, SvgIcon } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router-dom';
import { entities, permissions, permissionsConfig } from '../../../authSchemeConfig';
import { clientRoute } from '../../../clientRoute';
import {
    ActionMenuItem,
    AuthorizationCheck,
    AuthorizationCheckAll,
    ConfirmationDialog,
    MenuButton,
} from '../../../components';
import { useStore } from '../../../hooks';
import { CampaignRequestRow } from '../../../models';
import { ReactComponent as DotMenu } from '../../../resources/images/icons/dot-menu.svg';
import { AuthorizationCheckQuery } from '../../../store';

export type AuthorityDocListActionsBtnsProps = {
    campaignRow: CampaignRequestRow;
    reloadData: () => void;
    isConfirm?: boolean;
    setIsConfirm?: React.Dispatch<React.SetStateAction<boolean>>;
    handleEditExecutorDialogOpen: (campaignRow: CampaignRequestRow, title?: string) => void;
};

export const AuthorityDocListActionsBtns = observer(
    (props: AuthorityDocListActionsBtnsProps): JSX.Element => {
        const { campaignRow, reloadData, isConfirm, setIsConfirm, handleEditExecutorDialogOpen } = props;
        const { id } = campaignRow;

        const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] = useState<boolean>(false);

        const { requestStore } = useStore();

        useEffect(() => {
            if (setIsConfirm && isConfirm) {
                setIsConfirm(false);
                reloadData();
            }
        }, [isConfirm, setIsConfirm, reloadData]);

        const deleteCampaign = useCallback((): void => {
            try {
                requestStore.deleteRequest(id).finally(reloadData);
            } catch (error) {
                console.error(error);
            }
        }, [reloadData, id, requestStore]);

        const openDeleteConfirmationDialog = (): void => {
            setIsDeleteConfirmationDialogOpen(true);
        };

        const closeDeleteConfirmationDialog = (): void => {
            setIsDeleteConfirmationDialogOpen(false);
        };

        const onDeleteConfirm = async (): Promise<void> => {
            await deleteCampaign();
            closeDeleteConfirmationDialog();
        };

        const onDeleteCancel = (): void => {
            closeDeleteConfirmationDialog();
        };

        const handleConfirmAddExecutor = useCallback((): void => {
            requestStore.setCurrentUserAsExecutor(id).finally(reloadData);
        }, [reloadData, id, requestStore]);

        const addUpdateAppointmentQueries = useMemo<AuthorizationCheckQuery[]>(() => {
            return [permissionsConfig.addActualAppointment(id), permissionsConfig.updateActualAppointment(id)];
        }, [id]);

        const renderActionItems = useCallback((): ((hideMenu?: () => void) => ReactNode[]) => {
            const { id } = campaignRow;

            return (hideMenu): ReactNode[] => {
                const onDeleteClick = (): void => {
                    hideMenu && hideMenu();
                    openDeleteConfirmationDialog();
                };

                return [
                    <AuthorizationCheckAll key="appointment" or={false} queries={addUpdateAppointmentQueries}>
                        {(allowed: boolean[]) => {
                            const [addActualAppointment, updateActualAppointment] = allowed;
                            return (
                                <React.Fragment>
                                    {addActualAppointment && updateActualAppointment && (
                                        <ActionMenuItem
                                            messageId="campaignRequest.actions.appointExecutor"
                                            onClick={() => handleEditExecutorDialogOpen(campaignRow)}
                                        />
                                    )}

                                    {!addActualAppointment && updateActualAppointment && (
                                        <ActionMenuItem
                                            messageId="campaignRequest.actions.changeExecutor"
                                            onClick={() =>
                                                handleEditExecutorDialogOpen(
                                                    campaignRow,
                                                    'campaignRequest.dialog.changeExecutorTitle',
                                                )
                                            }
                                        />
                                    )}

                                    {addActualAppointment && (
                                        <ActionMenuItem
                                            messageId="campaignRequest.actions.requestToWork"
                                            onClick={handleConfirmAddExecutor}
                                        />
                                    )}
                                </React.Fragment>
                            );
                        }}
                    </AuthorizationCheckAll>,
                    <AuthorizationCheck
                        key="edit"
                        entityCode={entities.CampaignRequest}
                        entityId={id}
                        permCode={permissions.CampaignRequest.Edit}
                    >
                        <ActionMenuItem messageId="common.edit" path={generatePath(clientRoute.licenseEdit, { id })} />
                    </AuthorizationCheck>,
                    <AuthorizationCheck
                        key="delete"
                        entityCode={entities.CampaignRequest}
                        entityId={id}
                        permCode={permissions.CampaignRequest.Delete}
                    >
                        <MenuItem dense button={true} onClick={onDeleteClick}>
                            <FormattedMessage id="common.delete" />
                        </MenuItem>
                    </AuthorizationCheck>,
                ];
            };
        }, [campaignRow, handleEditExecutorDialogOpen, addUpdateAppointmentQueries, handleConfirmAddExecutor]);

        const renderActionsButton = (onClick: (event: React.MouseEvent<HTMLButtonElement>) => void): JSX.Element => {
            return (
                <IconButton onClick={onClick}>
                    <SvgIcon>
                        <DotMenu />
                    </SvgIcon>
                </IconButton>
            );
        };

        return (
            <React.Fragment>
                <MenuButton renderButton={renderActionsButton} renderMenuItems={renderActionItems()} />
                <ConfirmationDialog
                    id="delete"
                    open={isDeleteConfirmationDialogOpen}
                    title={<FormattedMessage id="common.confirmDeletion" />}
                    message={
                        <FormattedMessage
                            id="campaignRequest.confirmDeletionInfoText"
                            values={{ number: campaignRow.number }}
                        />
                    }
                    onConfirm={onDeleteConfirm}
                    onCancel={onDeleteCancel}
                    keepMounted
                />
            </React.Fragment>
        );
    },
);
