import { FormApi } from '@platform/formiojs-react';
import downloadFile from 'js-file-download';
import { action, computed, observable } from 'mobx';
import { asyncAction } from 'mobx-utils';
import { IntlStore, RequestStore, RootStore } from '../../store';
import { CampaignRequestPfDTO, CampaignRequestPfModel } from './CampaignRequestPfModel';

export class CampaignRequestPfListModel {
    formId = '';
    @observable printForms: CampaignRequestPfModel[] = [];

    @observable isFilesLoading = false;
    @observable rootStore: RootStore;
    @observable requestStore: RequestStore;
    @observable intlStore: IntlStore;
    @observable formApi?: FormApi;

    constructor(rootStore: RootStore, formId: string, formApi?: FormApi) {
        this.formId = formId;
        this.rootStore = rootStore;
        this.requestStore = rootStore.requestStore;
        this.intlStore = rootStore.intlStore;
        this.formApi = formApi;
    }

    @computed
    get isFilesGenerating() {
        return this.printForms.some(({ delayedTask }) => {
            return delayedTask?.taskStatus === 'NEW' || delayedTask?.taskStatus === 'ATTEMPT_FAILED';
        });
    }

    @action.bound
    load(dto: CampaignRequestPfDTO[]): void {
        this.printForms = dto.map((dto) => {
            return new CampaignRequestPfModel(this.rootStore, this.formId, this.formApi).load(dto);
        });
    }

    @computed
    get generatedPF(): CampaignRequestPfModel[] {
        return this.printForms.filter((form: CampaignRequestPfModel) => !!form.pf);
    }

    @asyncAction
    @action.bound
    async downloadAllPf(requestNumber: string): Promise<void> {
        const { notificationStore, intlStore } = this.rootStore;
        try {
            this.isFilesLoading = true;
            const { data } = await this.requestStore.downloadAllPrintFormFiles(this.formId);
            const fileName = `${this.intlStore.formatMessage('campaignRequest.allFiles', {
                title: requestNumber,
            })}.zip`;
            downloadFile(data, fileName);
        } catch (error) {
            if (error.request.responseType === 'blob' && error.response?.data instanceof Blob) {
                const reader = new FileReader();
                reader.onload = () => {
                    let errorMessage: string | undefined;
                    if (typeof reader.result === 'string') {
                        try {
                            errorMessage = JSON.parse(reader.result);
                        } catch {
                            errorMessage = reader.result;
                        }
                        typeof errorMessage === 'string' && notificationStore.onError(errorMessage);
                    }
                };

                reader.readAsText(error.response.data);
            } else if (typeof error.response?.data === 'string') {
                notificationStore.onError(error.response.data);
            } else {
                notificationStore.onError(intlStore.formatMessage('common.errorText'));
            }
        }
        this.isFilesLoading = false;
    }
}
