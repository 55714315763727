const digitalSignature: Record<string, Record<string, string> | string> = {
    form: {
        signatureEnabled: 'Включить использование электронной подписи',
        signatureRequired: 'Обязательно наличие подписи',
        signatureVariants: 'Способы подписания',
        signatureBrowserPlugin: 'Плагин браузера',
        signatureUpload: 'Загрузка подписи или файла с прикреплённой подписью',
    },
};

export default digitalSignature;
