import { action, observable } from 'mobx';
import 'mobx-react-lite/batchingForReactDom';
import { CatalogStore, QuotasStore } from '../../store';
import { QuotaCategoryCodes, QuotaDTO, QuotaFields, QuotaOperationCodes, QuotaTypesCodes } from '../../types';
import { Quota } from './Quota';
import { CatalogsModel } from '../catalogs';

const onTypoErrorMessage = 'При заполнении была допущена ошибка';

export class QuotasModel {
    @observable private quotasStore: QuotasStore;

    @observable catalogs: CatalogsModel;
    @observable quota: Quota;

    @observable isLoading: boolean = false;

    constructor(quotaStore: QuotasStore, catalogStore: CatalogStore) {
        this.quotasStore = quotaStore;
        this.quota = new Quota();
        this.catalogs = new CatalogsModel(catalogStore);
    }

    @action.bound
    private async preValidateQuota(): Promise<boolean> {
        this.quota.saveCountryGroups();
        const isFormValid = await this.quota.validate();
        let isCountryGroupsValid = true;
        let isParticipantsValid = true;
        if (this.quota.countryGroups.length > 0) {
            isCountryGroupsValid = await this.quota.validateAllCountryGroups();
        }
        if (isCountryGroupsValid && this.quota.participants.length > 0) {
            isParticipantsValid = await this.quota.validateAllParticipants();
        }

        return !isFormValid || !isParticipantsValid || !isCountryGroupsValid;
    }

    @action.bound
    loadQuota(quotaDTO: QuotaDTO): void {
        this.quota = new Quota(quotaDTO);
    }

    @action.bound
    async getQuota(quotaId: string): Promise<void> {
        this.setIsLoading(true);
        try {
            const quota = await this.quotasStore.getQuota(quotaId);
            this.loadQuota(quota);
        } finally {
            this.setIsLoading(false);
        }
    }

    @action.bound
    async updateQuota(): Promise<void> {
        const isQuotaInvalid = await this.preValidateQuota();
        if (isQuotaInvalid) {
            return Promise.reject(onTypoErrorMessage);
        }

        this.setIsLoading(true);
        try {
            await this.quotasStore.updateQuota(this.quota.getQuotaCreateDTO(), this.quota.id);
        } finally {
            this.setIsLoading(false);
        }
    }

    @action.bound
    async createQuota(): Promise<QuotaDTO | void> {
        const isQuotaInvalid = await this.preValidateQuota();
        if (isQuotaInvalid) {
            return Promise.reject(onTypoErrorMessage);
        }

        this.setIsLoading(true);
        try {
            return await this.quotasStore.postQuota(this.quota.getQuotaCreateDTO());
        } finally {
            this.setIsLoading(false);
        }
    }

    @action.bound
    async deleteQuota(): Promise<void> {
        if (!this.quota.id) {
            console.error('Got no id in deleteQuota');
            return Promise.resolve();
        }
        await this.quotasStore.deleteQuota(this.quota.id);
    }

    @action.bound
    setDefaultCategory(): void {
        this.quota.category =
            this.catalogs.categoryOptions.find((opt) => opt.code === QuotaCategoryCodes.distributed) || null;
    }

    @action.bound
    setDefaultQuotaType(): void {
        this.quota.quotaType = this.catalogs.typeOptions.find((opt) => opt.code === QuotaTypesCodes.year) || null;
    }

    @action.bound
    setDefaultOperation(): void {
        this.quota.operations = this.catalogs.operationOptions.filter((opt) => opt.code === QuotaOperationCodes.import);
        this.quota.setFieldUntouched(QuotaFields.operations);
    }

    @action.bound
    setIsLoading(status: boolean): void {
        this.isLoading = status;
    }
}
