import { Box, Grid, Tooltip } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { IconLink } from '../../../../components';
import { CampaignRequestPfModel, FileDTOWithId } from '../../../../models';
import { ReactComponent as FileIcon } from '../../../../resources/images/icons/file.svg';
import { CampaignRequestPrintFormsSignature } from './CampaignRequestPrintFormsSignature';

export type PfListTooltipFileProps = {
    downloadPf: () => void;
    file: FileDTOWithId;
    templateTitle: string;
};

export const PfListTooltipFile = (props: PfListTooltipFileProps): JSX.Element => {
    const { downloadPf, file, templateTitle } = props;

    return (
        <Tooltip title={<FormattedMessage id="common.downloadFile" values={{ fileName: file.filename }} />}>
            <IconLink component="button" onClick={downloadPf} icon={<FileIcon />} text={templateTitle} />
        </Tooltip>
    );
};

export type CampaignRequestPrintFormsListProps = {
    printForms: CampaignRequestPfModel[];
};

export const CampaignRequestPrintFormsList = observer((props: CampaignRequestPrintFormsListProps): JSX.Element => {
    const { printForms } = props;

    const renderPfListRow = (printForm: CampaignRequestPfModel): ReactNode => {
        const { templateId, templateTitle, pf } = printForm;
        return (
            pf && (
                <Grid container item alignItems="center" wrap="nowrap" spacing={2} key={templateId}>
                    <Grid item>
                        <PfListTooltipFile downloadPf={printForm.downloadPf} file={pf} templateTitle={templateTitle} />
                    </Grid>
                    <Grid item>
                        <CampaignRequestPrintFormsSignature printForm={printForm} isView={true} />
                    </Grid>
                </Grid>
            )
        );
    };

    return (
        <Box p={6}>
            <Grid container direction="column" spacing={4}>
                {printForms.map(renderPfListRow)}
            </Grid>
        </Box>
    );
});
