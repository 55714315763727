import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { ReactNode } from 'react';
import { CampaignRequestPfModel } from '../../../models';
import {
    CampaignRequestPrintFormsListProps,
    CampaignRequestPrintFormsSignature,
    PfListTooltipFile,
} from '../../campaign-request';

const containerStyle: React.CSSProperties = { padding: '6px 0px' };

export const LicensePrintFormList = observer((props: CampaignRequestPrintFormsListProps): JSX.Element => {
    const { printForms } = props;

    const renderPfListRow = (printForm: CampaignRequestPfModel): ReactNode => {
        const { templateId, templateTitle, pf } = printForm;
        return (
            pf && (
                <Grid container item alignItems="flex-start" wrap="nowrap" key={templateId}>
                    <Grid item>
                        <PfListTooltipFile downloadPf={printForm.downloadPf} file={pf} templateTitle={templateTitle} />
                    </Grid>
                    <Grid item>
                        <CampaignRequestPrintFormsSignature printForm={printForm} isView={true} />
                    </Grid>
                </Grid>
            )
        );
    };

    return (
        <Grid container direction="column" style={containerStyle}>
            {printForms.map(renderPfListRow)}
        </Grid>
    );
});
