import { computed, observable } from 'mobx';
import { clientRoute } from '../clientRoute';
import { BreadcrumbsSettings, mainRoutes, names, routesWithParams } from '../components';
import { ApiStore } from './ApiStore';
import { RootStore } from './RootStore';

export class BreadcrumbsStore {
    @observable private rootStore: RootStore;
    @observable private api: ApiStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.api = rootStore.api;
    }

    @computed
    get mainPageRoute(): string {
        return clientRoute.mainPage;
    }

    @computed
    get names() {
        return names;
    }

    @computed
    get mainRoutes() {
        return mainRoutes;
    }

    @computed
    get routesWithParams() {
        return routesWithParams;
    }

    @computed
    get breadCrumbsSettings(): BreadcrumbsSettings {
        const { names, mainRoutes, routesWithParams } = this;

        return {
            names,
            mainRoutes,
            routesWithParams,
        };
    }
}
