import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@material-ui/core';

export type CampaignEditButtonProps = {
    onEdit: () => void;
};

export const CampaignEditButton = (props: CampaignEditButtonProps): JSX.Element => {
    const { onEdit } = props;

    return (
        <Button onClick={onEdit} color="primary" size="small" variant="contained">
            <FormattedMessage id="campaignRequest.openEditMode" />
        </Button>
    );
};
