import { Grid, IconButton, Link, MenuItem } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { ReactNode } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { AuthorizationCheck, AuthorizationCheckAll, MenuButton } from '../';
import { entities, permissions } from '../../authSchemeConfig';
import { clientRoute } from '../../clientRoute';
import { useStore } from '../../hooks';
import { ReactComponent as SettingIcon } from '../../resources/images/icons/setting_2.svg';

const renderMenuItems = (hideMenu: () => void): ReactNode[] => {
    return [
        <AuthorizationCheck entityCode={entities.System} permCode={permissions.System.ViewCountryGroup}>
            <Link component={ReactRouterLink} to={clientRoute.countryGroups} onClick={hideMenu}>
                <MenuItem dense>Группы стран</MenuItem>
            </Link>
        </AuthorizationCheck>,
        <AuthorizationCheck entityCode={entities.System} permCode={permissions.System.ViewUserList}>
            <Link component={ReactRouterLink} to={clientRoute.users} onClick={hideMenu}>
                <MenuItem dense>Пользователи</MenuItem>
            </Link>
        </AuthorizationCheck>,
    ];
};

const renderButton = (onClick: (event: React.MouseEvent<HTMLButtonElement>) => void): JSX.Element => {
    return (
        <IconButton color="secondary" onClick={onClick}>
            <SettingIcon />
        </IconButton>
    );
};

const settingsButtonQueries = [
    { entityCode: entities.System, permCode: permissions.System.ViewCountryGroup },
    { entityCode: entities.System, permCode: permissions.System.ViewUserList },
];

export const SettingsButton = observer((): JSX.Element => {
    const { personStore } = useStore();
    const { person } = personStore;
    const { userId } = person;

    return (
        <AuthorizationCheckAll key={userId} or queries={settingsButtonQueries}>
            <Grid item>
                <MenuButton renderButton={renderButton} renderMenuItems={renderMenuItems} />
            </Grid>
        </AuthorizationCheckAll>
    );
});
