import { action, observable } from 'mobx';
import { CampaignRegFormDTO } from '../store/CampaignsStore';
import { DateUtils } from '../utils/DateUtils';

export class CampaignRegFormInfoModel {
    @observable id: string;
    @observable title = '';
    @observable campaignId = '';
    @observable requestFormCode = '';
    @observable procedureTitle?: string;
    @observable registrationStart?: Date;
    @observable registrationEnd?: Date;
    @observable reEnrollmentForm?: boolean;
    @observable allowAfterDeadline?: boolean;

    constructor(id: string) {
        this.id = id;
    }

    @action.bound
    load(dto: CampaignRegFormDTO): void {
        this.title = dto.title;

        this.campaignId = dto.campaignId;
        this.requestFormCode = dto.requestFormCode;
        this.procedureTitle = dto.procedureTitle;
        this.reEnrollmentForm = dto.reEnrollmentForm;
        this.allowAfterDeadline = dto.allowAfterDeadline;

        if (dto.registrationStart) {
            const registrationStart = new Date(dto.registrationStart);
            if (DateUtils.isValidDate(registrationStart)) {
                this.registrationStart = registrationStart;
            }
        }

        if (dto.registrationEnd) {
            const registrationEnd = new Date(dto.registrationEnd);
            if (DateUtils.isValidDate(registrationEnd)) {
                this.registrationEnd = registrationEnd;
            }
        }
    }
}
