import { Button } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React from 'react';
import { useStore } from '../../../../hooks';
import { FormikServiceCountryGroup } from '../../../../types';

export const CountryGroupsSaveButton = (): JSX.Element => {
    const { intlStore: intl } = useStore();
    const { submitForm } = useFormikContext<FormikServiceCountryGroup>();

    const saveChanges = intl.formatMessage('campaignRequest.saveChanges');

    return (
        <Button color="primary" size="small" variant="contained" onClick={submitForm}>
            {saveChanges}
        </Button>
    );
};
