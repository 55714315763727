import * as Yup from 'yup';
import { CountryGroupsFieldNames } from '../../../types';

export const yupCountryGroupSchema = Yup.object().shape({
    [CountryGroupsFieldNames.name]: Yup.string().required('Необходимо ввести наименование группы стран'),
    [CountryGroupsFieldNames.countries]: Yup.array().min(1, 'Необходимо выбрать хотя бы одну страну'),
    [CountryGroupsFieldNames.formationPrinciple]: Yup.object()
        .nullable()
        .required('Необходимо выбрать принцип формирования'),
});
