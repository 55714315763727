import { makeStyles } from '@material-ui/core';
import { useQuotaPageStyles } from '../../../quotas';

export const useCountryGroupsPageStyles = () => {
    const classes = useQuotaPageStyles();

    const overrides = makeStyles((theme) => ({
        mainContainerViewOnly: {
            paddingLeft: theme.spacing(10),
            paddingBottom: theme.spacing(8),
            '& *': {
                '& [class*=Mui-disabled]': {
                    opacity: 1,
                    '& input': {
                        color: theme.palette.text.primary,
                    },
                },
                '& [class*=MuiAutocomplete-endAdornment], & [class*=MuiOutlinedInput-adornedEnd]': {
                    '& svg': {
                        display: 'none',
                    },
                },
                '& .MuiRadio-colorSecondary, .MuiFormControlLabel-label': {
                    color: theme.palette.text.primary,
                },
            },
        },
    }));

    return { ...classes, ...overrides() };
};
