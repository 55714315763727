import { ColumnData, RowsData, TTableQueryData, TTableRow } from '@platform/ttable';
import fileDownload from 'js-file-download';
import { action, observable } from 'mobx';
import { apiConfigs } from '../apiConfigs';
import { CountryGroup, CountryGroupDTO } from '../types';
import { ApiStore } from './ApiStore';
import { CatalogStore } from './CatalogStore';
import { NotificationStore } from './NotificationStore';
import { RootStore } from './RootStore';

export class CountryGroupsStore {
    @observable protected rootStore: RootStore;
    @observable protected api: ApiStore;
    @observable protected notificationStore: NotificationStore;
    @observable catalogStore: CatalogStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.api = rootStore.api;
        this.notificationStore = rootStore.notificationStore;
        this.catalogStore = rootStore.catalogStore;
    }

    @action.bound
    createCountyGroup(countryGroup: CountryGroup): Promise<CountryGroupDTO> {
        return this.api.userActionClient(apiConfigs.createCountyGroup(countryGroup)).then((r) => r.data);
    }

    @action.bound
    getCountryGroup(countryGroupId: string): Promise<CountryGroupDTO> {
        return this.api.userActionClient(apiConfigs.getCountryGroup(countryGroupId)).then((r) => r.data);
    }

    @action.bound
    updateCountryGroup(countryGroup: CountryGroup, countryGroupId: string): Promise<CountryGroupDTO> {
        return this.api.userActionClient(apiConfigs.updateCountryGroup(countryGroup, countryGroupId)).then((r) => r.data);
    }

    @action.bound
    getCountryGroupsColumns(): Promise<ColumnData<any>[]> {
        return this.api.client(apiConfigs.getCountryGroupsColumns()).then((r) => r.data);
    }

    @action.bound
    getCountryGroupsTable(registryCode: string, queryData: TTableQueryData<TTableRow>): Promise<RowsData<any>> {
        return this.api.client(apiConfigs.getCountryGroupsTable(registryCode, queryData)).then((r) => r.data);
    }

    @action.bound
    async exportCountryGroupsXlsx<RowType extends TTableRow>(tableQueryData: TTableQueryData<RowType>): Promise<void> {
        try {
            const { data } = await this.api.client(apiConfigs.exportCountryGroupsXlsx(tableQueryData));
            const filename = this.rootStore.intlStore.formatMessage('homePage.headerLinks.сountryGroups');
            await fileDownload(data, `${filename}.xlsx`);
        } catch (error) {
            console.error(error);
        }
    }

    @action.bound
    async exportCountryGroupsDoc<RowType extends TTableRow>(tableQueryData: TTableQueryData<RowType>): Promise<void> {
        try {
            const { data } = await this.api.client(apiConfigs.exportCountryGroupsDoc(tableQueryData));
            const filename = this.rootStore.intlStore.formatMessage('homePage.headerLinks.сountryGroups');
            await fileDownload(data, `${filename}.doc`);
        } catch (error) {
            console.error(error);
        }
    }
    
}
