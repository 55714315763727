const homePage: Record<string, Record<string, string> | string> = {
    headerLinks: {
        requests: 'Statements',
        requestsOld: 'My statements',
        campaigns: 'Services',
        licenses: 'licenses',
        users: 'Users',
        console: 'Console',
        authorityDocs: 'Authority docs',
        quotas: 'Quotas',
        сountryGroups: 'Country groups',
    },
};

export default homePage;
