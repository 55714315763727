import React from 'react';
import { QuotasModel } from '../models';

export type QuotasContextType = {
    quotasModel: QuotasModel;
    isEditing: boolean;
    isCreating: boolean;
    isViewOnly: boolean;
};

export const QuotasContext = React.createContext<QuotasContextType>({} as QuotasContextType);
