import { FormikProps } from 'formik';
import { FormikServiceCountryGroup } from '../../../types';

export const handleFormikAutocompleteChange = <T extends unknown>(
    fieldName: keyof FormikServiceCountryGroup,
    props: FormikProps<FormikServiceCountryGroup>,
): ((e: any, value: T) => void) => {
    return (e: any, value: T) => {
        props.setFieldValue(fieldName, value);
    };
};
