import { AxiosError } from 'axios';
import { observer } from 'mobx-react';
import React from 'react';
import { useStore } from '../../hooks';
import { Transition } from '../../models';
import { LicenseSignatoryDialog } from '../LicenseSignatoryDialog';

export type LicenseSignatoryTransitionDialogProps = {
    objectId: string;
    isModalOpen: boolean;
    setModalIsClosed: () => void;
    transition: Transition;
    lifeCycleTransition: () => Promise<void>;
    handleSuccessTransition: () => void;
    handleErrorTransition: (error: AxiosError) => void;
};

export const LicenseSignatoryTransitionDialog = observer(
    (props: LicenseSignatoryTransitionDialogProps): JSX.Element => {
        const { transition, isModalOpen, setModalIsClosed, handleErrorTransition, handleSuccessTransition, objectId } =
            props;

        const { requestStore } = useStore();

        const saveSignatory = async (signatory: string): Promise<void> => {
            return requestStore
                .assignLicenseSignatoryAndChangeState(transition.id, objectId, signatory)
                .then(handleSuccessTransition)
                .catch(handleErrorTransition);
        };

        return <LicenseSignatoryDialog isOpen={isModalOpen} close={setModalIsClosed} saveSignatory={saveSignatory} />;
    },
);
