import { IconButton, MenuItem, SvgIcon } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import { ActionMenuItem, ConfirmationDialog, MenuButton } from '../../../components';
import { usePermissionsActionButtons, useStore } from '../../../hooks';
import { CampaignRequestRow } from '../../../models';
import { ReactComponent as DotMenu } from '../../../resources/images/icons/dot-menu.svg';

export type LicensesCampaignRequestActionsBtnsProps = {
    campaignRow: CampaignRequestRow;
    reloadData: () => void;
    isConfirm?: boolean;
    setIsConfirm?: React.Dispatch<React.SetStateAction<boolean>>;
    handleEditExecutorDialogOpen: (campaignRow: CampaignRequestRow, title?: string) => void;
};

export const LicensesCampaignRequestActionsBtns = observer(
    (props: LicensesCampaignRequestActionsBtnsProps): JSX.Element => {
        const { campaignRow, reloadData, isConfirm, setIsConfirm, handleEditExecutorDialogOpen } = props;
        const { id } = campaignRow;

        const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] = useState<boolean>(false);

        const [permissionsData, reloadPermissions] = usePermissionsActionButtons({ rowId: id });
        const [addActualAppointment, updateActualAppointment, campaignRequestEdit, campaignRequestDelete] =
            permissionsData;

        const { requestStore } = useStore();

        useEffect(() => {
            if (setIsConfirm && isConfirm) {
                setIsConfirm(false);
                reloadData();
                reloadPermissions();
            }
        }, [isConfirm, setIsConfirm, reloadData, reloadPermissions]);

        const deleteCampaign = useCallback((): void => {
            try {
                requestStore.deleteRequest(id).finally(reloadData);
            } catch (error) {
                console.error(error);
            }
        }, [reloadData, id, requestStore]);

        const openDeleteConfirmationDialog = (): void => {
            setIsDeleteConfirmationDialogOpen(true);
        };

        const closeDeleteConfirmationDialog = (): void => {
            setIsDeleteConfirmationDialogOpen(false);
        };

        const onDeleteConfirm = async (): Promise<void> => {
            await deleteCampaign();
            closeDeleteConfirmationDialog();
        };

        const onDeleteCancel = (): void => {
            closeDeleteConfirmationDialog();
        };

        const handleConfirmAddExecutor = useCallback((): void => {
            requestStore.setCurrentUserAsExecutor(id).finally(() => {
                reloadData();
                reloadPermissions();
            });
        }, [reloadData, reloadPermissions, id, requestStore]);

        const renderActionItems = useCallback((): ((hideMenu?: () => void) => ReactNode[]) => {
            const { id } = campaignRow;

            return (hideMenu): ReactNode[] => {
                const onDeleteClick = (): void => {
                    hideMenu && hideMenu();
                    openDeleteConfirmationDialog();
                };

                return [
                    addActualAppointment && updateActualAppointment && (
                        <ActionMenuItem
                            key="appointExecutor"
                            messageId="campaignRequest.actions.appointExecutor"
                            onClick={() => handleEditExecutorDialogOpen(campaignRow)}
                        />
                    ),
                    !addActualAppointment && updateActualAppointment && (
                        <ActionMenuItem
                            key="changeExecutor"
                            messageId="campaignRequest.actions.changeExecutor"
                            onClick={() =>
                                handleEditExecutorDialogOpen(campaignRow, 'campaignRequest.dialog.changeExecutorTitle')
                            }
                        />
                    ),
                    addActualAppointment && (
                        <ActionMenuItem
                            key="requestToWork"
                            messageId="campaignRequest.actions.requestToWork"
                            onClick={handleConfirmAddExecutor}
                        />
                    ),
                    campaignRequestEdit && (
                        <ActionMenuItem
                            key="edit"
                            messageId="common.edit"
                            path={generatePath(clientRoute.licenseEdit, { id })}
                        />
                    ),
                    campaignRequestDelete && (
                        <MenuItem key="delete" dense button={true} onClick={onDeleteClick}>
                            <FormattedMessage id="common.delete" />
                        </MenuItem>
                    ),
                ];
            };
        }, [campaignRow, handleEditExecutorDialogOpen, handleConfirmAddExecutor]);

        const renderActionsButton = (onClick: (event: React.MouseEvent<HTMLButtonElement>) => void): JSX.Element => {
            return (
                <IconButton onClick={onClick}>
                    <SvgIcon>
                        <DotMenu />
                    </SvgIcon>
                </IconButton>
            );
        };

        const isMenuButtonShown = permissionsData.some((checkResult) => checkResult);

        return (
            <React.Fragment>
                {isMenuButtonShown && (
                    <MenuButton renderButton={renderActionsButton} renderMenuItems={renderActionItems()} />
                )}
                <ConfirmationDialog
                    id="delete"
                    open={isDeleteConfirmationDialogOpen}
                    title={<FormattedMessage id="common.confirmDeletion" />}
                    message={
                        <FormattedMessage
                            id="campaignRequest.confirmDeletionInfoText"
                            values={{ number: campaignRow.number }}
                        />
                    }
                    onConfirm={onDeleteConfirm}
                    onCancel={onDeleteCancel}
                    keepMounted
                />
            </React.Fragment>
        );
    },
);
