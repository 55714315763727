import { Box, Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedDate } from 'react-intl';
import { useObjectHeaderStyles, useStore } from '../../../../../hooks';
import { RequestModel } from '../../../../../models';

export type CampaignRequestHeaderRegisteredDateProps = { requestModel: RequestModel };

export const CampaignRequestHeaderRegisteredDate = observer(
    (props: CampaignRequestHeaderRegisteredDateProps): JSX.Element => {
        const classes = useObjectHeaderStyles();
        const { intlStore: intl } = useStore();
        const { requestModel } = props;

        const registered = intl.formatMessage('campaignRequest.registered');

        return (
            <Grid item>
                <Typography variant="subtitle1" component="p">
                    <Box display="flex" flexWrap="wrap" component="span">
                        <span className={classes.lightText}>{registered}</span>
                        <Box fontWeight={600} pl={2} component="span">
                            <FormattedDate value={requestModel.registered} />
                        </Box>
                    </Box>
                </Typography>
            </Grid>
        );
    },
);
