import { WebForm } from '@platform/formio/WebForm';
import {
    ComponentWithValidationMessage,
    FormApi,
    FormioSidebarStore,
    validateReadonlyPage as validateReadonlyPageFormio,
} from '@platform/formiojs-react';
import { History } from 'history';
import { action, computed, observable } from 'mobx';
import { generatePath } from 'react-router-dom';
import { clientRoute, licenseRoutes, requestRoutes } from '../clientRoute';
import { FormioSignatureStore, IntlStore, NotificationStore, RequestStore, RootStore } from '../store';
import {
    DeadlineDTO,
    DeadlinesDTO,
    EmployeeDTO,
    ObjectRoutesContextType,
    RequestDTO,
    RequestType,
    TabDTO,
    TabWithPossibleParent,
    TabWithSubTabs,
} from '../types';
import { handleAxiosErrorByResponseStatus } from '../utils';
import { IdTitle } from './IdTitle';
import { RequestFormModel } from './RequestFormModel';

function findTabToOpen<T extends TabWithPossibleParent>(customTabs: T[]): TabWithPossibleParent | undefined {
    let foundTab: TabWithPossibleParent | undefined;
    for (let tab of customTabs) {
        if (tab.openOnCreation) {
            foundTab = tab;
        } else if (tab.subTabs) {
            foundTab = findTabToOpen<TabDTO>(tab.subTabs);
            foundTab && (foundTab.parent = { ...tab, subTabs: [] });
        }
        if (foundTab) {
            break;
        }
    }
    return foundTab;
}

export class RequestModel {
    @observable private readonly formioSidebarStore: FormioSidebarStore;
    @observable private requestStore: RequestStore;
    @observable private history: History;
    @observable private notificationStore: NotificationStore;
    @observable private intlStore: IntlStore;
    @observable private formioSignatureStore: FormioSignatureStore;
    @observable objectRoutes: ObjectRoutesContextType;
    @observable id: string;
    @observable number = '';
    @observable regFormTitle = '';
    @observable campaign: IdTitle = { id: '', title: '' };
    @observable state = '';
    @observable formsTabs: TabWithSubTabs[] = [];
    @observable firstTabName = '';
    @observable executors: EmployeeDTO[] = [];
    @observable firstTabId = '';
    @observable firstSubTabId? = '';
    @observable validate: boolean = false;

    @observable currentTab?: RequestFormModel;

    @observable formApi?: FormApi;
    @observable isFormReady = false;
    @observable formErrors: ComponentWithValidationMessage[] = [];
    @observable deadlines: DeadlinesDTO = {};

    @observable showValidation = false;

    @observable onFormChangeTimeOut?: number;
    @observable onFormReadyTimeOut?: number;

    @observable requestType?: RequestType;
    @observable relatedRequestType?: RequestType;
    @observable relatedRequestId?: string;
    @observable relatedRequestNumber?: string;
    @observable externalCreated?: string;
    @observable licenceDate?: string;
    @observable registered?: string;
    @observable uin?: string;
    @observable cnCategory?: string;
    @observable licenseSignatoryId?: string;

    formName = 'request';

    constructor(id: string, rootStore: RootStore, objectRoutes: ObjectRoutesContextType) {
        this.formioSidebarStore = rootStore.formioSidebarStore;
        this.requestStore = rootStore.requestStore;
        this.history = rootStore.history;
        this.notificationStore = rootStore.notificationStore;
        this.id = id;
        this.intlStore = rootStore.intlStore;
        this.formioSignatureStore = rootStore.formioSignatureStore;
        this.objectRoutes = objectRoutes;
    }

    @computed
    get isLicense(): boolean {
        return !!this.requestType && this.requestType === RequestType.license;
    }

    @computed
    get isResolution(): boolean {
        return !!this.requestType && this.requestType === RequestType.resolution;
    }

    @computed
    get isRequest(): boolean {
        return !!this.requestType && this.requestType === RequestType.request;
    }
    
    @computed
    get currentRequestTypeObjectRoutes(): ObjectRoutesContextType | undefined {
        switch (this.requestType) {
            case RequestType.request:
                return requestRoutes;
            case RequestType.license:
                return licenseRoutes;
            default:
                return undefined;
        }
    }

    @computed
    get executorsList(): string {
        if (!this.executors) {
            return '';
        }

        const executorsList = this.executors.map(
            (executor) =>
                `${executor.person.lastName} ${executor.person.firstName} ${executor.person.middleName || ''}`,
        );
        return executorsList.join(', ');
    }

    @action.bound
    resetFirstTabId(): void {
        this.firstTabId = '';
    }

    @action.bound
    load(dto: RequestDTO): void {
        const tab: TabWithPossibleParent = findTabToOpen(dto.customTabs) || dto.customTabs[0];

        /** Если есть родитель, то tab = subTab */
        if (tab.parent) {
            this.firstTabId = tab.parent.id;
            this.firstSubTabId = tab.id;
        } else {
            this.firstTabId = tab.id;
        }

        this.regFormTitle = dto.regFormTitle;
        this.campaign = dto.campaign;
        this.number = dto.number;
        this.state = dto.state;
        this.formsTabs = dto.customTabs;
        this.executors = dto.executors;
        this.validate = !!dto.validate;

        if (dto.requestType) {
            this.requestType = dto.requestType;
        }
        if (dto.relatedRequestId) {
            this.relatedRequestId = dto.relatedRequestId;
        }
        if (dto.relatedRequestNumber) {
            this.relatedRequestNumber = dto.relatedRequestNumber;
        }
        if (dto.externalCreated) {
            this.externalCreated = dto.externalCreated;
        }
        if (dto.licenceDate) {
            this.licenceDate = dto.licenceDate;
        }
        if (dto.registered) {
            this.registered = dto.registered;
        }
        if (dto.uin) {
            this.uin = dto.uin;
        }
        if (dto.cnCategory) {
            this.cnCategory = dto.cnCategory;
        }
        if (dto.licenseSignatoryId) {
            this.licenseSignatoryId = dto.licenseSignatoryId;
        }
        if (dto.relatedRequestType) {
            this.relatedRequestType = dto.relatedRequestType;
        }

        this.loadDeadlines();
    }

    @action.bound
    onFormChange(form: WebForm): void {
        this.onFormChangeTimeOut = setTimeout(() => {
            this.formioSidebarStore.updateItemsVisibility(this.formName, form);
        }, 100); // минимальный таймаут при котором все работает корректно
    }

    @computed
    get locale(): string {
        return this.intlStore.locale;
    }

    @action.bound
    saveForFormioCryptoButton(formId: string): () => void {
        return (): void => {
            const { formApi, requestStore } = this;

            if (formApi && formId) {
                formApi
                    .submit(false)
                    .then(() => {
                        return requestStore.saveRequestForm(formId, formApi.getSubmission());
                    })
                    .catch((error) => {
                        this.notificationStore.onError(error.response.data);
                    });
            }
        };
    }

    @action.bound
    onFormReady(formId: string): (form: FormApi) => void {
        return (form): void => {
            this.formApi = form;
            this.isFormReady = true;

            this.formioSignatureStore.setOnSuccessForFormioInstance(this.saveForFormioCryptoButton(formId));

            this.onFormReadyTimeOut = setTimeout(() => {
                this.validateAfterReadonly();
                this.formioSidebarStore.initSidebarItems(this.formName, form.form);
            }, 80); // минимальный таймаут при котором все работает корректно
        };
    }

    @action.bound
    updateCurrentTab(): void {
        if (!this.currentTab) {
            return;
        }

        const tab = this.requestStore.getRequestFormModel(this.currentTab?.id);
        this.currentTab.tabExecutor = tab.tabExecutor;
    }

    @action.bound
    setCurrentTab(formId: string): void {
        this.requestStore
            .getRequestFormModel(formId)
            .then((model: RequestFormModel) => {
                this.currentTab = model;
            })
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.push(generatePath(this.objectRoutes.object, { id: this.id })),
                    404: () => this.history.push(clientRoute.notFound),
                }),
            );
    }

    @action.bound
    updateExecutors(): void {
        this.requestStore.getRequestDTO(this.id).then((data) => {
            this.executors = data.executors;
            this.formsTabs = data.customTabs;
            this.updateCurrentTab();
        });
    }

    @action.bound
    validateEditPage(onSuccess?: () => Promise<void>): Promise<void> {
        if (this.formApi) {
            return this.formApi.submit(true).finally(() => {
                if (!this.formErrors.length) {
                    this.formioSidebarStore.updateSidebarErrors(this.formName);
                    return onSuccess ? onSuccess() : Promise.resolve();
                }
                this.formioSidebarStore.updateSidebarErrors(this.formName, this.formErrors);
                return Promise.reject(this.formErrors);
            });
        } else {
            return Promise.reject(['formApi is undefined']);
        }
    }

    @action.bound
    validateAfterReadonly(): void {
        if (this.showValidation) {
            this.validateEditPage().finally(() => {
                this.showValidation = false;
                this.formErrors = [];
            });
        }
    }

    @action.bound
    validateReadonlyPage(onSuccess?: () => Promise<void>, withoutSignatureValidate?: boolean): Promise<void> {
        const setShowValidation = (isShowValidation: boolean): void => {
            this.showValidation = isShowValidation;
        };
        const setFormErrors = (errors: ComponentWithValidationMessage[]): void => {
            this.formErrors = errors;
        };

        return validateReadonlyPageFormio({
            setFormErrors,
            setShowValidation,
            onSuccess,
            formApi: this.formApi,
            formioSidebarStore: this.formioSidebarStore,
            formName: this.formName,
            withoutSignatureValidate,
        });
    }

    @action.bound
    goToRequestPage(path?: string): void {
        const redirectPath = path || generatePath(this.objectRoutes.object, { id: this.id });
        this.isFormReady = false; // меняем состояние перед переходом на другой роут
        this.history.push({
            pathname: redirectPath,
            search: '?prompt=false',
        });
    }

    @action.bound
    saveRequestForm = (formId: string, goBackPath: string): (() => Promise<void>) => {
        return (): Promise<void> => {
            const { formApi, requestStore, goToRequestPage, validate } = this;
            if (!formApi) {
                return Promise.resolve();
            }
            const isFormValid = validate ? formApi.validate() : true;
            if (formApi && formId && isFormValid) {
                return formApi
                    .submit(false)
                    .then(() => {
                        return requestStore.saveRequestForm(formId, formApi.getSubmission());
                    })
                    .then(() => {
                        return requestStore.getRequestDTO(this.id).then(this.load);
                    })
                    .then(() => {
                        goToRequestPage(goBackPath);
                    })
                    .catch((error) => {
                        this.notificationStore.onError(error.response.data);
                        formApi.setSubmissionFromStartSubmission();
                    });
            }
            const errorMessage = this.intlStore.formatMessage('validation.invalidForm');
            this.notificationStore.onError(errorMessage);
            return Promise.resolve();
        };
    };

    @action.bound
    deleteRequest(): Promise<void> {
        const { id, requestStore, campaign, history } = this;

        return requestStore.deleteRequest(id).then(() => {
            this.isFormReady = false; // меняем состояние перед переходом на другой роут
            history.push(generatePath(clientRoute.campaign, { id: campaign.id }));
        });
    }

    @action.bound
    transitionLifeCycle(transitionId: string, requestId: string): Promise<void> {
        return this.requestStore.transitionToNextLifeCycleStep(transitionId, requestId);
    }

    @action.bound
    goToRequestEditPageAndValidate(path: string): () => void {
        return (): void => {
            this.isFormReady = false; // меняем состояние перед переходом на другой роут
            this.history.push(path);
        };
    }

    @action.bound
    loadDeadlines(): void {
        this.requestStore.getDeadlinesDTO(this.id).then((deadlinesDTO) => {
            const entityDeadlineDate = deadlinesDTO.entityDeadline?.date;
            const stateDeadlineDate = deadlinesDTO.stateDeadline?.date;

            if (entityDeadlineDate) {
                (deadlinesDTO.entityDeadline as DeadlineDTO).date = new Date(entityDeadlineDate);
            }

            if (stateDeadlineDate) {
                (deadlinesDTO.stateDeadline as DeadlineDTO).date = new Date(stateDeadlineDate);
            }

            this.deadlines = deadlinesDTO;
        });
    }
}
