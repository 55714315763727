import { AppFonts, AppIcons, AppPalette, ThemeOverrides } from '../../../themes';
import fonts from './fonts';

type ColorName =
    | 'black'
    | 'blackLight'
    | 'blackLighter'
    | 'white'
    | 'greyMain'
    | 'greyLight'
    | 'greyDark'
    | 'greyWhite'
    | 'greyLighter'
    | 'greyMiddleLight'
    | 'red'
    | 'lightRed'
    | 'green'
    | 'lightGreen'
    | 'lightOrange'
    | 'brown'
    | 'lighterBrown'
    | 'lighterBlue'
    | 'lighterGrey'
    | 'blue'
    | 'blueDark'
    | 'blueMiddle'
    | 'blueLight'
    | 'blueLighter'
    | 'blueLightest'
    | 'blueWhite'
    | 'light'
    | 'disabled';

const gispColors: Record<ColorName, string> = {
    black: '#000000',
    blackLight: '#151515',
    blackLighter: '#232323',
    white: '#ffffff',
    greyMain: '#63666a',
    greyLight: '#85898f',
    greyDark: '#2c302e',
    greyWhite: '#f2f2f2',
    greyLighter: '#fafafa',
    greyMiddleLight: '#e4e4e4',
    red: '#FDA29B',
    lightRed: '#FEE4E2',
    green: '#008000',
    lightGreen: '#D1FADF',
    lightOrange: '#FFE0B2',
    brown: '#9a554b',
    lighterBrown: '#6d6d6d',
    lighterBlue: 'rgba(53, 165, 255, 0.09)',
    lighterGrey: 'rgba(133, 147, 143, 0.09)',

    blue: '#1B6195',
    blueDark: '#124265',

    light: '#f1f4f7',
    blueLightest: '#FAFDFF',
    blueMiddle: '#D6E3EC',
    blueLight: '#f1f4f7',
    blueLighter: '#AEC7D9',
    blueWhite: '#E7EFF4',
    disabled: 'rgba(0, 0, 0, 0.12)',
};

const gispFonts: AppFonts = {
    ...fonts,
    mainFontAttrs: {
        fontSize: '13px',
        lineHeight: 1.69,
        letterSpacing: '0.2px',
    },
};

const gispIcons: AppIcons = {
    fontFamily: fonts.iconsFont,
    arrowIcon: {
        fontSize: 11,
        content: '"\\e90f"',
        width: 12,
        height: 7,
    },
    doneIcon: {
        fontSize: 14,
        content: '"\\e906"',
        width: 14,
        height: 11,
    },
    calendarIcon: {
        fontSize: 20,
        content: '"\\e905"',
        width: 18,
        height: 20,
    },
};
const accentBackgroundGradient = `linear-gradient(-30deg, ${gispColors.lighterBrown} 0%, ${gispColors.lighterBrown} 100%)`;

const gispPalette: AppPalette = {
    main: gispColors.brown,
    textMain: gispColors.greyMain,
    textDark: gispColors.black,
    mainMiddleLight: gispColors.greyMiddleLight,
    mainLight: gispColors.greyLight,
    mainDark: gispColors.greyDark,
    mainContrast: gispColors.white,
    mainContrastDarker: gispColors.greyLighter,
    accent: gispColors.black,
    accentDark: gispColors.brown,
    accentContrast: gispColors.black,
    accentBackgroundGradient: accentBackgroundGradient,
    hover: gispColors.brown,
    hoverInLists: gispColors.greyWhite,
    red: gispColors.red,
    green: gispColors.green,
    mainPageCard: {
        background: gispColors.blueLightest,
    },
    campaignList: {
        backgroundCard: gispColors.lighterBlue,
        selectedPaginationButton: gispColors.lighterGrey,
    },
    table: {
        row: {
            even: gispColors.white,
            odd: gispColors.greyLighter,
        },
    },
    card: {
        header: {
            main: gispColors.greyDark,
            color: gispColors.white,
        },
    },
    appBar: {
        mainContrast: gispColors.blackLight,
    },
    button: {
        accentContrast: gispColors.white,
        disabled: gispColors.disabled,
    },
    iconButton: {
        color: gispColors.brown,
        hoverColor: gispColors.white,
        hoverBackgroundColor: gispColors.brown,
    },
    background: {
        textMain: gispColors.black,
    },
    footer: {
        mainContrast: gispColors.blackLighter,
        textMain: gispColors.white,
        padding: 3.75,
    },
    tab: {
        backgroundColor: gispColors.greyMiddleLight,
        color: gispColors.black,
        hoverBackgroundColor: gispColors.brown,
        hoverColor: gispColors.white,
    },
    panel: {
        markerBackgroundColor: gispColors.brown,
        markerColor: gispColors.white,
    },
    header: {
        mainContrast: gispColors.light,
        activeColor: gispColors.black,
    },
    deadline: {
        valid: gispColors.greyMiddleLight,
        warning: gispColors.lightOrange,
        error: gispColors.lightRed
    },
    checks: {
        passed: gispColors.lightGreen,
        failed: gispColors.lightRed,
    },
    accentContrastBackgroundGradient: gispColors.white,
};

export default {
    fonts: gispFonts,
    palette: gispPalette,
    icons: gispIcons,
} as ThemeOverrides;
