import { CatalogCodes } from '../store';

export enum FormationPrincipleCodes {
    selectedCountries = 'SelectedCountries',
    exceptSelectedCountries = 'ExceptSelectedCountries',
}

export type FormationPrinciple = {
    archived: boolean;
    catalogCode: CatalogCodes.formationPrinciple;
    childLength: number;
    code: FormationPrincipleCodes;
    created: string;
    data: unknown;
    title: string;
    weight: number;
};
