import React from 'react';
import { observer } from 'mobx-react';
import { SnackbarComponent } from './SnackbarComponent';
import { useStore } from '../../hooks/useStore';

export const NotificationComponent = observer(
    (): JSX.Element => {
        const { notificationStore } = useStore();
        const { model } = notificationStore;

        const closeMessage = (id: string): (() => void) => {
            return () => model.deleteNotification(id);
        };

        return (
            <React.Fragment>
                {model.notifications.map((m, index) => (
                    <SnackbarComponent
                        key={m.id}
                        isOpen={m.isOpen}
                        closeMessage={closeMessage(m.id)}
                        index={index + 1}
                        text={m.title}
                        severity={m.severity}
                    />
                ))}
            </React.Fragment>
        );
    },
);
