import React from 'react';
import { Grid, Collapse } from '@material-ui/core';
import { AppHeaderLinksList } from './app-header-links/AppHeaderLinksList';
import { AppHeaderLinkData } from '../../store';

export type BurgerMenuProps = {
    isSmSize: boolean;
    isOpenMenu: boolean;
    hiddenLinksArray: AppHeaderLinkData[];
};

export const BurgerMenu = (props: BurgerMenuProps): JSX.Element => {
    const { isSmSize, isOpenMenu, hiddenLinksArray } = props;
    return (
        <Collapse in={isOpenMenu} timeout="auto">
            <Grid container direction="row" alignItems="center" justify={isSmSize ? 'center' : 'flex-end'} spacing={3}>
                {hiddenLinksArray && <AppHeaderLinksList elementsArray={hiddenLinksArray} />}
            </Grid>
        </Collapse>
    );
};
