import { Tab } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, NavLink, useParams } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import { RoutedTabs } from '../../../components';

type RouteParams = {
    id: string;
    rfId?: string;
};

export const CampaignSettingTabs = (): JSX.Element => {
    const { id } = useParams<RouteParams>();

    return (
        <RoutedTabs
            tabsProps={{ orientation: 'vertical', indicatorColor: 'primary' }}
            settings={[
                { tab: 0, path: [clientRoute.campaignSettings, clientRoute.campaignSettingsEdit], exact: true },
                { tab: 1, path: clientRoute.campaignRegForms },
                { tab: 2, path: clientRoute.campaignManagement },
                { tab: 3, path: clientRoute.generalSettings },
            ]}
        >
            {(updateIndicator): ReactNode => [
                <Tab
                    key="description"
                    value={0}
                    component={NavLink}
                    to={generatePath(clientRoute.campaignSettings, { id })}
                    label={<FormattedMessage id="campaign.campaignDescription" />}
                />,
                <Tab
                    key="regForms"
                    value={1}
                    component={NavLink}
                    to={generatePath(clientRoute.campaignRegForms, { id })}
                    label={<FormattedMessage id="campaign.campaignRegForms" />}
                />,
                <Tab
                    key="management"
                    value={2}
                    component={NavLink}
                    to={generatePath(clientRoute.campaignManagement, { id })}
                    style={{ whiteSpace: 'nowrap' }}
                    label={<FormattedMessage id="campaign.campaignManagement" />}
                />,
                <Tab
                    key="access"
                    value={3}
                    component={NavLink}
                    to={generatePath(clientRoute.generalSettings, { id })}
                    style={{ whiteSpace: 'nowrap' }}
                    label={<FormattedMessage id="campaign.generalSettings" />}
                />,
            ]}
        </RoutedTabs>
    );
};
