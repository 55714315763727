import { Button, Table, TableBody, TableCell, TableRow, TextField, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useQuotaContext } from '../../../../hooks';
import { useQuotaVTDTableStyles } from '../../quota-styles';
import { QuotaVTDColumnSum } from './quota-vtd-column-sum';
import { QuotaVTDRow } from './quota-vtd-row';

export const QuotaVTDTable = observer((): JSX.Element => {
    const classes = useQuotaVTDTableStyles();

    const { quotasModel, isViewOnly } = useQuotaContext();
    const { quota } = quotasModel;
    const {
        isThereAnyParticipants,
        addNewParticipant,
        participants,
        countryGroups,
        setVTDTableCommonVolume,
        setVolumesByCountryGroupsDict,
    } = quota;

    useEffect(() => {
        /**
         *  Устаналиваем "словарь" в котором по имени группы собираем данные всех
         *  связанных объектов с распределением (participant.volumes[n]) в каждом участнике ВТД (participants[n]).
         *  После обработки получаем Record<id группы, массив связанных объемов QuotaParticipantVolume>
         *  Нужно, чтобы посчитать столбец с распределением.
         *  Значение передается в последнюю, самую нижнюю клеточку динамического столбца.
         */
        setVolumesByCountryGroupsDict();
    }, [countryGroups, participants]);

    /** Эта константа является значением value в TextField внизу */
    const downRightLastColumnSum = participants.reduce((acc, curr) => acc + curr.commonRowVolume, 0);

    useEffect(() => {
        /** Далее мы обновляем значение в модели вот этим локальным lastColumnCommonSum */
        setVTDTableCommonVolume(downRightLastColumnSum);
    }, [downRightLastColumnSum]);

    const VTDRows: JSX.Element[] = participants.map((participant) => {
        return <QuotaVTDRow key={participant.id} participant={participant} />;
    });

    const columnsHeads: (JSX.Element | null)[] = countryGroups.map((group) => {
        return group.isValid ? <TableCell key={group.id}>{group.countryGroup?.title}</TableCell> : null;
    });

    const columnsSums: (JSX.Element | null)[] = countryGroups.map((group) => {
        return group.isValid ? <QuotaVTDColumnSum group={group} key={group.id} /> : null;
    });

    return (
        <Table className={isViewOnly ? classes.tableBodyViewOnly : classes.tableBody}>
            <TableBody className={classes.tableBody}>
                {isThereAnyParticipants && (
                    <TableRow className={classes.tableRow}>
                        <TableCell>ИНН</TableCell>
                        <TableCell>Участник ВТД</TableCell>
                        {columnsHeads}
                        <TableCell>Сумма</TableCell>
                        <TableCell />
                    </TableRow>
                )}
                {VTDRows}
                {!isViewOnly && (
                    <TableRow className={classes.sumCell}>
                        <TableCell className={classes.tableCell}>
                            <Button color="secondary" size="small" variant="contained" onClick={addNewParticipant}>
                                Добавить
                            </Button>
                        </TableCell>
                    </TableRow>
                )}
                {isThereAnyParticipants && (
                    <TableRow className={classes.tableRow}>
                        <TableCell className={classes.sumCell}>
                            <Typography className={classes.sumText}>Сумма</Typography>
                        </TableCell>
                        <TableCell />
                        {columnsSums}
                        <TableCell>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={downRightLastColumnSum}
                                disabled={!quota.errors.vtdTableCommonVolume}
                                error={!!quota.touched.vtdTableCommonVolume && !!quota.errors.vtdTableCommonVolume}
                                helperText={quota.errors.vtdTableCommonVolume}
                            />
                        </TableCell>
                        <TableCell />
                    </TableRow>
                )}
            </TableBody>
        </Table>
    );
});
