import { action, computed, IObservableArray, observable } from 'mobx';
import { CampaignInfoDTO } from '../store/CampaignsStore';
import { DateUtils } from '../utils/DateUtils';
import { apiConfigs } from '../apiConfigs';
import { FileDTOWithFileId } from './FileModel';
import { isAfter } from 'date-fns';

export class CampaignInfoModel {
    @observable id = '';
    @observable title = '';
    @observable from?: Date;
    @observable to?: Date;
    @observable annotation = '';
    @observable files: IObservableArray<FileDTOWithFileId> = observable.array();

    @observable private avatarId?: string;

    private readonly apiUrl?: string;

    constructor(apiUrl?: string) {
        this.apiUrl = apiUrl;
    }

    @computed
    get isCollectionCompleted() {
        return this.to && isAfter(new Date(), this.to);
    }

    @action.bound
    load(dto: CampaignInfoDTO): void {
        this.id = dto.id;
        this.title = dto.title;
        this.avatarId = dto.avatarId;
        this.annotation = dto.annotation || '';
        this.files = observable.array(
            dto.files.map((i) => {
                const { id, ...dto } = i;
                return {
                    fileId: id,
                    ...dto,
                };
            }),
        );

        if (dto.from) {
            const from = new Date(dto.from);
            if (DateUtils.isValidDate(from)) {
                this.from = from;
            }
        }

        if (dto.to) {
            const to = new Date(dto.to);
            if (DateUtils.isValidDate(to)) {
                this.to = to;
            }
        }
    }

    @computed
    get avatarUrl(): string | undefined {
        if (this.apiUrl && this.avatarId) {
            return `${this.apiUrl}${apiConfigs.campaignFileUrl(this.avatarId)}`;
        }
        return undefined;
    }

    @computed
    get campaignNotStarted(): boolean {
        if (this.from) {
            const now = new Date();
            return this.from > now;
        }
        return true;
    }
}
