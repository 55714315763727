import { Button, Dialog, DialogContent } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { clientRoute } from '../../../../../../clientRoute';
import { useModal, useStore } from '../../../../../../hooks';
import { CampaingPfTemplateTabModel } from '../../../../../../models';
import { RequestFormPermissionSettingsTable } from '../RequestFormPermissionSettingsTable';
import { EditPermissionDialog } from './EditPermissionDialog';

type RouteParams = {
    id: string;
    rfId: string;
    settingsId: string;
};

export const TemplatePermissionSettingsDialog = observer((): JSX.Element => {
    const history = useHistory();
    const { intlStore: intl, campaignsStore } = useStore();

    const { id: campaignId, rfId, settingsId } = useParams<RouteParams>();

    const templateTab = useMemo(() => new CampaingPfTemplateTabModel(intl), [intl]);

    const [currentPermission, setCurrentPermission] = useState<number>();
    const [editPermissionDialogOpen, setEditPermissionDialogOpen, setEditPermissionDialogClosed] = useModal();

    const handleEditPermissionDialogOpen = useCallback(
        (index: number): void => {
            setCurrentPermission(index);
            setEditPermissionDialogOpen();
        },
        [setCurrentPermission, setEditPermissionDialogOpen],
    );

    const handleClose = (): void => {
        history.push(generatePath(clientRoute.campaignRegFormTemplates, { id: campaignId, rfId }));
    };

    const handleSave = (): void => {
        templateTab.validationStarted = true;

        if (!templateTab.isValid) {
            return;
        }

        campaignsStore
            .saveTemplatePermissions(settingsId, templateTab.permissions)
            .then(handleClose)
            .finally(() => (templateTab.validationStarted = false));
    };

    const checkboxLabel = <FormattedMessage id="campaign.generate" />;

    useEffect(() => {
        campaignsStore.loadTemplatePermissions(settingsId).then((permissions) => {
            templateTab.permissions = permissions;
        });
        campaignsStore.loadStates(rfId).then((states) => {
            templateTab.statesList = states;
        });
        campaignsStore.loadPermissions().then((permissions) => {
            templateTab.permissionsList = permissions;
        });
    }, [campaignsStore, templateTab]);

    return (
        <React.Fragment>
            {currentPermission !== undefined && editPermissionDialogOpen && (
                <EditPermissionDialog
                    open={editPermissionDialogOpen}
                    closeDialog={setEditPermissionDialogClosed}
                    currentPermission={currentPermission}
                    model={templateTab}
                    checkboxLabel={checkboxLabel}
                />
            )}
            <Dialog maxWidth="md" fullWidth open={true} scroll="body">
                <DialogTitle>
                    <FormattedMessage id="campaign.accessSetting" />
                </DialogTitle>
                <DialogContent dividers>
                    <RequestFormPermissionSettingsTable
                        model={templateTab}
                        handleEditPermissionDialogOpen={handleEditPermissionDialogOpen}
                        checkboxLabel={checkboxLabel}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSave} color="primary" variant="contained">
                        <FormattedMessage id="common.save" />
                    </Button>
                    <Button onClick={handleClose} variant="text">
                        <FormattedMessage id="common.cancel" />
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
});
