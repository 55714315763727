import { makeStyles } from '@material-ui/core';

export const useQuotaPageStyles = makeStyles((theme) => {
    return {
        spinner: {
            minHeight: '500px',
            width: '800px',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        mainContainer: {
            paddingLeft: theme.spacing(10),
            paddingBottom: theme.spacing(8),
            maxWidth: '1000px',
        },
        mainContainerViewOnly: {
            paddingLeft: theme.spacing(10),
            paddingBottom: theme.spacing(8),
            '& *': {
                '& [class*=Mui-disabled]': {
                    opacity: 1,
                    '& input': {
                        color: theme.palette.text.primary,
                    },
                },
                '& [class*=MuiAutocomplete-endAdornment], & [class*=MuiOutlinedInput-adornedEnd]': {
                    '& svg': {
                        display: 'none',
                    },
                },
            },
        },
        formContainer: {
            maxWidth: '800px',
            '& > *': {
                '&:not(:last-child)': {
                    marginBottom: theme.spacing(4),
                },
            },
        },
        headingTitleWrapper: {
            maxWidth: '800px',
            paddingTop: theme.spacing(8),
            paddingBottom: theme.spacing(6),
            display: 'flex',
        },
        headingUploadDistributionLink: {
            display: 'flex',
            justifyContent: 'center',
            flex: 1,
        },
        popupIndicator: {
            marginRight: theme.spacing(1) + 1 + 'px',
        },
        datePickerLeft: {
            marginRight: theme.spacing(2),
            flex: 1,
            '& svg': {
                width: '28px',
                height: '28px',
            },
        },
        datePickerRight: {
            flex: 1,
            '& svg': {
                width: '28px',
                height: '28px',
            },
        },
        singleDatePicker: {
            '& svg': {
                width: '28px',
                height: '28px',
            },
        },
    };
});
