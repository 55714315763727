import { IconButton } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import KeyboardTabIcon from '@material-ui/icons/KeyboardTab';
import { PaginationItem, PaginationItemProps } from '@material-ui/lab';
import React from 'react';
import styled from 'styled-components';

export const ReversedKeyboardTabIcon = styled(KeyboardTabIcon)`
    transform: rotate(180deg);
`;

export const StyledIconButton = withTheme(
    styled(IconButton)`
        &:hover {
            color: ${({ theme }): string => theme.variables.palette.main};
            background-color: ${({ theme }): string => theme.variables.palette.hoverInLists};
        }
    `,
);

export const StyledPaginationItem = withTheme(styled(PaginationItem)`
    &:hover {
        background-color: ${({ theme }): string => theme.variables.palette.hoverInLists};
    }
`);

export const renderPaginationItem = (item: PaginationItemProps): JSX.Element => {
    const { disabled, onClick, type } = item;

    switch (type) {
        case 'first':
            return (
                <StyledIconButton disabled={disabled} onClick={onClick}>
                    <ReversedKeyboardTabIcon />
                </StyledIconButton>
            );
        case 'last':
            return (
                <StyledIconButton disabled={disabled} onClick={onClick}>
                    <KeyboardTabIcon />
                </StyledIconButton>
            );
        default:
            return <StyledPaginationItem {...item} />;
    }
};
