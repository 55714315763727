import { action, observable } from 'mobx';
import { CountryGroupsStore } from '../../store';
import { CountryGroup, CountryGroupDTO, FormikServiceCountryGroup } from '../../types';

export class CountryGroupsModel {
    @observable private countryGroupsStore: CountryGroupsStore;
    @observable isCountryGroupLoading: boolean = false;

    constructor(countryGroupsStore: CountryGroupsStore) {
        this.countryGroupsStore = countryGroupsStore;
    }

    @action.bound
    private mapFormikValuesToCountryGroup(countryGroupFromFormik: FormikServiceCountryGroup): CountryGroup | undefined {
        const { archived, name, formationPrinciple, countries } = countryGroupFromFormik;
        if (!formationPrinciple) {
            return;
        }
        return {
            archived,
            formationPrincipleCode: formationPrinciple.code,
            countryCodes: countries.map((country) => country.code),
            name,
        };
    }

    @action.bound
    async loadCountryGroup(id: string): Promise<CountryGroupDTO | undefined> {
        try {
            this.isCountryGroupLoading = true;
            return await this.countryGroupsStore.getCountryGroup(id);
        } finally {
            this.isCountryGroupLoading = false;
        }
    }

    @action.bound
    async createCountryGroup(values: FormikServiceCountryGroup): Promise<CountryGroupDTO | undefined> {
        const countryGroupPreparedToPost = this.mapFormikValuesToCountryGroup(values);
        if (!countryGroupPreparedToPost) {
            return Promise.reject();
        }
        return await this.countryGroupsStore.createCountyGroup(countryGroupPreparedToPost);
    }

    @action.bound
    async updateCountryGroup(values: FormikServiceCountryGroup, id: string): Promise<CountryGroupDTO | undefined> {
        const countryGroupPreparedToPost = this.mapFormikValuesToCountryGroup(values);
        if (!countryGroupPreparedToPost) {
            return Promise.reject();
        }
        return await this.countryGroupsStore.updateCountryGroup(countryGroupPreparedToPost, id);
    }

    @action.bound
    mapCountryGroupDTOToFormikValues(countryGroupDTO: CountryGroupDTO): FormikServiceCountryGroup {
        const { archived, formationPrinciple, name, countries } = countryGroupDTO;
        return {
            archived,
            name,
            countries,
            formationPrinciple,
        };
    }
}
