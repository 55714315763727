import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

export const useStateBoxStyles = makeStyles((theme) => {
    return {
        stateBox: {
            backgroundColor: `rgba(53, 167, 255, 0.09)`,
            borderRadius: 4,
            padding: `${theme.spacing(3.25)}px ${theme.spacing(4.25)}px ${theme.spacing(3.25)}px ${theme.spacing(4)}px`,
            display: 'inline-block',
        },
        stateTypography: {
            fontWeight: 'bold',
        },
    };
});

export type StateBoxProps = PropsWithChildren<{
    isBold?: boolean;
}>;

export const StateBox = (props: StateBoxProps): JSX.Element => {
    const { children, isBold = true } = props;
    const classes = useStateBoxStyles();
    return (
        <Box className={classes.stateBox}>
            <Typography
                variant="body2"
                className={classNames({
                    [classes.stateTypography]: isBold,
                })}
            >
                {children}
            </Typography>
        </Box>
    );
};
