const campaign: Record<string, string | Record<string, string>> = {
    titleCampaignListPage: 'Services',
    noCategoriesText: 'There are currently no active services to apply for',
    newCampaign: 'New service',
    campaignPeriod: 'Service period',
    titleCreateCampaignPage: 'Create service',
    createCampaign: 'Create service',
    editCampaign: 'Edit service',
    campaignRequests: 'Service statements',
    campaignSettings: 'Service settings',
    create: 'Create',
    sections: 'Sections',
    description: 'Description: ',
    files: 'Files',
    tabsSettings: 'Tabs settings',
    pfTemplates: 'PF templates',
    noTemplates: 'No templates configured',

    campaignDescription: 'Service description',
    campaignDescriptionEdit: 'Service editing',

    campaignRegForms: 'Statement forms',
    createRequest: 'Create statement',
    campaignRegForm: 'Statement {count, plural, one {form} other {forms}}',
    addCampaignRegFormButton: 'Add statement form',
    newCampaignRegForm: 'New statement form',
    editCampaignRegForm: 'Editing the "{formTitle}" statement form',
    deleteCampaignRegForm: 'Delete statement form',
    confirmDeleteCampaignRegForm: 'Are you sure you want to delete the statement form "{formTitle}"?',
    regFormInfo: 'Primary information',
    regFormExecutors: 'Responsible executors',

    templatesAndExport: 'Templates and export',
    campaignManagement: 'Service management',
    export: 'Export',
    templates: 'Templates',
    addTemplate: 'Add template',
    editTemplate: 'Edit template',
    editPermission: 'Edit permission',
    generate: 'Generate',
    accessSetting: 'Access setting',
    confirmDeletionTemplateInfoText: 'Are you sure you want to delete the template "{title}"?',
    lifecycle: 'Lifecycle',
    tabSettingForm: {
        title: "Tab's title",
        identifier: 'Identifier',
        parentTab: 'Parent tab',
        formCode: "Form's code",
        fileSize: 'The size of the package of uploaded documents in MB',
        executor: 'Responsible expert',
        access: 'Access',
        edit: 'Edit',
        view: 'View',
    },
    tabSettingFormTable: {
        role: 'Role',
        status: 'Lifecycle status',
        access: 'Access',
        allStatuses: 'All statuses',
    },
    templateForm: {
        title: 'Title',
        description: 'Description',
        regForms: 'Statement forms',
        file: 'Template file',
        templateIsRequired: 'Template is required for generation',
        includeRequestNumber: 'Include request number in the title',
        identifier: 'Template identifier for the PF',
    },
    templateFormErrors: {
        notUniqueTemplateName: 'Template name must be unique',
        executorsNotAllowed:
            'This method of appointment is not available, since the method of assigning persons in charge has been selected in fact',
    },
    documentCategory: 'Document category',
    tabs: 'Tabs',

    campaignManagementDelete: 'Are you sure you want to delete the service "{campaignName}"?',
    deleteCampaign: 'Delete Service',
    campaignTitle: 'Service management {campaignName}',
    generalSettings: 'General Settings',

    requestFormSettingsTitle: 'Setting for statement tabs',
    campaignRegFormExecutorRequestTab: 'Statements tab',
    requestTabLabel: 'Statement tab',
    campaignRegFormExecutorResponsibleExecutor: 'Responsible expert',
    requestFormSetting: 'Tab setting',
    createRequestFormSettings: 'Create tab',
    confirmDeletionRequestFormSettingInfoText: 'Are you sure you want to delete the tab "{title}"?',
    requestFormSettings: {
        title: 'Title',
        formTitle: 'Form',
    },

    fileFormat: 'Format',
    campaignRegFormExecutorEmployee: 'Employee',

    campaignRegFormExecutorAdd: 'Add',

    confirmDeletionInfoText: 'Are you sure you want to remove the responsible executive {executorName}?',

    employeeLabel: 'Employee',

    collectionCompleted: 'Collection completed',
    createRequestError:
        'You have already created an statement for this service. To view and edit, go to the statements tab',
};

export default campaign;
