import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { observer } from 'mobx-react-lite';
import React, { PropsWithChildren, ReactNode } from 'react';

export type CampaignRequestChecksAccordionProps = PropsWithChildren<{
    accordionTitle: ReactNode;
}>;

export const useCampaignRequestChecksAccordionStyles = makeStyles((theme) => {
    const { spacing } = theme;
    return {
        accordion: {
            boxShadow: 'none',
            '& .MuiAccordionSummary-root': {
                paddingLeft: `${spacing(3)}px`,
                paddingRight: `${spacing(4)}px`,
                paddingTop: `${spacing(4.5)}px`,
                paddingBottom: `${spacing(1.5)}px`,
                minHeight: 0,
            },
            '&.Mui-expanded, & .Mui-expanded': {
                marginTop: '0',
                marginBottom: '0',
            },
            '& .MuiSvgIcon-root': {
                color: '#C7C7C7',
            },
            '&::before': {
                opacity: 0,
            }
        },
        accordionSummary: {
            '& .MuiAccordionSummary-content': {
                margin: '0',
            },
        },
        accordionSummaryText: {
            fontWeight: 700,
            fontSize: '16px',
            lineHeight: '19px',
            color: theme.variables.palette.textDark,
        },
        accordionDetails: {
            display: 'block',
            padding: '0',
        },
    };
});

export const RequestChecksTabAccordion = observer((props: CampaignRequestChecksAccordionProps): JSX.Element => {
    const { accordionTitle, children } = props;
    const classes = useCampaignRequestChecksAccordionStyles();
    return <Accordion className={classes.accordion} defaultExpanded>
        <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.accordionSummaryText}>
                {accordionTitle}
            </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
            {children}
        </AccordionDetails>
    </Accordion>
});
