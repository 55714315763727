import { TableBody } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { Draggable, DroppableProvided } from 'react-beautiful-dnd';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useStore } from '../../../../../../hooks';
import { RequestFormSettingListDTO } from '../../../../../../store';
import { CampaignRegFormRouteParams } from '../../types';
import { renderDraggableRequestFormTabsSettingsTableRow } from './DraggableRequestFormTabsSettingsTableRow';

export type RequestFormTabsSettingsDroppableTableBodyType = {
    droppableProvided: DroppableProvided;
    settings: RequestFormSettingListDTO[];
    deleteSetting: (settingId: string) => () => Promise<void>;
};

export const RequestFormTabsSettingsDroppableTableBody = observer(
    (props: RequestFormTabsSettingsDroppableTableBodyType): JSX.Element => {
        const { droppableProvided, settings, deleteSetting } = props;
        const { id, rfId } = useParams<CampaignRegFormRouteParams>();
        const intl = useIntl();
        const rootStore = useStore();

        const draggableRows = settings.map((setting, index) => {
            const { id: settingId } = setting;
            const draggableRow = renderDraggableRequestFormTabsSettingsTableRow(
                setting,
                id,
                rfId,
                deleteSetting,
                intl,
                rootStore.services,
            );

            return (
                <Draggable draggableId={settingId} index={index} key={settingId}>
                    {draggableRow}
                </Draggable>
            );
        });

        return (
            <TableBody {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
                {draggableRows}
                {droppableProvided.placeholder}
            </TableBody>
        );
    },
);
