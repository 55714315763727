import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import { useStore } from '../../../hooks';
import { RegFormListModel } from '../../../models';
import { RegFormSelect } from './RegFormSelect';
import { RegFormSelectPageRouteParams } from './types';

export const RegFormSelectPage = observer((): JSX.Element => {
    const { requestStore, api, notificationStore } = useStore();
    const { campaignId } = useParams<RegFormSelectPageRouteParams>();
    const [regFormListModel, setRegFormListModel] = useState<RegFormListModel | null>();
    const history = useHistory();

    useEffect(() => {
        const model = new RegFormListModel(api, campaignId);
        setRegFormListModel(model);
    }, [api, campaignId]);

    const createRequest = (regFormId: string): (() => Promise<void>) => {
        return (): Promise<void> => {
            return requestStore
                .createRequest(regFormId)
                .then((id: string) => {
                    history.push(generatePath(clientRoute.requestEdit, { campaignId, regFormId, id }));
                })
                .catch((error) => {
                    notificationStore.onError(error.response.data);
                });
        };
    };

    if (!regFormListModel) {
        return <React.Fragment></React.Fragment>;
    }

    return <RegFormSelect regFormListModel={regFormListModel} createRequest={createRequest} />;
});
