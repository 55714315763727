import { Button, Container, Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, NavLink, Route, Switch } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';
import { StickyWrapper, TotObjectActionsBar } from '../../components';
import { UserPersonModel } from '../../models';

export type UserControlPanelProps = {
    userPersonModel: UserPersonModel;
    saveForm: () => void;
};

export const UserControlPanel = observer((props: UserControlPanelProps): JSX.Element => {
    const { userPersonModel, saveForm } = props;
    const { id } = userPersonModel;

    return (
        <StickyWrapper mode="bottom">
            <TotObjectActionsBar>
                <Container maxWidth="lg">
                    <Grid alignItems="center" container>
                        <Grid item>
                            <Grid spacing={3} alignItems="center" justify="center" container>
                                <Switch>
                                    <Route exact path={clientRoute.user}>
                                        <Grid item>
                                            <Button
                                                component={NavLink}
                                                to={generatePath(clientRoute.userEdit, { id })}
                                                size="small"
                                                color="primary"
                                                variant="contained"
                                            >
                                                <FormattedMessage id="common.edit" />
                                            </Button>
                                        </Grid>
                                    </Route>
                                </Switch>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid spacing={3} alignItems="center" justify="center" container>
                                <Switch>
                                    <Route exact path={clientRoute.userEdit}>
                                        <Grid item>
                                            <Button color="primary" variant="contained" onClick={saveForm}>
                                                <FormattedMessage id="common.save" />
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                color="secondary"
                                                variant="contained"
                                                component={NavLink}
                                                to={generatePath(clientRoute.user, { id })}
                                            >
                                                <FormattedMessage id="common.discardChanges" />
                                            </Button>
                                        </Grid>
                                    </Route>
                                </Switch>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </TotObjectActionsBar>
        </StickyWrapper>
    );
});
