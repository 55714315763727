import { createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SelectCheckbox } from '../../../../../../components';
import { CodeTitle } from '../../../../../../models';
import { ReactComponent as DeleteChipIcon } from '../../../../../../resources/images/icons/deleteChipIcon.svg';
import { checkboxProps } from '../../../../../campaign-request';

export const useAccessPermissionStyles = makeStyles(() =>
    createStyles({
        editPermission: {
            padding: 0,
        },
    }),
);

export const renderOptionCampaignRegForm = (option: CodeTitle, { selected }: { selected: boolean }): JSX.Element => {
    return (
        <React.Fragment>
            <SelectCheckbox {...checkboxProps} checked={selected} />
            {option.title}
        </React.Fragment>
    );
};

export const noOptionsText = <FormattedMessage id="common.noData" />;
export const editMessage = <FormattedMessage id="campaign.tabSettingForm.edit" />;
export const roleMessage = <FormattedMessage id="campaign.tabSettingFormTable.role" />;
export const statusMessage = <FormattedMessage id="campaign.tabSettingFormTable.status" />;

export const chipProps = {
    deleteIcon: <DeleteChipIcon />,
    size: 'small',
};
