import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';
import { Authentication } from '../../components';
import { AuthorityDocListPage } from './authority-doc-list-page';
import { AuthorityDocPage } from './authority-doc-page';

export const AuthorityDocPages = (): JSX.Element => {
    return (
        <Authentication>
            <Switch>
                <Route exact path={clientRoute.authorityDoc}>
                    <AuthorityDocPage />
                </Route>
                <Route exact route={clientRoute.authorityDocs}>
                    <AuthorityDocListPage />
                </Route>
            </Switch>
        </Authentication>
    );
};
