export enum CatalogName {
    categoryOptions = 'categoryOptions',
    typeOptions = 'typeOptions',
    CNOptions = 'CNOptions',
    operationOptions = 'operationOptions',
    measurementUnitOptions = 'measurementUnitOptions',
    countries = 'countries',
    formationPrinciples = 'formationPrinciples',
    countryGroup = 'countryGroup',
}
