import {
    Box,
    Button,
    Checkbox,
    Container,
    FormControlLabel,
    Grid,
    IconButton,
    Link,
    Paper,
    Typography,
    withTheme,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import classNames from 'classnames';
import { useFeature } from 'feature-toggle-jsx';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, Link as RouterLink, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { clientRoute } from '../../clientRoute';
import { EmailField, PasswordField } from '../../components';
import { useLoginRedirect, useStore } from '../../hooks';
import { CodeTitle, LoginModel } from '../../models';

const LoginCheckbox = withTheme(styled(Checkbox)`
    padding: 2px !important;
`);

const useLoginDialogStyles = makeStyles(() =>
    createStyles({
        container: {
            padding: 0,
        },
    }),
);

export const LoginDialog = observer((): JSX.Element => {
    const [redirectToCampaignsAllUsers] = useFeature('redirectToCampaignsAllUsers');

    const { authenticationStore, personStore } = useStore();
    const loginModel = useMemo<LoginModel>(() => authenticationStore.loginModel, [authenticationStore]);
    const { loginFailedMessage } = loginModel;

    const [isRedirect, backUrl] = useLoginRedirect();

    const [esiaLogin] = useFeature('esiaLogin');
    const [loginDialogClose] = useFeature('loginDialogClose');
    const [loginDialogAlert] = useFeature('loginDialogAlert');

    const [samlAuth, samlAuthConfig] = useFeature('samlAuth');
    const backUrlParam = backUrl ? `?backUrl=${backUrl}` : '';
    const redirectCampaignsOrRolePath = redirectToCampaignsAllUsers ? clientRoute.campaigns : personStore.redirectPath;
    const redirectPath = Boolean(backUrl) ? backUrl : redirectCampaignsOrRolePath;

    const classes = useLoginDialogStyles();

    return (
        <React.Fragment>
            <Grid container item direction="column" alignItems="center" justify="center" style={{ zIndex: 2 }}>
                <Container className={classNames('t-registration-page', classes.container)} maxWidth="xs">
                    <Paper elevation={0}>
                        <Box {...(!loginDialogClose && { pt: 8 })} px={12} pb={12}>
                            {loginDialogClose && (
                                <Box pt={4} mr={-8}>
                                    <Grid container justify="flex-end">
                                        <Grid item>
                                            <IconButton className="t-close-login" component={RouterLink} to="/">
                                                <Close />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Box>
                            )}
                            <Grid container justify="center">
                                <Grid item>
                                    <Typography variant="h5" className="t-login-title">
                                        <Box fontWeight="fontWeightBold">
                                            <FormattedMessage id="authentication.loginTitle" />
                                        </Box>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Box pt={8}>
                                <form noValidate onSubmit={loginModel.login}>
                                    <Grid container spacing={6} direction="column">
                                        {loginFailedMessage && (
                                            <Grid item>
                                                <Typography className="t-login-failed-message" color="error">
                                                    {loginFailedMessage}
                                                </Typography>
                                            </Grid>
                                        )}
                                        <Grid item>
                                            <EmailField model={loginModel} />
                                        </Grid>
                                        <Grid item>
                                            <PasswordField model={loginModel} />
                                        </Grid>
                                        <Grid item>
                                            <FormControlLabel
                                                value="top"
                                                control={
                                                    <LoginCheckbox
                                                        className="t-remember-me"
                                                        color="primary"
                                                        onChange={loginModel.onChangeRememberMe}
                                                    />
                                                }
                                                label={<FormattedMessage id="authentication.rememberMe" />}
                                                labelPlacement="end"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                className="t-login"
                                                fullWidth
                                                size="large"
                                                variant="contained"
                                                type="submit"
                                                color="primary"
                                            >
                                                <FormattedMessage id="authentication.login" />
                                            </Button>
                                        </Grid>
                                        {esiaLogin && (
                                            <Grid item>
                                                <Link
                                                    className="t-esia-login"
                                                    component={RouterLink}
                                                    to={clientRoute.esiaGetAuthUrl}
                                                    underline="none"
                                                >
                                                    <FormattedMessage id="authentication.loginEsia" />
                                                </Link>
                                            </Grid>
                                        )}
                                        {samlAuth &&
                                            samlAuthConfig.providers.map((provider: CodeTitle) => {
                                                return (
                                                    <Grid item key={provider.code}>
                                                        <Link
                                                            className="t-saml-login"
                                                            component={RouterLink}
                                                            to={
                                                                generatePath(clientRoute.samlGetAuthUrl, {
                                                                    provider: provider.code,
                                                                }) + backUrlParam
                                                            }
                                                            underline="none"
                                                        >
                                                            <FormattedMessage
                                                                id="authentication.samlAuth"
                                                                values={{ title: provider.title }}
                                                            />
                                                        </Link>
                                                    </Grid>
                                                );
                                            })}
                                        <Grid item container justify="space-between">
                                            <Grid item>
                                                <Link
                                                    className="t-forgot-password"
                                                    component={RouterLink}
                                                    to={clientRoute.recoveryPassword}
                                                    underline="none"
                                                >
                                                    <FormattedMessage id="authentication.forgotPassword" />
                                                </Link>
                                            </Grid>
                                            <Grid item>
                                                <Link
                                                    className="t-registration"
                                                    component={RouterLink}
                                                    to={clientRoute.registration}
                                                    underline="none"
                                                >
                                                    <FormattedMessage id="authentication.register" />
                                                </Link>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Box>
                        </Box>
                        {loginDialogAlert && (
                            <Alert severity="info">
                                <FormattedMessage id="authentication.alertInfo" />
                            </Alert>
                        )}
                    </Paper>
                </Container>
            </Grid>
            {isRedirect && <Redirect push to={redirectPath} />}
        </React.Fragment>
    );
});
