import { action, computed, IObservableArray, observable } from 'mobx';
import { Filter } from '../Filter';
import { ChangeEvent } from 'react';

export type InSetFilterValue = IObservableArray<string>;

export class InSetFilter extends Filter<InSetFilterValue> {
    constructor(defaultValue: string[] = []) {
        super(observable.array(defaultValue));
    }
    checkActive(value: InSetFilterValue): boolean {
        return value.length > 0;
    }

    @action.bound
    onChange(e: ChangeEvent<{}>, value: string[]): void {
        this.value = observable.array(value as string[]);
    }

    @action.bound
    clear(): void {
        this.value = observable.array();
    }

    @computed
    get values(): string[] {
        return this.value.toJSON();
    }

    @computed
    get asJson(): string[] {
        return this.values;
    }
}
