import { TextField, TextFieldProps } from '@material-ui/core';
import { AutocompleteRenderInputParams } from '@material-ui/lab';
import React from 'react';

export const getTextFieldRenderFunction = (
    textFieldProps: TextFieldProps,
): ((params: AutocompleteRenderInputParams) => JSX.Element) => {
    const { label, error, helperText, name, required } = textFieldProps;
    return (params) => (
        <TextField
            {...params}
            label={label}
            error={error}
            helperText={helperText}
            name={name}
            variant="outlined"
            required={required}
            size="medium"
        />
    );
};
