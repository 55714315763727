import { Button, Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { clientRoute } from '../../../../clientRoute';
import { withConfirmDialog } from '../../../../hocs';
import { useStore } from '../../../../hooks';
import { RouteParamsDefault } from '../../../../types';
import { CountryGroupsEditButton } from './CountryGroupsEditButton';

export const DeleteCountryGroupsButton = withConfirmDialog(({ openConfirmDialog }): JSX.Element => {
    const { intlStore: intl } = useStore();
    const deleteRequest = intl.formatMessage('campaignRequest.deleteRequest');

    return (
        <Button onClick={openConfirmDialog} color="secondary" size="small" variant="contained">
            {deleteRequest}
        </Button>
    );
});

export const CountryGroupsViewModeButtons = observer((): JSX.Element => {
    const history = useHistory();

    const { id } = useParams<RouteParamsDefault>();

    const redirectToEditing = (): void => {
        history.push(
            generatePath(clientRoute.countryGroupEdit, {
                id,
            }),
        );
    };

    return (
        <Grid spacing={3} alignItems="center" justify="center" container>
            <Grid item>
                <CountryGroupsEditButton onEdit={redirectToEditing} />
            </Grid>
        </Grid>
    );
});
