import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Portal, TextField } from '@material-ui/core';
import { AxiosError } from 'axios';
import { observer } from 'mobx-react';
import React, { ChangeEvent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAntiDoubleClick, useStore } from '../../hooks';
import { Transition } from '../../models';
import { textFieldProps } from '../../pages';

export type ThirdCharDialogProps = {
    objectId: string;
    isModalOpen: boolean;
    setModalIsClosed: () => void;
    transition: Transition;
    lifeCycleTransition: () => Promise<void>;
    handleSuccessTransition: () => void;
    handleErrorTransition: (error: AxiosError) => void;
};

export const ThirdCharDialog = observer((props: ThirdCharDialogProps): JSX.Element => {
    const { transition, isModalOpen, setModalIsClosed, handleErrorTransition, handleSuccessTransition, objectId } =
        props;

    const { licenseStore } = useStore();

    const [thirdCharValue, setThirdCharValue] = useState<number>(0);

    const handleConfirm = (): Promise<void> => {
        return licenseStore
            .setThirdCharLicenseNumberAndChangeState(transition.id, objectId, thirdCharValue)
            .then(handleSuccessTransition)
            .catch(handleErrorTransition)
            .finally(setModalIsClosed);
    };

    const handleChangeThirdChar = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        const value = +e.target.value;

        if (value.toString().length > 1) {
            return;
        }

        setThirdCharValue(value);
    };

    const [isSending, startIcon, confirmHandler] = useAntiDoubleClick(handleConfirm);

    return (
        <Portal>
            <Dialog fullWidth={true} maxWidth="xs" open={isModalOpen} keepMounted={true}>
                <DialogTitle>
                    <FormattedMessage id="campaignRequest.thirdChar" />
                </DialogTitle>
                <DialogContent>
                    <TextField {...textFieldProps} value={thirdCharValue} onChange={handleChangeThirdChar} required />
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={setModalIsClosed} color="primary">
                        <FormattedMessage id="common.cancel" />
                    </Button>
                    <Button
                        onClick={confirmHandler}
                        color="secondary"
                        variant="contained"
                        disabled={isSending}
                        startIcon={startIcon}
                    >
                        <FormattedMessage id="common.confirm" />
                    </Button>
                </DialogActions>
            </Dialog>
        </Portal>
    );
});
