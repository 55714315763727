import React from 'react';
import { Box, Container, Grid } from '@material-ui/core';
import { BreadcrumbsComponent, breadCrumbsSettings } from '../../../components';
import { CountryGroupsList } from './country-groups-list';

const containerStyles: React.CSSProperties = { width: '100%' };

export const CountryGroupsListPage = (): JSX.Element => {
    return (
        <Container maxWidth="lg">
            <Box pt={5.5} pb={15}>
                <Box pb={5}>
                    <BreadcrumbsComponent breadcrumbsSettings={breadCrumbsSettings} />
                </Box>
                <Grid container direction="column">
                    <Grid item style={containerStyles}>
                        <CountryGroupsList />
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};
