import { Box, Typography } from '@material-ui/core';
import { flkValidateReadonlyPage, Form, FormView } from '@platform/formiojs-react';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Route, useParams } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import { useStore } from '../../../hooks';
import { useObjectRoutesContext } from '../../../hooks';
import { RequestModel } from '../../../models';
import { RequestFormParams } from '../../../types';
import { RequestChecksTab } from './campaign-request-checks';
import { CampaignRequestPrintForms } from './campaign-request-page-print-forms';
import { RequestLogTab } from './RequestLogTab';

export type CampaignRequestFormProps = {
    requestModel: RequestModel;
    setFormIsChanged: (formIsChanged: boolean) => void;
    requestNumber: string;
};

export const CampaignRequestForm = observer((props: CampaignRequestFormProps): JSX.Element => {
    const { requestModel, setFormIsChanged, requestNumber } = props;
    const {
        onFormReady,
        onFormChange,
        currentTab: model,
        isFormReady,
        formApi,
        validateEditPage,
        validateReadonlyPage,
    } = requestModel;

    const rootStore = useStore();
    const { intlStore, requestStore } = rootStore;

    const { locale } = intlStore;
    const { id, tabId, subTabId } = useParams<RequestFormParams>();
    const formId = subTabId || tabId;

    const objectRoutes = useObjectRoutesContext();

    const [canGenerate, setCanGenerate] = useState<boolean>(false);

    useEffect(() => {
        requestModel.setCurrentTab(formId);
        requestStore
            .checkEditablePf(formId)
            .then(() => setCanGenerate(true))
            .catch(() => setCanGenerate(false));

        return () => {
            requestModel.currentTab = undefined;
            requestModel.resetFirstTabId();
        };
    }, [requestStore, formId, requestModel, id]);

    useEffect(() => {
        if (formApi) {
            requestStore.flkValidateFields(formId).then((result) => {
                // setTimeout нужен для того, чтобы функция выполнилась немного позже,
                // так как форма не успевает отрисовываться, а уже начинает валидироваться
                // следствие -->
                // периодичность показа результата (форма успела отрисоваться и в нее попал результат валидации)
                setTimeout(
                    () =>
                        flkValidateReadonlyPage({
                            formApi,
                            errors: result.errors?.fields,
                        }),
                    500,
                );
            });
        }
    }, [formApi, formId]);
    if (!model) {
        return <React.Fragment />;
    }

    const { formModel, title } = model;
    const handleFormReady = onFormReady(formId);

    return (
        <React.Fragment>
            {subTabId && (
                <Box mb={2}>
                    <Typography variant="h3" component="h2">
                        {title}
                    </Typography>
                </Box>
            )}
            <Route path={[objectRoutes.objectSubTabEdit, objectRoutes.objectTabEdit]} exact>
                <Form
                    form={formModel}
                    onFormReady={handleFormReady}
                    onFormChange={onFormChange}
                    setFormIsChanged={setFormIsChanged}
                />
            </Route>
            <Route path={[objectRoutes.objectSubTab, objectRoutes.objectTab]} exact>
                <RequestChecksTab />
                <RequestLogTab requestNumber={requestNumber} />
                <FormView locale={locale} form={formModel} onFormReady={handleFormReady} className="print-form" />
                <CampaignRequestPrintForms
                    isShown={isFormReady}
                    formApi={formApi}
                    canGenerate={canGenerate}
                    validateEditPage={validateEditPage}
                    validateReadonlyPage={validateReadonlyPage}
                    requestNumber={requestNumber}
                />
            </Route>
        </React.Fragment>
    );
});
