import React from 'react';
import {
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Grid,
    TextField,
    Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ExpandMore } from '@material-ui/icons';
import { UserListModel } from '../../../models/users/UserListModel';
import { textFieldProps } from '../../campaign-request/campaign-request-list-page/campaign-requests-list-filters/CampaignRequestsFilterProps';

export type UserListFilterPanelProps = {
    model: UserListModel;
};

export const UserListFilterPanel = observer(
    (props: UserListFilterPanelProps): JSX.Element => {
        const { model } = props;
        const { filtering } = model;
        const intl = useIntl();

        return (
            <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                    <Typography variant="subtitle2">
                        <FormattedMessage id="common.filters" />
                    </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Grid container direction="column" spacing={6}>
                        <Grid item container spacing={6}>
                            <Grid item xs={6}>
                                <TextField
                                    {...textFieldProps}
                                    label={<FormattedMessage id="users.fields.login" />}
                                    placeholder={intl.formatMessage({ id: 'common.all' })}
                                    value={filtering.login.value}
                                    onChange={filtering.login.onChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    {...textFieldProps}
                                    label={<FormattedMessage id="users.fields.name" />}
                                    placeholder={intl.formatMessage({ id: 'common.all' })}
                                    value={filtering.name.value}
                                    onChange={filtering.name.onChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    },
);
