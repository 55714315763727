import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@material-ui/core';

export type QuotasEditButtonProps = {
    onEdit: () => void;
};

export const QuotasEditButton = (props: QuotasEditButtonProps): JSX.Element => {
    const { onEdit } = props;

    return (
        <Button onClick={onEdit} color="primary" size="small" variant="contained">
            <FormattedMessage id="campaignRequest.openEditMode" />
        </Button>
    );
};
