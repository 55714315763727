import { action, computed, observable } from 'mobx';
import { ApiStore, IntlStore } from '../store';
import { asyncAction } from 'mobx-utils';
import { ChangeEvent, FormEvent } from 'react';
import { apiConfigs } from '../apiConfigs';
import { PasswordModel } from './fields';

export interface NewPasswordDTO {
    token?: string;
    newPassword: string;
}

export class NewPasswordModel {
    @observable api: ApiStore;
    @observable intl: IntlStore;

    @observable token?: string;

    @observable newPassword = '';
    @observable repeatPassword = '';

    @observable validationStarted = false;
    @observable status?: 'success' | 'failed';

    @observable isTokenValid?: boolean;

    constructor(api: ApiStore, intlStore: IntlStore) {
        this.api = api;
        this.intl = intlStore;
    }

    @action.bound
    onChangeNewPassword(event: ChangeEvent<HTMLInputElement>): void {
        this.newPassword = event.target.value;
    }

    @action.bound
    onChangeRepeatPassword(event: ChangeEvent<HTMLInputElement>): void {
        this.repeatPassword = event.target.value;
    }

    @asyncAction
    @action.bound
    async updatePassword(event: FormEvent<HTMLFormElement>): Promise<void> {
        event.preventDefault();
        this.validationStarted = true;
        if (this.isValid) {
            try {
                await this.api.client(apiConfigs.resetPassword(this.asJson));
                this.status = 'success';
            } catch (e) {
                this.status = 'failed';
            }
        }
    }

    @asyncAction
    @action.bound
    async validateToken(): Promise<void> {
        if (this.token) {
            try {
                await this.api.client(apiConfigs.checkIsPasswordRecoveryTokenValid(this.token));
                this.isTokenValid = true;
            } catch {
                this.isTokenValid = false;
            }
        }
    }

    @computed
    get asJson(): NewPasswordDTO {
        return {
            token: this.token,
            newPassword: this.newPassword,
        };
    }

    @computed
    get newPasswordModel(): PasswordModel {
        return {
            errorPassword: this.errorNewPassword,
            onChangePassword: this.onChangeNewPassword,
        };
    }

    @computed
    get repeatPasswordModel(): PasswordModel {
        return {
            errorPassword: this.errorRepeatPassword,
            onChangePassword: this.onChangeRepeatPassword,
        };
    }

    @computed
    get changedSucceed(): boolean {
        return this.status === 'success';
    }

    @computed
    get errorNewPassword(): string {
        const minLength = 8;
        if (this.validationStarted && this.newPassword.length < minLength) {
            return this.intl.formatMessage('validation.minLength', { length: minLength });
        }
        return '';
    }

    @computed
    get errorRepeatPassword(): string {
        if (this.validationStarted) {
            if (this.newPassword !== this.repeatPassword) {
                return this.intl.formatMessage('authentication.passwordsMustMatch');
            }
            if (!this.repeatPassword) {
                return this.intl.formatMessage('validation.required');
            }
        }
        return '';
    }

    @computed
    get failedMessage(): string {
        if (this.status === 'failed') {
            return this.intl.formatMessage('authentication.linkInvalidOrExpired');
        }
        return '';
    }

    @computed
    get isValid(): boolean {
        return !this.errorNewPassword && !this.errorRepeatPassword;
    }
}
