import { Button, Grid } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { entities, permissions } from '../../../../../authSchemeConfig';
import { AuthorizationCheck } from '../../../../../components';
import { useStore } from '../../../../../hooks';
import { RequestFormParams } from '../../../../../types';

export const RequestFTSExecutionInfoButton = observer((): JSX.Element => {
    const { id } = useParams<RequestFormParams>();
    const { requestStore } = useStore();

    const [isButtonEnabled, setIsButtonEnabled] = useState<boolean>(false);
    const [isButtonShown, setIsButtonShown] = useState<boolean>(false);

    const checkIfButtonIsEnabled = (): void => {
        requestStore.checkFTSButtonVisibility(id).then((isEnabled) => {
            setIsButtonEnabled(isEnabled);
        });
    };

    const triggerRequestingFTSExecutionInfo = (): void => {
        setIsButtonEnabled(false);
        requestStore.triggerRequestFTSExecutionInfo(id);
    };

    useEffect(() => {
        if (isButtonShown) {
            checkIfButtonIsEnabled();
        }
    }, [isButtonShown]);

    return (
        <AuthorizationCheck
            entityCode={entities.CampaignRequest}
            permCode={permissions.CampaignRequest.PublishFTS}
            entityId={id}
        >
            {(allowed) => {
                allowed && setIsButtonShown(allowed);
                return allowed ? (
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={triggerRequestingFTSExecutionInfo}
                            disabled={!isButtonEnabled}
                        >
                            <FormattedMessage id="campaignRequest.requestFTSExecutionInfo" />
                        </Button>
                    </Grid>
                ) : (
                    <React.Fragment />
                );
            }}
        </AuthorizationCheck>
    );
});
